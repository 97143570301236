import React, { Component } from "react";
import { Text, View, ImageBackground,Image } from "react-native";

export default class HomeFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  render() {
    return (
      <View>
         {this.props.brokerageURL != '' && this.props.brokerageURL &&
              <View style={{
                  display:'flex',
                  flexDirection:'row',
                  paddingTop: 20,
                  justifyContent:'center',
                  alignItems:'center',
                }}>
                    <Image
                      style={{
                        resizeMode:'contain',
                        height:60,
                        width:60,
                        marginLeft: 5,
                      }}
                      source={{uri:this.props.brokerageURL}}
                      />
                  <View style={{ padding:10 }}>
                    <Text style={{fontSize: 12,
                      fontWeight: 'bold',
                      color: "white",
                      }}>powered by</Text>
                    <Text style={{
                      fontSize: 18,
                      fontWeight: 'bold',
                      color: "white",
                    }}>KeyPleaz<Text style={{fontSize: 13}}>®</Text></Text>
                  </View>
              </View>
           }
        <ImageBackground
          source={require("../../assets/bottom_bg.png")}
          style={{
            height: (this.props.brokerageURL != '' && this.props.brokerageURL) ? 107 : 100,
            width: "100%",
            resizeMode: "contain",
            alignItems: "center",
          }}
        >
          <View style={{ flexDirection: "row", marginTop: 10 }}>
            <Text style={{ color: "white", fontSize: 25, fontWeight: "700" }}>
              See{" "}
            </Text>
            <Text style={{ color: "#A3C7E3", fontSize: 25, fontWeight: "700" }}>
              it fast.
            </Text>
            <Text style={{ color: "white", fontSize: 25, fontWeight: "700" }}>
              Sell{" "}
            </Text>
            <Text style={{ color: "#A3C7E3", fontSize: 25, fontWeight: "700" }}>
              it fast.
            </Text>
          </View>
        </ImageBackground>
        <View
          style={{ backgroundColor: "#4990C8E5", width: "100%", height: 10 }}
        />
      </View>
    );
  }
}
