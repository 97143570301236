import React from "react";
import { StyleSheet, Text, TouchableWithoutFeedback, View } from "react-native";
import Checkbox from "./Checkbox";

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
  },
  text: {
    lineHeight: 32,
    marginLeft: 10,
  },
});

export default function (props) {
  return (
    <View style={[props.style, styles.container]}>
      <TouchableWithoutFeedback onPress={props.onPress}>
        <Checkbox onPress={props.onPress} checked={props.checked} />
      </TouchableWithoutFeedback>
      <TouchableWithoutFeedback onPress={props.onPress}>
        <Text style={[props.textStyle, styles.text]}>{props.text}</Text>
      </TouchableWithoutFeedback>
    </View>
  );
}
