import { SET_ERRORS, CLEAR_ERRORS } from "./actions";

const initialState = {
  errors: [],
};

// Reducers (Modifies The State And Returns A New State)
const errorsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ERRORS: {
      return {
        ...state,
        errors: action.errors,
      };
    }
    case CLEAR_ERRORS: {
      return {
        ...state,
        errors: [],
      };
    }
    default: {
      return state;
    }
  }
};

export default errorsReducer;
