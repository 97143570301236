import { store } from "./store";
import { setErrors } from "./errors/actions";
import { setMasageFromScreen } from "./auth/actions";
// import NavigationService from "../NavigationService";
import LogsService from "../LogsService";
import { Alert, Platform } from "react-native";
// var RNFS = require('react-native-fs');


export const BASE_URL = "";
export const doPost = (url, body, headers) => {

  const state = store.getState();  
  // LogsService.fileLog('api.js','doPost URL=', state.app.APIBaseURL + url);
  return fetch(state.app.APIBaseURL + url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...authHeadersFromState(state),
      ...(headers || {}),
    },
    body: JSON.stringify(body),
  })
    .then((response) => {
      checkAuth(response, url);
      return response.json();
    })
    .then((response) => {
      if (response.status === "error") {
        store.dispatch(setErrors(response.errors));
      }
      return response;
    })
    .catch((error) => {
      store.dispatch(setErrors(["An error has occurred."]));
      // LogsService.fileLog('api.js','doPost','An error has occurred.');
      // LogsService.fileLog('api.js','doPost error=', error);
    });
};

export const doPostFormData = async (url, body, headers) => {

  const state = store.getState();
  // console.log('URL=',state.app.APIBaseURL + url)
  // console.log('body=',body)

  // const data = new FormData();
  // const file = {
  //   uri: Platform.OS === "android" ? "file://" + body : body,
  //   name:"KeyPleazLogs",
  //   type: 'text/plain'
  // };
  // console.log('file=',file)
  // data.append('Log_File', file)

  // return fetch(state.app.APIBaseURL + url, {
  //   method: "POST",
  //   headers: {
  //     ...authHeadersFromState(state),
  //     ...(headers || {}),
  //     'Content-Type': 'multipart/form-data'
  //   },
  //   body: data,
  // })
  //   .then((response) => {
  //     checkAuth(response, url);
  //     return response.json();
  //   })
  //   .then((response) => {
  //     if (response.status === "error") {
  //       store.dispatch(setErrors(response.errors));
  //       if(response.errors.length>0){
  //         Alert.alert(
  //           "",
  //           response.errors[0],
  //           [
  //             {
  //               text: "Ok",
  //               onPress: () => {},
  //             }
  //           ]
  //         );
  //       }
  //     }else{
  //       store.dispatch(setMasageFromScreen(response.message ? response.message : "Email Sent successfully"));
  //       Alert.alert(
  //         "",
  //         response.message ? response.message : "Email Sent successfully",
  //         [
  //           {
  //             text: "Ok",
  //             onPress: () => {},
  //           }
  //         ]
  //       );
  //     }
  //     return response;
  //   })
  //   .catch((error) => {
  //     console.log('error=',error);
  //     LogsService.fileLog('api.js','doPostFormData error=', error);
  //     store.dispatch(setErrors(["An error has occurred."]));
  //     Alert.alert(
  //       "",
  //       "An error has occurred.",
  //       [
  //         {
  //           text: "Ok",
  //           onPress: () => {},
  //         }
  //       ]
  //     );
  //   });


    var files = [
      {
        name: 'KeyPleazLogs',
        filename: 'KeyPleazLogs.txt',
        filepath: body,
        filetype: 'text/plain'
      }
    ];
    
    var uploadBegin
    = (response) => {
      var jobId = response.jobId;
      console.log('UPLOAD HAS BEGUN! JobId: ' + jobId);
    };
    
    var uploadProgress = (response) => {
      var percentage = Math.floor((response.totalBytesSent/response.totalBytesExpectedToSend) * 100);
      console.log('UPLOAD IS ' + percentage + '% DONE!');
    };
    
    // upload files
    // return RNFS.uploadFiles({
    //   toUrl: state.app.APIBaseURL + url,
    //   files: files,
    //   method: 'POST',
    //   headers: {
    //     'Accept': 'application/json',
    //     ...authHeadersFromState(state),
    //     ...(headers || {}),
    //   },
    //   begin: uploadBegin,
    //   progress: uploadProgress
    // }).promise.then((response) => {
    //     if (response.statusCode == 200) {
    //       console.log('FILES UPLOADED!'); // response.statusCode, response.headers, response.body
    //       var resp = JSON.parse(response.body);
    //       if (resp.status === "error") {
    //               if(resp.errors.length>0){
    //                 Alert.alert(
    //                   "",
    //                   resp.errors[0],
    //                   [
    //                     {
    //                       text: "Ok",
    //                       onPress: () => {},
    //                     }
    //                   ]
    //                 );
    //               }
    //             }else{
    //               Alert.alert(
    //                 "",
    //                 resp.message ? resp.message : "Email Sent successfully",
    //                 [
    //                   {
    //                     text: "Ok",
    //                     onPress: () => {},
    //                   }
    //                 ]
    //               );
    //             }
    //     } else {
    //       Alert.alert(
    //         "",
    //         "Failed to upload server error",
    //         [
    //           {
    //             text: "Ok",
    //             onPress: () => {},
    //           }
    //         ]
    //       );
    //     }
    //     return resp
    //   })
    //   .catch((err) => {
    //     Alert.alert(
    //             "",
    //             "An error has occurred.",
    //             [
    //               {
    //                 text: "Ok",
    //                 onPress: () => {},
    //               }
    //             ]
    //           );
    //   });
    



  
};

export const doPut = (url, body, headers) => {
  const state = store.getState();
  // LogsService.fileLog('api.js','doPut URL=', state.app.APIBaseURL + url);
  return fetch(state.app.APIBaseURL + url, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...authHeadersFromState(state),
      ...(headers || {}),
    },
    body: JSON.stringify(body),
  })
    .then((response) => {
      checkAuth(response, url);
      return response.json();
    })
    .then((response) => {
      if (response.status === "error") {
        store.dispatch(setErrors(response.errors));
      }
      return Promise.resolve(response);
    })
    .catch((error) => {
      store.dispatch(setErrors(["An error has occurred."]));
      // LogsService.fileLog('api.js','doPut','An error has occurred.');
      // LogsService.fileLog('api.js','doPut error=', error);
    });
};

export const doGet = (url, params, headers) => {
  const state = store.getState();
  // LogsService.fileLog('api.js','doGet URL=', state.app.APIBaseURL + url);
  return fetch(state.app.APIBaseURL + url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...authHeadersFromState(state),
      ...(headers || {}),
    },
  })
    .then((response) => {
      checkAuth(response, url);
      return response.json();
    })
    .then((response) => {
      if (response.status === "error") {
        if (response.errors[0] == "Could not fetch address information") {
        } else {
          store.dispatch(setErrors(response.errors));
        }
      }
      return response;
    })
    .catch((error) => {
      console.log('error=',error)
      store.dispatch(setErrors(["An error has occurred."]));
      // LogsService.fileLog('api.js','doGet','An error has occurred.');
      // LogsService.fileLog('api.js','doGet error=', error);
    });
};

export const doDelete = (url, body, headers) => {
  const state = store.getState();
  // LogsService.fileLog('api.js','doDelete URL=', state.app.APIBaseURL + url);
  return fetch(state.app.APIBaseURL + url, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...authHeadersFromState(state),
      ...(headers || {}),
    },
    body: JSON.stringify(body),
  })
    .then((response) => {
      checkAuth(response, url);
      return response.json();
    })
    .then((response) => {
      if (response.status === "error") {
        store.dispatch(setErrors(response.errors));
      }
      return Promise.resolve(response);
    })
    .catch((error) => {
      store.dispatch(setErrors(["An error has occurred."]));
      // LogsService.fileLog('api.js','doDelete','An error has occurred.');
      // LogsService.fileLog('api.js','doDelete error=', error);
    });
};
export const doPostForPassword = (url, body, headers) => {
  const state = store.getState();
  // LogsService.fileLog('api.js','dodoPostForPassword URL=', state.app.APIBaseURL + url);
  return fetch(state.app.APIBaseURL + url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...authHeadersFromState(state),
      ...(headers || {}),
    },
    body: JSON.stringify(body),
  })
    .then((response) => {
      checkAuth(response, url);
      return response.json();
    })
    .then((response) => {
      if (response.status === "error") {
        store.dispatch(setErrors(["The Old Password entered was invalid"]));
      }
      return response;
    })
    .catch((error) => {
      store.dispatch(setErrors(["An error has occurred."]));
      // LogsService.fileLog('api.js','doPostForPassword','An error has occurred.');
      // LogsService.fileLog('api.js','doPostForPassword error=', error);
    });
};

const authHeadersFromState = (state) => {
  return state.auth.loggedIn
    ? {
        Authorization: `Bearer ${state.auth.user.token}`,
      }
    : {};
};
const handleUnauth = () => {
  NavigationService.navigate("Login", {
    isSessionExpired: true,
  });
};
const checkAuth = (response, url = null) => {
  // LogsService.fileLog('api.js','checkAuth url=', url);
  const statusCode = response.status;
  // LogsService.fileLog('api.js','checkAuth statusCode=', statusCode);
  if (statusCode === 401 && url != "login" && url != "users/reset-password") {
    // LogsService.fileLog('api.js','checkAuth response=', JSON.stringify(response));
    handleUnauth();
  }
};
