import React, { useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  ActivityIndicator,
  ScrollView,
  Alert,
} from "react-native";
import { connect } from "react-redux";
// import { WebView } from "@react-navigation/coreview";
// import NavigationService, { pageTransitions } from "../NavigationService";
import HeaderImage from "../Images/Header";
import BaseLayout, { headerStyleCustom } from "./BaseLayout";
import Header from "../Components/Signup/Header";
import Title from "../Components/Signup/Title";
import { ButtonOne as Button } from "../Components/Button";
import { getStripeRegistrationURL } from "../../config";
import LogsService from "../LogsService";

const styles = StyleSheet.create({
  description: {
    marginLeft: 35,
    marginTop: 15,
    fontSize: 17,
    width: "80%",
  },
  description2: {
    marginLeft: 35,
    marginTop: 15,
    fontSize: 17,
    width: "80%",
    fontWeight: "700",
    color: "red",
    fontFamily: "RobotoCondensed-Bold",
    textDecorationLine: "underline",
  },
  description3: {
    marginLeft: 35,
    fontSize: 17,
    width: "80%",
    color: "red",
  },
  description4: {
    marginLeft: 35,
    fontSize: 17,
    width: "80%",
    color: "red",
    marginBottom: "3%",
  },
  buttonContainer: {
    alignItems: "center",
  },
  container: {
    flex: 1,
    justifyContent: "center",
    top: 50,
  },
});

const ConnectStripe = (props) => {
  const [buttonClicked, setButtonClicked] = useState(false);
  const [visible, setVisible] = useState(false);
  const hideSpinner = () => {
    setVisible(false);
  };
  return (
    <BaseLayout>
      {!buttonClicked && (
        <ScrollView>
          <View style={{ height: "100%", width: "100%" }}>
            {!buttonClicked && (
              <View>
                <Header total={3} complete={3} />
                <Title
                  text="Connect bank or debit card"
                  style={{ fontSize: 24 }}
                />
                <Text style={styles.description}>
                KeyPleaz® uses Stripe to process payments from Hiring Agents to Showing Agents.
                </Text>
                <Text style={styles.description2}>Important Note:</Text>
                <Text style={styles.description3}>
                Please complete the 2 KeyPleaz® payment screens.
                </Text>
                <Text style={styles.description3}>
                1) Payout Screen - bank or debit card - account used by
                showing agents to receive their showing fee
                </Text>
                <Text style={styles.description4}>
                2) Payment Screen - credit card, bank or debit card - account
                used by hiring agent to pay the showing agent
                </Text>
              </View>
            )}
            <View style={styles.buttonContainer}>
              <Button
                style={styles.button}
                text="Connect with Stripe"
                onPress={() => {
                  LogsService.fileLog('ConnectStripe','onPress','Connect with Stripe clicked');
                  setButtonClicked(true);
                  setVisible(true);
                  LogsService.fileLog('ConnectStripe','onPress','Opening webview for Stripe');
                }}
              />
            </View>
          </View>
        </ScrollView>
      )}
      {visible && (
        <View style={[styles.container]}>
          <ActivityIndicator size="large" color="#215F90" />
        </View>
      )}
      {/* {buttonClicked && (
        <WebView
          onLoad={() => {
              LogsService.fileLog('ConnectStripe','onLoad','WebView load successful..!');
              hideSpinner()
            }
          }
          style={{ flex: 1 }}
          source={{
            uri: getStripeRegistrationURL(props.user.id, props.APIBaseURL),
          }}
          onMessage={(event) => {
            let message = event.nativeEvent.data;
            LogsService.fileLog('ConnectStripe','onMessage','WebView Response => ' + JSON.stringify(message));
            if (message == "STRIPE_CONNECT_SUCCESSFUL"){
              LogsService.fileLog('ConnectStripe','STRIPE_CONNECT_SUCCESSFUL', 'Navigating to ["ConnectStripe"]["onComplete"] i.e. PaymentInformation');
              NavigationService.navigate(
                pageTransitions["ConnectStripe"]["onComplete"]
              );
            }else {
              // STRIPE_CONNECT_ERROR
              Alert.alert(
                "Error",
                "Server is busy and cannot process requests at this time, please try again shortly!",
                [
                  {
                    text: "Ok",
                    onPress: () => {
                      LogsService.fileLog('ConnectStripe','onPress', 'Navigating to ["ConnectStripe"]["onError"]');
                      NavigationService.navigate(
                        pageTransitions["ConnectStripe"]["onError"]
                      );
                    },
                  },
                ]
              );
            }
          }}
          onError={(error) => {
            LogsService.fileLog('ConnectStripe','onError', JSON.stringify(error));
            Alert.alert(
              "Error",
              "There is a Network or Server issue, please try again shortly!",
              [
                {
                  text: "Ok",
                  onPress: () => {
                    LogsService.fileLog('ConnectStripe','onPress', 'Navigating to ["ConnectStripe"]["onError"]');
                    NavigationService.navigate(
                      pageTransitions["ConnectStripe"]["onError"]
                    );
                  },
                },
              ]
            );
          }}
        />
      )} */}
    </BaseLayout>
  );
};

ConnectStripe.navigationOptions = ({ navigation }) => ({
  headerStyle: headerStyleCustom(),
  headerTintColor: "#fff",
  headerTitleStyle: {
    fontWeight: "bold",
  },
  headerBackTitle: "Back",
  headerLeft: null,
  headerTitle: () => <HeaderImage height={35} />,
});

export default connect(
  (state) => ({
    user: state.auth.user,
    APIBaseURL: state.app.APIBaseURL,
  }),
  () => ({})
)(ConnectStripe);
