import React from "react";
import { View, Text, StyleSheet, Dimensions, Platform } from "react-native";
import { connect } from "react-redux";
// import * as Animatable from "react-native-animatable";
// import NotificationModel from "./NotificationModel";
// import PushNotifiaction from "./PushNotifiaction";
import DeviceInfo from "react-native-device-info";

let width = Dimensions.get("window").width;

const styles = StyleSheet.create({
  container: {},
  messageContainer: {
    position: "absolute",
    left: 0,
    top: 0,
    width: width,
    zIndex: 100000,
    display: "flex",
    alignItems: "center",
    marginTop: 30,
  },
  error: {
    width: width - 15,
    backgroundColor: "#CC0000",
    minHeight: 30,
    borderRadius: 15,
    padding: 5,
    marginTop: 5,
    paddingLeft: 15,
    shadowColor: "black",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 2,
    zIndex: 99999999,
  },
  errorText: {
    lineHeight: 20,
    color: "white",
    fontSize: 15,
    zIndex: 999999999,
  },
  message: {
    width: width - 15,
    backgroundColor: "white",
    minHeight: 30,
    borderRadius: 15,
    padding: 5,
    marginTop: 5,
    paddingLeft: 15,
    shadowColor: "black",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 2,
    zIndex: 99999999,
  },
  messageText: {
    lineHeight: 20,
    color: "black",
    fontSize: 15,
    zIndex: 999999999,
  },
  messageGreen: {
    width: width - 15,
    backgroundColor: "#DC612F",
    minHeight: 30,
    borderRadius: 15,
    padding: 5,
    marginTop: 5,
    paddingLeft: 15,
    shadowColor: "black",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 2,
    zIndex: 99999999,
  },
  messageGText: {
    lineHeight: 20,
    color: "black",
    fontSize: 15,
    zIndex: 999999999,
  },
});

export const headerStyleCustom = () => {
    return {
      backgroundColor: "#215F90",
      elevation: 0, // remove shadow on Android
      shadowOpacity: 0, // remove shadow on iOS
    };
};

const BaseLayout = (props) => {
  return (
    <View style={{ position: "relative", flex: 1 }}>
        <View>
          {/* <PushNotifiaction />
          <NotificationModel /> */}
        </View>

      {props.errors.length > 0 && (
        <View
          animation="fadeIn"
          easing="ease-in"
          duration={400}
          style={{
            ...styles.messageContainer,
            marginTop: props.marginTop || 30,
          }}
        >
          {props.errors.map((error, i) => {
            return (
              <View key={i} style={styles.error}>
                <Text style={styles.errorText}>{error}</Text>
              </View>
            );
          })}
        </View>
      )}
      {props.messages.length > 0 && (
        <View
          animation="fadeIn"
          easing="ease-in"
          duration={400}
          style={{
            ...styles.messageContainer,
            marginTop: props.marginTop || 30,
          }}
        >
          {props.messages.map((message, i) => {
            return (
              <View key={i} style={styles.message}>
                <Text style={styles.messageText}>{message}</Text>
              </View>
            );
          })}
        </View>
      )}
      {props.messagesSuccess.length > 0 && (
        <View
          animation="fadeIn"
          easing="ease-in"
          duration={400}
          style={{
            ...styles.messageContainer,
            marginTop: props.marginTop || 30,
          }}
        >
          {props.messagesSuccess.map((messagesSuccess) => {
            return (
              <View style={styles.messageGreen}>
                <Text style={styles.messageText}>{messagesSuccess}</Text>
              </View>
            );
          })}
        </View>
      )}
      {props.children}
    </View>
  );
};

export default connect(
  (state) => {
    return {
      errors: state.errors.errors,
      messages: state.messages.messages,
      messagesSuccess: state.messages.messagesSuccess,
    };
  },
  () => ({})
)(BaseLayout);
