import React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={32} height={32} viewBox="0 0 32 32" {...props}>
      <Path
        data-name="2B"
        d="M16 32a16 16 0 1116-16 16.018 16.018 0 01-16 16zm-5.52-16.412a1.291 1.291 0 00-.835.3 1.205 1.205 0 00-.119 1.737l2.96 3.282a1.289 1.289 0 00.908.419h.047a1.284 1.284 0 00.891-.355l8.568-8.197a1.207 1.207 0 00.011-1.742 1.3 1.3 0 00-1.794-.011l-7.6 7.286-2.072-2.3a1.287 1.287 0 00-.956-.421z"
        fill="#ff8959"
      />
    </Svg>
  );
}

export default SvgComponent;
