import { StyleSheet, Dimensions } from "react-native";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#FFFFFF",
  },
  mainTitle: {
    fontSize: 24,
    color: "#518AC1",
    paddingTop: 15,
    fontWeight: "bold",
    textAlign: "center",
  },
  payoutOptionsOuter:{
    marginTop: 15,
  },
  seperator:{
    backgroundColor: '#518AC150',
    height: 0.2,
    marginLeft: 20,
    marginRight: 20,
    marginTop: 30,
  },
  payoutOuter: {
    alignItems: "center",
    justifyContent: "flex-start",
    flex: 1,
    flexDirection:'row',
    padding: 20,
    marginLeft: 25,
    marginRight: 25,
    marginTop: 5,
    borderColor: '#ddd',
    borderWidth: 1,
    borderRadius: 6,
    backgroundColor: "#518AC105",
  },
  activePayoutOuter:{
    backgroundColor: "#518AC1",
  },
  title:{
    color: "#63696F",
    fontSize: 14,
    paddingLeft: 15,
    fontWeight: "500",
  },
  activeTitle:{
    color: "#FFFFFF",
    fontWeight: "800",
  },
  detailsTitle:{
      fontSize: 22,
      fontWeight: 'normal',
      color: '#4990C8',
      marginLeft: 30,
      marginRight: 25,
      marginTop: 15,
      fontFamily: "RobotoCondensed-Regular"
  },
  btnView: {
    flex: 1,
    alignItems: "center",
    paddingBottom: 10,
    marginTop: "5%",
  },
  btnWidth: {
    width: 140,
  },
});

export default styles;
