import React from "react";
import { StyleSheet, View, Dimensions } from "react-native";

import { times } from "lodash";

const styles = StyleSheet.create({
  container: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    marginTop: 2,
  },
  progressItem: {
    height: 10,
  },
  filled: {
    backgroundColor: "#4690CD",
  },
  empty: {
    backgroundColor: "#A2C6E8",
  },
});

export default function (props) {
  const empty = props.total - props.complete;
  const widthPercent = 100 / props.total;
  const screenWidth = Math.round(Dimensions.get("window").width);
  const barWidth = (widthPercent / 100) * screenWidth - 2;
  return (
    <View style={styles.container}>
      {times(props.complete).map((i) => {
        return (
          <View
            key={i}
            style={{
              ...styles.progressItem,
              ...styles.filled,
              width: barWidth,
            }}
          ></View>
        );
      })}
      {times(empty).map((i) => {
        return (
          <View
            key={i}
            style={{ ...styles.progressItem, ...styles.empty, width: barWidth }}
          />
        );
      })}
    </View>
  );
}
