import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  bgImage: {
    width: "100%",
    height: "100%",
    flex: 1,
    marginTop: 0,
  },
  bgImage2: {
    marginTop: "0%",
    marginBottom: "10%",
    alignSelf: "center",
    width: "50%",
    height: "50%",
    flex: 1,
    resizeMode: "contain",
    top: 15,
  },
  bgImage3: {
    marginBottom: "10%",
    alignSelf: "center",
    width: "50%",
    height: "50%",
    flex: 1,
  },
  patentPending: {
    flexDirection: "row",
    alignSelf: "center",
    height: 60,
    backgroundColor: "#2f597d",
    color: "#fff",
    width: "100%",
    justifyContent: "center",
  },
  bottomView: {
    backgroundColor: "#4990C8",
    height: 50,
    width: "100%",
    justifyContent: "center",
  },
  bottomViewLogin: {
    backgroundColor: "#3880b8",
    color: "#fff",
    height: 50,
    width: "100%",
    justifyContent: "center",
  },
  termStyles: {
    color: "#FFFFFF",
    fontSize: 20,
    alignSelf: "center",
    fontFamily: "RobotoCondensed-Regular",
  },
  cardViewLogin: {
    backgroundColor: "#FFFFFF",
    width: "80%",
    borderColor: "#D1E3F1",
    borderRadius: 25,
    borderWidth: 1,
    marginLeft: "10%",
    marginBottom: "4%",
    top: 0,
  },
  cardView: {
    backgroundColor: "#FFFFFF",
    width: "90%",
    borderColor: "#D1E3F1",
    borderRadius: 25,
    borderWidth: 1,
    marginLeft: "5%",
    marginBottom: "5%",
    top: -60,
  },
  cardView1: {
    backgroundColor: "#FFFFFF",
    width: "90%",
    borderColor: "#D1E3F1",
    borderRadius: 25,
    borderWidth: 1,
    marginLeft: "5%",
    marginBottom: "5%",
    top: 10,
  },
  textView: {
    borderColor: "#00467E",
    borderBottomWidth: 1,
    height: 40,
  },
  inputTextLogin: {
    height: 40,
    color: "#292B34",
    fontFamily: "RobotoCondensed-Light",
    fontSize: 17,
    paddingHorizontal: 10,
  },
  inputText: {
    height: 40,
    color: "#292B34",
    fontFamily: "RobotoCondensed-Light",
    fontSize: 17,
    paddingHorizontal: 25,
  },
  textImg: {
    height: 20,
    width: 20,
    marginTop: "2%",
  },
  btnView: {
    marginTop: 10,
    alignItems: "center",
  },
  btnWidth: {
    width: 180,
  },
  circleChecked: {
    height: 32,
    width: 32,
  },
  loggedTxt: {
    color: "#4990C8",
    fontSize: 16,
    fontFamily: "RobotoCondensed-Regular",
    textAlignVertical: "center",
    marginLeft: "1%",
  },
  forgetTxt: {
    color: "#4990C8",
    fontFamily: "RobotoCondensed-Regular",
    fontSize: 16,
    alignSelf: "center",
    marginTop: "4%",
  },
  heddingTxt: {
    color: "#4990C8",
    fontFamily: "RobotoCondensed-bold",
    fontSize: 20,
    fontWeight: "700",
    alignSelf: "center",
    marginTop: "2%",
  },
  joinTxt: {
    color: "#4990C8",
    fontFamily: "RobotoCondensed-bold",
    fontSize: 17,
    fontWeight: "700",
    alignSelf: "center",
    marginTop: "5%",
  },
  bottomTxt: {
    fontFamily: "RobotoCondensed-Regular",
    fontSize: 16,
  },
  bottomViewTxt: {
    fontFamily: "RobotoCondensed-Regular",
    fontSize: 16,
  },
  bottomViewTxt2: {
    fontFamily: "RobotoCondensed-Regular",
    fontSize: 12,
  },
});

export default styles;
