import React, { useState } from "react";
import moment from "moment-timezone";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  Linking,
  Alert,
  Platform,
  ActivityIndicator,
  Modal,
} from "react-native";
import Location from "../Images/Location";
import styles from "../Styles/AvaliableShowingstyle.js";
// import CancelIcon from "../Images/CancelIcon";
// import CloseIcon from "../Images/CloseIcon";
// import CancelDisableIcon from "../Images/CancelDisableIcon";
// import CompleteGreen from "../Images/CompleteGreen";
// import MasterCard from "../Images/MasterCard";
// import VisaCard from "../Images/VisaCard";
// import DefaultCard from "../Images/DefaultCard";
// import UnipayCard from "../Images/UnipayCard";
// import JcbCard from "../Images/JcbCard";
// import DiscoverCard from "../Images/DiscoverCard";
// import DinersCard from "../Images/DinersCard";
// import AmericanExpressCard from "../Images/AmericanExpressCard";
// import CompleteGreenDisable from "../Images/CompleteGreenDisable";
import { connect } from "react-redux";
import { getAppointmentTypeText } from "../utils/appointmentType";
import { getAvailablePayoutMethods } from "../redux/appointments/actions";
import { ButtonBoldBig } from "../Components/Button.js";
import * as api from "../redux/appointments/api";
import CancelAppointmentModal from "./CancelAppointmentModal";
import CompleteAppointmentModal from "./CompleteAppointmentModal";
// import getTimezoneAbbreviation from "../utils/timezone";
// import { color } from "react-native-reanimated";

const mystyles = StyleSheet.create({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  listingDetails: {
    width: "95%",
    borderRadius: 12,
    backgroundColor: "#F2F7FB",
    marginTop: 10,
  },
  listingDetailsInner: {
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "flex-start",
    marginTop: 15,
    marginLeft: 10,
  },
  listingText: {
    color: "#4990C8",
    fontSize: 10,
  },
  appointmentDetails: {
    marginTop: 20,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    marginLeft: "20%",
  },
  appointmentDetailsHeading: {
    fontSize: 20,
    color: "#4990C8",
    fontWeight: "bold",
  },
  containernew: {
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "flex-start", // if you want to fill rows left to right
  },
  newCardSectionOuter: {
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.5)",
    justifyContent: "center",
  },
  newCardSection: {
    marginHorizontal: 10,
    backgroundColor: "#FFFFFF",
    paddingVertical: 25,
    borderRadius: 10,
  },
  closeModalOuter: {
    position: "absolute",
    top: 15,
    right: 15,
    zIndex: 1000
  },
  modalTitle: {
    fontSize: 20,
    fontWeight: "bold",
    color: "#518AC1",
    textAlign: "center",
  },
  payoutContentSection: {
    paddingHorizontal: 35,
    paddingTop: 25,
  },
  payoutOptionOuter: {
    flexDirection: "row",
    marginBottom: 25,
  },
  radioButtonRound: {
    width: 25,
    height: 25,
    borderColor: "#EC8F64",
    borderWidth: 2,
    borderRadius: 12.5,
    flexDirection: "column",
    marginRight: 15,
    marginTop: 5,
    alignItems: "center",
    justifyContent: "center",
  },
  radioButtonInner: {
    width: 10,
    height: 10,
    borderRadius: 5,
    backgroundColor: "#EC8F64",
  },
  payoutOptionTextOuter: {
    flex: 1,
  },
  payoutOptionText: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#63696F",
  },
  transferToText: {
    color: "#518AC1",
    fontSize: 18,
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: 10,
  },
  transferToDetailsOuter: {
    borderColor: "#E7E7E7",
    borderWidth: 1,
    borderRadius: 10,
    marginBottom: 15,
    flexDirection: "row",
    alignItems: "center",
    padding: 10,
  },
  transferCardOuter: {
    marginRight: 15,
  },
  transferCardBankDetailsOuter: {
    flex: 1,
  },
  transferCardBankText: {
    color: "#63696F",
    fontSize: 16,
    fontWeight: "bold",
  },
  payoutGrayText: {
    color: "#63696F",
    fontSize: 11,
    marginBottom: 30,
  },
  modalButtonsOuter: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  modalSaveButton: {
    width: 200,
  },
});

const Appointment = (props) => {
  const [isShowingCompleteBtn, setIsShowingCompleteBtn] = useState(true);
  const [isShowingCancelBtn, setIsShowingCancelBtn] = useState(true);
  const [loaderText, setLoaderText] = useState(
    "Processing request, please wait."
  );
  const [isFeedbackBoxOpened, setIsFeedbackBoxOpened] = useState(false);
  const [isCancelModalOpened, setIsCancelModalOpened] = useState(false);
  const [isShowPayoutOptionModal, setIsShowPayoutOptionModal] = useState(false);
  const [payoutOption, setPayoutOption] = useState("");
  const [availablePayoutDetails, setAvailablePayoutDetails] = useState({});
  const [isOpened, setIsOpened] = useState(false);

  if (!props.appointment) {
    return <></>;
  }
  const closeCancelModal = () => {
    setIsCancelModalOpened(false);
    setIsFeedbackBoxOpened(false);
  };

  const openMap = async (address) => {
    const destination = encodeURIComponent(`${address}`);
    const provider = Platform.OS === "ios" ? "apple" : "google";
    const link = `http://maps.${provider}.com/?daddr=${destination}`;
    console.log("link=", link);
    try {
      const supported = await Linking.canOpenURL("https://google.com/");

      if (supported) Linking.openURL(link);
    } catch (error) {
      console.log("error=", error);
    }
  };
  const captureFeedbackNotes = () => {
    setIsFeedbackBoxOpened(true);
  };
  const showCancelButtonDisabled = (appointmentState = null) => {
    if (
      /^[\],:{}\s]*$/.test(
        props.appointment.time_zone
          .replace(/\\["\\\/bfnrtu]/g, "@")
          .replace(
            /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
            "]"
          )
          .replace(/(?:^|:|,)(?:\s*\[)+/g, "")
      )
    ) {
      var currentDateTime = moment.tz(
        JSON.parse(props.appointment.time_zone).timeZoneId
      );
      var startsAtTime = moment.tz(
        moment(props.appointment.startsAt).utc().format("YYYY-MM-DD HH:mm:ss"),
        JSON.parse(props.appointment.time_zone).timeZoneId
      );
      var startsAtTimeNew = moment.tz(
        moment(props.appointment.startsAt).utc().format("YYYY-MM-DD HH:mm:ss"),
        JSON.parse(props.appointment.time_zone).timeZoneId
      );
      const newDateTime = startsAtTimeNew.subtract({ minutes: 30 });
      //.format("YYYY-MM-DD HH:mm:ss");
      var duration = currentDateTime.diff(startsAtTime);

      var isAfter30Mins = moment(newDateTime).isAfter(currentDateTime);
      if (
        props.appointment.userID == props.user.id &&
        props.appointment.appointmentType === "scheduled" &&
        !isAfter30Mins &&
        (props.appointment.state == "assigned" ||
          appointmentState == "assigned")
      )
        return true;
      else return false;
    } else {
      return false;
    }
  };

  const getScheduledTime = (timezoneObjString) => {
    console.log("app5=",props.appointment)
    if (props.appointment.appointmentType === "asap") {
      return (
        moment.utc(props.appointment.startsAt).format("MM/DD/YYYY") + " ASAP"
      );
    }

    return getTimeIdFromTimezoneObjString(timezoneObjString);
  };

  const getAbrrFromTimezoneObjString = (timezoneObjString) => {
    var abbr = "";
    try {
      var jsn = JSON.parse(timezoneObjString);
      abbr = jsn.abrr;
    } catch (e) {
      abbr = getAbrr(timezoneObjString);
    }
    return abbr;
  };
  const getTimeIdFromTimezoneObjString = (timezoneObjString) => {
    return (
      moment.utc(props.appointment.startsAt).format("MM/DD hh:mm A ") +
      "- " +
      moment.utc(props.appointment.endsAt).format("MM/DD hh:mm A ")
    );
  };
  const getAbrr = (timeZoneName) => {
    return getTimezoneAbbreviation(timeZoneName);
  };

  const getPayoutMethods = () => {
    props.getAvailablePayoutMethods().then((resp) => {
      if (resp.status == "ok") {
        setAvailablePayoutDetails(resp || {});
        let payoutMethods = resp.AvailablePayoutMethods || [];
        if (payoutMethods.length > 1) {
          setPayoutOption("standard");
          setIsShowPayoutOptionModal(true);
        } else {
          setPayoutOption(payoutMethods[0]);
          showConfirmAlert();
        }
      } else if (resp.status == "error") {
        store.dispatch(setErrors(resp.errors, 10));
      }
    });
  };

  const showConfirmAlert = () => {
    Alert.alert(
      "Confirm Completing Appointment",
      "Are you sure you want to complete this appointment?",
      [
        {
          text: "Yes",
          onPress: () => {
            captureFeedbackNotes();
          },
        },
        { text: "No", onPress: () => {}, style: "cancel" },
      ]
    );
  };


  return (
    <View style={mystyles.container}>
      <View style={mystyles.listingDetails}>
        <View style={mystyles.listingDetailsInner}>
          <View>
            <Location />
          </View>
          <View style={{ flex: 1, marginLeft: 10 }}>
            <Text style={mystyles.appointmentDetailsHeading}>
              {getAppointmentTypeText(props.appointment.showing_type)}
            </Text>
            {props.appointment.showing_type.toUpperCase() === "RUNNER" && props.appointment.runner_activity_details.length > 0 &&
              <View style={{ flexDirection: "row", justifyContent:'flex-start', alignItems:'flex-start', flexWrap:'wrap' }}>
                {props.appointment.runner_activity_details.map((descObj, i) => {
                  if(!isOpened && i < 2 || isOpened){
                    return (
                      <View style={{
                        backgroundColor:'#4990C8',
                        color:"#ffffff",
                        borderRadius: 15,
                        padding:2,
                        margin:4,
                        marginLeft:0,
                      }}>
                        <Text style={{
                          padding:5,
                          paddingLeft:8,
                          paddingRight:8,
                          color:"#ffffff",
                          fontSize:12,
                        }}>{!descObj['other_reason_note'] 
                          ?
                            descObj.activity_description
                          :
                            descObj.activity_description + ": " + descObj.other_reason_note
                          }</Text>
                        </View>
                    )
                  }
                })}
                {props.appointment.runner_activity_details.length > 2 &&
                <View style={{
                  backgroundColor:'#EC8F64',
                  color:"#ffffff",
                  borderRadius: 15,
                  padding:2,
                  margin:4,
                  marginLeft:0,
                }}>
                    <TouchableOpacity
                      onPress={() => {
                        setIsOpened(!isOpened);
                      }}
                    >
                      <Text style={{
                        padding:5,
                        paddingLeft:8,
                        paddingRight:8,
                        color:"#ffffff",
                        fontSize:12,
                      }}>{isOpened ? 'View less' : 'View more'}</Text>
                    </TouchableOpacity>
                  </View>
                }
              </View> 
              
            }
            <View style={{ flexDirection: "row" }}>
              <Text style={styles.showTime}>
                DATE / TIME{" "}
                {getScheduledTime(props.appointment.time_zone) != "ASAP" && (
                  <Text style={{ color: "#292B34" }}>
                    {props.appointment.time_zone == "Mountain Daylight Time" &&
                      " (MST)"}
                    {getAbrrFromTimezoneObjString(props.appointment.time_zone)}
                  </Text>
                )}
              </Text>
            </View>
            <View>
              <Text style={styles.showTime1}>
                {getScheduledTime(props.appointment.time_zone)}
              </Text>
            </View>
            <View style={{ flexDirection: "row" }}>
              <Text style={styles.showTime}>FEE </Text>
              <Text style={styles.showFee1}>
                ${(props.appointment.feeCents / 100).toFixed(2)}
              </Text>
            </View>
            <View>
              {props.appointment.payout_percentage ? (
                <View>
                  <Text style={{ fontSize: 13 }}>
                    *Less a {props.appointment.payout_percentage}%{" "}
                    {props.appointment.is_instant_payout
                      ? "instant payout"
                      : "standard payout"}
                  </Text>
                  <Text style={{ fontSize: 13 }}>
                    transaction fee = $
                    {(
                      props.appointment.feeCents / 100 -
                      parseFloat(
                        (props.appointment.feeCents / 100) *
                          (props.appointment.payout_percentage / 100)
                      ).toFixed(2)
                    ).toFixed(2)}
                  </Text>
                </View>
              ) : (
                <View>
                  <Text style={{ fontSize: 13 }}>
                    *Less a 2.99%{" "}
                    {props.appointment.is_instant_payout
                      ? "instant payout"
                      : "standard payout"}{" "}
                    transaction fee = $
                    {(
                      props.appointment.feeCents / 100 -
                      parseFloat(
                        (props.appointment.feeCents / 100) * (2.99 / 100)
                      ).toFixed(2)
                    ).toFixed(2)}
                  </Text>
                </View>
              )}
            </View>
          </View>
          {props.showButton && (
            <TouchableOpacity
              style={{ position: "absolute", right: 0 }}
              disabled={props.disbleBtn}
              onPress={props.onButtonPress}
            >
              {props.disbleBtn && (
                <Text
                  style={{ fontSize: 17, fontWeight: "600", color: "#6a6b6b" }}
                >
                  {props.buttonText}
                </Text>
              )}
              {!props.disbleBtn && (
                <Text
                  style={{ fontSize: 17, fontWeight: "600", color: "#4990C8" }}
                >
                  {props.buttonText}
                </Text>
              )}
            </TouchableOpacity>
          )}
        </View>
        <View
          style={{
            alignContent: "center",
            paddingVertical: 10,
            paddingHorizontal: 20,
          }}
        >
          {props.appointment.dataListings &&
            props.appointment.dataListings.map((listing, i) => (
              <View key={i}>
                <View>
                  <View style={{ flexDirection: "row" }}>
                    <Text style={mystyles.listingText}>PROPERTY </Text>
                  </View>
                  <Text
                    onPress={() => {
                      openMap(listing.address);
                    }}
                    style={{ fontSize: 15, maxWidth: "90%" }}
                  >
                    {listing.address}
                  </Text>
                </View>
                <View>
                  <Text style={{ fontWeight: "500" }}>
                    Unit(s) #
                    {listing.unit_number ? (
                      <Text> {listing.unit_number}</Text>
                    ) : (
                      <Text> </Text>
                    )}
                  </Text>
                </View>
              </View>
            ))}
        </View>
        {props.showCompleteCancelButton &&
          props.appointment.state != "completed" && (
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {props.showCompletedButton && (
                <View
                  style={{
                    flex: 1,
                    minWidth: "48%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <TouchableOpacity
                    style={{
                      paddingHorizontal: 8,
                      paddingVertical: 6,
                      borderRadius: 4,
                      flex: 1,
                      alignItems: "center",
                      marginHorizontal: "1%",
                      marginBottom: 6,
                    }}
                    onPress={() => {
                      getPayoutMethods();
                    }}
                  >
                    {/* {isShowingCompleteBtn && <CompleteGreen />}
                    {!isShowingCompleteBtn && (
                      <CompleteGreenDisable onPress={() => {}} />
                    )} */}
                    <Text
                      style={{
                        color:
                          !isShowingCompleteBtn || !isShowingCancelBtn
                            ? "#9fbdab"
                            : "#196F3D",
                        fontSize: 10,
                        fontWeight: "bold",
                      }}
                    >
                      COMPLETE
                    </Text>
                  </TouchableOpacity>
                </View>
              )}
              <View
                style={{
                  flex: 1,
                  minWidth: "48%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <TouchableOpacity
                  style={{
                    paddingHorizontal: 8,
                    paddingVertical: 6,
                    borderRadius: 4,
                    alignItems: "center",
                    marginHorizontal: "1%",
                    marginBottom: 6,
                    textAlign: "center",
                  }}
                  onPress={() => {
                    if (props.appointment.userID == props.user.id) {
                      setIsShowingCancelBtn(false);
                      setLoaderText("Verifying appointment current state");
                      api
                        .getAppointment(props.appointment.id)
                        .then((response) => {
                          setIsShowingCancelBtn(true);
                          if (response.status === "error") {
                            return;
                          }
                          if (
                            response.appointment.state == "assigned" &&
                            showCancelButtonDisabled("assigned")
                          ) {
                            Alert.alert("", "Claimed showings cannot be canceled by Hiring agent prior to 30 mins of the scheduled time", [
                              { text: "OK", onPress: () => {} },
                            ]);
                          } else {
                            setIsCancelModalOpened(true);
                          }
                        });
                    } else {
                      setIsCancelModalOpened(true);
                    }
                  }}
                >
                  {/* {isShowingCancelBtn && !showCancelButtonDisabled() && (
                    <CancelIcon />
                  )} */}
                  {/* {(!isShowingCancelBtn || showCancelButtonDisabled()) && (
                    <CancelDisableIcon
                      onPress={() => {
                        if (showCancelButtonDisabled())
                          Alert.alert("", "Claimed showings cannot be canceled by Hiring agent prior to 30 mins of the scheduled time", [
                            { text: "OK", onPress: () => {} },
                          ]);
                      }}
                    />
                  )} */}
                  <Text
                    style={{
                      color:
                        !isShowingCompleteBtn ||
                        !isShowingCancelBtn ||
                        showCancelButtonDisabled()
                          ? "#c47e82"
                          : "#E41E2A",
                      fontSize: 10,
                      fontWeight: "bold",
                    }}
                  >
                    CANCEL
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          )}
        {(!isShowingCompleteBtn || !isShowingCancelBtn) && (
          <View
            style={{
              position: "absolute",
              top: "50%",
              right: 0,
              left: 0,
              ...StyleSheet.absoluteFill,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ActivityIndicator size="large" color="#DC612F" />
            <Text
              style={{
                backgroundColor: "#DC612F",
                padding: 10,
                color: "#fff",
                borderRadius: 10,
              }}
            >
              {loaderText}
            </Text>
          </View>
        )}
      </View>

      <View>
        <CancelAppointmentModal
          closeModal={() => {
            closeCancelModal();
          }}
          appointment={props.appointment}
          showModal={isCancelModalOpened}
        ></CancelAppointmentModal>
        <CompleteAppointmentModal
          closeModal={() => {
            closeCancelModal();
          }}
          appointment={props.appointment}
          isInstantPayout={payoutOption == "instant" ? true : false}
          showModal={isFeedbackBoxOpened}
        ></CompleteAppointmentModal>
        <Modal
          transparent={true}
          visible={isShowPayoutOptionModal}
          onRequestClose={() => {
            setIsShowPayoutOptionModal(false);
          }}
        >
          <TouchableOpacity
            activeOpacity={1}
            onPress={() => {}}
            style={mystyles.newCardSectionOuter}
          >
            <TouchableOpacity
              activeOpacity={1}
              onPress={() => {}}
              style={mystyles.newCardSection}
            >
              <TouchableOpacity
                onPress={() => {
                  setIsShowPayoutOptionModal(false);
                }}
                style={mystyles.closeModalOuter}
              >
                {/* <CloseIcon /> */}
              </TouchableOpacity>
              <Text style={mystyles.modalTitle}>Payout Option</Text>
              <View style={mystyles.payoutContentSection}>
                <TouchableOpacity
                  onPress={() => {
                    setPayoutOption("standard");
                  }}
                  style={mystyles.payoutOptionOuter}
                >
                  <View style={mystyles.radioButtonRound}>
                    {payoutOption == "standard" ? (
                      <View style={mystyles.radioButtonInner}></View>
                    ) : null}
                  </View>
                  <View style={mystyles.payoutOptionTextOuter}>
                    <Text style={mystyles.payoutOptionText}>
                      Standard Payout, a 2.99% fee, typically hits your account
                      in 1-3 business days. ($
                      {(props.appointment.feeCents / 100).toFixed(2)} - $
                      {(
                        (props.appointment.feeCents / 100) *
                        (2.99 / 100)
                      ).toFixed(2)}{" "}
                      fee)
                    </Text>
                  </View>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => {
                    setPayoutOption("instant");
                  }}
                  style={mystyles.payoutOptionOuter}
                >
                  <View style={mystyles.radioButtonRound}>
                    {payoutOption == "instant" ? (
                      <View style={mystyles.radioButtonInner}></View>
                    ) : null}
                  </View>
                  <View style={mystyles.payoutOptionTextOuter}>
                    <Text style={mystyles.payoutOptionText}>
                      Instant Payout* charges an additional 2% fee, for a total
                      of 4.99%, providing funds into your payout account
                      typically within 30 minutes. ($
                      {(props.appointment.feeCents / 100).toFixed(2)} - $
                      {(
                        (props.appointment.feeCents / 100) *
                        (4.99 / 100)
                      ).toFixed(2)}{" "}
                      fee)
                    </Text>
                  </View>
                </TouchableOpacity>
                {/* {availablePayoutDetails.ObjectType ? (
                  <View>
                    <Text style={mystyles.transferToText}>Transfer to</Text>
                    <View style={mystyles.transferToDetailsOuter}>
                      {availablePayoutDetails.ObjectType === "card" ? (
                        <View style={mystyles.transferCardOuter}>
                          {availablePayoutDetails.CardType == "Visa" ||
                          availablePayoutDetails.CardType == "Visa (debit)" ? (
                            <VisaCard />
                          ) : availablePayoutDetails.CardType == "MasterCard" ||
                            availablePayoutDetails.CardType ==
                              "Mastercard (2-series)" ||
                            availablePayoutDetails.CardType ==
                              "Mastercard (debit)" ||
                            availablePayoutDetails.CardType ==
                              "Mastercard (prepaid)" ? (
                            <MasterCard />
                          ) : availablePayoutDetails.CardType ==
                            "American Express" ? (
                            <AmericanExpressCard />
                          ) : availablePayoutDetails.CardType == "Discover" ? (
                            <DiscoverCard />
                          ) : availablePayoutDetails.CardType ==
                              "Diners Club" ||
                            availablePayoutDetails.CardType ==
                              "Diners Club (14 digit card)" ? (
                            <DinersCard />
                          ) : availablePayoutDetails.CardType == "JCB" ? (
                            <JcbCard />
                          ) : availablePayoutDetails.CardType == "UnionPay" ? (
                            <UnipayCard />
                          ) : (
                            <DefaultCard />
                          )}
                        </View>
                      ) : null}
                      <View style={mystyles.transferCardBankDetailsOuter}>
                        {availablePayoutDetails.ObjectType === "card" ? (
                          <Text style={mystyles.transferCardBankText}>
                            {availablePayoutDetails.CardType} ****{" "}
                            {availablePayoutDetails.CardLast4}
                          </Text>
                        ) : (
                          <Text style={mystyles.transferCardBankText}>
                            {availablePayoutDetails.BankName}{" "}
                            {availablePayoutDetails.AccountHolderName} ****{" "}
                            {availablePayoutDetails.BankAccountNumberLast4}
                          </Text>
                        )}
                      </View>
                    </View>
                  </View>
                ) : null} */}
                <Text style={mystyles.payoutGrayText}>
                  Instant Payouts are 24/7, including weekends and holidays, and
                  funds typically appear in the supported debit or bank account
                  within 30 minutes.
                </Text>
                <View style={mystyles.modalButtonsOuter}>
                  <ButtonBoldBig
                    text={
                      "Transfer $" +
                      (
                        props.appointment.feeCents / 100 -
                        parseFloat(
                          (props.appointment.feeCents / 100) *
                            ((payoutOption == "instant" ? 4.99 : 2.99) / 100)
                        ).toFixed(2)
                      ).toFixed(2)
                    }
                    style={mystyles.modalSaveButton}
                    onPress={() => {
                      setIsShowPayoutOptionModal(false);
                      showConfirmAlert();
                    }}
                  />
                </View>
              </View>
            </TouchableOpacity>
          </TouchableOpacity>
        </Modal>
      </View>
    </View>
  );
};

Appointment.defaultProps = {
  showButton: true,
  showCompleteCancelButton: true,
  showCompletedButton: true,
};

export default connect(
  (state) => ({
    user: state.auth.user,
  }),
  (dispatch) => ({
    getAvailablePayoutMethods: () => dispatch(getAvailablePayoutMethods()),
  })
)(Appointment);
