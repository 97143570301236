import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
// import geolocation from "@react-native-community/geolocation";
import {
  View,
  Text,
  ImageBackground,
  Switch,
  ScrollView,
  Alert,
  Platform,
  // BackHandler
} from "react-native";
import styles from "../Styles/AgentPortalstyle.js";
import { getCancelReasonList, setCancelReasonList } from "../redux/app/actions";
import { ButtonBoldBig } from "../Components/Button.js";
import HeaderImage from "../Images/Header";
import AppVersion from "../Images/Header2";
import HomeFooter from "../Components/HomeFooter";
import ActionSheetMenu from "../Components/ActionSheetMenu";
import BaseLayout, { headerStyleCustom } from "./BaseLayout";
// import AppUpdateModel from "./AppUpdateModel";
import {
  updateUser,
  updateUserToggle,
  updateUserCoords,
  getStripeLoginURL,
  setPayoutInfo,
  setPaymentInfo,
  isCheckStripeDone,
  getNotificationUnreadCount,
} from "../redux/auth/actions";
import { setReadNotification } from "../redux/messages/actions";
import {
  getBaseLocations
} from "../redux/appointments/actions";
// import { NavigationEvents } from "react-navigation";
import {
  clearCurrentListings,
  addPropertyListingType,
  addTimeZone,
  storePreviousPropertyList,
} from "../redux/address/actions";
import {
  resetCurrentAppointment,
  setCountryCode,
} from "../redux/appointments/actions";
import DeviceInfo from "react-native-device-info";
import ProcessLoadingIndicator from "./ProcessLoadingIndicator";
import LogsService from "../LogsService";
// import GPSLocationService from "../GPSLocationService";
// import AsyncStorage from "@react-native-async-storage/async-storage";
import moment, { utc } from "moment-timezone";
import {
  logFileDeleteTimingsHours,
  logFileDeleteTimingsMinues,
  logFileDeleteIntervalPeriod,
} from "../../config";
//  import Analytics from 'appcenter-analytics';

// AGENT HOME PAGE/PORTAL

const AgentPortal = (props) => {
  const [isLoadingPrepare, setIsLoadingPrepare] = useState(false);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
  const [locationType, setLocationType] = useState(false);

  const backAction = () => {
    LogsService.fileLog('AgentPortal', 'backAction', 'backAction clicked');
    if (props.navigation.isFocused()) {
      LogsService.fileLog('AgentPortal', 'backAction', 'Are you sure you want to close the app?');
      Alert.alert("Hold on!", "Are you sure you want to close the app?", [
        {
          text: "Cancel",
          onPress: () => null,
          style: "cancel",
        },
        {
          text: "YES", onPress: () => {
            LogsService.fileLog('AgentPortal', 'backAction onPress', 'YES clicked and exiting the app');
            // BackHandler.exitApp() 
          }
        },
      ]);
      return true;
    } else {
      return false;
    }
  };
  useEffect(() => {
    // BackHandler.addEventListener("hardwareBackPress", backAction);
    LogsService.fileLog('AgentPortal', 'useEffect', 'hardwareBackPress listerner added');
    // Analytics.trackEvent('AgentPortal', {Details: 'Useeffect called'});
    // checkForLogFileDelete();

    return () => {
      // BackHandler.removeEventListener("hardwareBackPress");
    };
  }, []);


  useEffect(() => {
    const unsubscribe = props.navigation.addListener('didFocus', () => {
      getBaseLocationsList();
    });
    return unsubscribe;
  }, []);

  const getBaseLocationsList = () => {
    LogsService.fileLog('AgentPortal', 'getBaseLocationsList', 'getBaseLocationsList is called');
    LogsService.fileLog('AgentPortal', 'getBaseLocationsList', 'getBaseLocations API is calling');
    props.getBaseLocations().then((resp) => {
      LogsService.fileLog('AgentPortal', 'getBaseLocationsList response', JSON.stringify(resp));
      console.log("getBaseLocationsList response=", resp)
      if (resp.status.toLowerCase() == "ok") {
        if (resp.Location_Type && resp.Location_Type === 2) {
          // GPSLocationService.requestLocationPermission();
        }
        // setLocationType(
        //   resp.Location_Type
        //     ? resp.Location_Type == "1"
        //       ? false
        //       : true
        //     : false
        // );
      } else if (resp.status.toLowerCase() == "error") {
        store.dispatch(setErrors(resp.errors, 10));
      }
    });
  };

  /**
   * checkForLogFileDelete()
   * Delete log file at specific time interval
   */
  // const checkForLogFileDelete = async () => {
  //   var recevieddate = JSON.parse(await AsyncStorage.getItem("lastLogFileDeletedTime"));
  //   console.log("recevieddate=",recevieddate);
  //   if(recevieddate){
  //     recevieddate = moment(recevieddate).add(logFileDeleteIntervalPeriod,'d').toDate(); // Adding logFileDeleteIntervalPeriod days into last deleted file date 
  //     console.log("recevieddateNew=",recevieddate);
  //     if(logFileDeleteTimingsHours)
  //       recevieddate.setHours(logFileDeleteTimingsHours); // Adding logFileDeleteTimingsHours hours into last deleted file date 
  //     if(logFileDeleteTimingsMinues)
  //     recevieddate.setMinutes(logFileDeleteTimingsMinues); // Adding logFileDeleteTimings Minutes into last deleted file date 
  //     recevieddate.setSeconds(0); // Adding logFileDeleteTimings Seconds into last deleted file date 
  //     var fileDate = moment(recevieddate).format("YYYY-MM-DD HH:mm:ss")
  //     var fileModifiedDate = new Date();
  //     var todayDate = new Date();
  //     if(fileDate){
  //       var dateArray = fileDate.split(' ');
  //       if (dateArray.length > 0){
  //         var splitDateArray = dateArray[0].split('-');
  //         var splitTimeArray = dateArray[1].split(':');
  //         fileModifiedDate.setFullYear(splitDateArray[0]);
  //         fileModifiedDate.setMonth(splitDateArray[1] -1);
  //         fileModifiedDate.setDate(splitDateArray[2]);
  //         fileModifiedDate.setHours(splitTimeArray[0]);
  //         fileModifiedDate.setMinutes(splitTimeArray[1]);
  //         fileModifiedDate.setSeconds(splitTimeArray[2]);
  //         if(todayDate.getTime() > fileModifiedDate.getTime()){
  //           LogsService.deleteFile(); // Delete the log file
  //           AsyncStorage.setItem('lastLogFileDeletedTime', JSON.stringify(new Date())); // Store the file deleted timings into storage
  //         }
  //       }
  //     }else{
  //       console.log('else');
  //       AsyncStorage.setItem('lastLogFileDeletedTime', JSON.stringify(new Date())); // Store the file deleted timings into storage
  //     }
  //   }else{
  //     AsyncStorage.setItem('lastLogFileDeletedTime', JSON.stringify(new Date())); // Store the file deleted timings into storage
  //   }
  // }

  const [isNofification, setNotifications] = useState(true);
  if (!props.loggedIn) {
    LogsService.fileLog('AgentPortal', '!props.loggedIn', 'User is not logged in and navigating to Login Screen');
    // props.navigation.navigate("Login");
  }
  var deviceInfoManufacturer = "";
  DeviceInfo.getManufacturer().then((manufacturer) => {
    deviceInfoManufacturer = manufacturer;
  });
  const checkStripeUserInfo = () => {
    LogsService.fileLog('AgentPortal', 'checkStripeUserInfo', 'checkStripeUserInfo called');
    setIsLoadingPrepare(true);
    isCheckStripeDone(props.user.id).then((response) => {
      setIsLoadingPrepare(false);
      LogsService.fileLog('AgentPortal', 'checkStripeUserInfo isCheckStripeDone', 'Response=>' + JSON.stringify(response));
      if (response && response.status === "ok") {
        props.setPayoutInfo(response.has_stripe_user);
        props.setPaymentInfo(response.payment_info);
        if (
          response &&
          (response.has_stripe_user === false ||
            response.has_stripe_user === null)
        ) {
          LogsService.fileLog('AgentPortal', 'checkStripeUserInfo isCheckStripeDone', 'has_stripe_user false or null and navigating to ConnectStripeForExistingUser');
          props.navigation.navigate("ConnectStripeForExistingUser");
        } else if (
          response &&
          (response.payment_info === false || response.payment_info === null)
        ) {
          LogsService.fileLog('AgentPortal', 'checkStripeUserInfo isCheckStripeDone', 'payment_info false or null and navigating to PaymentForExistingUser');
          props.navigation.navigate("PaymentForExistingUser");
        }
      }
    });
  };
  const checkNotificationUnreadCount = () => {
    LogsService.fileLog('AgentPortal', 'checkNotificationUnreadCount', 'checkNotificationUnreadCount is called');
    props.getNotificationUnreadCount().then((response) => {
      LogsService.fileLog('AgentPortal', 'checkNotificationUnreadCount response', JSON.stringify(response));
      var unreadCount = response.count || 0;
      if (unreadCount > 0) {
        props.setReadNotification(true);
      }
    });
  };
  var isLocationServiceEnabled = false;
  var checkIfLocationServiceEnabledCount = 0;
  const checkIfLocationServiceEnabled = () => {
    LogsService.fileLog('AgentPortal', 'checkIfLocationServiceEnabled', 'checkIfLocationServiceEnabled is called');
    checkIfLocationServiceEnabledCount++;
    setTimeout(() => {
      if (
        !isLocationServiceEnabled &&
        checkIfLocationServiceEnabledCount <= 5
      ) {
        LogsService.fileLog('AgentPortal', 'getCurrentPosition', 'getCurrentPosition is calling');
        // geolocation.getCurrentPosition(
        //   ({ coords }) => {
        //     console.log("coords=", coords);
        //     LogsService.fileLog('AgentPortal','getCurrentPosition response', JSON.stringify(coords));
        //     isLocationServiceEnabled = true;
        //     updateUserCoords(coords);
        //   },
        //   (error) => {
        //     LogsService.fileLog('AgentPortal','getCurrentPosition error', JSON.stringify(error));
        //   },
        //   { enableHighAccuracy: true, timeout: 15000, maximumAge: 10000 }
        // );
        checkIfLocationServiceEnabled();
      }
    }, 10000);
    if (checkIfLocationServiceEnabledCount == 1) updateUserInfo(null);
  };
  const updateUserInfo = (userCoords) => {
    LogsService.fileLog('AgentPortal', 'updateUserInfo', 'updateUserInfo is called');
    if (userCoords != null && userCoords.latitude && userCoords.longitude) {
      const lat = userCoords.latitude;
      const lng = userCoords.longitude;
      props.updateUser({
        ...props.user,
        lat,
        lng,
        notification_token: props.pushnotificationToken.token,
        os: Platform.OS,
        app_version: DeviceInfo.getVersion(),
        platform_version:
          Platform.OS == "ios"
            ? Platform.constants.osVersion
            : Platform.constants.Release,
        devise_model: DeviceInfo.getModel(),
        manufacturer: deviceInfoManufacturer,
      });
      LogsService.fileLog('AgentPortal', 'updateUserInfo', 'updateUserInfo updated with lat long');
    } else {
      props.updateUser({
        ...props.user,
        notification_token: props.pushnotificationToken.token,
        os: Platform.OS,
        app_version: DeviceInfo.getVersion(),
        platform_version:
          Platform.OS == "ios"
            ? Platform.constants.osVersion
            : Platform.constants.Release,
        devise_model: DeviceInfo.getModel(),
        manufacturer: deviceInfoManufacturer,
      });
      LogsService.fileLog('AgentPortal', 'updateUserInfo', 'updateUserInfo updated without lat long');
    }
  };

  const updateUserCoords = (userCoords) => {
    LogsService.fileLog('AgentPortal', 'updateUserCoords', 'updateUserCoords is called');
    if (userCoords != null && userCoords.latitude && userCoords.longitude) {
      const lat = userCoords.latitude;
      const lng = userCoords.longitude;
      props.updateUserCoords(props.user, {
        lat: lat + "",
        lng: lng + "",
      });
      LogsService.fileLog('AgentPortal', 'updateUserCoords', 'updateUserCoords updated with lat long');
    }
  };

  const refreshButtonNavBack = () => {
    LogsService.fileLog('AgentPortal', 'refreshButtonNavBack', 'refreshButtonNavBack is licked');
    if (props.loggedIn) {
      LogsService.fileLog('AgentPortal', 'refreshButtonNavBack is logged in', props.loggedIn);
      setNotifications(props.user.acceptingShowings);
      if (!props.isUserStripePayoutInfo || !props.isUserStripePaymentInfo) {
        LogsService.fileLog('AgentPortal', 'refreshButtonNavBack', 'checkStripeUserInfo is calling');
        checkStripeUserInfo();
      }
    }
  };

  useEffect(() => {
    LogsService.fileLog('AgentPortal', 'useEffect', 'useEffect is called');
    if (props.loggedIn) {
      checkIfLocationServiceEnabled();
      LogsService.fileLog('AgentPortal', 'useEffect checkStripeUserInfo', 'checkStripeUserInfo is calling');
      checkStripeUserInfo();
      checkNotificationUnreadCount();
      setNotifications(props.user.acceptingShowings);
      LogsService.fileLog('AgentPortal', 'useEffect getCancelReasonList', 'getCancelReasonList is calling');
      getCancelReasonList()
        .then((response) => {
          // LogsService.fileLog('AgentPortal','useEffect getCancelReasonList response', JSON.stringify(response));
          LogsService.fileLog('AgentPortal', 'useEffect getCancelReasonList response', "Setting the cancel reason list to props");
          props.setCancelReasonList(response.CancelReasonList);
        })
        .catch((error) => {
          LogsService.fileLog('AgentPortal', 'useEffect getCancelReasonList error', JSON.stringify(error));
        });
    }
  }, [props.user.id]);

  const onNoticationChange = (value) => {
    LogsService.fileLog('AgentPortal', 'onNoticationChange', 'onNoticationChange is called');
    LogsService.fileLog('AgentPortal', 'onNoticationChange toggle', value);
    setNotifications(value);
    props.updateUserToggle({
      ...props.user,
      acceptingShowings: value,
    });

    var userDetails = { ...props.user };
    userDetails['acceptingShowings'] = value;
    console.log('userDetails=', userDetails);
    setIsLoadingUpdate(true);
    props.updateUserToggle(userDetails).then((resp) => {
      setIsLoadingUpdate(false);
      console.log("updateUserToggle response=", resp)
    }).catch((error) => {
      setIsLoadingUpdate(false);
      LogsService.fileLog('AgentPortal', 'updateUserToggle error', JSON.stringify(error));
    });
  };

  return (
    <BaseLayout>
      <View style={{ flex: 1 }}>
        {/* <NavigationEvents onDidFocus={() => refreshButtonNavBack()} /> */}
        <ImageBackground
          source={require("../../assets/KeyPleazBackground.png")}
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          {isLoadingPrepare && (
            <ProcessLoadingIndicator
              message="Please wait while we prepare your dashboard"
              backColor="#fff"
            />
          )}
          {isLoadingUpdate && (
            <ProcessLoadingIndicator
              message="Please wait while we update the notification setting."
              backColor="#ffffff40"
            />
          )}
          {/* <AppUpdateModel /> */}
          <ScrollView style={{ marginTop: -40 }}>
            <View style={styles.container}>
              <Text style={styles.agentText}>Welcome to KeyPleaz®</Text>
              <Text style={styles.keyWelcome}>Let's Get Started!</Text>
              <View style={styles.cardView}>
                <Text style={styles.textKey}>Schedule an Agent ...</Text>
                <View style={styles.textBtnCenter}>
                  <ButtonBoldBig
                    text={"HOME SHOWING"}
                    style={styles.widthStyle}
                    onPress={() => {
                      LogsService.fileLog('AgentPortal','onPress', 'HOME SHOWING is clicked');
                      if (!props.isUserStripePayoutInfo) {
                        LogsService.fileLog('AgentPortal','onPress', 'user do not have stripe payout info and navigating to ConnectStripeForExistingUser page');
                        props.navigation.navigate(
                          "ConnectStripeForExistingUser"
                        );
                      } else if (!props.isUserStripePaymentInfo) {
                        LogsService.fileLog('AgentPortal','onPress', 'user do not have stripe payment info and navigating to PaymentForExistingUser page');
                        props.navigation.navigate("PaymentForExistingUser");
                      } else {
                        let tempdeletedAddressStored = [];
                        props.setCountryCode("+1");
                        props.storePreviousPropertyList({
                          tempdeletedAddressStored,
                        });
                        LogsService.getDataFromSQLTE();
                        props.addPropertyListingType("HOMESHOWING");
                        props.addTimeZone({});
                        props.clearCurrentListings();
                        props.resetCurrentAppointment();
                        LogsService.fileLog('AgentPortal','onPress', 'navigating to CreateListing page');
                        props.navigation.navigate("CreateListing");
                        // props.navigation.navigate("StripePayment");
                        // props.navigation.navigate("MyPaymentsSelect");
                      }
                    }}
                  />
                </View>
                <View style={styles.btnView1}>
                  <ButtonBoldBig
                    text={"OPEN HOUSE"}
                    style={styles.widthStyle}
                    onPress={() => {
                      LogsService.fileLog('AgentPortal', 'onPress', 'OPEN HOUSE is clicked');
                      if (!props.isUserStripePayoutInfo) {
                        LogsService.fileLog('AgentPortal', 'onPress', 'user do not have stripe payout info and navigating to ConnectStripeForExistingUser page');
                        props.navigation.navigate(
                          "ConnectStripeForExistingUser"
                        );
                      } else if (!props.isUserStripePaymentInfo) {
                        LogsService.fileLog('AgentPortal', 'onPress', 'user do not have stripe payment info and navigating to PaymentForExistingUser page');
                        props.navigation.navigate("PaymentForExistingUser");
                      } else {
                        let tempdeletedAddressStored = [];
                        props.setCountryCode("+1");
                        props.storePreviousPropertyList({
                          tempdeletedAddressStored,
                        });
                        props.addPropertyListingType("OPENHOUSE");
                        props.addTimeZone({});
                        props.clearCurrentListings();
                        props.resetCurrentAppointment();
                        LogsService.fileLog('AgentPortal', 'onPress', 'navigating to CreateListing page');
                        props.navigation.navigate("CreateListing");
                      }
                    }}
                  />
                </View>
                <View style={styles.btnView1}>
                  <ButtonBoldBig
                    text={"RUNNER"}
                    style={styles.widthStyle}
                    onPress={() => {
                      LogsService.fileLog('AgentPortal', 'onPress', 'RUNNER is clicked');
                      if (!props.isUserStripePayoutInfo) {
                        LogsService.fileLog('AgentPortal', 'onPress', 'user do not have stripe payout info and navigating to ConnectStripeForExistingUser page');
                        props.navigation.navigate(
                          "ConnectStripeForExistingUser"
                        );
                      } else if (!props.isUserStripePaymentInfo) {
                        LogsService.fileLog('AgentPortal', 'onPress', 'user do not have stripe payment info and navigating to PaymentForExistingUser page');
                        props.navigation.navigate("PaymentForExistingUser");
                      } else {
                        let tempdeletedAddressStored = [];
                        props.setCountryCode("+1");
                        props.storePreviousPropertyList({
                          tempdeletedAddressStored,
                        });
                        props.addPropertyListingType("RUNNER");
                        props.addTimeZone({});
                        props.clearCurrentListings();
                        props.resetCurrentAppointment();
                        LogsService.fileLog('AgentPortal', 'onPress', 'navigating to RunnerDescription page');
                        props.navigation.navigate("RunnerDescription");
                      }
                    }}
                  />
                </View>
              </View>

              <View style={styles.showView}>
                {props.user.acceptingShowings && (
                  <View>
                    <Text style={styles.opportunityText}>Available ...</Text>
                    <View style={styles.textBtnCenter}>
                      <ButtonBoldBig
                        text={"OPPORTUNITIES"}
                        style={styles.widthStyle}
                        onPress={() => {
                          LogsService.fileLog('AgentPortal', 'onPress', 'OPPORTUNITIES is clicked');
                          if (!props.isUserStripePayoutInfo) {
                            LogsService.fileLog('AgentPortal', 'onPress', 'user do not have stripe payout info and navigating to ConnectStripeForExistingUser page');
                            props.navigation.navigate(
                              "ConnectStripeForExistingUser"
                            );
                          } else if (!props.isUserStripePaymentInfo) {
                            LogsService.fileLog('AgentPortal', 'onPress', 'user do not have stripe payment info and navigating to PaymentForExistingUser page');
                            props.navigation.navigate("PaymentForExistingUser");
                          } else {
                            LogsService.fileLog('AgentPortal', 'onPress', 'navigating to AvaliableShowing page');
                            props.navigation.navigate("AvaliableShowing");
                          }
                        }}
                      />
                    </View>
                  </View>
                )}
                <View
                  style={[
                    styles.notificationView,
                    {
                      flexDirection: "row",
                      justifyContent: "space-around",
                      alignItems: "center",
                      paddingHorizontal: 25,
                    },
                  ]}
                >
                  <Text style={{ color: "#4990C8", fontSize: 22, left: 20 }}>
                    Notifications{" "}
                  </Text>
                  <Switch
                    style={{
                      marginTop: 5,
                      marginBottom: 0,
                      left: 25,
                      color: "red",
                    }}
                    value={isNofification}
                    onValueChange={(value) => onNoticationChange(value)}
                  />
                </View>
              </View>
            </View>
          </ScrollView>
          <HomeFooter brokerageURL={props.user.ImageURL ? props.user.ImageURL : ''} />
        </ImageBackground>
      </View>
    </BaseLayout>
  );
};

AgentPortal.navigationOptions = ({ navigation }) => ({
  headerStyle: headerStyleCustom(),
  headerTintColor: "#fff",
  headerTitleStyle: {
    fontWeight: "bold",
  },
  headerBackTitle: "Back",
  headerLeft: <AppVersion height={35} />,
  headerTitle: () => <HeaderImage height={35} />,
  headerRight: () => (
    <ActionSheetMenu numberOfLines={1} navigation={navigation} />
  ),
});

export default connect(
  (state) => ({
    user: state.auth.user,
    loggedIn: state.auth.loggedIn,
    isUserStripePayoutInfo: state.auth.isUserStripePayoutInfo,
    isUserStripePaymentInfo: state.auth.isUserStripePaymentInfo,
    pushnotificationToken: state.auth.pushnotificationToken,
  }),
  (dispatch) => ({
    updateUser: (user) => dispatch(updateUser(user)),
    updateUserToggle: (user) => dispatch(updateUserToggle(user)),
    updateUserCoords: (user, location) => dispatch(updateUserCoords(user, location)),
    getCancelReasonList: (user) => dispatch(getCancelReasonList(user)),
    setCancelReasonList: (list) => dispatch(setCancelReasonList(list)),
    getBaseLocations: () => dispatch(getBaseLocations()),
    getStripeLoginURL: () => dispatch(getStripeLoginURL()),
    getNotificationUnreadCount: () => dispatch(getNotificationUnreadCount()),
    setReadNotification: (isUnRead) => dispatch(setReadNotification(isUnRead)),
    isCheckStripeDone: (id) => dispatch(isCheckStripeDone(id)),
    addPropertyListingType: (propertylistingtype) =>
      dispatch(addPropertyListingType(propertylistingtype)),
    addTimeZone: (timezone) => dispatch(addTimeZone(timezone)),
    setCountryCode: (countryCode) => dispatch(setCountryCode(countryCode)),
    setPayoutInfo: (setPayoutFlag) => dispatch(setPayoutInfo(setPayoutFlag)),
    setPaymentInfo: (setPayoutFlag) => dispatch(setPaymentInfo(setPayoutFlag)),
    clearCurrentListings: () => dispatch(clearCurrentListings()),
    resetCurrentAppointment: () => dispatch(resetCurrentAppointment()),
    storePreviousPropertyList: (address) =>
      dispatch(storePreviousPropertyList(address)),
  })
)(AgentPortal);
