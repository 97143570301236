import {
  Set_API_Base_URL,
  Set_User_Profile_Image,
  SetCancelReasonList,
} from "./actions";

const initialState = {
  Set_API_Base_URL: null,
  Set_User_Profile_Image: null,
  SetCancelReasonList: null,
};

// Reducers (Modifies The State And Returns A New State)
const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case Set_API_Base_URL: {
      return {
        ...state,
        APIBaseURL: action.APIBaseURL,
      };
    }
    case Set_User_Profile_Image: {
      return {
        ...state,
        UserProfileImage: action.UserProfileImage,
      };
    }
    case SetCancelReasonList: {
      return {
        ...state,
        CancelReasonList: action.CancelReasonList,
      };
    }
    // Default
    default: {
      return state;
    }
  }
};

export default appReducer;
