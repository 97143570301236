export function getTimezoneAbbreviation(timeZoneName) {
  if (
    timeZoneName == "Mountain Daylight Time" ||
    timeZoneName == "Mountain Standard Time"
  )
    return " (MST)";
  if (
    timeZoneName == "Pacific Daylight Time" ||
    timeZoneName == "Pacific Standard Time"
  )
    return " (PST)";
  if (
    timeZoneName == "Eastern Daylight Time" ||
    timeZoneName == "Eastern Standard Time"
  )
    return " (EST)";
  if (
    timeZoneName == "Central Daylight Time" ||
    timeZoneName == "Central Standard Time"
  )
    return " (CST)";
  if (timeZoneName == "India Standard Time") return " (IST)";
  return "";
}
