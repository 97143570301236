import React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={18} height={18} viewBox="0 0 18 18" {...props}>
      <Path
        d="M9 18a9 9 0 119-9 9.01 9.01 0 01-9 9zM9 1.161A7.839 7.839 0 1016.839 9 7.848 7.848 0 009 1.161zm0 12.92a1.017 1.017 0 111.016-1.017A1.018 1.018 0 019 14.081zm.278-2.9H8.7a.436.436 0 01-.436-.435v-.014A2.724 2.724 0 019.915 8.26c.621-.391 1.158-.729 1.158-1.429 0-.726-.645-1.459-2.084-1.459a2.4 2.4 0 00-2.149 1.04.433.433 0 01-.59.088l-.477-.332a.435.435 0 01-.182-.294.428.428 0 01.086-.329 3.826 3.826 0 013.311-1.626 4.065 4.065 0 012.48.769 2.62 2.62 0 011.056 2.142 2.755 2.755 0 01-1.634 2.424c-.632.413-1.177.769-1.177 1.474v.014a.436.436 0 01-.435.435z"
        fill="#ff8959"
      />
    </Svg>
  );
}

export default SvgComponent;
