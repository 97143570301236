import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  container: {
    height: "100%",
    width: "100%",
    backgroundColor: "#fff",
    justifyContent: "flex-start",
  },
  Paragraph: {
    fontSize: 40,
    fontWeight: "bold",
    color: "#ffffff",
    backgroundColor: "#4682b4",
    paddingTop: 55,
    textAlign: "center",
  },
  Agent: {
    fontSize: 25,
    fontWeight: "bold",
    color: "#ffffff",
    textAlign: "center",
    paddingTop: 10,
  },
  Agent2: {
    fontSize: 25,
    fontWeight: "bold",
    color: "#ffffff",
    textAlign: "center",
    marginTop: 5,
  },
  real: {
    flex: 1,
    backgroundColor: "#ffffff",
    justifyContent: "center",
    paddingTop: 265,
  },
  susan: {
    textAlign: "center",
    color: "#4682b4",
    fontSize: 25,
    fontWeight: "bold",
    paddingBottom: 100,
    paddingRight: 65,
  },
  Famous: {
    fontSize: 15,
    fontWeight: "normal",
    textAlign: "center",
  },

  sell: {
    backgroundColor: "#4682b4",
    justifyContent: "flex-end",
  },
  fast: {
    color: "#ffffff",
    fontSize: 25,
    fontWeight: "bold",
    textAlign: "center",
    paddingBottom: 50,
  },
  it: {
    color: "#a9a9a9",
    fontSize: 25,
    fontWeight: "bold",
  },
  It: {
    color: "#ffffff",
    fontSize: 25,
    fontWeight: "bold",
  },
  Color: {
    color: "#a9a9a9",
    fontSize: 25,
    fontWeight: "bold",
  },
  showDetails: {
    backgroundColor: "#215F90",
    height: "auto",
  },
  listNo: {
    color: "#A3C7E3",
    fontSize: 10,
    marginLeft: -3,
  },
  listNo1: {
    color: "#A3C7E3",
    fontSize: 10,
    marginLeft: 10,
  },
  lineDown: {
    borderBottomWidth: 1,
    borderColor: "#000",
    width: "90%",
  },
  scheduleText: {
    color: "#A3C7E3",
    fontSize: 12,
    marginTop: 2,
  },
  showName: {
    fontSize: 25,
    color: "#4990C8",
    fontWeight: "bold",
  },
  Location: {
    color: "#4990C8",
    fontSize: 15,
  },
  widthStyle: {
    width: 180,
  },
  headerImage: {
    height: 80,
    alignSelf: "center",
  },
  card: {
    backgroundColor: "#fff",
    height: "50%",
    marginTop: "10%",
    padding: 10,
    marginBottom: "10%",
    borderRadius: 10,
    borderWidth: 1,
  },
  textmsg: {
    marginRight: 40,
    marginLeft: 40,
    marginTop: 10,
    fontSize: 18,
    paddingTop: 20,
    paddingBottom: 20,
  },
  buttonok: {
    width: "10%",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#e65c00",
    borderRadius: 10,
    borderWidth: 1,
    borderColor: "#fff",
    position: "absolute",
    color: "#e91e63",
    right: 0,
  },
  buttonok1: {
    marginRight: 40,
    marginLeft: 40,
    marginTop: 100,
    paddingTop: 12,
    width: "30%",
    color: "#fff",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 12,
    backgroundColor: "#e65c00",
    borderRadius: 10,
    borderWidth: 1,
    borderColor: "#fff",
    position: "absolute",
    right: "32%",
  },
  radioButtonList: {
    marginHorizontal: 30,
    marginVertical: 20,
  },
  radioButtonOuter: {
    borderBottomColor: "#E2E9EE",
    borderBottomWidth: 1,
    paddingVertical: 10,
    flexDirection: "row",
    alignItems: "center",
  },
  radioButtonRound: {
    width: 25,
    height: 25,
    borderColor: "#EC8F64",
    borderWidth: 2,
    borderRadius: 12.5,
    flexDirection: "column",
    marginRight: 15,
    alignItems: "center",
    justifyContent: "center",
  },
  radioButtonInner: {
    width: 10,
    height: 10,
    borderRadius: 5,
    backgroundColor: "#EC8F64",
  },
  radioButtonTextOuter: {
    flexDirection: "column",
  },
  radioButtonMainText: {
    fontSize: 16,
    color: "#63696F",
  },
  radioButtonSmallText: {
    fontSize: 12,
    color: "#63696F",
  },
  radioButtonDisableTextColor: {
    color: "#ADB4BC",
  },
});
export default styles;
