/**
 * LogsService.js
 * This service store the logs into device file.
 **/ 

// var RNFS = require('react-native-fs');
// import Share from 'react-native-share'
// import { cacheFileName, getLogsFlag, setLogsFlag } from "../config"; 
// import {
//   setMasageFromScreen,
//   shareLogFile
// } from "./redux/auth/actions";
// import { store } from "./redux/store";
// import { setMessages } from "./redux/messages/actions";
// import * as api from "./redux/auth/api";
// import { openDatabase } from "react-native-sqlite-storage";
// import { 
//   logFileDeleteIntervalPeriod, 
//  } from "../config"; 
//  import moment, { utc } from "moment-timezone";

// const db = openDatabase({
//   name: "applogs_sqlite",
// });

// On Android, use "RNFS.DocumentDirectoryPath" (MainBundlePath is not defined)
// let _documentpath = RNFS.DocumentDirectoryPath; //directory path
// console.log('path=',_documentpath);
// let _filepath = _documentpath + '/' + cacheFileName; // File path


/**
 * Share file using social media links
 */
function shareFile(id){
  // setLogsFlag(false);
  // let _path = RNFS.DocumentDirectoryPath + '/' + cacheFileName; // File path
  //   return api.shareLogFile(id, _path)
  //   .then((response) => {
  //     console.log('shareLogFile Response=',response);
  //     setLogsFlag(true);
  //     return ''
  //   }).catch((error) => {
  //     console.log('ShareLogFile error=',error);
  //     setLogsFlag(true);
  //     return ''
  //   })  
  }


/**
 * fileLog(screenName, functionName, logMessage)
 * screenName: Name of the logged screen
 * functionName: Name of the logged function
 * logMessage: Actual log message
 * 1. This function read the document directory and identify if the file is exist or not.
 * 2. If it is not present then it will create the text file   
 * 3. If it is present then it will write the logs into that file
 * 4. Logs functionality only be enabled when the logs enabled from flag
 */
function fileLog(screenName, functionName, logMessage){
	// if(getLogsFlag()){
  //   createSQLIETable();
	// 	RNFS.readDir(_documentpath)
	// 	.then((result) => {		
	// 		// stat the Log file
	// 		if(result.length>0){
	// 		var foundIndex = result.findIndex(ele => ele.path.includes(cacheFileName));
  //     if(foundIndex > -1){
	// 		  return Promise.all([RNFS.stat(result[foundIndex].path), result[foundIndex].path]);
  //     }else{
  //       return Promise.all([]);
  //     }
	// 		}else{
	// 		return Promise.all([]);
	// 		}
	// 	})
	// 	.then((statResult) => {
  //     var logContent = "";
	// 		if (statResult.length > 0 && statResult[0].isFile()) {
  //         logContent = prepareLogContent(screenName, functionName, logMessage);
  //         // appeandDataToFile(logContent);
  //         insertLogsInSQLITE(logContent);
  //       }else{
  //         logContent = prepareLogContent(screenName, functionName, logMessage);
  //         writeLogsIntoFile(logContent);
  //         insertLogsInSQLITE(logContent);
  //       }
	// 	})
	// 	.catch((error) => {
	// 		console.log('error=',error.message, error.code);
	// 	});
	// }
  };


/**
 * writeLogsIntoFile()
 * 1. Write logs in the file
 */
  function writeLogsIntoFile(data){
    // write the file
    // RNFS.writeFile(_filepath, data, 'utf8')
    //   .then((success) => {
    //     console.log('FILE WRITTEN!');
    //     console.log('success=',success);
    //   })
    //   .catch((err) => {
    //     console.log('err=',err.message);
    //   });
  }


/**
 * appeandDataToFile()
 * 1. appeand logs to existing file
 */
  function appeandDataToFile(data){
    // console.log('_filepath=',_filepath);
    // RNFS.write(_filepath, data, -1,'utf8')
    //   .then((success) => {
    //     // console.log('FILE data Append!');
    //   })
    //   .catch((err) => {
    //     console.log('err=',err.message);
    //   });
  }

/**
 * prepareLogContent(screenName, functionName, logMessage)
 * Prepare log content using given data
 */
   function prepareLogContent(screenName, functionName, logMessage) {
    //  var dateString = new Date() + "";
    //  var finalLogstring = "";
    //  finalLogstring = dateString + " :: " + "Screen : " + screenName + ", Function : " + functionName + ", LOG : " + logMessage + "\n\n"; 
    // return finalLogstring
  }


/**
 * deleteFile()
 * 1. Delete the file
 */
  function deleteFile() {
      // return RNFS.unlink(_filepath)
      //   .then(() => {
      //     // setMasageFromScreen("LOG FILE DELETED SUCCESSFULLY");
      //   })
      //   // `unlink` will throw an error, if the item to unlink does not exist
      //   .catch((err) => {
      //     alert(err)
      //     console.log('delete file error= ',err.message);
      //   });
    }

    /**
     * Create a SQLite table named as "logs" to store the app logs into database
     * Fields: id - autoincremented id for the log
     * log_name - Actual formated content to write the log.
     */
    function createSQLIETable() {
      // db.transaction(txn => {
      //   txn.executeSql(
      //     `CREATE TABLE IF NOT EXISTS logs (id INTEGER PRIMARY KEY AUTOINCREMENT, log_name VARCHAR(1000), timestamp varchar (20))`,
      //     [],
      //     (sqlTxn, res) => {
      //       // console.log("logs table created successfully");
      //     },
      //     error => {
      //       console.log("error on creating logs table " + error.message);
      //     },
      //   );
      // });
    };

/**
 * Insert formatted logs content into the SQLite storage
 * @param {*} logContent 
 */
  function insertLogsInSQLITE(logContent) {
      // db.transaction(txn => {
  
      //       txn.executeSql(
      //         `INSERT INTO logs (log_name, timestamp) VALUES (?,?)`,
      //         [logContent, new Date().getTime()],
      //         (sqlTxn, res) => {
      //           // console.log(`${logContent} log added successfully`);
      //         },
      //         error => {
      //           console.log("error on adding log " + error.message);
      //         },
      //       );
      // });
    };

/**
 * Get logs data from SQLITE storage
 */
  function getDataFromSQLTE() {
    // db.transaction(txn => {
    //   txn.executeSql(
    //     `SELECT * FROM logs ORDER BY id`,
    //     [],
    //     async(sqlTxn, res) => {
    //       console.log("logs retrieved successfully");
    //       let len = res.rows.length;
    //       console.log("len=",len);
    //       if (len > 0) {
    //         let results = [];
    //         var completelogStr = "";
    //         for (let i = 0; i < len; i++) {
    //           let item = res.rows.item(i);
    //           completelogStr = completelogStr + "\n" + item.log_name;
    //         }
    //         writeLogs(completelogStr, len);
    //       }
    //     },
    //     error => {
    //       console.log("error on getting logs " + error.message);
    //     },
    //   );
    // });
  }

  /**
   * Delete the logs data from SQLITE database previous of 8 days
   */
 function deleteDataFromSQLITE () {
    // db.transaction(txn => {
    //   var timestamp = (moment(new Date()).subtract(logFileDeleteIntervalPeriod,'d').toDate()).getTime();
    //   txn.executeSql(
    //     `DELETE FROM logs WHERE timestamp < ${timestamp}`,
    //     // `DROP TABLE logs`,
    //     [],
    //     (sqlTxn, res) => {
    //       console.log("Logs of "+ logFileDeleteIntervalPeriod + "days before table data deleted successfully");
    //     },
    //     error => {
    //       console.log("error on deleting table " + error.message);
    //     },
    //   );
    // });
  };


  /**
   * Write a logs data into file.
   * @param {*} completelogStr 
   * @param {*} len 
   */
  async function writeLogs (completelogStr,len) {
    // await RNFS.write(RNFS.DocumentDirectoryPath + "/" + cacheFileName, completelogStr, -1, 'utf8')
    // .then((success) => {
    //   console.log('FILE WRITTEN!');
    //   console.log("length=",len);
    // })
    // .catch((err) => {
    //   console.log('err=',err.message);
    // });
  };
    
// add other logs functions that you need and export them

export default {
  fileLog,
  deleteFile,
  shareFile,
  getDataFromSQLTE,
  deleteDataFromSQLITE,
};
