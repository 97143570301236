import React, { useEffect, useState, useRef } from "react";
import {
  ScrollView,
  View,
  KeyboardAvoidingView,
  Text,
  TouchableOpacity,
  Alert,
  TextInput,
  Modal,
  Dimensions,
  Platform
} from "react-native";
import { connect } from "react-redux";
import styles from "./styles";
import BaseLayout, { headerStyleCustom } from "./../BaseLayout";
import HeaderImage from "../../Images/Header";
import ActionSheetMenu from "../../Components/ActionSheetMenu";
import ProcessLoadingIndicator from "./../ProcessLoadingIndicator";
import CheckboxWithText from "../../Components/CheckboxWithText";
import LogsService from "../../LogsService";
import Header from "../../Components/Signup/Header";
import Title from "../../Components/Signup/Title";
// import { NavigationEvents } from "react-navigation";
import Form from "../../Components/Form";
// import Mail from "../../Images/Mail";
// import Card from "../../Images/Card";
import CardWhite from "../../Images/CardWhite";
import CardBlack from "../../Images/CardBlack";
// import Hash from "../../Images/Hash";
// import Bank from "../../Images/Bank";
import BankBlack from "../../Images/BankBlack";
import BankWhite from "../../Images/BankWhite";

import { ButtonOne } from "../../Components/Button";
import { key } from "../../../assets/SvgImages";

const StripePayment = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [loaderText, setLoaderText] = useState(
    "Processing request, please wait."
  );

  const [isActive, setIsActive] = useState("2");
  const [bankName, setBankName] = useState("");
  const [routingNumber, setRoutingNumber] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [name, setName] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [expirationMonth, setExpirationMonth] = useState("");
  const [expirationYear, setExpirationYear] = useState("");
  const [cvv, setCvv] = useState("");
  const [businessProfile, setBusinessProfile] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [statee, setStatee] = useState("");
  const [zip, setZip] = useState("");

  const savePress = () =>{
    if(isActive === "1"){
        if(bankName.trim() == "" || routingNumber.trim() == "" || accountNumber.trim() == ""){
            Alert.alert("", "All fields are mandatory", [
                { text: "OK", onPress: () => {
                } },
              ]);
        }else{

        }
    }else{
        if(
            name.trim() == "" ||
            cardNumber.trim() == "" || 
            expirationMonth.trim() == "" ||
            expirationYear.trim() == "" ||
            cvv.trim() == ""
        ){
            Alert.alert("", "All fields are mandatory", [
                { text: "OK", onPress: () => {
                } },
              ]);
        }else{
            
        }
    }
  };
  
  useEffect(() => {
    LogsService.fileLog('StripePayment','useEffect', 'useEffect is called');
    }, []);

  return (
    <BaseLayout>
      <View style={styles.container}>
        {isLoading && (
          <ProcessLoadingIndicator message={loaderText} backColor="" />
        )}
        <Header total={4} complete={3} />
        {/* <NavigationEvents onDidFocus={() => this.refreshButtonNavBack()} /> */}
          <KeyboardAvoidingView
            style={{ flex: 9, flexDirection: "column" }}
            behavior={Platform.OS == "ios" ? "padding" : null}
            enabled
            keyboardVerticalOffset={100}
          >
            <ScrollView keyboardShouldPersistTaps="handled">
              <Title text="Choose where you'd like to receive your payouts." />
                <View style={styles.payoutOptionsOuter}>
                                {/* <TouchableOpacity 
                                    style={
                                        isActive == "1"
                                            ? [styles.payoutOuter, styles.activePayoutOuter]
                                            : styles.payoutOuter}
                                    onPress={() => {
                                        setIsActive("1")
                                    }}
                                    >
                                    {isActive == "1" 
                                        ? <BankWhite />
                                        : <BankBlack />
                                    }
                                    <Text style={
                                        isActive == "1" 
                                        ? [styles.title, styles.activeTitle]
                                        : styles.title
                                        }>{"Bank Account"}</Text>
                                </TouchableOpacity> */}
                                <TouchableOpacity 
                                    style={
                                        isActive == "2"
                                            ? [styles.payoutOuter, styles.activePayoutOuter]
                                            : styles.payoutOuter}
                                    onPress={() => {
                                        setIsActive("2")
                                    }}
                                    >
                                    {/* {isActive == "2" 
                                        ? <CardWhite /> 
                                        : <CardBlack />
                                        } */}
                                    <Text style={
                                        isActive == "2" 
                                        ? [styles.title, styles.activeTitle]
                                        : styles.title
                                        }>{"Debit Card"}</Text>
                                </TouchableOpacity>
                                <View style={styles.seperator} />
                </View>
                <Text style={styles.detailsTitle} >{isActive === "1" ? "Bank Account Details" : "Card Details" } </Text>
                {isActive === "1" && 
                    <Form
                    fields={[
                    {
                        label: "Bank name",
                        key: "bankName",
                        value: bankName,
                        required: true,
                        maxlength:100,
                        type:"text",
                        // image: () => Bank,
                    },
                    {
                        label: "Routing Number",
                        key: "routingNumber",
                        value: routingNumber,
                        required: true,
                        // maxlength:100,
                        type:"numeric",
                        // image: () => Card,
                    },
                    {
                        label: "Account Number",
                        key: "accountNumber",
                        value: accountNumber,
                        // maxlength:16,
                        required: true,
                        type:"numeric",
                        // image: () => Hash,
                    }
                    ]}
                    onChange={(k, v) => {
                        if(k === "bankName"){
                            setBankName(v);
                        }else if(k === "routingNumber"){
                            setRoutingNumber(v);
                        }else if(k === "accountNumber"){
                            setAccountNumber(v);
                        }else{}
                    }}
                />
              }
               {isActive === "2" &&
                <View>
                  <View
                    style={{ flexDirection: "row", alignItems: "flex-start", marginLeft:30 }}
                  >
                    <CheckboxWithText
                      // onPress={this.handlePress}
                      // checked={this.props.rememberMe}
                      text="Use same Card details for Payment"
                      textStyle={styles.loggedTxt}
                      style={{
                        marginTop: 8,
                      }}
                    />
                  </View>

                  <Form
                    fields={[
                    {
                        label: "Name on Card",
                        key: "name",
                        value: name,
                        required: true,
                        maxlength:100,
                        type:"text",
                        // image: () => Card,
                    },
                    {
                        label: "Credit Card Number",
                        key: "cardNumber",
                        value: cardNumber,
                        maxlength:16,
                        required: true,
                        type:"numeric",
                        // image: () => Hash,
                    },
                    {
                        label: "Expiration Month (MM)",
                        key: "expirationMonth",
                        value: expirationMonth,
                        maxlength:2,
                        type:"numeric",
                        required: true,
                    },
                    {
                        label: "Expiration Year (YYYY)",
                        key: "expirationYear",
                        value: expirationYear,
                        maxlength:4,
                        type:"numeric",
                        required: true,
                    },
                    {
                        label: "CVV",
                        key: "cvv",
                        value: cvv,
                        maxlength:4,
                        required: true,
                        type:"numeric",
                    }
                    ]}
                    onChange={(k, v) => {
                        if(k === "name"){
                            setName(v)
                        }else if(k === "cardNumber"){
                            setCardNumber(v);
                        }else if(k === "expirationMonth"){
                            setExpirationMonth(v);
                        }else if(k === "expirationYear"){
                            setExpirationYear(v);
                        }else if(k === "cvv"){
                            setCvv(v);
                        }else{}
                    }}
                />
                </View>
              }
               <View style={styles.seperator} />
              <Text style={styles.detailsTitle} >{ "Individual Details" } </Text>
              <Form
                    fields={[
                    {
                        label: "Business Profile",
                        key: "businessProfile",
                        value: businessProfile,
                        required: true,
                        maxlength:100,
                        type:"text",
                        // image: () => Card,
                    },
                    {
                      label: "Address Line 1",
                      key: "address1",
                      value: address1,
                      required: true,
                      maxlength:100,
                      type:"text",
                      // image: () => Card,
                  },
                  {
                    label: "Address Line 2",
                    key: "address2",
                    value: address2,
                    required: true,
                    maxlength:100,
                    type:"text",
                    // image: () => Card,
                  },
                  {
                    label: "City",
                    key: "city",
                    value: city,
                    required: true,
                    maxlength:20,
                    type:"text",
                    // image: () => Card,
                },
                {
                  label: "State",
                  key: "statee",
                  value: statee,
                  required: true,
                  maxlength:100,
                  type:"text",
                  // image: () => Card,
                },
                {
                  label: "Zip/Postal Code",
                  key: "zip",
                  value: zip,
                  required: true,
                  maxlength:6,
                  type:"numeric",
                  // image: () => QuestionMark,
                },
                    ]}
                    onChange={(k, v) => {
                        if(k === "businessProfile"){
                          setBusinessProfile(v)
                        }else if(k === "address1"){
                            setAddress1(v);
                        }else if(k === "address2"){
                          setAddress2(v);
                        }else if(k === "city"){
                            setCity(v);
                        }else if(k === "statee"){
                            setStatee(v);
                        }else if(k === "zip"){
                            setZip(v);
                        }else{}
                    }}
                />
            <View style={styles.btnView}>
                <ButtonOne
                    text={"SAVE"}
                    style={styles.btnWidth}
                    onPress={savePress}
                />
            </View>
            </ScrollView>
        </KeyboardAvoidingView>
      </View>
    </BaseLayout>
  );
};

StripePayment.navigationOptions = ({ navigation }) => ({
  headerStyle: headerStyleCustom(),
  headerTintColor: "#fff",
  headerTitleStyle: {
    fontWeight: "bold",
  },
  headerBackTitle: "Back",
  headerTitle: () => <HeaderImage height={35} />,
  headerRight: () => (
    <ActionSheetMenu numberOfLines={1} navigation={navigation} />
  ),
});

export default connect(
  (state) => {
    return {
      user: state.auth.user,
    };
  },
  (dispatch) => ({
  })
)(StripePayment);
