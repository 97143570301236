import React from "react";
import { View, Text } from "react-native";

const styles = {
  container: {
    marginLeft: "8%",
    marginTop: 10,
  },
  textBox: {
    color: "#4990C8",
    fontSize: 30,
    fontFamily: "RobotoCondensed-Bold",
    fontWeight: "bold",
  },
  subtitle: {
    fontFamily: "RobotoCondensed-Bold",
    color: "#4990C8FF",
    fontSize: 14,
    fontWeight: "800",
  },
};
export default function (props) {
  let textBox = { ...styles.textBox };
  textBox.fontSize = props.fontSize ? props.fontSize : styles.textBox.fontSize;
  return (
    <View style={{ ...styles.container, ...(props.style || {}) }}>
      <Text style={textBox}>{props.text}</Text>
      {!!props.subtitle && (
        <Text style={styles.subtitle}>{props.subtitle}</Text>
      )}
    </View>
  );
}
