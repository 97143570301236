export const camelCaseAppointment = (appointment) => {
  const {
    fee_cents,
    appointment_type,
    starts_at,
    ends_at,
    buyer_first_name,
    buyer_last_name,
    buyer_phone,
    assigned_agent_id,
    user_id,
    ...rest
  } = appointment;
  return Object.assign(
    {
      ...rest,
    },
    fee_cents && { feeCents: fee_cents },
    appointment_type && { appointmentType: appointment_type },
    starts_at && { startsAt: starts_at },
    ends_at && { endsAt: ends_at },
    buyer_first_name && { buyerFirstName: buyer_first_name },
    buyer_last_name && { buyerLastName: buyer_last_name },
    buyer_phone && { buyerPhone: buyer_phone },
    assigned_agent_id && { assignedAgentID: assigned_agent_id },
    user_id && { userID: user_id }
  );
};

export const snakeCaseAppointment = (appointment) => {
  const {
    feeCents,
    appointmentType,
    startsAt,
    endsAt,
    buyerFirstName,
    buyerLastName,
    buyerPhone,
    userID,
    ...rest
  } = appointment;
  return {
    fee_cents: feeCents,
    appointment_type: appointmentType,
    starts_at: startsAt,
    ends_at: endsAt,
    buyer_first_name: buyerFirstName,
    buyer_last_name: buyerLastName,
    buyer_phone: buyerPhone,
    user_id: userID,
    ...rest,
  };
};
