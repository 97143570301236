export const SET_MESSAGES = "SET_MESSAGES";
export const CLEAR_MESSAGES = "CLEAR_MESSAGES";
export const SET_MESSAGES_SUCCESS = "SET_MESSAGES_SUCCESS";
export const CLEAR_MESSAGES_SUCCESS = "CLEAR_MESSAGES_SUCCESS";
export const SET_PUSH_MESSAGES = "SET_PUSH_MESSAGES";
export const SET_READ_NOTIFICATION = "SET_READ_NOTIFICATION";
export const CLEAR_PUSH_MESSAGES = "CLEAR_PUSH_MESSAGES";
export const SET_PUSHNOTIFICATION_MESSAGES = "SET_PUSHNOTIFICATION_MESSAGES";

export const setMessages = (messages) => {
  return (dispatch) => {
    dispatch(_setMessages(messages));
    setTimeout(() => dispatch(clearMessages()), 10000);
  };
};

export const setMessagesSucess = (messages) => {
  return (dispatch) => {
    dispatch(_setMessageSuccess(messages));
    setTimeout(() => dispatch(clearMessageSuccess()), 120000);
  };
};

export const _setMessages = (messages) => {
  return {
    type: SET_MESSAGES,
    messages,
  };
};
export const _setMessageSuccess = (messagesSuccess) => {
  return {
    type: SET_MESSAGES_SUCCESS,
    messagesSuccess,
  };
};
export const setPushNotificationMessages = (pushnotificationsMsgs) => {
  return {
    type: SET_PUSHNOTIFICATION_MESSAGES,
    pushnotificationsMsgs,
  };
};
export const setPushMessages = (pushMsgs, isUnRead) => {
  return {
    type: SET_PUSH_MESSAGES,
    pushMsgs,
    isUnRead,
  };
};

export const setReadNotification = (isUnRead) => {
  return {
    type: SET_READ_NOTIFICATION,
    isUnRead,
  };
};
export const clearPushMessages = () => {
  return {
    type: CLEAR_PUSH_MESSAGES,
  };
};
export const clearMessages = () => {
  return {
    type: CLEAR_MESSAGES,
  };
};
export const clearMessageSuccess = () => {
  return {
    type: CLEAR_MESSAGES_SUCCESS,
  };
};
