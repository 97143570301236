import React from "react";
import Checked from "../Images/Checked";
import Unchecked from "../Images/Unchecked";

export default function (props) {
  return props.checked ? (
    <Checked onPress={props.onPress} />
  ) : (
    <Unchecked onPress={props.onPress} />
  );
}
