import React from 'react';
import { StyleSheet, View, TextInput, Text } from 'react-native';

const styles = StyleSheet.create({
  container: {
    padding: "5%",
  },
  inner: {
    backgroundColor: '#D1E3F1',
    padding: "5%",
    paddingBottom: "7%",

    borderRadius: 20,
  },
  textInput: {
    height: 40,
    color: "#292B34",
    fontFamily: "RobotoCondensed-Light",
    fontSize: 15,
    flex: 1,
  },
  textInputWrapper: {
    borderBottomWidth: 1,
    height: 40,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 10,
    borderColor: "#00467E",
  },
  required: {
    borderColor: '#E41E2A',
  },
  textInputImage: {
    marginTop: 10,

  },
  row: {
    flex: 1,
    flexDirection: "row"
  },
  inputWrap1: {
    flex: 0.2,

  },
  inputWrap2: {
    flex: 1,
  },
  code: {
    height: 40,
    textAlign: 'center',
    paddingLeft: 1,
    paddingRight: 1,
    borderRightColor: '#00467E',
    borderLeftColor: '#00467E',
    borderRadius: 15,
    borderRightWidth: 1,
    borderLeftWidth: 1,
  },
  inputphone: {
    height: 40,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 10,
    borderColor: "#00467E",
  }
})

export default (props) => {
  const onChange = (key) => {
    return (value) => props.onChange(key, value);
  }

  return (
    <View style={styles.container}>
      <View style={styles.inner}>
        {props.fields.map((field, i) => {
          const ImageComponent = field.image && field.image();
          return (
            <View key={i}>
              { field.key != "ccode" &&
                <View key={`field-wrapper-${i}`} style={{ ...styles.textInputWrapper, ...(field.required ? styles.required : {}) }}>
                  {field.prefix && <Text style={{ marginTop: 10 }}>{field.prefix}</Text>}
                  {
                    field.key == "phoneWithoutCcode" || field.key == "buyerPhoneWithoutCcode" ?
                      (<View style={styles.row}>
                        <View style={styles.inputWrap1}>
                          <TextInput style={styles.code}
                            placeholder='+1'
                            key='ccode'
                            onChangeText={onChange('ccode')}
                            {...(field.textInputProps || {})}
                            value={props.fields[2].value && String(props.fields[2].value)}
                            maxLength={5}
                          />
                        </View>
                        <View style={styles.inputWrap2}>
                          <TextInput style={styles.inputphone}
                          
                            keyboardType = 'numeric'
                            returnKeyType="done"
                            placeholder={field.label}
                            placeholderTextColor="black"
                            onChangeText={onChange(field.key)}
                            key={`field-input-${i}`}
                            {...(field.textInputProps || {})}
                            value={field.value && String(field.value)}                   
                          />
                        </View>
                      </View>) : (
                        <TextInput
                          style={{ ...styles.textInput }}
                          placeholder={field.label}
                          maxLength={field.maxlength}
                          placeholderTextColor="black"
                          editable={field.disabled === true ? false : true }
                          keyboardType={field.type && field.type === "numeric" ? field.type : 'default'}
                          returnKeyType={field.type && field.type === "numeric" ? "done" : "default"}
                          onChangeText={onChange(field.key)}
                          key={`field-input-${i}`}
                          {...(field.textInputProps || {})}
                          value={field.value && String(field.value)}
                        />
                      )
                  }
                  {ImageComponent && <ImageComponent style={styles.textInputImage} />}
                </View>}
            </View>
          )
        })}
      </View>
    </View>
  )
}