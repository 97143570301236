import React from "react";
import {
  Text,
  View,
  KeyboardAvoidingView,
  ScrollView,
  TouchableOpacity,
} from "react-native";
import moment from "moment";
import styles from "../Styles/AgentHomestyle.js";
import { ButtonSmall, ButtonBoldBig } from "../Components/Button.js";
import Footer from "../Components/Footer";
import Location from "../Images/Location";
import HeaderImage from "../Images/Header";
import { connect } from "react-redux";
import {
  createAppointment,
  getMyBlockedAgents,
  getMyFavouriteAgents,
  updateAppointment,
} from "../redux/appointments/actions";
import { getUsers } from "../redux/auth/actions";
import { store } from "../redux/store";
import BaseLayout, { headerStyleCustom } from "./BaseLayout";
import ListingMapView from "./ListingMapView";
import ActionSheetMenu from "../Components/ActionSheetMenu";
// import Modal from "react-native-modal";
// import { SvgXml } from "react-native-svg";
import LogsService from "../LogsService";

class AgentRequest extends React.Component {
  hideBtn = true;
  static navigationOptions = ({ navigation }) => ({
    headerStyle: headerStyleCustom(),
    headerTintColor: "#fff",
    headerTitleStyle: {
      fontWeight: "bold",
    },
    headerBackTitle: "Back",
    headerTitle: () => <HeaderImage height={35} />,
    headerRight: () => (
      <ActionSheetMenu numberOfLines={1} navigation={navigation} />
    ),
  });

  constructor(props) {
    super(props);
    this.getScheduled = this.getScheduled.bind(this);

    this.state = {
      showList: false,
      isLessView: true,
      isFavoriteOnly: false,
      isFavoriteFirst: false,
      isAll: true,
      favoriteAgents: [],
      users:[],
      blockedAgents:[]
    };
    // LogsService.fileLog('AgentRequest','constructor','constructor initiated');
  }

  getFavoriteAgentList() {
    // LogsService.fileLog('AgentRequest','getFavoriteAgentList','getFavoriteAgentList called');
    // LogsService.fileLog('AgentRequest','getFavoriteAgentList','getMyFavouriteAgents api calling');
    // this.props.getMyFavouriteAgents().then((resp) => {
    //   // LogsService.fileLog('AgentRequest','getFavoriteAgentList response', JSON.stringify(resp));
    //   if (resp.status == "ok") {
    //     let userAgentList = resp.user_agents || [];
    //     console.log("getFavoriteAgentList=",userAgentList)
    //     // this.setState({
    //     //   favoriteAgents: userAgentList,
    //     // });
    //     this.getMyBlockedAgents(userAgentList);
    //   } else if (resp.status == "error") {
    //     store.dispatch(setErrors(resp.errors, 10));
    //   }
    // });
  }
  getMyBlockedAgents(userAgentList) {
    // LogsService.fileLog('AgentRequest','getMyBlockedAgents','getMyBlockedAgents called');
    // LogsService.fileLog('AgentRequest','getMyBlockedAgents','getMyBlockedAgents api calling');
    this.props.getMyBlockedAgents().then((resp) => {
      // LogsService.fileLog('AgentRequest','getMyBlockedAgents response', JSON.stringify(resp));
      if (resp.status == "ok") {
        let blockedAgentList = resp.user_agents || [];
        console.log("getMyBlockedAgents=",blockedAgentList)
        this.setState({
          blockedAgents: blockedAgentList,
        });
        var agentList = userAgentList;
        blockedAgentList.map((eachAgent)=>{
          var index = agentList.findIndex((ele)=> ele.id === eachAgent.id);
          if(index > -1){
            agentList.splice(index, 1);
          }
        });
        this.setState({
          favoriteAgents: agentList,
        });
        console.log("favoriteAgents=",agentList)

      } else if (resp.status == "error") {
        store.dispatch(setErrors(resp.errors, 10));
      }
    });
  }

  componentDidMount() {
    // LogsService.fileLog('AgentRequest','componentDidMount', 'componentDidMount is called');
    this.hideBtn = true;
    // LogsService.fileLog('AgentRequest','componentDidMount', 'getFavoriteAgentList is calling');
    this.getFavoriteAgentList();
    // if(this.props.listings.length>0){
    //   LogsService.fileLog('AgentRequest','componentDidMount', 'getUsers is calling');
    //     this.props.getUsers(this.props.listings[0].lat, this.props.listings[0].lng).then((resp) => {
    //       LogsService.fileLog('AgentRequest','getUsers response', JSON.stringify(resp));
    //       if (resp.status == "ok") {
    //         console.log("getUsers=",resp.users || [])
    //         var agentList = resp.users || [];
    //         this.state.blockedAgents.map((eachAgent)=>{
    //           var index = agentList.findIndex((ele)=> ele.id === eachAgent.id);
    //           if(index > -1){
    //             agentList.splice(index, 1);
    //           }
    //         });
    //         this.setState({
    //           users: agentList,
    //         });
    //       } else if (resp.status == "error") {
    //         store.dispatch(setErrors(resp.errors, 10));
    //       }
    //     })
    // }
  } 

  getScheduled() {
    // LogsService.fileLog('AgentRequest','getScheduled', 'getScheduled is called');
    if (this.props.appointment.appointmentType === "asap") {
      return (
        moment.utc(this.props.appointment.startsAt).format("MM/DD/YYYY") +
        " ASAP"
      );
    }

    return moment(this.props.appointment.startsAt).format("MM-DD-YYYY hh:mm a");
  }

  requestAgent() {
    // LogsService.fileLog('AgentRequest','requestAgent', 'requestAgent is called');
    let updatedAppointment = this.props.appointment;
    if (this.state.isFavoriteOnly) {
      updatedAppointment["notifications_send_to"] = 1;
    } else if (this.state.isFavoriteFirst) {
      updatedAppointment["notifications_send_to"] = 2;
    } else if (this.state.isAll) {
      updatedAppointment["notifications_send_to"] = 3;
    }
    // LogsService.fileLog('AgentRequest','requestAgent', 'navigating to PaymentMethods screen');
    // LogsService.fileLog('AgentRequest','requestAgent', 'updatedAppointment=', JSON.stringify(updatedAppointment));
    // LogsService.fileLog('AgentRequest','requestAgent', 'listingIDs=', JSON.stringify(this.props.listingIDs));
    // LogsService.fileLog('AgentRequest','requestAgent', 'listings=', JSON.stringify(this.props.listings));
    // LogsService.fileLog('AgentRequest','requestAgent', 'storeDescription=', JSON.stringify(this.props.storeDescription));
    // LogsService.fileLog('AgentRequest','requestAgent', 'storeDescriptionNote=', JSON.stringify(this.props.storeDescriptionNote));

    this.props.navigation.navigate("PaymentMethods", {
      isRequestAgent: true,
      appointmentDetails: updatedAppointment,
      listingIDs: this.props.listingIDs,
      listings: this.props.listings,
      storeDescription: this.props.storeDescription,
      storeDescriptionNote: this.props.storeDescriptionNote
    });
  }
  render() {
    return (
      <BaseLayout>
        <View style={{ flex: 1 }}>
          <View style={styles.container}>
            <KeyboardAvoidingView
              style={{ flex: 9, flexDirection: "column" }}
              behavior="padding"
              enabled
              keyboardVerticalOffset={0}
            >
              <ScrollView>
                <View style={{ backgroundColor: "#92BDDF", height: 50 }}>
                  <Text style={styles.Agent}>Available Agents in the Area</Text>
                </View>
                <ListingMapView
                  listings={this.props.listings}
                  agents={
                    this.state.isFavoriteOnly
                      ? this.state.favoriteAgents
                      : this.state.users
                  }
                />
                <View style={styles.showDetails}>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginTop: 15,
                    }}
                  >
                    {/* <Location style={{ marginLeft: 10 }} /> */}
                    <View style={{ paddingLeft: 15 }}>
                      <View style={{ flexDirection: "row", marginLeft: 5 }}>
                        <Text style={styles.listNo}>LISTING </Text>
                      </View>
                      <Text
                        style={{
                          fontSize: 18,
                          color: "#fff",
                          minWidth: "90%",
                          maxWidth: "90%",
                        }}
                      >
                        {/* {this.props.listings[0].address} */}
                      </Text>
                      {/* {this.props.listings.length != 1 && (
                        <View style={{ marginTop: 5 }}>
                          <ButtonSmall
                            disabled={true}
                            text={
                              this.state.showList == true
                                ? " Show Less "
                                : "+ " +
                                  (this.props.listings.length - 1) +
                                  " More"
                            }
                            style={{
                              width: 92,
                            }}
                            onPress={() =>{
                                LogsService.fileLog('AgentRequest','onPress', 'Show ' + this.state.showList == true ? 'Less' : 'More'  + ' pressed');
                                this.setState({ showList: !this.state.showList })
                              }
                            }
                          />
                        </View>
                      )} */}
                      {/* {this.state.showList == true &&
                        this.props.listings.map((lists, i) => {
                          if (i !== 0) {
                            return (
                              <View>
                                <Text
                                  style={{
                                    marginTop: 10,
                                    marginBottom: 10,
                                    fontSize: 18,
                                    color: "#fff",
                                    minWidth: "90%",
                                    maxWidth: "90%",
                                  }}
                                >
                                  {lists.address}
                                </Text>
                                {i != this.props.listings.length - 1 && (
                                  <View style={styles.lineDown} />
                                )}
                              </View>
                            );
                          }
                        })} */}
                    </View>
                  </View>
                  <View
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      padding: 10,
                      marginLeft: 35,
                    }}
                  >
                    <View style={styles.lineDown} />
                    <View style={{ flexDirection: "row", marginTop: 10 }}>
                      <Text style={styles.scheduleText}>SCHEDULED</Text>
                      <Text style={{ color: "#fff", marginLeft: 10 }}>
                        {this.getScheduled()}
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={styles.radioButtonList}>
                  <TouchableOpacity
                    activeOpacity={
                      this.state.favoriteAgents.length === 0 ? 1 : 0.2
                    }
                    style={styles.radioButtonOuter}
                    onPress={() => {
                      // LogsService.fileLog('AgentRequest','onPress', 'Send to Favorites ONLY pressed');
                      if (this.state.favoriteAgents.length > 0) {
                        this.setState({
                          isFavoriteOnly: true,
                          isFavoriteFirst: false,
                          isAll: false,
                        });
                      }
                    }}
                  >
                    <View style={styles.radioButtonRound}>
                      {this.state.isFavoriteOnly && (
                        <View style={styles.radioButtonInner}></View>
                      )}
                    </View>
                    <View style={styles.radioButtonTextOuter}>
                      <Text
                        style={[
                          styles.radioButtonMainText,
                          this.state.favoriteAgents.length === 0
                            ? styles.radioButtonDisableTextColor
                            : null,
                        ]}
                      >
                        Send to Favorites ONLY
                      </Text>
                    </View>
                  </TouchableOpacity>
                  <TouchableOpacity
                    activeOpacity={
                      this.state.favoriteAgents.length === 0 ? 1 : 0.2
                    }
                    style={styles.radioButtonOuter}
                    onPress={() => {
                      // LogsService.fileLog('AgentRequest','onPress', 'Send to Favorites FIRST pressed');
                      if (this.state.favoriteAgents.length > 0) {
                        this.setState({
                          isFavoriteFirst: true,
                          isFavoriteOnly: false,
                          isAll: false,
                        });
                      }
                    }}
                  >
                    <View style={styles.radioButtonRound}>
                      {this.state.isFavoriteFirst && (
                        <View style={styles.radioButtonInner}></View>
                      )}
                    </View>
                    <View style={styles.radioButtonTextOuter}>
                      <Text
                        style={[
                          styles.radioButtonMainText,
                          this.state.favoriteAgents.length === 0
                            ? styles.radioButtonDisableTextColor
                            : null,
                        ]}
                      >
                        Send to Favorites FIRST
                      </Text>
                      <Text
                        style={[
                          styles.radioButtonSmallText,
                          this.state.favoriteAgents.length === 0
                            ? styles.radioButtonDisableTextColor
                            : null,
                        ]}
                      >
                        (1-minute advance notice)
                      </Text>
                    </View>
                  </TouchableOpacity>
                  <TouchableOpacity
                    activeOpacity={
                      this.state.favoriteAgents.length === 0 ? 1 : 0.2
                    }
                    style={styles.radioButtonOuter}
                    onPress={() => {
                      // LogsService.fileLog('AgentRequest','onPress', 'Send to ALL pressed');
                      this.setState({
                        isAll: true,
                        isFavoriteFirst: false,
                        isFavoriteOnly: false,
                      });
                    }}
                  >
                    <View style={styles.radioButtonRound}>
                      {this.state.isAll && (
                        <View style={styles.radioButtonInner}></View>
                      )}
                    </View>
                    <View style={styles.radioButtonTextOuter}>
                      <Text style={styles.radioButtonMainText}>
                        Send to ALL
                      </Text>
                    </View>
                  </TouchableOpacity>
                </View>
                <View
                  style={{ justifyContent: "center", alignItems: "center" }}
                >
                  <View style={{ paddingBottom: 35, paddingTop: 15 }}>
                    {this.hideBtn && (
                      <ButtonBoldBig
                        disabled={true}
                        text={"Next"}
                        style={styles.widthStyle}
                        onPress={() => {
                          // LogsService.fileLog('AgentRequest','onPress', 'Next button clicked and calling requestAgent');
                          this.requestAgent()
                        }}
                      />
                    )}
                    {!this.hideBtn && (
                      <ButtonBoldBig
                        disabled={true}
                        text={"LOADING ..."}
                        style={styles.widthStyle}
                        onPress={() => {
                          // LogsService.fileLog('AgentRequest','onPress', 'LOADING ... button clicked');
                        }}
                      />
                    )}
                  </View>
                </View>
                <View>
                  {/* <Modal isVisible={false}>
                    <View style={styles.card}>
                      <ScrollView>
                        {this.props.listings.map((lists, i) => {
                          if (i !== 0) {
                            return (
                              <View
                                key={i}
                                style={{
                                  fontSize: 18,
                                  maxWidth: "90%",
                                  padding: 10,
                                  color: "#4990C8",
                                  borderBottomColor: "#4990C8",
                                  borderBottomWidth: 1,
                                }}
                              >
                                <Text>{lists.address}</Text>
                              </View>
                            );
                          }
                        })}
                      </ScrollView>
                      <TouchableOpacity
                        style={styles.buttonok}
                        onPress={() => this.setState({ showList: false })}
                      >
                        <Text style={{ color: "white", fontSize: 16 }}>
                          {" "}
                          <SvgXml
                            width={27}
                            height={27}
                            xml={`<svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Close</title><path fill="none" stroke="#FFFFFF" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M368 368L144 144M368 144L144 368"/></svg>`}
                          />
                        </Text>
                      </TouchableOpacity>
                    </View>
                  </Modal> */}
                </View>
              </ScrollView>
            </KeyboardAvoidingView>
          </View>
        </View>
        <Footer />
      </BaseLayout>
    );
  }
}

export default connect(
  (state) => {
    return {
      listings: state.addresses.current.map((id) => state.addresses.byID[id]),
      listingIDs: state.addresses.current,
      appointment: state.appointments.current,
      users: state.auth.usersList,
      storeDescription: state.addresses.storeDescription,
      storeDescriptionNote: state.addresses.storeDescriptionNote
    };
  },
  (dispatch) => ({
    createAppointment: (appointment, listingIDs) =>
      dispatch(createAppointment(appointment, listingIDs)),
    getUsers: (lat, lng,index) => dispatch(getUsers(lat, lng)),
    getMyBlockedAgents: () => dispatch(getMyBlockedAgents()),
    getMyFavouriteAgents: () => dispatch(getMyFavouriteAgents()),
  })
)(AgentRequest);
