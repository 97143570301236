import React from "react";
import { View, Text, Platform } from "react-native";
import DeviceInfo from "react-native-device-info";
import { isUATEnv } from "../../config";

function Header2() {
  return (
    <View>
      {Platform.OS === "ios" ? (
        <View style={{ flex: 1 }}>
          <Text
            style={{
              left: 12,
              top: 10,
              color: "white",
              fontSize: 16,
              position: "absolute",
              fontWeight: "bold",
            }}
          >
            V {DeviceInfo.getVersion()}
            {isUATEnv && <Text> UAT</Text>}
          </Text>
        </View>
      ) : (
        <View style={{ flex: 1 }}>
          <Text
            style={{
              left: 12,
              top: 20,
              color: "white",
              fontSize: 16,
              position: "absolute",
              fontWeight: "bold",
            }}
          >
            V {DeviceInfo.getVersion()}
            {isUATEnv && <Text> UAT</Text>}
          </Text>
        </View>
      )}
    </View>
  );
}

export default Header2;
