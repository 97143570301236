import React from "react";
import { View, Text, StyleSheet, ActivityIndicator } from "react-native";

const ProcessLoadingIndicator = (props) => {
  const style = {
    position: "absolute",
    top: "50%",
    right: 0,
    left: 0,
    ...StyleSheet.absoluteFill,
    justifyContent: "center",
    alignItems: "center",
    zIndex: 500,
    backgroundColor: props.backColor ? props.backColor : "transparent",
  };
  return (
    <View style={style}>
      <View
        style={{
          position: "absolute",
          top: "50%",
          right: 0,
          left: 0,
          ...StyleSheet.absoluteFill,
          justifyContent: "center",
          alignItems: "center",
          zIndex: 500,
        }}
      >
        <ActivityIndicator size="large" color="#DC612F" />
        <Text
          style={{
            backgroundColor: "#DC612F",
            padding: 10,
            color: "#fff",
            borderRadius: 10,
          }}
        >
          {props.message}
        </Text>
      </View>
    </View>
  );
};

export default ProcessLoadingIndicator;
