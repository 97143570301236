import React from "react";
import Svg, { G, Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={27} height={27} viewBox="0 0 74 74" fill="none" {...props}>
      <G>
        <Path
          d="M72.6,36.1c0,0-0.2-0.3-0.3-0.5c-1-1.7-3.9-6.9-8.9-12.2c-8-8.5-17.1-12.9-26.4-12.9h-0.1
		c-2.2,0-4.5,0.3-6.8,0.8c-1,0.2-1.6,1.3-1.4,2.2c0.2,1,1.3,1.6,2.3,1.4c1.8-0.5,3.9-0.7,6.1-0.7c5.6,0,19.8,2.2,31.9,22.8
		c-1.7,3.1-3.7,6-6,8.6c-0.6,0.8-0.5,2,0.2,2.7c0.3,0.3,0.7,0.4,1.2,0.4c0.6,0,1-0.2,1.4-0.7c2.6-3,5-6.4,6.9-10.1
    C72.9,37.2,72.9,36.6,72.6,36.1z"
          fill="#4990c8"
        />
        <Path
          d="M10.4,7.7C9.6,7,8.5,7,7.7,7.7s-0.8,1.9,0,2.7l8.1,8.1C9.9,23.3,5,29.3,1.3,36.2c-0.3,0.7-0.3,1.3,0,1.7
		c0,0,0.2,0.3,0.3,0.5c1,1.7,3.9,6.9,8.9,12.2c8,8.5,17.1,12.9,26.5,12.9h0h0c6.6-0.1,12.8-2.1,18.2-5.7l8.6,8.6
		c0.3,0.3,0.8,0.5,1.3,0.5c0.4,0,0.9-0.2,1.4-0.5c0.8-0.8,0.8-1.9,0-2.7L10.4,7.7z M40.7,43.4c-0.3,0.2-0.6,0.3-0.9,0.5
		C39,44.3,38,44.5,37,44.5c-0.9,0.1-1.9-0.1-2.8-0.5c-0.9-0.4-1.8-1-2.5-1.7c-0.8-0.7-1.3-1.7-1.7-2.4c-0.3-1-0.5-2-0.5-2.9
		c0-1,0.2-2,0.6-2.9c0.1-0.2,0.2-0.4,0.3-0.6c0-0.1,0.1-0.2,0.1-0.2L40.7,43.4z M27.9,30.6c-0.4,0.6-0.8,1.3-1.2,2.1
		c-0.7,1.4-1,2.8-1,4.3c-0.1,1.5,0.2,3,0.8,4.4c0.5,1.3,1.3,2.7,2.4,3.7c0.9,1,2.2,1.9,3.7,2.5c1.3,0.6,2.7,0.8,4.4,0.8c0,0,0,0,0,0
		c1.6-0.1,3-0.4,4.3-1c0.8-0.3,1.5-0.8,2.1-1.3l8.9,8.9c-4.6,3-9.9,4.6-15.4,4.7C19,59.8,7.5,40.9,5.2,37c3.3-6,7.8-11.3,13.2-15.8
    L27.9,30.6z"
          fill="#4990c8"
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
