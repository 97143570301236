import React, { useEffect, useState, useRef } from "react";
import {
  ScrollView,
  View,
  Text,
  TouchableOpacity,
  TextInput,
  Modal,
  Dimensions,
} from "react-native";
import { connect } from "react-redux";
import styles from "./styles";
import {
  getPaymentMethods,
  deleteCard,
  defaultCard,
  addCard,
  updateCard,
  createAppointment,
} from "../../redux/appointments/actions";
import {
  storeRunnerDescription,
  storeRunnerDescriptionNote,
} from "../../redux/address/actions";
import { setMasageFromScreen } from "../../redux/auth/actions";
import { ButtonBoldBig } from "../../Components/Button.js";
import BaseLayout, { headerStyleCustom } from "./../BaseLayout";
import HeaderImage from "../../Images/Header";
// import CloseIcon from "../../Images/CloseIcon";
// import PaymentCard from "../../Images/PaymentCard";
// import More from "../../Images/More";
// import MasterCard from "../../Images/MasterCard";
// import VisaCard from "../../Images/VisaCard";
// import DefaultCard from "../../Images/DefaultCard";
// import UnipayCard from "../../Images/UnipayCard";
// import JcbCard from "../../Images/JcbCard";
// import DiscoverCard from "../../Images/DiscoverCard";
// import DinersCard from "../../Images/DinersCard";
// import AmericanExpressCard from "../../Images/AmericanExpressCard";
// import PaymentDelete from "../../Images/PaymentDelete";
// import AddCard from "../../Images/AddCard";
import ActionSheetMenu from "../../Components/ActionSheetMenu";
import ProcessLoadingIndicator from "./../ProcessLoadingIndicator";
import LogsService from "../../LogsService";

const PaymentMethods = (props) => {
  const itemsRef = useRef([]);
  // const isRequestAgent = props.navigation.getParam("isRequestAgent", false);
  // LogsService.fileLog(
  //   "PaymentMethods",
  //   "PaymentMethods isRequestAgent=",
  //   isRequestAgent
  // );
  // const appointmentDetails = props.navigation.getParam(
  //   "appointmentDetails",
  //   {}
  // );
  // LogsService.fileLog(
  //   "PaymentMethods",
  //   "PaymentMethods appointmentDetails=",
  //   JSON.stringify(appointmentDetails)
  // );
  // const listingIDs = props.navigation.getParam("listingIDs", {});
  // const listings = props.navigation.getParam("listings", {});
  // const storeDescription = props.navigation.getParam("storeDescription", []);
  // const storeDescriptionNote = props.navigation.getParam(
  //   "storeDescriptionNote",
  //   ""
  // );
  const [isLoading, setIsLoading] = useState(false);
  const [loaderText, setLoaderText] = useState(
    "Processing request, please wait."
  );
  const [loadingPaymentMethodsList, setLoadingPaymentMethodsList] =
    useState(true);
  const [paymentMethodsList, setPaymentMethodsList] = useState([]);
  const [selectedCardIndex, setSelectedCardIndex] = useState("");
  const [isShowAddNewCardModal, setIsShowAddNewCardModal] = useState(false);
  const [isShowMoreSection, setIsShowMoreSection] = useState(false);
  const [isShowDeclineModal, setIsShowDeclineModal] = useState(false);
  const [selectedCard, setSelectedCard] = useState({});
  const [cardName, setCardName] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [cardExpiryDate, setCardExpiryDate] = useState("");
  const [cvv, setCvv] = useState("");
  const [isEditCard, setIsEditCard] = useState(false);
  const [clickedButton, setClickedButton] = useState(false);
  const [defaultCardIndex, setDefaultCardIndex] = useState(0);
  const [hideButton, setHideButton] = useState(false);
  const [cardNameError, setCardNameError] = useState("");
  const [cardNumberError, setCardNumberError] = useState("");
  const [expiryDateError, setExpiryError] = useState("");
  const [cvvError, setCvvError] = useState("");
  const [menuTopPosition, setMenuTopPosition] = useState(0);
  const [isDataUpdated, setIsDataUpdated] = useState(false);

  const getPaymentMethodsList = () => {
    LogsService.fileLog(
      "PaymentMethods",
      "PaymentMethods getPaymentMethodsList=",
      "getPaymentMethodsList is called"
    );
    props.getPaymentMethods().then((resp) => {
      LogsService.fileLog(
        "PaymentMethods",
        "PaymentMethods getPaymentMethods response=",
        JSON.stringify(resp)
      );
      setLoadingPaymentMethodsList(false);
      if (resp.status == "ok") {
        let cardList = resp.CardDetails || [];
        setPaymentMethodsList(JSON.parse(JSON.stringify(cardList)));
      } else if (resp.status == "error") {
        store.dispatch(setErrors(resp.errors, 10));
      }
    });
  };

  /**
   * Swipe to delete scroll reset
   */
  const resetScroll = () => {
    LogsService.fileLog(
      "PaymentMethods",
      "PaymentMethods resetScroll",
      "resetScroll is called"
    );
    itemsRef.current.map((item, index) => {
      itemsRef.current[index].scrollTo(0, 0, true);
    });
  };

  const setAsDefaultSelectedCard = () => {
    LogsService.fileLog(
      "PaymentMethods",
      "setAsDefaultSelectedCard",
      "setAsDefaultSelectedCard is called"
    );
    setIsLoading(true);
    props.defaultCard(selectedCard.id).then((resp) => {
      LogsService.fileLog(
        "PaymentMethods",
        "setAsDefaultSelectedCard defaultCard response=",
        JSON.stringify(resp)
      );
      setIsLoading(false);
      if (resp.status == "Ok") {
        LogsService.fileLog(
          "PaymentMethods",
          "setAsDefaultSelectedCard defaultCard response=",
          "getPaymentMethodsList is calling"
        );
        getPaymentMethodsList();
        LogsService.fileLog(
          "PaymentMethods",
          "setAsDefaultSelectedCard defaultCard response=",
          "Successfully set card as default."
        );
        props.setMasageFromScreen("Successfully set card as default.");
        setDefaultCardIndex(0);
      } else if (resp.status == "error") {
        store.dispatch(setErrors(resp.errors, 10));
      }
    });
  };
  const editSelectedCard = () => {
    LogsService.fileLog(
      "PaymentMethods",
      "editSelectedCard",
      "editSelectedCard is called"
    );
    var splittedArr = selectedCard.Card_Expiry.split("/");
    setCardName(selectedCard.Card_Holder_Name);
    setCardNumber("************" + selectedCard.Last_Four_Digit_Of_Card);
    setCardExpiryDate(
      splittedArr.length > 0 && splittedArr[0].length == 1
        ? "0" + selectedCard.Card_Expiry
        : selectedCard.Card_Expiry
    );
    setCvv(selectedCard.Cvc || "***");
    setCardNumberError("");
    setIsEditCard(true);
    setIsShowAddNewCardModal(true);
  };
  const deleteSelectedCard = (cardIndex) => {
    LogsService.fileLog(
      "PaymentMethods",
      "deleteSelectedCard",
      "deleteSelectedCard is called with cardIndex=",
      cardIndex
    );
    let selectedDeleteCard = paymentMethodsList[cardIndex];
    let selectedId = selectedDeleteCard.id;
    if (itemsRef.current && itemsRef.current[cardIndex] != null) {
      itemsRef.current[cardIndex].scrollTo({
        x: 0,
        animated: true,
      });
    }
    setIsLoading(true);
    props.deleteCard(selectedId).then((resp) => {
      LogsService.fileLog(
        "PaymentMethods",
        "deleteSelectedCard deleteCard response=",
        JSON.stringify(resp)
      );
      setIsLoading(false);
      if (resp.status == "ok") {
        getPaymentMethodsList();
        props.setMasageFromScreen("Card deleted successfully.");
      } else if (resp.status == "error") {
        store.dispatch(setErrors(resp.errors, 10));
      }
    });
  };
  const formatExpiryDate = (value) => {
    LogsService.fileLog(
      "PaymentMethods",
      "formatExpiryDate",
      "formatExpiryDate is calling with value=" + value
    );
    if (value != null) {
      const currentValue = value.replace(/[^\d]/g, "");
      const cvLength = value.length;
      if (cvLength <= 2) return currentValue;
      return `${currentValue.slice(0, 2)}/${currentValue.slice(2, 6)}`;
    }
  };
  const saveCardDetails = () => {
    LogsService.fileLog(
      "PaymentMethods",
      "saveCardDetails",
      "saveCardDetails is called"
    );
    setCardNameError("");
    setCardNumberError("");
    setExpiryError("");
    setCvvError("");
    var card_name =
      cardName != undefined && cardName != "" ? cardName.trim() : "";
    const nameRegex = /^([a-zA-Z]+\s)*[a-zA-Z]+$/;
    const cardNumberRegex = /^\d{15,16}$/;
    const cvvRegex = /^\d{3,4}$/;
    const monthRegex = /^\d{2}$/;
    const yearRegex = /^\d{4}$/;
    let isValid = true;
    if (card_name == "") {
      setCardNameError("Please enter name");
      isValid = false;
      LogsService.fileLog(
        "PaymentMethods",
        "saveCardDetails validation=",
        "Please enter name"
      );
    } else if (!nameRegex.test(card_name)) {
      setCardNameError("Please enter valid name");
      isValid = false;
      LogsService.fileLog(
        "PaymentMethods",
        "saveCardDetails validation=",
        "Please enter valid name"
      );
    }
    if (cardNumber == "") {
      setCardNumberError("Please enter card number");
      isValid = false;
      LogsService.fileLog(
        "PaymentMethods",
        "saveCardDetails validation=",
        "Please enter card number"
      );
    } else if (!cardNumberRegex.test(cardNumber)) {
      setCardNumberError("Please enter valid card number");
      isValid = false;
      LogsService.fileLog(
        "PaymentMethods",
        "saveCardDetails validation=",
        "Please enter valid card number"
      );
    }
    if (cardExpiryDate == "") {
      setExpiryError("Please enter card expiry date");
      isValid = false;
      LogsService.fileLog(
        "PaymentMethods",
        "saveCardDetails validation=",
        "Please enter card expiry date"
      );
    } else {
      let expiryDateArray = cardExpiryDate.split("/");
      let expirationMonth = expiryDateArray[0] || "";
      let expirationYear = expiryDateArray[1] || "";
      var year = new Date().getFullYear();
      if (
        !monthRegex.test(expirationMonth) ||
        !yearRegex.test(expirationYear) ||
        year > expirationYear ||
        expirationMonth > 12
      ) {
        setExpiryError("Please enter valid card expiry date");
        isValid = false;
        LogsService.fileLog(
          "PaymentMethods",
          "saveCardDetails validation=",
          "Please enter valid card expiry date"
        );
      }
    }
    if (cvv == "") {
      setCvvError("Please enter cvv");
      isValid = false;
      LogsService.fileLog(
        "PaymentMethods",
        "saveCardDetails validation=",
        "Please enter cvv"
      );
    }
    //  else if (cvv == "000" || cvv == "0000") {
    //   setCvvError("Please enter valid cvv");
    //   isValid = false;
    //   LogsService.fileLog('PaymentMethods','saveCardDetails validation=', 'Please enter valid cvv');
    // }
    else if (!cvvRegex.test(cvv)) {
      setCvvError("Please enter valid cvv");
      isValid = false;
      LogsService.fileLog(
        "PaymentMethods",
        "saveCardDetails validation=",
        "Please enter valid cvv"
      );
    }
    if (isValid) {
      setClickedButton(true);
      let expiryDateArray = cardExpiryDate.split("/");
      let expirationMonth = expiryDateArray[0] || "";
      let expirationYear = expiryDateArray[1] || "";
      let cardDetails = {
        Name: card_name,
        Number: parseInt(cardNumber || 0),
        ExpMonth: parseInt(expirationMonth),
        ExpYear: parseInt(expirationYear),
        Cvc: cvv,
      };
      props.addCard(cardDetails).then((resp) => {
        setClickedButton(false);
        LogsService.fileLog(
          "PaymentMethods",
          "saveCardDetails addCard response=",
          JSON.stringify(resp)
        );
        if (resp.status == "ok") {
          setIsShowAddNewCardModal(false);
          LogsService.fileLog(
            "PaymentMethods",
            "saveCardDetails addCard response=",
            "getPaymentMethodsList is calling"
          );
          getPaymentMethodsList();
        } else if (resp.status == "error") {
          let errors = resp.errors || [];
          if (errors.length > 0) {
            if (errors[0] == "Your card's expiration year is invalid.") {
              setExpiryError(errors[0]);
            } else if (errors[0] == "Your card's security code is invalid.") {
              setCvvError(errors[0]);
            } else {
              setCardNumberError(errors[0]);
            }
          }
        }
      });
    }
  };
  const updateCardDetails = () => {
    LogsService.fileLog(
      "PaymentMethods",
      "updateCardDetails",
      "updateCardDetails is calling"
    );
    setCardNameError("");
    setCardNumberError("");
    setExpiryError("");
    setCvvError("");
    var card_name =
      cardName != undefined && cardName != "" ? cardName.trim() : "";
    const nameRegex = /^([a-zA-Z]+\s)*[a-zA-Z]+$/;
    const monthRegex = /^\d{2}$/;
    const yearRegex = /^\d{4}$/;
    let isValid = true;
    if (card_name == "") {
      setCardNameError("Please enter name");
      isValid = false;
      LogsService.fileLog(
        "PaymentMethods",
        "updateCardDetails validation=",
        "Please enter name"
      );
    } else if (!nameRegex.test(card_name)) {
      setCardNameError("Please enter valid name");
      isValid = false;
      LogsService.fileLog(
        "PaymentMethods",
        "updateCardDetails validation=",
        "Please enter valid name"
      );
    }
    if (cardExpiryDate == "") {
      setExpiryError("Please enter card expiry date");
      isValid = false;
      LogsService.fileLog(
        "PaymentMethods",
        "updateCardDetails validation=",
        "Please enter card expiry date"
      );
    } else {
      let expiryDateArray = cardExpiryDate.split("/");
      let expirationMonth = expiryDateArray[0] || "";
      let expirationYear = expiryDateArray[1] || "";
      var year = new Date().getFullYear();
      if (
        !monthRegex.test(expirationMonth) ||
        !yearRegex.test(expirationYear) ||
        year > expirationYear ||
        expirationMonth > 12
      ) {
        setExpiryError("Please enter valid card expiry date");
        isValid = false;
        LogsService.fileLog(
          "PaymentMethods",
          "updateCardDetails validation=",
          "Please enter valid card expiry date"
        );
      }
    }
    if (isValid) {
      setClickedButton(true);
      let expiryDateArray = cardExpiryDate.split("/");
      let expirationMonth = expiryDateArray[0] || "";
      let expirationYear = expiryDateArray[1] || "";
      let cardDetails = {
        Name: card_name,
        ExpMonth: parseInt(expirationMonth),
        ExpYear: parseInt(expirationYear),
        CardId: selectedCard.id,
      };
      if (!isDataUpdated) {
        setIsShowAddNewCardModal(false);
        props.setMasageFromScreen("Nothing is changed to update / save.");
        setClickedButton(false);
        LogsService.fileLog(
          "PaymentMethods",
          "updateCardDetails validation=",
          "Nothing is changed to update / save"
        );
      } else {
        props.updateCard(cardDetails).then((resp) => {
          setClickedButton(false);
          setIsDataUpdated(false);
          LogsService.fileLog(
            "PaymentMethods",
            "updateCardDetails updateCard response=",
            JSON.stringify(resp)
          );
          if (resp.status == "ok") {
            setIsShowAddNewCardModal(false);
            LogsService.fileLog(
              "PaymentMethods",
              "updateCardDetails updateCard response=",
              "getPaymentMethodsList is calling"
            );
            getPaymentMethodsList();
            props.setMasageFromScreen("Card details updated successfully.");
            LogsService.fileLog(
              "PaymentMethods",
              "updateCardDetails updateCard response=",
              "Card details updated successfully."
            );
          } else if (resp.status == "error") {
            let errors = resp.errors || [];
            if (errors.length > 0) {
              if (errors[0] == "Your card's expiration year is invalid.") {
                setExpiryError(errors[0]);
              } else if (errors[0] == "Your card's security code is invalid.") {
                setCvvError(errors[0]);
              } else {
                setCardNumberError(errors[0]);
              }
            }
          }
        });
      }
    }
  };
  const requestAgent = () => {
    LogsService.fileLog(
      "PaymentMethods",
      "requestAgent",
      "requestAgent is called"
    );
    if (paymentMethodsList.length > 0) {
      let updatedAppointment = appointmentDetails;
      updatedAppointment["card_id"] = paymentMethodsList[defaultCardIndex].id;
      updatedAppointment["hiring_agent_card_type"] =
        paymentMethodsList[defaultCardIndex].Card_Type;
      updatedAppointment["hiring_agent_payment_last4"] =
        paymentMethodsList[defaultCardIndex].Last_Four_Digit_Of_Card;
      updatedAppointment["dataListings"] = listings;
      updatedAppointment["runner_activity"] =
        updatedAppointment.showing_type === "RUNNER"
          ? JSON.parse(storeDescription)
          : [];
      updatedAppointment["runner_activity_note"] =
        updatedAppointment.showing_type === "RUNNER"
          ? storeDescriptionNote
          : "";
      setHideButton(true);
      props.createAppointment(updatedAppointment, listingIDs).then((resp) => {
        setHideButton(false);
        LogsService.fileLog(
          "PaymentMethods",
          "requestAgent createAppointment response=",
          JSON.stringify(resp)
        );
        if (resp.status == "error") {
          let errors = resp.errors || [];
          if (errors.length > 0) {
            if (errors[0] == "Your card was declined.") {
              setIsShowDeclineModal(true);
            }
          }
        }
      });
    }
  };
  useEffect(() => {
    LogsService.fileLog("PaymentMethods", "useEffect", "useEffect is called");
    const unsubscribe = props.navigation.addListener("didFocus", () => {
      LogsService.fileLog(
        "PaymentMethods",
        "useEffect",
        "getPaymentMethodsList is calling"
      );
      getPaymentMethodsList();
    });
  }, []);
  useEffect(() => {
    LogsService.fileLog("PaymentMethods", "useEffect", "useEffect is called");
    if (itemsRef.current) {
      itemsRef.current = itemsRef.current.slice(0, paymentMethodsList.length);
    }
  }, [paymentMethodsList]);
  return (
    <BaseLayout>
      <View style={styles.container}>
        {isLoading && (
          <ProcessLoadingIndicator message={loaderText} backColor="" />
        )}
        {loadingPaymentMethodsList && (
          <ProcessLoadingIndicator
            message="Please wait... fetching payment methods"
            backColor=""
          />
        )}
        {!loadingPaymentMethodsList && (
          <View style={styles.listOuterSection}>
            <View style={styles.notificationTopOuter}>
              <View style={styles.notificationTitleOuter}>
                <Text style={styles.listTitle}>
                  {isRequestAgent ? "Select Payment Method" : "Payment Methods"}
                </Text>
              </View>
            </View>
            <ScrollView
              keyboardShouldPersistTaps={"handled"}
              style={styles.agentListOuter}
            >
              <View>
                {paymentMethodsList.length > 0
                  ? paymentMethodsList.map(
                      (eachPaymentMethod, paymentMethodIndex) => {
                        return (
                          <View
                            key={"payement-method-" + paymentMethodIndex}
                            style={[
                              paymentMethodIndex == defaultCardIndex
                                ? styles.notificationSelectedItemOuter
                                : styles.notificationItemOuter,
                            ]}
                          >
                            <ScrollView
                              keyboardShouldPersistTaps={"handled"}
                              showsVerticalScrollIndicator={false}
                              showsHorizontalScrollIndicator={false}
                              horizontal={true}
                              ref={(el) =>
                                (itemsRef.current[paymentMethodIndex] = el)
                              }
                            >
                              <TouchableOpacity
                                activeOpacity={isRequestAgent ? 0.2 : 1}
                                onPress={() => {
                                  if (isRequestAgent) {
                                    LogsService.fileLog(
                                      "PaymentMethods",
                                      "onPress",
                                      "setDefaultCardIndex =" +
                                        paymentMethodIndex
                                    );
                                    setDefaultCardIndex(paymentMethodIndex);
                                  }
                                }}
                                style={styles.directionFlexRow}
                              >
                                <View
                                  style={styles.scrollableFirstSection}
                                  onTouchEnd={(e) => {}}
                                >
                                  {/* <View style={styles.notificationIconOuter}>
                                    {eachPaymentMethod.Card_Type == "Visa" ||
                                    eachPaymentMethod.Card_Type ==
                                      "Visa (debit)" ? (
                                      <VisaCard />
                                    ) : eachPaymentMethod.Card_Type ==
                                        "MasterCard" ||
                                      eachPaymentMethod.Card_Type ==
                                        "Mastercard (2-series)" ||
                                      eachPaymentMethod.Card_Type ==
                                        "Mastercard (debit)" ||
                                      eachPaymentMethod.Card_Type ==
                                        "Mastercard (prepaid)" ? (
                                      <MasterCard />
                                    ) : eachPaymentMethod.Card_Type ==
                                      "American Express" ? (
                                      <AmericanExpressCard />
                                    ) : eachPaymentMethod.Card_Type ==
                                      "Discover" ? (
                                      <DiscoverCard />
                                    ) : eachPaymentMethod.Card_Type ==
                                        "Diners Club" ||
                                      eachPaymentMethod.Card_Type ==
                                        "Diners Club (14 digit card)" ? (
                                      <DinersCard />
                                    ) : eachPaymentMethod.Card_Type == "JCB" ? (
                                      <JcbCard />
                                    ) : eachPaymentMethod.Card_Type ==
                                      "UnionPay" ? (
                                      <UnipayCard />
                                    ) : (
                                      <DefaultCard />
                                    )}
                                  </View> */}
                                  <View style={styles.notificationDetailsOuter}>
                                    <Text style={styles.notificationTitle}>
                                      **** **** ****{" "}
                                      {
                                        eachPaymentMethod.Last_Four_Digit_Of_Card
                                      }
                                    </Text>
                                    <View
                                      style={
                                        styles.notificationDescriptionOuter
                                      }
                                    >
                                      <Text
                                        style={[
                                          paymentMethodIndex == defaultCardIndex
                                            ? styles.notificationDefaultDescription
                                            : styles.notificationDescription,
                                        ]}
                                      >
                                        Expires {eachPaymentMethod.exp_month}/
                                        {eachPaymentMethod.exp_year}
                                      </Text>
                                    </View>
                                  </View>
                                  {selectedCardIndex === paymentMethodIndex ? (
                                    <TouchableOpacity
                                      onPress={() => {
                                        setSelectedCardIndex("");
                                        resetScroll();
                                        setSelectedCard({});
                                        setIsShowMoreSection(false);
                                        LogsService.fileLog(
                                          "PaymentMethods",
                                          "onPress",
                                          "More clicked"
                                        );
                                      }}
                                      style={styles.moreSelected}
                                    >
                                      {/* <More /> */}
                                    </TouchableOpacity>
                                  ) : (
                                    <TouchableOpacity
                                      onPress={(event) => {
                                        const { pageY } = event.nativeEvent;
                                        setMenuTopPosition(pageY);
                                        resetScroll();
                                        setSelectedCardIndex(
                                          paymentMethodIndex
                                        );
                                        setSelectedCard(eachPaymentMethod);
                                        setIsShowMoreSection(true);
                                        LogsService.fileLog(
                                          "PaymentMethods",
                                          "onPress",
                                          "More clicked"
                                        );
                                      }}
                                      style={styles.moreOuter}
                                    >
                                      {/* <More /> */}
                                    </TouchableOpacity>
                                  )}
                                </View>
                                <TouchableOpacity
                                  activeOpacity={1}
                                  onPress={() => {
                                    LogsService.fileLog(
                                      "PaymentMethods",
                                      "onPress",
                                      "deleteSelectedCard is calling with paymentMethodIndex=" +
                                        paymentMethodIndex
                                    );
                                    deleteSelectedCard(paymentMethodIndex);
                                  }}
                                  style={styles.scrollableSecondSection}
                                >
                                  {/* <PaymentDelete /> */}
                                  <View
                                    style={styles.deleteFirstSection}
                                  ></View>
                                </TouchableOpacity>
                              </TouchableOpacity>
                            </ScrollView>
                          </View>
                        );
                      }
                    )
                  : null}
                <TouchableOpacity
                  onPress={() => {
                    resetScroll();
                    setCardNameError("");
                    setCardNumberError("");
                    setExpiryError("");
                    setCvvError("");
                    setCardName("");
                    setCardNumber("");
                    setCardExpiryDate("");
                    setCvv("");
                    setIsEditCard(false);
                    setCardNumberError("");
                    setIsShowAddNewCardModal(true);
                    LogsService.fileLog(
                      "PaymentMethods",
                      "onPress",
                      "Add New Card pressed"
                    );
                  }}
                  style={styles.addCardSection}
                >
                  {/* <AddCard /> */}
                  <Text style={styles.addCardText}>Add New Card</Text>
                </TouchableOpacity>
              </View>
            </ScrollView>
            {isRequestAgent ? (
              <View style={styles.requestButtonOuter}>
                <ButtonBoldBig
                  disabled={true}
                  text={hideButton ? "Loading ..." : "Request Agent"}
                  style={styles.requestButton}
                  onPress={() => (!hideButton ? requestAgent() : {})}
                />
              </View>
            ) : null}
          </View>
        )}
        <Modal
          transparent={true}
          visible={isShowMoreSection}
          onRequestClose={() => {
            setSelectedCardIndex("");
            setSelectedCard({});
            setIsShowMoreSection(false);
            LogsService.fileLog(
              "PaymentMethods",
              "onRequestClose",
              "Modal close is called"
            );
          }}
        >
          <TouchableOpacity
            activeOpacity={1}
            onPress={() => {
              setSelectedCardIndex("");
              setSelectedCard({});
              setIsShowMoreSection(false);
            }}
            style={styles.moreSectionOuter}
          >
            <View
              style={[
                {
                  top:
                    menuTopPosition < Dimensions.get("window").height / 1.5
                      ? 30 + menuTopPosition
                      : menuTopPosition - 200,
                },
                styles.moreSectionInner,
              ]}
            >
              {selectedCardIndex !== 0 ? (
                <TouchableOpacity
                  onPress={() => {
                    LogsService.fileLog(
                      "PaymentMethods",
                      "onPress",
                      "Set as Default"
                    );
                    setSelectedCardIndex("");
                    setIsShowMoreSection(false);
                    setAsDefaultSelectedCard();
                  }}
                  style={styles.moreInnerSection}
                >
                  <Text style={styles.moreOptionText}>Set as Default</Text>
                </TouchableOpacity>
              ) : null}
              <TouchableOpacity
                onPress={() => {
                  LogsService.fileLog(
                    "PaymentMethods",
                    "onPress",
                    "Edit Card is pressed"
                  );
                  setCardNameError("");
                  setCardNumberError("");
                  setExpiryError("");
                  setCvvError("");
                  setSelectedCardIndex("");
                  setIsShowMoreSection(false);
                  editSelectedCard();
                }}
                style={styles.moreInnerSection}
              >
                <Text style={styles.moreOptionText}>Edit Card</Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  LogsService.fileLog(
                    "PaymentMethods",
                    "onPress",
                    "Delete Card is pressed"
                  );
                  setSelectedCardIndex("");
                  setIsShowMoreSection(false);
                  deleteSelectedCard(selectedCardIndex);
                }}
                style={styles.lastMoreInnerSection}
              >
                <Text style={styles.moreOptionText}>Delete Card</Text>
              </TouchableOpacity>
            </View>
          </TouchableOpacity>
        </Modal>
        <Modal
          transparent={true}
          visible={isShowAddNewCardModal}
          onRequestClose={() => {
            setIsShowAddNewCardModal(false);
            LogsService.fileLog(
              "PaymentMethods",
              "onRequestClose",
              "modal onRequestClose is pressed"
            );
          }}
        >
          <TouchableOpacity
            activeOpacity={1}
            onPress={() => {}}
            style={styles.newCardSectionOuter}
          >
            <TouchableOpacity
              activeOpacity={1}
              onPress={() => {}}
              style={styles.newCardSection}
            >
              <TouchableOpacity
                onPress={() => {
                  setIsShowAddNewCardModal(false);
                  LogsService.fileLog(
                    "PaymentMethods",
                    "onPress",
                    "Add New Card modal close is pressed"
                  );
                }}
                style={styles.closeModalOuter}
              >
                {/* <CloseIcon /> */}
              </TouchableOpacity>
              <Text style={styles.modalTitle}>
                {isEditCard ? "Update Card" : "Add New Card"}
              </Text>
              <View style={styles.modalContentSection}>
                <View
                  style={[
                    cardNameError != ""
                      ? styles.errorInputIconOuter
                      : styles.inputIconOuter,
                  ]}
                >
                  <View style={styles.modalInputOuter}>
                    <TextInput
                      style={[
                        cardNameError != ""
                          ? styles.errorModalInput
                          : styles.modalInput,
                      ]}
                      placeholder="Name on Card"
                      placeholderTextColor={
                        cardNameError != "" ? "#EC4D2C" : "#63696F"
                      }
                      value={cardName}
                      autoCapitalize="none"
                      onChangeText={(inputText) => {
                        setCardName(inputText);
                        setIsDataUpdated(true);
                      }}
                    />
                  </View>
                  {/* <PaymentCard /> */}
                </View>
                {cardNameError != "" ? (
                  <View>
                    <Text style={styles.errorMessage}>{cardNameError}</Text>
                  </View>
                ) : null}
                <View
                  style={[
                    cardNumberError != ""
                      ? styles.errorInputIconOuter
                      : styles.inputIconOuter,
                  ]}
                >
                  <View style={styles.modalInputOuter}>
                    <TextInput
                      editable={!isEditCard}
                      value={cardNumber}
                      onChangeText={(inputText) => {
                        setCardNumber(inputText);
                      }}
                      maxLength={16}
                      keyboardType="numeric"
                      returnKeyType="done"
                      style={[
                        cardNumberError != ""
                          ? styles.errorModalInput
                          : styles.modalInput,
                      ]}
                      placeholder="Card Number"
                      placeholderTextColor={
                        cardNumberError != "" ? "#EC4D2C" : "#63696F"
                      }
                    />
                  </View>
                  <Text style={styles.modalInputRightText}>#</Text>
                </View>
                {cardNumberError != "" ? (
                  <View>
                    <Text style={styles.errorMessage}>{cardNumberError}</Text>
                  </View>
                ) : null}
                <View
                  style={[
                    expiryDateError != ""
                      ? styles.errorInputIconOuter
                      : styles.inputIconOuter,
                  ]}
                >
                  <View style={styles.modalInputOuter}>
                    <TextInput
                      value={cardExpiryDate}
                      onChangeText={(inputText) => {
                        setCardExpiryDate(formatExpiryDate(inputText));
                        setIsDataUpdated(true);
                      }}
                      style={[
                        expiryDateError != ""
                          ? styles.errorModalInput
                          : styles.modalInput,
                      ]}
                      placeholder="Card Expiry Date (MM/YYYY)"
                      placeholderTextColor={
                        expiryDateError != "" ? "#EC4D2C" : "#63696F"
                      }
                      keyboardType="numeric"
                      returnKeyType="done"
                      maxLength={7}
                    />
                  </View>
                </View>
                {expiryDateError != "" ? (
                  <View>
                    <Text style={styles.errorMessage}>{expiryDateError}</Text>
                  </View>
                ) : null}
                <View
                  style={[
                    cvvError != ""
                      ? styles.errorInputIconOuter
                      : styles.inputIconOuter,
                  ]}
                >
                  <View style={styles.modalInputOuter}>
                    <TextInput
                      editable={!isEditCard}
                      value={cvv}
                      onChangeText={(inputText) => {
                        setCvv(inputText);
                      }}
                      maxLength={4}
                      keyboardType="numeric"
                      returnKeyType="done"
                      style={[
                        cvvError != ""
                          ? styles.errorModalInput
                          : styles.modalInput,
                      ]}
                      placeholder="CVV"
                      placeholderTextColor={
                        cvvError != "" ? "#EC4D2C" : "#63696F"
                      }
                    />
                  </View>
                </View>
                {cvvError != "" ? (
                  <View>
                    <Text style={styles.errorMessage}>{cvvError}</Text>
                  </View>
                ) : null}
              </View>
              <View style={styles.modalButtonsOuter}>
                <TouchableOpacity
                  onPress={() => {
                    setIsShowAddNewCardModal(false);
                    LogsService.fileLog(
                      "PaymentMethods",
                      "onPress",
                      "Cancel button is pressed from Add new Card modal"
                    );
                  }}
                  style={styles.modalCancelButton}
                >
                  <Text style={styles.cancelText}>Cancel</Text>
                </TouchableOpacity>
                <ButtonBoldBig
                  text={clickedButton ? "Loading ..." : "Save"}
                  style={styles.modalSaveButton}
                  onPress={() => {
                    {
                      LogsService.fileLog(
                        "PaymentMethods",
                        "onPress",
                        "Save button is pressed from Add new Card modal"
                      );
                      isEditCard && !clickedButton
                        ? updateCardDetails()
                        : !isEditCard && !clickedButton
                        ? saveCardDetails()
                        : null;
                    }
                  }}
                />
              </View>
            </TouchableOpacity>
          </TouchableOpacity>
        </Modal>
        <Modal
          transparent={true}
          visible={isShowDeclineModal}
          onRequestClose={() => {
            setIsShowDeclineModal(false);
            LogsService.fileLog(
              "PaymentMethods",
              "onRequestClose",
              "card decliened modal close action"
            );
          }}
        >
          <TouchableOpacity
            activeOpacity={1}
            onPress={() => {
              LogsService.fileLog(
                "PaymentMethods",
                "onPress",
                "card decliened modal close action"
              );
              setIsShowDeclineModal(false);
            }}
            style={styles.newCardSectionOuter}
          >
            <TouchableOpacity
              activeOpacity={1}
              onPress={() => {}}
              style={styles.newCardSection}
            >
              <TouchableOpacity
                onPress={() => {
                  setIsShowDeclineModal(false);
                  LogsService.fileLog(
                    "PaymentMethods",
                    "onPress",
                    "card decliened modal close button clicked"
                  );
                }}
                style={styles.closeModalOuter}
              >
                {/* <CloseIcon /> */}
              </TouchableOpacity>
              <Text style={styles.modalDeclineTitle}>
                Your Card has been Declined!
              </Text>
              <View style={styles.declineContentSection}>
                <Text style={styles.declineText}>
                  Please choose another card OR
                </Text>
                <Text style={styles.declineText}>
                  Add a new card to proceed to payment.
                </Text>
              </View>
              <View style={styles.modalButtonsOuter}>
                <ButtonBoldBig
                  text={"OK"}
                  style={styles.modalSaveButton}
                  onPress={() => {
                    setIsShowDeclineModal(false);
                    LogsService.fileLog(
                      "PaymentMethods",
                      "onPress",
                      "card decliened modal OK button clicked and closing the modal"
                    );
                  }}
                />
              </View>
            </TouchableOpacity>
          </TouchableOpacity>
        </Modal>
      </View>
    </BaseLayout>
  );
};

PaymentMethods.navigationOptions = ({ navigation }) => ({
  headerStyle: headerStyleCustom(),
  headerTintColor: "#fff",
  headerTitleStyle: {
    fontWeight: "bold",
  },
  headerBackTitle: "Back",
  headerTitle: () => <HeaderImage height={35} />,
  headerRight: () => (
    <ActionSheetMenu numberOfLines={1} navigation={navigation} />
  ),
});

export default connect(
  (state) => {
    return {
      user: state.auth.user,
    };
  },
  (dispatch) => ({
    getPaymentMethods: () => dispatch(getPaymentMethods()),
    defaultCard: (ids) => dispatch(defaultCard(ids)),
    deleteCard: (cardId) => dispatch(deleteCard(cardId)),
    addCard: (cardDetails) => dispatch(addCard(cardDetails)),
    updateCard: (cardDetails) => dispatch(updateCard(cardDetails)),
    createAppointment: (appointment, listingIDs) =>
      dispatch(createAppointment(appointment, listingIDs)),
    setMasageFromScreen: (msg) => dispatch(setMasageFromScreen(msg)),
    storeRunnerDescription: () => dispatch(storeRunnerDescription([])),
    storeRunnerDescriptionNote: () => dispatch(storeRunnerDescriptionNote("")),
  })
)(PaymentMethods);
