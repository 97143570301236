import React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={20} height={24} viewBox="0 0 20 24" fill="none" {...props}>
      <Path
        d="M2.00033 21.3333C2.00033 22.8 3.20033 24 4.66699 24H15.3337C16.8003 24 18.0003 22.8 18.0003 21.3333V5.33333H2.00033V21.3333ZM19.3337 1.33333H14.667L13.3337 0H6.66699L5.33366 1.33333H0.666992V4H19.3337V1.33333Z"
        fill="#CD7047"
      />
    </Svg>
  );
}

export default SvgComponent;
