import { StyleSheet, Platform } from "react-native";
import Colors from "../../assets/colors"

const styles = StyleSheet.create({

	headerImage:{
		height: 80, 
		alignSelf:'center'
	},

	mainView:{
		marginTop:5,
		marginVertical:5,
		marginHorizontal:10
	},
	heading:{
		fontFamily:Platform ==='ios' ?'RobotoCondensed' :'Arial',
		color:Colors.text,
		fontSize: 25,
		fontWeight:'bold',
		marginLeft:10,
		marginTop:10
	},
	subHeading:{
		fontFamily:Platform === 'ios' ? 'RobotoCondensed' :'Arial',
		color:Colors.text,
		fontSize: 14,
		fontWeight:'800'
	},
	textInput:{
		color: Colors.textInput,
		fontSize:15,	
	},
	textInputIcon:{
		alignSelf:'flex-end', 
		marginRight:5, 
		marginBottom:15
	},
	manualAddress:{
		marginTop:5,
		borderWidth:1,
		backgroundColor:Colors.orange,
		borderColor:Colors.orange,
		alignItems:'center',
		justifyContent:'center',
		width:30,
		height:30,
		borderRadius:50,
	},
	backgroundView:{
		backgroundColor:Colors.backgroundBlue, 
		borderRadius:10, 
		padding:10,
	},
	linearGradient: {
		width:140,
		borderRadius: 30,
		//alignItems:'center',
		justifyContent:'center',
		borderWidth: 2,
		borderColor: 'white',
		borderBottomWidth: 0,
		shadowColor: '#2E363D99',
		shadowOffset: { width: 5, height: 5 },
		shadowOpacity: 0.8,
		shadowRadius: 10,
		elevation: 6,
		marginVertical: 10,
	},
	buttonText: {
		fontSize: 15,
		fontFamily: 'Gill Sans',
		textAlign: 'center',
		margin: 10,
		color: '#ffffff',
		backgroundColor: 'transparent',
	},
	bottomView:{ 
        backgroundColor: '#4990C8', 
        height: 50, 
        width:'100%', 
        justifyContent:'center',
	},
	termStyles:{
        color: '#FFFFFF', 
        fontSize: 20, 
        alignSelf:'center',
        fontFamily: 'RobotoCondensed-Regular',
	},
	btnWidth:{
		width:140
	},
	borderView:{
		flexDirection:'row', 	
		borderBottomColor:Colors.text,
		borderBottomWidth:1
	},
	textTop:{
        marginLeft:10,
        fontSize:10,
        color:Colors.text
    },
	searchBar:{
		//position:'absolute',
		width:100,
	},
	textBox: {
		textAlign:'center',
		color:'#4990C8', 
		fontSize:25, 
		fontFamily:'RobotoCondensed-Bold', 
		fontWeight:'bold',
		marginTop:10,
		marginBottom:10
	  },
	addressTextInput: {
		height: 38,
		color: "#5d5d5d",
		fontSize: 16,
		backgroundColor: "#FFFFFF",
		padding: 10,
	},
	addressListOuter: {
		height: 155,
		backgroundColor: "#FFFFFF",
	},
	addressTextOuter: {
		borderTopColor: "#E2E9EE",
		borderTopWidth: 1,
	},
	addressText: {
		color: "#5d5d5d",
		fontSize: 16,
		backgroundColor: "#FFFFFF",
		paddingHorizontal: 10,
		paddingVertical: 8
	},

})


export default styles;
