import {
  SET_LOADING,
  ADD_PROPERTY_LISTING_TYPE,
  ADD_TIME_ZONE,
  STORE_PREVIOUS_PROPERTY_LISTING,
  ADD_CURRENT_LISTING,
  RECEIVE_UPDATE_LISTING,
  UPDATE_CURRENT_LISTINGS,
  SAVE_RUNNER_DESCRIPTION,
  STORE_RUNNER_DESCRIPTION,
  STORE_RUNNER_DESCRIPTION_NOTE
} from "./actions";
import { camelCaseListing } from "./utils";

const initialState = {
  loading: false,
  propertylistingtype: "",
  timezone: {},
  previouspropertylisting: {},
  current: [],
  byID: {},
  // key = appointment_id, value = array of listing IDs
  byAppointmentID: {},
  runnerDescription:[],
  storeDescription:[],
  storeDescriptionNote:""
};

// Reducers (Modifies The State And Returns A New State)
const addressesReducer = (state = initialState, action) => {
  
  switch (action.type) {
    case SET_LOADING: {
      return {
        ...state,
        loading: action.loading,
      };
    }
    case ADD_PROPERTY_LISTING_TYPE: {
      return {
        ...state,
        propertylistingtype: action.propertylistingtype,
      };
    }
    case ADD_TIME_ZONE: {
      return {
        ...state,
        timezone: action.timezone,
      };
    }

    case ADD_CURRENT_LISTING: {
      return {
        ...state,
        current: [...state.current, action.listing.id],
        byID: {
          ...state.byID,
          [action.listing.id]: camelCaseListing(action.listing),
        },
      };
    }
    case UPDATE_CURRENT_LISTINGS: {
      return {
        ...state,
        current: action.current,
      };
    }

    case STORE_PREVIOUS_PROPERTY_LISTING: {
      return {
        ...state,
        previouspropertylisting: action.storeaddress,
      };
    }

    case SAVE_RUNNER_DESCRIPTION: {
      return {
        ...state,
        runnerDescription: JSON.stringify(action.runnerDescription),
      };
    }

    case STORE_RUNNER_DESCRIPTION: {
      return {
        ...state,
        storeDescription: JSON.stringify(action.storeDescription),
      };
    }

    case STORE_RUNNER_DESCRIPTION_NOTE: {
      return {
        ...state,
        storeDescriptionNote: action.storeDescriptionNote,
      };
    }

    case RECEIVE_UPDATE_LISTING: {
      return {
        ...state,
        byID: {
          ...state.byID,
          [action.listing.id]: camelCaseListing(action.listing),
        },
        byAppointmentID: {
          ...state.byAppointmentID,
          [action.listing.appointment_id]: Array.from(
            new Set([
              ...(state.byAppointmentID[action.listing.appointment_id] || []),
              action.listing.id,
            ])
          ),
        },
      };
    }
    // Default
    default: {
      return state;
    }
  }
};

export default addressesReducer;
