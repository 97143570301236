import React from "react";
import {
  View,
  StyleSheet,
  TextInput,
  KeyboardAvoidingView,
  Alert,
  ScrollView,
  Platform,
  Keyboard,
} from "react-native";
import { connect } from "react-redux";
import {
  setCurrentUser,
  createUser,
  validateUser,
  getFindAgent,
  updateUser,
  setInitial,
  getAllBrokerages,
  setPasswordFieldDisableGoOnStrip,
  setFetchedUserInfoBeforeLogin,
} from "../redux/auth/actions";
import { snakeCaseUser } from "../redux/auth/utils";
// import { NavigationEvents } from "react-navigation";
import styles from "../Styles/AccountProfileStyles";
import { ButtonOne } from "../Components/Button";
import Header from "../Components/Signup/Header";
import Form from "../Components/Form";
import BaseLayout, { headerStyleCustom } from "./BaseLayout";
import AgentLicense from "../Images/AgentLicense";
import BrokerLicense from "../Images/BrokerLicense";
import Name from "../Images/Name";
import Call from "../Images/Call";
import Mail from "../Images/Mail";
import HeaderImage from "../Images/Header";
import Title from "../Components/Signup/Title";
import TermsOfUseLink from "../Components/Signup/TermsOfUseLink";
// import NavigationService, { pageTransitions } from "../NavigationService.js";
import { store } from "../redux/store";
import { clearMessageSuccess } from "../redux/messages/actions";
import SearchableDropdown from "../Components/SearchableDropDown";
import LogsService from "../LogsService";
import DeviceInfo from "react-native-device-info";
import geolocation from "@react-native-community/geolocation";

const stylesB = StyleSheet.create({
  container: {
    padding: "5%",
    zIndex: 1100,
  },
  inner: {
    backgroundColor: "#D1E3F1",
    padding: "5%",
    paddingBottom: "7%",

    borderRadius: 20,
  },
  textInput: {
    height: 40,
    color: "#292B34",
    fontFamily: "RobotoCondensed-Light",
    fontSize: 15,
    flex: 1,
  },
  textInputWrapper: {
    borderBottomWidth: 1,
    height: 40,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 10,
    borderColor: "#00467E",
  },
  required: {
    borderColor: "#E41E2A",
  },
  textInputImage: {
    marginTop: 10,
  },
  row: {
    flex: 1,
    flexDirection: "row",
  },
  inputWrap1: {
    flex: 0.2,
  },
  inputWrap2: {
    flex: 1,
  },
  code: {
    height: 40,
    textAlign: "center",
    paddingLeft: 1,
    paddingRight: 1,
    borderRightColor: "#00467E",
    borderLeftColor: "#00467E",
    borderRadius: 15,
    borderRightWidth: 1,
    borderLeftWidth: 1,
  },
  inputphone: {
    height: 40,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 10,
    borderColor: "#00467E",
  },
});


class AccountProfile extends React.Component {
  static navigationOptions = ({ navigation }) => ({
    headerStyle: headerStyleCustom(),
    headerTintColor: "#fff",
    headerTitleStyle: {
      fontWeight: "bold",
    },
    headerBackTitle: "Back",
    headerTitle: () => <HeaderImage height={35} />,
  });

  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.state = {
      ccode: "+1",
      selectedItem: "",
      selectedItemWithName: "",
      grecID: "",
      clickedButton: true,
      showFindBtn: true,
      deviceInfoManufacturer: "",
      isLocationServiceEnabled: false,
      checkIfLocationServiceEnabledCount: 0,
    };
    LogsService.fileLog("AccountProfile", "Constructor", "Constructor called");
  }

  componentWillMount() {
    LogsService.fileLog(
      "AccountProfile",
      "componentWillMount",
      "setInitial() is calling"
    );
    this.props.setInitial();
    LogsService.fileLog("AccountProfile", "setInitial", "setInitial called");
    LogsService.fileLog(
      "AccountProfile",
      "getAllBrokerages",
      "getAllBrokerages() is calling"
    );
    // this.props.getAllBrokerages();
    // this.props.setPasswordFieldDisableGoOnStrip(true);
    // this.props.setUser({ ["ccode"]: "+1" });
    // this.state.clickedButton = true;
    // this.checkIfLocationServiceEnabled();
    // DeviceInfo.getManufacturer().then((manufacturer) => {
    //   this.setState({ deviceInfoManufacturer: manufacturer });
    // });
  }

  refreshButtonNavBack() {
    store.dispatch(clearMessageSuccess());
  }

  onChange(k, v) {
    this.props.setUser({ [k]: v });
    if (k == "ccode") {
      if (v == "") {
        this.props.setUser({ ["ccode"]: "+" });
      } else {
        this.state.ccode = v;
      }
      if (this.state.ccode != "") {
        if (this.state.ccode.slice(0, 1) != "+") {
          var setPlus = "+" + this.state.ccode;
          this.props.setUser({ ["ccode"]: setPlus });
        }
      }
    }
    if (k == "grecID") {
      if (v.length <= 2) {
        // this.props.setInitial();
      }
    }
  }
  findAgent() {
    if (this.state.grecID == "" && this.state.selectedItem == "") {
      Alert.alert("", "All fields are required", [
        {
          text: "OK",
          onPress: () => {
            // LogsService.fileLog(
            //   "AccountProfile",
            //   "findAgent() validation",
            //   "All fields are required"
            // );
          },
        },
      ]);
    } else if (this.state.grecID == "") {
      Alert.alert("", "Agent License is required", [
        {
          text: "OK",
          onPress: () => {
            // LogsService.fileLog(
            //   "AccountProfile",
            //   "findAgent() validation",
            //   "Agent License is required"
            // );
          },
        },
      ]);
    } else if (this.state.selectedItem == "") {
      Alert.alert("", "Brokerage License is required", [
        {
          text: "OK",
          onPress: () => {
            // LogsService.fileLog(
            //   "AccountProfile",
            //   "findAgent() validation",
            //   "Brokerage License is required"
            // );
          },
        },
      ]);
    } else {
      this.props.user.grecID = this.state.grecID;
      this.props.user.brokerageID = this.state.selectedItem;

      // LogsService.fileLog(
      //   "AccountProfile",
      //   "findAgent()",
      //   "getFindAgent() is calling"
      // );
      this.props.getFindAgent(snakeCaseUser(this.props.user));
      this.props.setUser({ ["ccode"]: "+1" });
    }
  }

  checkIfLocationServiceEnabled = () => {
    // LogsService.fileLog(
    //   "AccountProfile",
    //   "checkIfLocationServiceEnabled",
    //   "checkIfLocationServiceEnabled is called"
    // );
    this.setState({
      checkIfLocationServiceEnabledCount: this.state
        .checkIfLocationServiceEnabledCount++,
    });

    setTimeout(() => {
      if (
        !this.state.isLocationServiceEnabled &&
        this.state.checkIfLocationServiceEnabledCount <= 5
      ) {
        // LogsService.fileLog(
        //   "AccountProfile",
        //   "getCurrentPosition",
        //   "getCurrentPosition is calling"
        // );
        geolocation.getCurrentPosition(
          ({ coords }) => {
            console.log("coords=", coords);
            // LogsService.fileLog(
            //   "AccountProfile",
            //   "getCurrentPosition response",
            //   JSON.stringify(coords)
            // );
            this.setState({ isLocationServiceEnabled: true });
            // updateUserInfo(coords);
            if (coords != null && coords.latitude && coords.longitude) {
              this.props.user.lat = coords.latitude;
              this.props.user.lng = coords.longitude;
            }
          },
          (error) => {
            // LogsService.fileLog(
            //   "AccountProfile",
            //   "getCurrentPosition error",
            //   JSON.stringify(error)
            // );
          },
          { enableHighAccuracy: true, timeout: 15000, maximumAge: 10000 }
        );
        this.checkIfLocationServiceEnabled();
      }
    }, 10000);
    if (this.state.checkIfLocationServiceEnabledCount == 1) {
      // updateUserInfo(null);
    }
  };

  updatePhone() {
    // LogsService.fileLog(
    //   "AccountProfile",
    //   "updatePhone()",
    //   "updatePhone() called"
    // );
    var regex = /^(\+?\d{1,5}|\d{1,4})$/gm;
    if (this.props.user.ccode != "") {
      var match = this.state.ccode.match(regex);
    }
    if (this.props.user.phoneWithoutCcode != "") {
      let phoneNo = this.props.user.phoneWithoutCcode;
      let phone = phoneNo.replace(/\-/g, "");
      if (phone.slice(0, 1) == "+") {
        var s = 0;
        var l = phone.length;
        s = l - 10;
        this.props.user.phoneWithoutCcode = phone.slice(s, l);
      } else {
        this.props.user.phoneWithoutCcode = phone.slice(0, 10);
      }
    }

    if (
      this.props.user.firstName.trim() == "" ||
      !this.props.user.lastName ||
      this.props.user.lastName.trim() == "" ||
      this.props.user.email.trim() == "" ||
      this.props.user.phoneWithoutCcode == ""
    ) {
      Alert.alert("", "All fields are mandatory", [
        {
          text: "OK",
          onPress: () => {
            // LogsService.fileLog(
            //   "AccountProfile",
            //   "updatePhone() validation",
            //   "All fields are required"
            // );
          },
        },
      ]);
    } else {
      const re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(String(this.props.user.email.trim()).toLowerCase())) {
        Alert.alert("", "Please enter a valid email address", [
          {
            text: "OK",
            onPress: () => {
              // LogsService.fileLog(
              //   "AccountProfile",
              //   "updatePhone() validation",
              //   "Please enter a valid email address"
              // );
            },
          },
        ]);
      } else if (this.props.user.ccode == "") {
        Alert.alert("", "Please enter country code", [
          {
            text: "OK",
            onPress: () => {
              // LogsService.fileLog(
              //   "AccountProfile",
              //   "updatePhone() validation",
              //   "Please enter country code"
              // );
            },
          },
        ]);
      } else if (match == null) {
        Alert.alert("", "Please enter a valid country code", [
          {
            text: "OK",
            onPress: () => {
              // LogsService.fileLog(
              //   "AccountProfile",
              //   "updatePhone() validation",
              //   "Please enter a valid country code"
              // );
            },
          },
        ]);
      } else if (this.props.user.phoneWithoutCcode.length != 10) {
        Alert.alert("", "Please enter a valid phone number", [
          {
            text: "OK",
            onPress: () => {
              // LogsService.fileLog(
              //   "AccountProfile",
              //   "updatePhone() validation",
              //   "Please enter a valid phone number"
              // );
            },
          },
        ]);
      } else {
        let setUserValue = {};
        this.state.clickedButton = false;
        let codeContry = "";
        codeContry = this.props.user.ccode;
        let phoneNo = "";
        phoneNo = this.props.user.phoneWithoutCcode;
        this.props.user.phone = codeContry + phoneNo;

        setUserValue = {
          acceptingShowings: this.props.user.acceptingShowings,
          address: this.props.user.address,
          brokerageID: this.props.user.brokerageID,
          ccode: this.props.user.ccode,
          email: this.props.user.email,
          firstName: this.props.user.firstName,
          grecID: this.props.user.grecID,
          hasStripeUser: this.props.user.hasStripeUser,
          id: this.props.user.id,
          lastName: this.props.user.lastName,
          lat: this.props.user.lat,
          lng: this.props.user.lng,
          phone: this.props.user.phone,
          phoneWithoutCcode: this.props.user.phoneWithoutCcode,
          token: this.props.user.phoneWithoutCcode,
          os: Platform.OS,
          app_version: DeviceInfo.getVersion(),
          platform_version:
            Platform.OS == "ios"
              ? Platform.constants.osVersion
              : Platform.constants.Release,
          devise_model: DeviceInfo.getModel(),
          manufacturer: this.state.deviceInfoManufacturer,
        };
        this.props.user.os = Platform.OS;
        this.props.user.app_version = DeviceInfo.getVersion();
        this.props.user.platform_version =
          Platform.OS == "ios"
            ? Platform.constants.osVersion
            : Platform.constants.Release;
        this.props.user.devise_model = DeviceInfo.getModel();
        this.props.user.manufacturer = this.state.deviceInfoManufacturer;
        this.props.validateUser(setUserValue);
      }
    }
  }

  formatPhoneNumber(value) {
    if (value != null) {
      const currentValue = value.replace(/[^\d]/g, "");
      const cvLength = value.length;
      // returns: "x", "xx", "xxx"
      if (cvLength <= 3) return currentValue;
      // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
      if (cvLength <= 7)
        return `${currentValue.slice(0, 3)}-${currentValue.slice(3)}`;
      // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
      return `${currentValue.slice(0, 3)}-${currentValue.slice(
        3,
        6
      )}-${currentValue.slice(6, 10)}`;
    }
  }

  render() {
    return (
      <BaseLayout>
        <View style={{ height: "100%", width: "100%" }}>
          <Header total={3} complete={1} />
          {/* <NavigationEvents onDidFocus={() => this.refreshButtonNavBack()} /> */}
          <KeyboardAvoidingView
            style={{ flex: 9, flexDirection: "column" }}
            behavior={Platform.OS == "ios" ? "padding" : null}
            enabled
            keyboardVerticalOffset={100}
          >
            <ScrollView keyboardShouldPersistTaps="always" style={{ flex: 1 }}>
              <Title text="My Account Profile" />
              <View style={stylesB.container}>
                <View style={stylesB.inner}>
                  <View
                    style={{ ...stylesB.textInputWrapper, ...stylesB.required }}
                  >
                    <View style={{ width: "100%" }}>
                      <TextInput
                        style={stylesB.textInputImage}
                        placeholder="Agent License #"
                        placeholderTextColor="black"
                        key="ccode"
                        value={this.state.grecID}
                        keyboardType="default"
                        autoCapitalize="none"
                        onChangeText={(grecID) => {
                          this.setState({ grecID: grecID, showFindBtn: true });
                          this.props.setFetchedUserInfoBeforeLogin();
                        }}
                      />
                      <AgentLicense
                        style={{ position: "absolute", right: 0, top: 2 }}
                      />
                    </View>
                  </View>
                  <View
                    style={{ ...stylesB.textInputWrapper, ...stylesB.required }}
                  >
                    <View
                      keyboardShouldPersistTaps="always"
                      style={{ width: "100%" }}
                    >
                      <SearchableDropdown
                        onItemSelect={(item) => {
                          // LogsService.fileLog(
                          //   "AccountProfile",
                          //   "Brokerage License onItemSelect",
                          //   "selected Item=>" + JSON.stringify(item)
                          // );
                          this.setState({
                            selectedItem: item.brokerage_code,
                            selectedItemWithName: item.selectedItemWithName,
                          });
                          this.props.setFetchedUserInfoBeforeLogin();
                          // this.setState({ showFindBtn: true });
                        }}
                        onInputFouceChange={() => {
                          // this.setState({ showFindBtn: false });
                        }}
                        containerStyle={{ padding: 5, zIndex: 999 }}
                        itemStyle={{
                          padding: 10,
                          marginTop: 2,
                          backgroundColor: "#ddd",
                          borderWidth: 0.5,
                          borderRadius: 2,
                        }}
                        itemsContainerStyle={{
                          maxHeight: 300,
                          width: 300,
                          zIndex: 998,
                          position: "absolute",
                          marginTop: 40,
                        }}
                        items={this.props.brokeragesList}
                        resetValue={false}
                        textInputProps={{
                          // value: this.state.selectedItem,
                          placeholder: "Search Brokerage License #",
                          placeholderTextColor: "black",
                          style: stylesB.textInputImage,
                          onTextChange: (text) => {
                            // LogsService.fileLog(
                            //   "AccountProfile",
                            //   "Brokerage License onTextChange",
                            //   text
                            // );
                            this.setState({
                              selectedItem: text,
                              selectedItemWithName: text,
                              showFindBtn: true,
                            });
                            this.props.setFetchedUserInfoBeforeLogin();
                          },
                        }}
                        listProps={{
                          nestedScrollEnabled: true,
                        }}
                      />
                      <BrokerLicense
                        style={{ position: "absolute", right: 0, top: 10 }}
                      />
                    </View>
                  </View>
                </View>
              </View>
              {!this.props.fetchedUserInfo && this.state.showFindBtn && (
                <View style={styles.btnView}>
                  <ButtonOne
                    text={"Find me"}
                    style={styles.btnWidth}
                    onPress={() => {
                      // LogsService.fileLog(
                      //   "AccountProfile",
                      //   "Find me onPress",
                      //   "Find me clicked"
                      // );
                      Keyboard.dismiss();
                      this.findAgent();
                    }}
                  />
                </View>
              )}
              {this.props.fetchedUserInfo && (
                <View style={{ marginBottom: 100 }}>
                  <Form
                    fields={[
                      {
                        label: "First Name",
                        key: "firstName",
                        value: this.props.user.firstName,
                        image: () => AgentLicense,
                      },
                      {
                        label: "Last Name",
                        key: "lastName",
                        value: this.props.user.lastName,
                        image: () => Name,
                      },
                      {
                        label: "Code",
                        key: "ccode",
                        value: this.props.user.ccode,
                        image: () => Call,
                      },

                      // {
                      //   label: "Mobile Phone",
                      //   key: "phoneWithoutCcode",
                      //   value: this.formatPhoneNumber(
                      //     this.props.user.phoneWithoutCcode
                      //   ),
                      //   image: () => Call,
                      // },
                      {
                        label: "Email Address",
                        key: "email",
                        value: this.props.user.email,
                        // disabled: true,
                        image: () => Mail,
                      },
                    ]}
                    onChange={this.onChange}
                  />
                </View>
              )}
            </ScrollView>
          </KeyboardAvoidingView>
          <View style={styles.btnView}>
            {/* {!!this.props.user.phoneWithoutCcode &&
              this.props.user.phoneWithoutCcode !== "" &&
              this.props.fetchedUserInfo && (
                <ButtonOne
                  text={"NEXT"}
                  style={styles.btnWidth}
                  onPress={() => {
                    // LogsService.fileLog(
                    //   "AccountProfile",
                    //   "NEXT onPress",
                    //   "NEXT button clicked"
                    // );
                    this.updatePhone();
                  }}
                />
              )} */}
          </View>

          <TermsOfUseLink />
        </View>
      </BaseLayout>
    );
  }
}


export default connect(
  (state) => ({
    user: state.auth.user,
    fetchedUserInfo: state.auth.fetchedUserInfo,
    passwordDisable: state.auth.passwordDisable,
    brokeragesList: state.auth.brokeragesList,
  }),
  (dispatch) => ({
    setUser: (user) => dispatch(setCurrentUser(user)),
    setInitial: () => dispatch(setInitial()),
    createUser: (user) => dispatch(createUser(user)),
    getFindAgent: (user) => dispatch(getFindAgent(user)),
    getAllBrokerages: () => dispatch(getAllBrokerages()),
    setFetchedUserInfoBeforeLogin: () =>
      dispatch(setFetchedUserInfoBeforeLogin(false)),
    setPasswordFieldDisableGoOnStrip: (passwordDisable) =>
      dispatch(setPasswordFieldDisableGoOnStrip(passwordDisable)),
    updateUser: (user) => {
      dispatch(updateUser(user)).then((r) => {
        if (r.status === "ok" || r.status === 200) {
          NavigationService.navigate(
            pageTransitions["AccountProfile"]["onUpdateUser"]
          );
        }
      });
    },
    validateUser: (user) => {
      dispatch(validateUser(user)).then((r) => {
        // LogsService.fileLog(
        //   "AccountProfile",
        //   "validateUser(user) response ",
        //   JSON.stringify(r)
        // );
        if (r.status === "ok" || r.status === 200) {
          // LogsService.fileLog(
          //   "AccountProfile",
          //   "validateUser(user) response ",
          //   'Navigating to ["AccountProfile"]["onUpdateUser"]'
          // );
          // NavigationService.navigate(
          //   pageTransitions["AccountProfile"]["onUpdateUser"]
          // );
        }
      });
    },
  })
)(AccountProfile);
