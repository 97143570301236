import React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={18} height={18} viewBox="0 0 18 18" {...props}>
      <Path
        d="M16.312 18H6.75a.566.566 0 01-.252-.059l-2-1-2 1A.569.569 0 012.25 18a.562.562 0 01-.562-.562v-3.659l-.08-.022-.076-.021a.692.692 0 01-.484-.495l-.017-.063A2.13 2.13 0 00.2 11.754a.72.72 0 01-.179-.684v-.005a2.208 2.208 0 000-1.709.719.719 0 01.179-.684l.015-.012a2.134 2.134 0 00.819-1.427l.012-.048a.694.694 0 01.484-.495l.042-.011a2.054 2.054 0 001.382-.807L3 5.828a.68.68 0 01.481-.2.66.66 0 01.174.023l.038.01a3.225 3.225 0 00.811.167V1.69A1.694 1.694 0 016.187 0h7.177a1.7 1.7 0 011.192.5L17.5 3.444a1.7 1.7 0 01.5 1.2v11.669A1.689 1.689 0 0116.312 18zM6.187 1.125a.564.564 0 00-.562.565v3.956A.676.676 0 016 5.827a2.108 2.108 0 001.422.85l.044.012a.692.692 0 01.483.495l.015.059A2.137 2.137 0 008.8 8.672a.716.716 0 01.178.684 2.211 2.211 0 000 1.711.718.718 0 01-.177.687l-.013.013a2.132 2.132 0 00-.82 1.427l-.012.047a.692.692 0 01-.483.495h-.008l-.149.039v3.1h9a.563.563 0 00.563-.562V5.625H13.22a.844.844 0 01-.844-.841V1.125zM4.5 15.75a.566.566 0 01.252.059l1.435.718V14.41l-.007.008a.673.673 0 01-.48.2.661.661 0 01-.357-.106 1.583 1.583 0 00-1.679 0 .662.662 0 01-.357.1.67.67 0 01-.48-.2l-.007-.007v2.118l1.436-.718a.562.562 0 01.244-.055zm0-2.6a2.685 2.685 0 011.12.242 2.682 2.682 0 011.309-.681 2.875 2.875 0 01.9-1.58 2.857 2.857 0 010-1.842 2.855 2.855 0 01-.9-1.585 2.817 2.817 0 01-1.542-.9 3.254 3.254 0 01-.887.146 3.238 3.238 0 01-.887-.15 2.775 2.775 0 01-1.543.9 2.869 2.869 0 01-.9 1.584 2.9 2.9 0 010 1.842 2.853 2.853 0 01.9 1.583 2.747 2.747 0 011.309.679 2.68 2.68 0 011.121-.235zm9-12.007V4.5h3.357a.553.553 0 00-.148-.259l-2.948-2.948a.548.548 0 00-.261-.147z"
        fill="#4990c8"
      />
    </Svg>
  );
}

export default SvgComponent;
