import React from "react";
import { View, Image, Platform } from "react-native";

function LogoTitle() {
  return (
    <View>
      {Platform.OS === "ios" ? (
        <View style={{ flex: 1 }}>
          <Image
            style={{
              top: -25,
              width: 240,
              height: 35,
              resizeMode: "contain",
              justifyContent: "center",
              alignItems: "center",
            }}
            source={require("../../assets/KeyPleazHortizonal.png")}
          />
        </View>
      ) : (
        <View style={{ flex: 1 }}>
          <Image
            style={{
              left: "15%",
              width: "70%",
              height: 35,
              resizeMode: "contain",
              justifyContent: "center",
              alignItems: "center",
            }}
            source={require("../../assets/KeyPleazHortizonal.png")}
          />
        </View>
      )}
    </View>
  );
}

export default LogoTitle;
