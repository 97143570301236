import React, { useState, useEffect } from "react";
import {
  View,
  KeyboardAvoidingView,
  Switch,
  Text,
  Alert,
  TouchableOpacity,
  ScrollView,
} from "react-native";
import styles from "../Styles/CreatePasswordStyles";
import { ButtonOne } from "../Components/Button";
import Header from "../Components/Signup/Header";
import PwdKey from "../Images/PwdKey";
import Form from "../Components/Form";
import HeaderImage from "../Images/Header";
import Title from "../Components/Signup/Title";
import TermsOfUseLink from "../Components/Signup/TermsOfUseLink";
import BaseLayout, { headerStyleCustom } from "./BaseLayout";
import { connect } from "react-redux";
import {
  updateUser,
  createUser,
  setCurrentUser,
  setPasswordFieldDisableGoOnStrip,
} from "../redux/auth/actions";
// import NavigationService from "../NavigationService.js";
import Eyes from "../Images/Eyes";
import Eyesoff from "../Images/Eyesoff";
import { store } from "../redux/store";
import { clearMessageSuccess } from "../redux/messages/actions";
import LogsService from "../LogsService";

const CreatePassword = (props) => {
  const [acceptedTermsAndConditions, setAcceptedTermsAndConditions] =
    useState(false);
  const [passwordDisable, setPasswordDisable] = useState(true);
  const [disableButton, setDisableButton] = useState(false);
  const [passwordView, setPasswordView] = useState(true);
  const [cnfPasswordView, setCnfPasswordView] = useState(true);
  const [btnText, setBtnText] = useState("SUBMIT");

  useEffect(() => {
    if (
      props.user.password != undefined ||
      props.user.passwordConfirmation != undefined
    ) {
      setPasswordDisable(props.passwordDisable);
      setBtnText("NEXT");
      LogsService.fileLog('CreatePassword','useEffect()','useEffect called');
    }
  }, []);
  const exitUsers = () => {
    LogsService.fileLog('CreatePassword', 'exitUsers()', 'exitUsers() is called');
    setDisableButton(false);
    if (!props.passwordDisable) {
      LogsService.fileLog('CreatePassword', 'exitUsers()', 'navigating to ConnectStripe page');
      // NavigationService.navigate("ConnectStripe");
    }
  };
  const updateUserData = () => {
    LogsService.fileLog('CreatePassword', 'updateUserData()', 'updateUserData clicked');

    var validateCode = props.user.validateCode;
    var password = props.user.password;
    var passwordConfirmation = props.user.passwordConfirmation;

    if (
      password == undefined ||
      passwordConfirmation == undefined ||
      validateCode == undefined
    ) {
      Alert.alert("", "All fields are mandatory", [
        { text: "OK", onPress: () => {
          LogsService.fileLog('CreatePassword', 'updateUserData() validation', 'All fields are mandatory');
        } },
      ]);
    } else if (validateCode == "") {
      Alert.alert("", "All fields are mandatory", [
        { text: "OK", onPress: () => {
          LogsService.fileLog('CreatePassword', 'updateUserData() validation', 'All fields are mandatory');
        } },
      ]);
    } else if (password.length < 6 || passwordConfirmation.length < 6) {
      //
      Alert.alert("", "Password is too short (minimum is 6 characters)", [
        { text: "OK", onPress: () => {
          LogsService.fileLog('CreatePassword', 'updateUserData() validation', 'Password is too short (minimum is 6 characters');
        } },
      ]);
    } else if (password == "" || passwordConfirmation == "") {
      Alert.alert(
        "",
        "The password and confirmation password fields are mandatory",
        [{ text: "OK", onPress: () => {
          LogsService.fileLog('CreatePassword', 'updateUserData() validation', 'The password and confirmation password fields are mandatory');
        } }]
      );
    } else if(/\s/.test(password)){
      Alert.alert("", "Spaces are not allowed in Password", [
        { text: "OK", onPress: () => {
          LogsService.fileLog('CreatePassword','updateUserData() validation','Spaces are not allowed in Password');
        } },
      ]);
  } else if(/\s/.test(passwordConfirmation)){
    Alert.alert("", "Spaces are not allowed in Confirm Password", [
      { text: "OK", onPress: () => {
        LogsService.fileLog('CreatePassword','updateUserData() validation','Spaces are not allowed in Confirm Password');
      } },
    ]);
    } else {
      if (password != passwordConfirmation) {
        Alert.alert("", "The password and confirmation password do not match", [
          { text: "OK", onPress: () => {
            LogsService.fileLog('CreatePassword', 'updateUserData() validation', 'The password and confirmation password do not match');
          } },
        ]);
      } else {
        setDisableButton(true);
        LogsService.fileLog('CreatePassword', 'updateUserData()', 'createUser calling');
        props.createUser(props.user, props.pushnotificationToken.token);
        setDisableButton(false);
        setBtnText("NEXT");
        store.dispatch(clearMessageSuccess());
      }
    }
  };
  return (
    <BaseLayout>
      <View style={{ height: "100%", width: "100%" }}>
        <Header total={3} complete={3} />
        <Title text="Create Password" />
        <KeyboardAvoidingView
          style={{ flex: 9, flexDirection: "column" }}
          behavior="padding"
          enabled
          keyboardVerticalOffset={100}
        >
          <ScrollView style={{ paddingHorizontal: 10 }}>
            <Form
              fields={[
                {
                  label: "One Time Passcode",
                  key: "validateCode",
                  value: props.user.validateCode,
                  required: true,
                  textInputProps: {
                    secureTextEntry: false,
                    editable: props.passwordDisable,
                  },
                },
                {
                  label: "Password",
                  key: "password",
                  value: props.user.password,
                  required: true,
                  image: () => PwdKey,
                  textInputProps: {
                    secureTextEntry: passwordView,
                    editable: props.passwordDisable,
                  },
                },
                {
                  label: "Confirm Password",
                  key: "passwordConfirmation",
                  value: props.user.passwordConfirmation,
                  required: true,
                  image: () => PwdKey,
                  textInputProps: {
                    secureTextEntry: cnfPasswordView,
                    editable: props.passwordDisable,
                  },
                },
              ]}
              onChange={(k, v) => {
                  if(k == "password" && /\s/.test(v)){
                      Alert.alert("", "Spaces are not allowed in Password", [
                        { text: "OK", onPress: () => {
                          LogsService.fileLog('CreatePassword','onChangeText Password','Spaces are not allowed in Password');
                        } },
                      ]);
                  }else if(k == "passwordConfirmation" && /\s/.test(v)){
                    Alert.alert("", "Spaces are not allowed in Confirm Password", [
                      { text: "OK", onPress: () => {
                        LogsService.fileLog('CreatePassword','onChangeText Confirm Password','Spaces are not allowed in Confirm Password');
                      } },
                    ]);
                  }else{
                    props.setCurrentUser({ [k]: v })
                  }
                }
              }
            />
            {!passwordView && (
              <TouchableOpacity
                onPress={() => {
                  LogsService.fileLog('CreatePassword','onPress', 'Hide password');
                  setPasswordView(true);
                }}
                style={{ marginTop: 83, right: 70, position: "absolute" }}
              >
                <Eyes />
              </TouchableOpacity>
            )}
            {passwordView && (
              <TouchableOpacity
                onPress={() => {
                  LogsService.fileLog('CreatePassword','onPress', 'Show password');
                  setPasswordView(false);
                }}
                style={{ marginTop: 83, right: 70, position: "absolute" }}
              >
                <Eyesoff />
              </TouchableOpacity>
            )}
            {!cnfPasswordView && (
              <TouchableOpacity
                onPress={() => {
                  LogsService.fileLog('CreatePassword','onPress', 'Hide Confirm password');
                  setCnfPasswordView(true);
                }}
                style={{ marginTop: 123, right: 70, position: "absolute" }}
              >
                <Eyes />
              </TouchableOpacity>
            )}
            {cnfPasswordView && (
              <TouchableOpacity
                onPress={() => {
                  LogsService.fileLog('CreatePassword','onPress', 'Show Confirm password');
                  setCnfPasswordView(false);
                }}
                style={{ marginTop: 123, right: 70, position: "absolute" }}
              >
                <Eyesoff />
              </TouchableOpacity>
            )}
          </ScrollView>
        </KeyboardAvoidingView>

        <View style={{ width: "100%", alignItems: "center" }}>
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Switch
              style={{ marginTop: 5 }}
              value={acceptedTermsAndConditions}
              onValueChange={(value) => {
                LogsService.fileLog('CreatePassword','Switch onValueChange', value ? 'I Accept the Terms of Use --> ' + "Toggle enabled" : 'I Accept the Terms of Use --> ' + "Toggle disabled");
                setAcceptedTermsAndConditions(value);
              }}
            />
            <Text style={{ marginLeft: 30 }}>I Accept the Terms of Use</Text>
          </View>
        </View>

        <View style={styles.btnView}>
          {acceptedTermsAndConditions && (
            <ButtonOne
              text={btnText}
              style={styles.btnWidth}
              onPress={() => {
                LogsService.fileLog('CreatePassword', btnText + ' onPress', btnText + 'clicked');
                !disableButton 
                ? 
                  props.passwordDisable ? updateUserData() : exitUsers()
                :
                  null
              }}
            />
          )}
        </View>

        <TermsOfUseLink />
      </View>
    </BaseLayout>
  );
};

CreatePassword.navigationOptions = ({ navigation }) => ({
  headerStyle: headerStyleCustom(),
  headerTintColor: "#fff",
  headerTitleStyle: {
    fontWeight: "bold",
  },
  headerBackTitle: "Back",
  headerTitle: () => <HeaderImage height={35} />,
});

export default connect(
  (state) => ({
    user: state.auth.user,
    loading: state.auth.loading,
    passwordDisable: state.auth.passwordDisable,
    pushnotificationToken: state.auth.pushnotificationToken,
  }),
  (dispatch) => ({
    setCurrentUser: (user) => dispatch(setCurrentUser(user)),
    createUser: (user, pushNotificationToken) =>
      dispatch(createUser(user, pushNotificationToken)),
    setPasswordFieldDisableGoOnStrip: (passwordDisable) =>
      dispatch(setPasswordFieldDisableGoOnStrip(passwordDisable)),
    updateUser: (user) => {
      dispatch(updateUser(user)) // TODO stop this from redirecting if something fails
        .then((r) => {
          LogsService.fileLog('CreatePassword', 'dispatch(updateUser(user))', JSON.stringify(r));
          if (r.status === "ok" || r.status === 200) {
            LogsService.fileLog('CreatePassword', 'dispatch(updateUser(user))', 'navigating to ConnectStripe page');
            // NavigationService.navigate("ConnectStripe");
          }
        });
    },
  })
)(CreatePassword);
