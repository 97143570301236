import React, { Component } from "react";
import { Text, View, ImageBackground } from "react-native";

export default class Footer extends Component {
  render() {
    return (
      <View>
        <ImageBackground
          source={require("../../assets/bottom_bg.png")}
          style={{
            height: 40,
            width: "100%",
            resizeMode: "contain",
            alignItems: "center",
          }}
        >
          <View style={{ flexDirection: "row" }}>
            <Text style={{ color: "white", fontSize: 20 }}>See </Text>
            <Text style={{ color: "#A3C7E3", fontSize: 20 }}>it fast.</Text>
            <Text style={{ color: "white", fontSize: 20 }}>Sell </Text>
            <Text style={{ color: "#A3C7E3", fontSize: 20 }}>it fast.</Text>
          </View>
        </ImageBackground>
        <View
          style={{ backgroundColor: "#4990C8E5", width: "100%", height: 10 }}
        />
      </View>
    );
  }
}
