import React, { Component } from "react";
import {
  Text,
  View,
  ScrollView,
  Alert,
  TextInput,
  TouchableOpacity,
  Platform,
  KeyboardAvoidingView,
} from "react-native";
// import Slider from "react-native-smooth-slider";
import moment from "moment-timezone";
import styles from "../../Styles/AppointmentDetailsStyles";
// import Icon from "react-native-vector-icons/MaterialCommunityIcons";
import Footer from "../../Components/Footer.js";
import ProgressBar from "../../Components/ProgressBar";
import { ButtonOne } from "../../Components/Button";
import HeaderImage from "../../Images/Header";
import CheckboxWithText from "../../Components/CheckboxWithText";
import Title from "../../Components/CreateAppointment/Title";
import { connect } from "react-redux";
import { updateCurrentAppointment } from "../../redux/appointments/actions";
import BaseLayout, { headerStyleCustom } from "../BaseLayout";
import { __esModule } from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import ActionSheetMenu from "../../Components/ActionSheetMenu";
import RNDateTimePicker from "@react-native-community/datetimepicker";
// import RNDatePickerNew from "react-native-datepicker";
import LogsService from "../../LogsService";

// import { SvgXml } from "react-native-svg";

const defaultFee = 50;
const minFee = 20;
const maxFee = 400;
const APPOINTMENT_TYPES = {
  asap: "asap",
  scheduled: "scheduled",
};
const DatePicker = (props) => {
  console.log("DatePicker Cancelled");
  return (
    <RNDateTimePicker
      style={{ width: "100%", alignSelf: "flex-start" }}
      value={new Date(moment(props.value).format("dddd, MMM DD, YYYY"))}
      mode="date"
      placeholder="Select start date"
      placeholderTextColor="black"
      format="dddd, MMM DD, YYYY"
      minDate={moment().toDate()}
      // iconComponent={
      //   <Icon size={27} color="#4990C8" name="calendar-month-outline" />
      // }
      confirmBtnText="Confirm"
      cancelBtnText="Cancel"
      customStyles={{
        datePicker: {
          backgroundColor: "#888888",
        },
        dateInput: {
          alignItems: "flex-start",
          borderWidth: 0,
          marginLeft: 5,
        },
      }}
      onChange={props.onDateChange}
    />
  );
};
const DatePickerIOS = (props) => {
  return (
    <RNDatePickerNew
      style={{ width: "100%", alignSelf: "flex-start" }}
      // value={ new Date()}
      date={moment(props.value).format("dddd, MMM DD, YYYY")}
      mode="date"
      placeholder="Select start date"
      placeholderTextColor="black"
      format="dddd, MMM DD, YYYY"
      minDate={moment().toDate()}
      iconComponent={
        <Icon size={27} color="#4990C8" name="calendar-month-outline" />
      }
      confirmBtnText="Confirm"
      cancelBtnText="Cancel"
      customStyles={{
        datePicker: {
          backgroundColor: "#888888",
        },
        dateInput: {
          alignItems: "flex-start",
          borderWidth: 0,
          marginLeft: 5,
        },
      }}
      onDateChange={props.onDateChange}
    />
  );
};

const TimePicker = (props) => {
  return (
    <RNDateTimePicker
      style={{ width: "100%", justifyContent: "center" }}
      value={new Date()}
      mode="time"
      placeholder="Select start time"
      placeholderTextColor="black"
      format="hh:mm a"
      minuteInterval={5}
      // iconComponent={
      //   <SvgXml
      //     width={27}
      //     height={27}
      //     xml={`<svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Time</title><path d="M256 64C150 64 64 150 64 256s86 192 192 192 192-86 192-192S362 64 256 64z" fill="transparent" stroke="#4990C8" stroke-miterlimit="10" stroke-width="32"/><path fill="none" stroke="#4990C8" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M256 128v144h96"/></svg>`}
      //   />
      // }
      confirmBtnText="Confirm"
      cancelBtnText="Cancel"
      customStyles={{
        datePicker: {
          backgroundColor: "#888888",
        },
        dateInput: {
          alignItems: "flex-start",
          borderWidth: 0,
          marginLeft: 5,
        },
      }}
      onChange={props.onDateChange}
    />
  );
};
const TimeIOSPicker = (props) => {
  return (
    <RNDatePickerNew
      style={{ width: "100%", justifyContent: "center" }}
      date={moment(props.value).format("hh:mm a")}
      mode="time"
      placeholder="Select start time"
      placeholderTextColor="black"
      format="hh:mm a"
      minuteInterval={15}
      // iconComponent={
      //   <SvgXml
      //     width={27}
      //     height={27}
      //     xml={`<svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Time</title><path d="M256 64C150 64 64 150 64 256s86 192 192 192 192-86 192-192S362 64 256 64z" fill="transparent" stroke="#4990C8" stroke-miterlimit="10" stroke-width="32"/><path fill="none" stroke="#4990C8" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M256 128v144h96"/></svg>`}
      //   />
      // }
      confirmBtnText="Confirm"
      cancelBtnText="Cancel"
      customStyles={{
        datePicker: {
          backgroundColor: "#888888",
        },
        dateInput: {
          alignItems: "flex-start",
          borderWidth: 0,
          marginLeft: 5,
        },
      }}
      onDateChange={props.onDateChange}
    />
  );
};

class AppointmentDetails extends Component {
  static navigationOptions = ({ navigation }) => ({
    headerStyle: headerStyleCustom(),
    headerTintColor: "#fff",
    headerTitleStyle: {
      fontWeight: "bold",
    },
    headerBackTitle: "Back",
    headerTitle: () => <HeaderImage height={35} />,
    headerRight: () => (
      <ActionSheetMenu numberOfLines={1} navigation={navigation} />
    ),
  });

  constructor(props) {
    super(props);

    this.state = {
      appointmentType: APPOINTMENT_TYPES["scheduled"],
      fee: defaultFee,
      feeInput: defaultFee,
      showRNTimePicker: false,
      showRNTimePickerEnd: false,
      note: "",
      showStartDatePicker: false,
      showEndDatePicker: false,
    };
    LogsService.fileLog('AppointmentDetails','constructor','constructor initiated');
    LogsService.fileLog('AppointmentDetails','constructor','Redux updateCurrentAppointment is called');

    this.props.updateCurrentAppointment({
      ...this.props.appointment,
      feeCents: defaultFee * 100,
    });
    this.onChangeDate = this.onChangeDate.bind(this);
    this.getConvenienceFee = this.getConvenienceFee.bind(this);
    this.getShowingPrice = this.getShowingPrice.bind(this);
  }
  componentDidMount() {
    LogsService.fileLog('AppointmentDetails','componentDidMount','componentDidMount is called');
    var currentDateTime = moment()
      // .tz(JSON.parse(this.props.appointment.time_zone).timeZoneId)
      // .format("YYYY-MM-DD HH:mm:ss");
    // this.props.appointment.startsAt = currentDateTime;
    // this.props.appointment.endsAt = currentDateTime;
    // this.props.updateCurrentAppointment({
    //   ...this.props.appointment,
    //   startsAt: currentDateTime,
    // });
    // this.props.updateCurrentAppointment({
    //   ...this.props.appointment,
    //   endsAt: currentDateTime,
    // });
    if (this.props.addresses.propertylistingtype == "OPENHOUSE") {
      this.props.updateCurrentAppointment({
        ...this.props.appointment,
        appointmentType: APPOINTMENT_TYPES["scheduled"],
      });
    }
  }

  onChangeDate(key) {
    LogsService.fileLog('AppointmentDetails','onChangeDate','onChangeDate is called with key=', key);
    return (date) => {
      console.log("date=", date);
      LogsService.fileLog('AppointmentDetails','onChangeDate date',date);
      if (date.nativeEvent.timestamp != undefined) {
        if (key == "endsAt") this.setState({ showEndDatePicker: false });
        else this.setState({ showStartDatePicker: false });
        var tempDate = new Date(date.nativeEvent.timestamp);
        const currentDateTime = this.props.appointment[key];
        const d = moment(currentDateTime);
        const newDateTime = moment(tempDate)
          .add({ hours: d.hour(), minutes: d.minutes() })
          .format("YYYY-MM-DD HH:mm:ss");
        this.props.updateCurrentAppointment({
          ...this.props.appointment,
          [key]: newDateTime,
        });
        LogsService.fileLog('AppointmentDetails','onChangeDate newDateTime=', newDateTime);
        LogsService.fileLog('AppointmentDetails','onChangeDate', 'Hide date picker');
      } else {
        this.setState({ showStartDatePicker: false, showEndDatePicker: false });
        LogsService.fileLog('AppointmentDetails','onChangeDate', 'Hide date picker');
      }
    };
  }

  onChangeDateIOS(key) {
    LogsService.fileLog('AppointmentDetails','onChangeDateIOS','onChangeDateIOS is called with key=', key);
    return (date) => {
      LogsService.fileLog('AppointmentDetails','onChangeDateIOS date',date);
      const currentDateTime = this.props.appointment[key];
      const d = moment(currentDateTime);
      const newDateTime = moment(date)
        .add({ hours: d.hour(), minutes: d.minutes() })
        .format("YYYY-MM-DD HH:mm:ss");
      this.props.updateCurrentAppointment({
        ...this.props.appointment,
        [key]: newDateTime,
      });
      LogsService.fileLog('AppointmentDetails','onChangeDateIOS newDateTime=', newDateTime);
      LogsService.fileLog('AppointmentDetails','onChangeDateIOS', 'Hide date picker');
    };
  }

  onChangeTime(key) {
    LogsService.fileLog('AppointmentDetails','onChangeTime','onChangeTime is called with key=', key);
    return (time) => {
      LogsService.fileLog('AppointmentDetails','onChangeTime time', time);
      if (time.nativeEvent.timestamp != undefined) {
        if (key == "endsAt") this.setState({ showRNTimePickerEnd: false });
        else this.setState({ showRNTimePicker: false });
        var tempTime = new Date(time.nativeEvent.timestamp);
        const currentDateTime = this.props.appointment[key];
        const d = moment(currentDateTime).startOf("day");
        var parsedTime = moment(tempTime).format("hh:mm a");
        // This is about to get super hacky
        // First remove the 'am' or 'pm'. If pm, we'll add 12 hours
        const modifierString = parsedTime.slice(6, 8);
        var modifierHours = modifierString === "pm" ? 12 : 0;
        // This breaks if the user selects something like "12:00" so now we subtract 12 hours if needed
        if (parsedTime.slice(0, 2) === "12") {
          modifierHours -= 12;
        }
        // Now grab the first 5 characters and turn it into a moment duration
        const duration = moment
          .duration(parsedTime.slice(0, 5))
          .add(modifierHours, "hour");
        const newDateTime = moment(d)
          .add(duration)
          .format("YYYY-MM-DD HH:mm:ss");
        this.props.updateCurrentAppointment({
          ...this.props.appointment,
          [key]: newDateTime,
        });
        LogsService.fileLog('AppointmentDetails','onChangeTime newDateTime=', newDateTime);
        LogsService.fileLog('AppointmentDetails','onChangeTime', 'Hide time picker');
      }
      else {
        this.setState({ showRNTimePicker: false,showRNTimePickerEnd: false });
        LogsService.fileLog('AppointmentDetails','onChangeTime', 'Hide time picker');
      }
    };
  }
  onChangeIosTime(key) {
    LogsService.fileLog('AppointmentDetails','onChangeIosTime','onChangeIosTime is called with key=', key);
    return (time) => {
      LogsService.fileLog('AppointmentDetails','onChangeIosTime time', time);
      const currentDateTime = this.props.appointment[key];
      const d = moment(currentDateTime).startOf("day");
      // This is about to get super hacky
      // First remove the 'am' or 'pm'. If pm, we'll add 12 hours
      const modifierString = time.slice(6, 8);
      var modifierHours = modifierString === "pm" ? 12 : 0;
      // This breaks if the user selects something like "12:00" so now we subtract 12 hours if needed
      if (time.slice(0, 2) === "12") {
        modifierHours -= 12;
      }
      // Now grab the first 5 characters and turn it into a moment duration
      const duration = moment
        .duration(time.slice(0, 5))
        .add(modifierHours, "hour");
      const newDateTime = moment(d).add(duration).format("YYYY-MM-DD HH:mm:ss");
      this.props.updateCurrentAppointment({
        ...this.props.appointment,
        [key]: newDateTime,
      });
      LogsService.fileLog('AppointmentDetails','onChangeIosTime newDateTime=', newDateTime);
      LogsService.fileLog('AppointmentDetails','onChangeIosTime', 'Hide time picker');
    };
  }

  checkFeeValue(value) {
    var obj = {
      msg: "",
      flag: true,
      fee: 0,
    };
    const regex = new RegExp("^[0-9]+$");
    if (regex.test(value)) {
      var valueOfMod = value % 5;
      if (value < minFee) {
        obj.msg = "Showing fee cannot be less than $" + minFee;
        obj.fee = minFee;
        obj.flag = false;
      } else if (value > maxFee) {
        obj.msg = "Maximum showing fee is $" + maxFee;
        obj.fee = maxFee;
        obj.flag = false;
      } else if (valueOfMod != 0) {
        obj.msg = "Fee is in $5 Increments";
        obj.fee = minFee;
        obj.flag = false;
      }
    } else {
      obj.msg = "Please Enter Valid Value";
      obj.fee = minFee;
      obj.flag = false;
    }
    return obj;
  }

  getConvenienceFee() {
    return this.getShowingPrice() * 0.145;
  }

  getShowingPrice() {
    return this.state.fee.toFixed(2);
  }

  getShowingPlusConvenience() {
    return (
      parseFloat(this.getShowingPrice()) + parseFloat(this.getConvenienceFee())
    );
  }

  getTotalPrice() {
    return this.getShowingPlusConvenience().toFixed(2);
  }
  getPropertyListingTypeLabelText(type) {
    const propertyListingTypeArr = {
      HOMESHOWING: "Home Showing",
      OPENHOUSE: "Open House",
      RUNNER: "Runner",
    };
    return propertyListingTypeArr[type];
  }
  render() {
    const sliderStyle = {
      sliderDummy: {
        backgroundColor: "#12487D",
        width: 270,
        height: 5,
        borderRadius: 50,
        position: "absolute",
      },
      sliderReal: {
        backgroundColor: "#12487D",
        width: 270,
        height: 5,
      },
    };
    return (
      <BaseLayout>
        <View style={{ height: "100%", width: "100%" }}>
          <ProgressBar total={4} complete={4} />
          <Title
            text={
              this.getPropertyListingTypeLabelText(
                this.props.addresses.propertylistingtype
              ) + " Details"
            }
          />
          <KeyboardAvoidingView
            style={{ flex: 9, flexDirection: "column" }}
            behavior="padding"
            enabled
            keyboardVerticalOffset={100}
          >
            <ScrollView style={{ paddingHorizontal: 10 }}>
              <View>
                <View>
                  {this.props.addresses.propertylistingtype != "OPENHOUSE" && (
                    <View
                      style={{
                        marginTop: 10,
                        flexDirection: "row",
                        alignItems: "center",
                        paddingHorizontal: 20,
                      }}
                    >
                      <CheckboxWithText
                        checked={
                          this.props.appointment.appointmentType ===
                          APPOINTMENT_TYPES["asap"]
                        }
                        onPress={() => {
                          LogsService.fileLog('AppointmentDetails','onPress', "Request an agent as soon as possible");
                          this.props.updateCurrentAppointment({
                            ...this.props.appointment,
                            appointmentType: APPOINTMENT_TYPES["asap"],
                          });
                        }}
                        text="Request an agent as soon as possible"
                        textStyle={{ ...styles.text, marginLeft: 10 }}
                      />
                    </View>
                  )}

                  <View
                    style={{
                      marginTop: 10,
                      flexDirection: "row",
                      alignItems: "center",
                      paddingHorizontal: 20,
                    }}
                  >
                    <CheckboxWithText
                      checked={
                        this.props.appointment.appointmentType ===
                        APPOINTMENT_TYPES["scheduled"]
                      }
                      onPress={() => {
                        LogsService.fileLog('AppointmentDetails','onPress', "Schedule an appointment window");
                        this.props.updateCurrentAppointment({
                          ...this.props.appointment,
                          appointmentType: APPOINTMENT_TYPES["scheduled"],
                        });
                      }}
                      text="Schedule an appointment window"
                      textStyle={{ ...styles.text, marginLeft: 10 }}
                    />
                  </View>
                </View>

                {this.props.appointment.appointmentType ===
                  APPOINTMENT_TYPES["scheduled"] && (
                  <View>
                    <Text style={[styles.otherHeading, { marginTop: 10 }]}>
                      Start :{" "}
                    </Text>

                    <View style={[styles.backgroundView, { marginTop: 5 }]}>
                      <View style={styles.borderView}>
                        {Platform.OS == "android" && (
                          <TouchableOpacity
                            style={{
                              flex: 1,
                              height: 40,
                              paddingVertical: 10,
                            }}
                            onPress={() => {
                              LogsService.fileLog('AppointmentDetails','onPress', "showStartDatePicker");
                              this.setState({
                                showRNTimePickerEnd: false,
                                showRNTimePicker: false,
                                showEndDatePicker: false,
                                showStartDatePicker: true,
                              });
                            }}
                          >
                            <Text style={{ paddingHorizontal: 15 }}>
                              {moment(this.props.appointment.startsAt).format(
                                "dddd, MMM DD, YYYY"
                              )}
                            </Text>
                            {/* <SvgXml
                              width={27}
                              height={27}
                              xml={`
                                    <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="#4990c8" d="M7 12h2v2H7v-2m14-6v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1V2h2v2h8V2h2v2h1a2 2 0 0 1 2 2M5 8h14V6H5v2m14 12V10H5v10h14m-4-6v-2h2v2h-2m-4 0v-2h2v2h-2m-4 2h2v2H7v-2m8 2v-2h2v2h-2m-4 0v-2h2v2h-2Z"/></svg>`}
                              style={{
                                position: "absolute",
                                top: 7.5,
                                right: 12,
                              }}
                            /> */}
                          </TouchableOpacity>
                        )}
                        {/* {this.state.showStartDatePicker &&
                          Platform.OS == "android" && ( */}
                            <DatePicker
                              value={this.props.appointment.startsAt}
                              onDateChange={this.onChangeDate("startsAt")}
                            />
                          {/* )} */}
                        {Platform.OS == "ios" && (
                          <DatePickerIOS
                            value={this.props.appointment.startsAt}
                            onDateChange={this.onChangeDateIOS("startsAt")}
                          />
                        )}
                      </View>
                      {Platform.OS === "android" && (
                        <View style={{ flex: 1, flexDirection: "row" }}>
                          <View style={styles.borderViewTime}>
                            <TouchableOpacity
                              style={{
                                flex: 1,
                                height: 40,
                                paddingVertical: 10,
                              }}
                              onPress={() => {
                                LogsService.fileLog('AppointmentDetails','onPress', "show Start showRNTimePicker");
                                this.setState({
                                  showRNTimePickerEnd: false,
                                  showRNTimePicker: true,
                                  showEndDatePicker: false,
                                  showStartDatePicker: false,
                                });
                              }}
                            >
                              <Text style={{ paddingHorizontal: 15 }}>
                                {moment(this.props.appointment.startsAt).format(
                                  "hh:mm a"
                                )}
                              </Text>
                              {/* <SvgXml
                                width={27}
                                height={27}
                                xml={`<svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Time</title><path d="M256 64C150 64 64 150 64 256s86 192 192 192 192-86 192-192S362 64 256 64z" fill="transparent" stroke="#4990C8" stroke-miterlimit="10" stroke-width="32"/><path fill="none" stroke="#4990C8" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M256 128v144h96"/></svg>`}
                                style={{
                                  position: "absolute",
                                  top: 7.5,
                                  right: 12,
                                }}
                              /> */}
                            </TouchableOpacity>
                          </View>
                          <View>
                            {this.state.showRNTimePicker && (
                              <TimePicker
                                value={this.props.appointment.startsAt}
                                onDateChange={this.onChangeTime("startsAt")}
                              />
                            )}
                          </View>

                          {this.props.appointment.time_zone && (
                            <Text style={styles.textAlignTimeZone}>
                              {
                                JSON.parse(this.props.appointment.time_zone)
                                  .abrr
                              }
                            </Text>
                          )}
                        </View>
                      )}
                      {Platform.OS === "ios" && (
                        <View style={{ flex: 1, flexDirection: "row" }}>
                          <View style={styles.borderViewTime}>
                            <TimeIOSPicker
                              value={this.props.appointment.startsAt}
                              onDateChange={this.onChangeIosTime("startsAt")}
                            />
                          </View>
                          {this.props.appointment.time_zone && (
                            <Text style={styles.textAlignTimeZone}>
                              {
                                JSON.parse(this.props.appointment.time_zone)
                                  .abrr
                              }
                            </Text>
                          )}
                        </View>
                      )}
                    </View>

                    <Text style={[styles.otherHeading, { marginTop: 10 }]}>
                      End
                    </Text>

                    <View style={[styles.backgroundView]}>
                      <View style={styles.borderView}>
                        {Platform.OS == "android" && (
                          <TouchableOpacity
                            style={{
                              flex: 1,
                              height: 40,
                              paddingVertical: 10,
                            }}
                            onPress={() => {
                              LogsService.fileLog('AppointmentDetails','onPress', "showEndDatePicker");
                              this.setState({
                                showRNTimePickerEnd: false,
                                showRNTimePicker: false,
                                showEndDatePicker: true,
                                showStartDatePicker: false,
                              });
                            }}
                          >
                            <Text style={{ paddingHorizontal: 15 }}>
                              {moment(this.props.appointment.endsAt).format(
                                "dddd, MMM DD, YYYY"
                              )}
                            </Text>
                            {/* <SvgXml
                              width={27}
                              height={27}
                              xml={`
                                    <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="#4990c8" d="M7 12h2v2H7v-2m14-6v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1V2h2v2h8V2h2v2h1a2 2 0 0 1 2 2M5 8h14V6H5v2m14 12V10H5v10h14m-4-6v-2h2v2h-2m-4 0v-2h2v2h-2m-4 2h2v2H7v-2m8 2v-2h2v2h-2m-4 0v-2h2v2h-2Z"/></svg>`}
                              style={{
                                position: "absolute",
                                top: 7.5,
                                right: 12,
                              }}
                            /> */}
                          </TouchableOpacity>
                        )}
                        {this.state.showEndDatePicker &&
                          Platform.OS == "android" && (
                            <DatePicker
                              value={this.props.appointment.endsAt}
                              onDateChange={this.onChangeDate("endsAt")}
                            />
                          )}
                        {Platform.OS == "ios" && (
                          <DatePickerIOS
                            value={this.props.appointment.endsAt}
                            onDateChange={this.onChangeDateIOS("endsAt")}
                          />
                        )}
                      </View>
                      {Platform.OS === "android" && (
                        <View style={{ flex: 1, flexDirection: "row" }}>
                          <View style={styles.borderViewTime}>
                            <TouchableOpacity
                              style={{
                                flex: 1,
                                height: 40,
                                paddingVertical: 10,
                              }}
                              onPress={() => {
                                LogsService.fileLog('AppointmentDetails','onPress', "showRNTimePickerEnd");
                                this.setState({
                                  showRNTimePickerEnd: true,
                                  showRNTimePicker: false,
                                  showEndDatePicker: false,
                                  showStartDatePicker: false,
                                });
                              }}
                            >
                              <Text style={{ paddingHorizontal: 15 }}>
                                {moment(this.props.appointment.endsAt).format(
                                  "hh:mm a"
                                )}
                              </Text>
                              {/* <SvgXml
                                width={27}
                                height={27}
                                xml={`<svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Time</title><path d="M256 64C150 64 64 150 64 256s86 192 192 192 192-86 192-192S362 64 256 64z" fill="transparent" stroke="#4990C8" stroke-miterlimit="10" stroke-width="32"/><path fill="none" stroke="#4990C8" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M256 128v144h96"/></svg>`}
                                style={{
                                  position: "absolute",
                                  top: 7.5,
                                  right: 12,
                                }}
                              /> */}
                            </TouchableOpacity>
                          </View>
                          <View>
                            {this.state.showRNTimePickerEnd && (
                              <TimePicker
                                value={this.props.appointment.endsAt}
                                onDateChange={this.onChangeTime("endsAt")}
                              />
                            )}
                          </View>
                          {this.props.appointment.time_zone && (
                            <Text style={styles.textAlignTimeZone}>
                              {
                                JSON.parse(this.props.appointment.time_zone)
                                  .abrr
                              }
                            </Text>
                          )}
                        </View>
                      )}
                      {Platform.OS === "ios" && (
                        <View style={{ flex: 1, flexDirection: "row" }}>
                          <View style={styles.borderViewTime}>
                            <TimeIOSPicker
                              value={this.props.appointment.endsAt}
                              onDateChange={this.onChangeIosTime("endsAt")}
                            />
                          </View>
                          {this.props.appointment.time_zone && (
                            <Text style={styles.textAlignTimeZone}>
                              {
                                JSON.parse(this.props.appointment.time_zone)
                                  .abrr
                              }
                            </Text>
                          )}
                        </View>
                      )}
                    </View>
                  </View>
                )}

                <Title
                  text={
                    this.getPropertyListingTypeLabelText(
                      this.props.addresses.propertylistingtype
                    ) + " Fee"
                  }
                  style={{ marginTop: 20, marginLeft:21 }}
                />
                <View style={[styles.seekbackgroundView]}>
                  <View
                    style={{
                      borderRadius: 50,
                      marginHorizontal: 10,
                      alignSelf: "center",
                      marginTop: 10,
                    }}
                  >
                    {/* <Slider
                      value={this.state.fee > maxFee ? maxFee : this.state.fee }
                      useNativeDriver={true}
                      minimumValue={minFee}
                      thumbTintColor="#FF8959"
                      maximumValue={maxFee}
                      step={5}
                      onSlidingComplete={(value) => {
                        this.setState({ feeInput: value, fee: value });
                        this.props.updateCurrentAppointment({
                          ...this.props.appointment,
                          feeCents: value * 100,
                        });
                      }}
                    /> */}
                    <View
                      style={{
                        flexDirection: "row",
                        width: 270,
                        marginTop: 15,
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 18,
                          flex: 1,
                          color: "#4990C8",
                          fontWeight: "bold",
                        }}
                      >
                        $ {minFee}
                      </Text>
                      <View
                        style={{
                          flex: 1,
                          justifyContent: "flex-end",
                          alignItems: "flex-end",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 18,
                            color: "#4990C8",
                            fontWeight: "bold",
                          }}
                        >
                          $ {maxFee}
                        </Text>
                      </View>
                    </View>
                  </View>

                  <View
                    style={{
                      width: 75,
                      borderColor: "#FF8959",
                      borderWidth: 1,
                      borderRadius: 5,
                      alignSelf: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 22,
                        fontWeight: "bold",
                        textAlign: "left",
                        color: "#FF8959",
                        paddingVertical: 10,
                        paddingHorizontal: 1,
                      }}
                    >
                      $
                    </Text>
                    <TextInput
                      style={{
                        fontSize: 22,
                        fontWeight: "bold",
                        textAlign: "center",
                        color: "#FF8959",
                        position: "absolute",
                        alignSelf: "center",
                        paddingVertical: 10,
                        paddingHorizontal: 2,
                      }}
                      value={this.state.feeInput.toString()}
                      maxLength={3}
                      onChangeText={(value) => {
                        if (value != "") {
                          const regex = new RegExp("^[0-9]+$");
                          if (regex.test(value)) {
                            value = parseInt(value);
                            this.setState({ fee: value, feeInput: value });
                            this.props.updateCurrentAppointment({
                              ...this.props.appointment,
                              feeCents: value * 100,
                            });
                          } else {
                            Alert.alert("", "Please Enter Valid Value", [
                              {
                                text: "OK",
                                onPress: () => {
                                  LogsService.fileLog('AppointmentDetails','onPress', "OK pressed and resetting the fee = " + minFee);
                                  this.setState({
                                    fee: minFee,
                                    feeInput: minFee,
                                  });
                                  this.props.updateCurrentAppointment({
                                    ...this.props.appointment,
                                    feeCents: minFee * 100,
                                  });
                                },
                              },
                            ]);
                          }
                        } else {
                          this.setState({ feeInput: value });
                        }
                      }}
                      autoCapitalize="none"
                      onEndEditing={(e) => {
                        var checkValidValue = this.checkFeeValue(
                          e.nativeEvent.text
                        );
                        if (!checkValidValue.flag) {
                          Alert.alert("", checkValidValue.msg, [
                            {
                              text: "OK",
                              onPress: () => {
                                this.setState({
                                  fee: checkValidValue.fee,
                                  feeInput: checkValidValue.fee,
                                });
                                this.props.updateCurrentAppointment({
                                  ...this.props.appointment,
                                  feeCents: checkValidValue.fee * 100,
                                });
                              },
                            },
                          ]);
                        }
                      }}
                    />
                  </View>
                </View>
                <View
                  style={{ justifyContent: "center", alignItems: "center" }}
                >
                  <View
                    style={{
                      borderTopWidth: 1,
                      borderBottomColor: "#00467E",
                      width: "83%",
                      marginTop: 10,
                    }}
                  />
                  <Text
                    style={{
                      fontSize: 21,
                      fontWeight: "bold",
                      textAlign: "left",
                      color: "#FF8959",
                      paddingVertical: 10,
                      paddingHorizontal: 2,
                    }}
                  >
                    Total Charge: $ {this.getTotalPrice()}
                  </Text>
                  {this.props.addresses.propertylistingtype == "RUNNER" && (
                    <Text
                      style={{
                        textAlign: "center",
                        marginTop: 5,
                        color: "#FF8959",
                        marginBottom: 15,
                      }}
                    >
                      *Fee includes transactions fees of $
                      {parseFloat(this.getConvenienceFee()).toFixed(2)}
                    </Text>
                  )}
                  {this.props.addresses.propertylistingtype != "RUNNER" && (
                    <Text
                      style={{
                        textAlign: "center",
                        marginTop: 5,
                        color: "#FF8959",
                        marginBottom: 15,
                      }}
                    >
                      *Showing price includes transactions fees of $
                      {parseFloat(this.getConvenienceFee()).toFixed(2)}
                    </Text>
                  )}
                </View>
              </View>
            </ScrollView>
          </KeyboardAvoidingView>

          <View style={{ justifyContent: "center", alignItems: "center" }}>
            {this.props.appointment.appointmentType ===
              APPOINTMENT_TYPES["scheduled"] && (
              <ButtonOne
                text={"NEXT"}
                style={styles.btnWidth}
                onPress={() => {
                  LogsService.fileLog('AppointmentDetails','onPress NEXT', 'NEXT button clicked');
                  LogsService.fileLog('AppointmentDetails','onPress NEXt', 'checkFeeValue is calling with feeInput = ',this.state.feeInput);
                  var checkValidValue = this.checkFeeValue(this.state.feeInput);
                  const newDatedTime = moment(this.props.appointment.startsAt)
                    .add(1, "seconds")
                    .format("YYYY-MM-DD HH:mm:ss");
                  var currentDateTime = moment
                    .tz(JSON.parse(this.props.appointment.time_zone).timeZoneId)
                    .format("YYYY-MM-DD HH:mm:ss");
                    LogsService.fileLog('AppointmentDetails','onPress NEXT startAt', this.props.appointment.startsAt);
                    LogsService.fileLog('AppointmentDetails','onPress NEXT currentDateTime', currentDateTime);
                    LogsService.fileLog('AppointmentDetails','onPress NEXT endsAt', this.props.appointment.endsAt);
                    LogsService.fileLog('AppointmentDetails','onPress NEXT newDatedTime', newDatedTime);

                  if (this.props.appointment.startsAt <= currentDateTime) {
                    LogsService.fileLog('AppointmentDetails','onPress NEXT validation', 'The start date/time must be after current date/time');
                    Alert.alert(
                      "",
                      "The start date/time must be after current date/time",
                      [
                        {
                          text: "OK",
                          onPress: () => {
                            LogsService.fileLog('AppointmentDetails','onPress validation', 'OK pressed');
                          },
                        },
                      ]
                    );
                  } else if (newDatedTime > this.props.appointment.endsAt) {
                    LogsService.fileLog('AppointmentDetails','onPress NEXT validation', 'The end date/time must be after start date/time');
                    Alert.alert(
                      "",
                      "The end date/time must be after start date/time",
                      [
                        {
                          text: "OK",
                          onPress: () => {
                            LogsService.fileLog('AppointmentDetails','onPress validation', 'OK pressed');
                          },
                        },
                      ]
                    );
                  } else if (this.state.feeInput == "") {
                    LogsService.fileLog('AppointmentDetails','onPress NEXT validation', 'fee should not be blank');
                    Alert.alert("", "fee should not be blank", [
                      {
                        text: "OK",
                        onPress: () => {
                          LogsService.fileLog('AppointmentDetails','onPress validation', 'OK pressed');
                          this.setState({ fee: minFee, feeInput: minFee });
                          this.props.updateCurrentAppointment({
                            ...this.props.appointment,
                            feeCents: minFee * 100,
                          });
                        },
                      },
                    ]);
                  } else if (!checkValidValue.flag) {
                    LogsService.fileLog('AppointmentDetails','onPress NEXT validation', checkValidValue.msg);
                    Alert.alert("", checkValidValue.msg, [
                      {
                        text: "OK",
                        onPress: () => {
                          LogsService.fileLog('AppointmentDetails','onPress validation', 'OK pressed');
                          this.setState({
                            fee: checkValidValue.fee,
                            feeInput: checkValidValue.fee,
                          });
                          this.props.updateCurrentAppointment({
                            ...this.props.appointment,
                            feeCents: checkValidValue.fee * 100,
                          });
                        },
                      },
                    ]);
                  } else {
                    this.props.updateCurrentAppointment({
                      ...this.props.appointment,
                      feeCents: this.state.fee * 100,
                    });
                    LogsService.fileLog('AppointmentDetails','onPress NEXT', 'Navigating to AgentRequest');
                    this.props.navigation.navigate("AgentRequest");
                  }
                }}
              />
            )}
            {this.props.appointment.appointmentType !=
              APPOINTMENT_TYPES["scheduled"] && (
              <ButtonOne
                text={"NEXT"}
                style={styles.btnWidth}
                onPress={() => {
                  LogsService.fileLog('AppointmentDetails','onPress NEXT', 'NEXT is clicked');
                  var checkValidValue = this.checkFeeValue(this.state.feeInput);
                  LogsService.fileLog('AppointmentDetails','onPress NEXT checkValidValue=', checkValidValue);
                  if (this.state.feeInput == "") {
                    LogsService.fileLog('AppointmentDetails','onPress NEXT validation', 'fee should not be blank');
                    Alert.alert("", "fee should not be blank", [
                      {
                        text: "OK",
                        onPress: () => {
                          LogsService.fileLog('AppointmentDetails','onPress NEXT validation', 'OK pressed');
                          this.setState({ fee: minFee, feeInput: minFee });
                          this.props.updateCurrentAppointment({
                            ...this.props.appointment,
                            feeCents: minFee * 100,
                          });
                        },
                      },
                    ]);
                  } else if (!checkValidValue.flag) {
                    LogsService.fileLog('AppointmentDetails','onPress NEXT validation', checkValidValue.msg);
                    Alert.alert("", checkValidValue.msg, [
                      {
                        text: "OK",
                        onPress: () => {
                          LogsService.fileLog('AppointmentDetails','onPress validation', 'OK pressed');
                          this.setState({
                            fee: checkValidValue.fee,
                            feeInput: checkValidValue.fee,
                          });
                          this.props.updateCurrentAppointment({
                            ...this.props.appointment,
                            feeCents: checkValidValue.fee * 100,
                          });
                        },
                      },
                    ]);
                  } else {
                    this.props.updateCurrentAppointment({
                      ...this.props.appointment,
                      feeCents: this.state.fee * 100,
                    });
                    LogsService.fileLog('AppointmentDetails','onPress NEXT', 'Navigating to AgentRequest');
                    this.props.navigation.navigate("AgentRequest");
                  }
                }}
              />
            )}
          </View>
          <View style={{ marginTop: 10 }}>
            <Footer />
          </View>
        </View>
      </BaseLayout>
    );
  }
}

export default connect(
  (state) => ({
    appointment: state.appointments.current,
    addresses: state.addresses,
  }),
  (dispatch) => ({
    updateCurrentAppointment: (appointment) => {
      dispatch(updateCurrentAppointment(appointment));
    },
  })
)(AppointmentDetails);
