import React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={18} height={18} viewBox="0 0 18 18" {...props}>
      <Path
        d="M1.916 18a1.1 1.1 0 01-1.072-.854l-.815-3.541a1.1 1.1 0 01.636-1.255l3.811-1.635a1.079 1.079 0 01.43-.088 1.1 1.1 0 01.854.4l1.551 1.9a12.347 12.347 0 005.609-5.615l-1.9-1.55a1.1 1.1 0 01-.312-1.283L12.347.665a1.1 1.1 0 011.259-.637l3.543.82A1.087 1.087 0 0118 1.916 15.974 15.974 0 0113.283 13.3 15.984 15.984 0 011.916 18zm2.989-6.243l-3.776 1.617.809 3.5A14.974 14.974 0 0016.872 1.94l-3.5-.808-1.619 3.776L14.313 7A13.605 13.605 0 017 14.316l-2.1-2.559z"
        fill="rgba(27,117,187,.8)"
      />
    </Svg>
  );
}

export default SvgComponent;
