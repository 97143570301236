import React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={36} height={42} viewBox="0 0 36 42" {...props}>
      <Path d="M18 0L0 10.5v21L18 42l18-10.5v-21z" fill="#a3c7e3" />
      <Path
        d="M22.14 29.675a2.927 2.927 0 01-.848-.128l-7.285-2.612-7.2 2.7a.8.8 0 01-.255.043.673.673 0 01-.465-.193.634.634 0 01-.213-.496v-10.7a1.355 1.355 0 01.233-.771 1.451 1.451 0 01.614-.513l6.353-2.225a5.064 5.064 0 01-.424-1.754 5.124 5.124 0 01.724-2.633 5.266 5.266 0 011.967-1.926 5.643 5.643 0 015.465 0 5.267 5.267 0 011.968 1.926 5.161 5.161 0 01.72 2.654 8.718 8.718 0 01-1.779 4.13l.423.171 7.2-2.7a.766.766 0 01.256-.043.683.683 0 01.466.192.645.645 0 01.212.493v10.7a1.345 1.345 0 01-.233.77 1.464 1.464 0 01-.615.515l-6.44 2.268a2.914 2.914 0 01-.844.132zm6.777-13.4l-6.1 2.269v9.588l6.142-2.141zm-14.232 1.286v8.175l6.778 2.4v-9.589l-.551-.172a40.843 40.843 0 01-2.33 2.825.63.63 0 01-1.016 0 42.62 42.62 0 01-2.881-3.639zM13.33 16.15l-6.143 2.14.043 9.715 6.1-2.268zm4.744-7.019a4.035 4.035 0 00-2.881 1.13 3.693 3.693 0 00-1.186 2.765 6.325 6.325 0 001.1 2.7 30.376 30.376 0 002.967 4.019 30.441 30.441 0 002.965-4.023 6.333 6.333 0 001.1-2.7 3.693 3.693 0 00-1.185-2.761 4.03 4.03 0 00-2.88-1.13z"
        fill="#4990c8"
      />
    </Svg>
  );
}

export default SvgComponent;
