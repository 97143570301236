import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  headerImage: {
    height: 80,
    alignSelf: "center",
  },
  topView: {
    flexDirection: "row",
    marginTop: "5%",
    marginLeft: "5%",
  },
  agentTxt: {
    color: "#FF8959",
    fontSize: 16,
    fontFamily: "RobotoCondensed-Regular",
  },
  signupTxt: {
    color: "#4990C8",
    fontSize: 24,
    fontFamily: "RobotoCondensed-Bold",
    fontWeight: "bold",
    marginTop: "2%",
  },
  accountProfile: {
    color: "#4990C8",
    fontSize: 26,
    fontFamily: "RobotoCondensed-Bold",
    fontWeight: "bold",
  },
  cardView: {
    backgroundColor: "#D1E3F1",
    width: "90%",
    borderColor: "#D1E3F1",
    borderRadius: 25,
    borderWidth: 1,
    marginLeft: "5%",
    opacity: 0.8,
    marginTop: "3%",
  },
  textView: {
    borderColor: "#00467E",
    borderBottomWidth: 1,
    height: 40,
  },
  innerCard: {
    flexDirection: "row",
    marginTop: "5%",
    marginLeft: "5%",
  },
  EnableText: {
    color: "#292B34",
    fontFamily: "RobotoCondensed-Light",
    fontSize: 17,
    lineHeight: 35,
  },
  notifyIcon: {
    height: 25,
    width: 25,
    marginTop: "2%",
    marginRight: "2%",
  },
  txtView: {
    marginLeft: "5%",
    width: "75%",
  },
  mainTxtView: {
    flexDirection: "row",
    marginTop: "5%",
    marginLeft: "5%",
  },
  btnView: {
    flex: 1,
    alignItems: "center",
    paddingBottom: 10,
    marginTop: "5%",
  },
  btnWidth: {
    width: 140,
  },
  bottomView: {
    backgroundColor: "#4990C8",
    height: 50,
    width: "100%",
    justifyContent: "center",
  },
  termStyles: {
    color: "#FFFFFF",
    fontSize: 20,
    alignSelf: "center",
    fontFamily: "RobotoCondensed-Regular",
  },
  circleChecked: {
    height: 36,
    width: 40,
  },
  inputText: {
    height: 40,
    color: "#292B34",
    fontFamily: "RobotoCondensed-Light",
    fontSize: 15,
    paddingHorizontal: 10,
  },
  textImg: {
    height: 20,
    width: 20,
    marginTop: "2%",
  },
});

export default styles;
