import React from "react";
import { View } from "react-native";
import styles from "../Styles/NotificationSettingstyles";
import { ButtonOne } from "../Components/Button";
import CheckboxWithText from "../Components/CheckboxWithText";
import Header from "../Components/Signup/Header";
import HeaderImage from "../Images/Header";
import Title from "../Components/Signup/Title";
import TermsOfUseLink from "../Components/Signup/TermsOfUseLink";

export default class NotificationSettings extends React.Component {
  static navigationOptions = ({ navigation }) => ({
    headerStyle: headerStyleCustom(),
    headerTintColor: "#fff",
    headerTitleStyle: {
      fontWeight: "bold",
    },
    headerBackTitle: "Back",
    headerTitle: () => <HeaderImage height={35} />,
  });

  constructor(props) {
    super(props);
    this.state = {
      notificationsEnabled: true,
      smsNotifications: true,
      emailNotifications: true,
    };

    this.onNotificationToggle = this.onNotificationToggle.bind(this);
    this.onSMSNotificationToggle = this.onSMSNotificationToggle.bind(this);
    this.onEmailNotificationToggle = this.onEmailNotificationToggle.bind(this);
  }

  onNotificationToggle() {
    this.setState({ notificationsEnabled: !this.state.notificationsEnabled });
  }

  onSMSNotificationToggle() {
    this.setState({ smsNotifications: !this.state.smsNotifications });
  }

  onEmailNotificationToggle() {
    this.setState({ emailNotifications: !this.state.emailNotifications });
  }

  render() {
    return (
      <View style={{ height: "100%", width: "100%" }}>
        <Header complete={2} total={3} />

        <Title text="Notification Settings" />

        <View style={{ flex: 9 }}>
          <View style={[styles.cardView, { height: 175 }]}>
            <View style={styles.innerCard}>
              <CheckboxWithText
                onPress={this.onNotificationToggle}
                text="Enable Notifications"
                checked={this.state.notificationsEnabled}
                textStyle={styles.EnableText}
                style={styles.txtView}
              />
              <View
                style={{
                  borderTopWidth: 1,
                  borderBottomColor: "#00467E",
                  width: "83%",
                  marginLeft: "15%",
                }}
              />
            </View>

            <View style={styles.mainTxtView}>
              <CheckboxWithText
                onPress={this.onSMSNotificationToggle}
                text="Send SMS messages to mobile"
                checked={this.state.smsNotifications}
                textStyle={styles.EnableText}
                style={styles.txtView}
              />
            </View>

            <View style={styles.mainTxtView}>
              <CheckboxWithText
                onPress={this.onEmailNotificationToggle}
                text="Send emails"
                checked={this.state.emailNotifications}
                textStyle={styles.EnableText}
                style={styles.txtView}
              />
            </View>
          </View>
        </View>

        <View style={styles.btnView}>
          <ButtonOne
            text={"NEXT"}
            style={styles.btnWidth}
            onPress={() => this.props.navigation.navigate("CreatePassword")}
          />
        </View>

        <TermsOfUseLink />
      </View>
    );
  }
}
