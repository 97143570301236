import React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={36} height={36} viewBox="0 0 36 36" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.586 18.415h-1.314v10.586c0 .415-.347.761-.831.761h-4.774c-.484 0-.831-.346-.831-.83V23.12c0-.415-.415-.83-.899-.83h-3.874c-.485 0-.9.415-.9.83v5.812c0 .484-.346.83-.83.83H9.559c-.485 0-.831-.346-.831-.76V18.414H7.414c-.762 0-1.107-.899-.554-1.384l2.422-2.422V8.866c0-.415.346-.83.83-.83h3.183c.484 0 .83.415.83.83v.9l3.321-3.322c.277-.276.83-.276 1.107 0L29.14 17.031c.553.485.207 1.384-.554 1.384zM18 0C8.059 0 0 8.06 0 18s8.059 18 18 18 18-8.06 18-18S27.941 0 18 0z"
        fill="#4990C8"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.553 6.445c-.277-.276-.83-.276-1.107 0l-3.32 3.32v-.898a.841.841 0 00-.83-.83h-3.184a.84.84 0 00-.83.83v5.743L6.86 17.032c-.554.484-.208 1.384.553 1.384h1.315v10.586c0 .415.346.76.83.76h4.774c.485 0 .831-.345.831-.83V23.12c0-.415.415-.831.9-.831h3.874c.484 0 .9.416.9.83v5.812c0 .485.345.83.83.83h4.774c.484 0 .83-.345.83-.76V18.416h1.315c.761 0 1.107-.9.553-1.384L18.553 6.445z"
        fill="#fff"
      />
    </Svg>
  );
}

export default SvgComponent;
