import React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={16} height={18} viewBox="0 0 16 18" {...props}>
      <Path
        d="M16 18H0v-.7a.426.426 0 01.428-.422h.715V.844A.852.852 0 012 0h12a.852.852 0 01.857.844v16.031h.715A.426.426 0 0116 17.3v.7zm-8.714-4.535h1.429a.426.426 0 01.428.422v2.988h4.571V1.125H2.286v15.75h4.571v-2.988a.426.426 0 01.429-.422zM11 12.34H9.571a.426.426 0 01-.428-.422v-1.406a.426.426 0 01.428-.422H11a.426.426 0 01.428.422v1.406a.426.426 0 01-.428.422zm-4.571 0H5a.426.426 0 01-.428-.422v-1.406A.426.426 0 015 10.09h1.429a.426.426 0 01.428.422v1.406a.426.426 0 01-.428.422zM11 8.965H9.571a.426.426 0 01-.428-.422V7.137a.426.426 0 01.428-.422H11a.426.426 0 01.428.422v1.406a.426.426 0 01-.428.422zm-4.571 0H5a.426.426 0 01-.428-.422V7.137A.426.426 0 015 6.715h1.429a.426.426 0 01.428.422v1.406a.426.426 0 01-.428.422zM11 5.59H9.571a.426.426 0 01-.428-.422V3.761a.425.425 0 01.428-.421H11a.425.425 0 01.428.421v1.407A.426.426 0 0111 5.59zm-4.571 0H5a.426.426 0 01-.428-.422V3.761A.425.425 0 015 3.34h1.429a.425.425 0 01.428.421v1.407a.426.426 0 01-.428.422z"
        fill="#4990c8"
      />
    </Svg>
  );
}

export default SvgComponent;
