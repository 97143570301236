import * as api from "./api";
import DeviceInfo from "react-native-device-info";
export const Set_API_Base_URL = "Set_API_Base_URL";
export const Set_User_Profile_Image = "Set_User_Profile_Image";
export const SetCancelReasonList = "SetCancelReasonList";
// import LogsService from "../../LogsService";
import { SERVER_URL } from "../../../config";

export const setAPIBaseURL = (APIBaseURL) => {
  return {
    type: Set_API_Base_URL,
    APIBaseURL,
  };
};
export const setCancelReasonList = (CancelReasonList) => {
  return {
    type: SetCancelReasonList,
    CancelReasonList,
  };
};
export const setUserProfileImage = (UserProfileImage) => {
  return {
    type: Set_User_Profile_Image,
    UserProfileImage,
  };
};

export const APIBaseURLSetup = () => {
  const url =
    SERVER_URL +
    "APIBaseURL/getAPIBaseURL?appVersion=" + "3.14";
  // DeviceInfo.getVersion();
  // const url =
  //   SERVER_URL +
  //   "Versions/getAPIBaseURL?appVersion=" +
  //   DeviceInfo.getVersion();

  return fetch(url, {
    method: "GET",
    mode:'no-cors',
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      console.log('response----',response)
      // return response.json();
    })
    .then((response) => {
      // return response;
    })
    .catch((error) => { });
};
export const getCancelReasonList = () => {
  LogsService.fileLog('redux/app/actions.js', 'getCancelReasonList', 'getCancelReasonList api calling');
  return api.getCancelReasonList();
};
