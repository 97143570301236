import React, { Component, useState } from "react";
import {
  View,
  ScrollView,
  KeyboardAvoidingView,
  Alert,
  Text,
  TouchableOpacity,
  Platform,
  Keyboard,TextInput
} from "react-native";
import { connect } from "react-redux";
import styles from "../../Styles/CreateListingStyles";
// import { NavigationEvents } from "react-navigation";
import Footer from "../../Components/Footer.js";
import { ButtonOne } from "../../Components/Button";
import CheckboxWithText from "../../Components/CheckboxWithText";
import ActionSheetMenu from "../../Components/ActionSheetMenu";
import ProcessLoadingIndicator from "./../ProcessLoadingIndicator";
import HeaderImage from "../../Images/Header";
import BaseLayout, { headerStyleCustom } from "../BaseLayout";
import ListMapView from "./ListMapView";
import {
  addAddressByMLSID,
  createListing,
  createAddressListing,
  clearCurrentListings,
  checkListingUpdate,
  storePreviousPropertyList,
  addTimeZone,
  createListingForUnitNo,
} from "../../redux/address/actions";
// import ActionSheet from "react-native-actions-sheet";
// import { TextInput } from "react-native-gesture-handler";
// import RNGooglePlaces from "react-native-google-places";
import {
  check,
  request,
  openSettings,
  PERMISSIONS,
  RESULTS,
} from "react-native-permissions";
// import Geolocation from "@react-native-community/geolocation";
import LogsService from "../../LogsService";

const actionSheetRef = React.createRef();
let selectedTimeZone = null;
const ActionSheetComponent = (props) => {
  let [timeZone, setTimeZone] = useState("");
  const tmZones = [
    {
      timeZoneId: "America/Phoenix",
      timeZoneName: "MST (Mountain Standard Time)",
      abbr: "MST",
    },
    {
      timeZoneId: "America/Los_Angeles",
      timeZoneName: "PST (Pacific Standard Time)",
      abbr: "PST",
    },
    {
      timeZoneId: "America/New_York",
      timeZoneName: "EST (Eastern Standard Time)",
      abbr: "EST",
    },
    {
      timeZoneId: "America/Chicago",
      timeZoneName: "CST (Central Standard Time)",
      abbr: "CST",
    },
  ];
  return (
    <View style={{ backgroundColor: "#215F90", flex: 1 }}>
      {/* <ActionSheet ref={actionSheetRef}>*/}
      <View ref={actionSheetRef}>
        <View style={{ padding: 20 }}>
          <View
            style={{
              marginTop: 15,
              borderBottomColor: "#D9E9F0",
              borderBottomWidth: 0.7,
            }}
          >
            <Text
              style={{
                color: "#DC612F",
                fontSize: 22,
                fontWeight: "700",
                paddingBottom: 20,
              }}
            >
              We could not find timezone for the selected address, please select
              timezone for selected property
            </Text>
          </View>
          <View style={{ paddingBottom: 30, paddingTop: 1 }}>
            {tmZones.map((tmz, i) => {
              return (
                <TouchableOpacity
                  key={i}
                  style={[
                    selectedTimeZone &&
                    selectedTimeZone.timeZoneName == tmz.timeZoneName
                      ? {
                          backgroundColor: "lightgray",
                          borderRadius: 20,
                          padding: 5,
                          marginTop: 15,
                          borderBottomColor: "#D9E9F0",
                          borderBottomWidth: 0.7,
                        }
                      : {
                          padding: 5,
                          marginTop: 15,
                          borderBottomColor: "#D9E9F0",
                          borderBottomWidth: 0.7,
                        },
                  ]}
                  onPress={() => {
                    setTimeZone(tmz.timeZoneName);
                    selectedTimeZone = tmz;
                  }}
                >
                  <Text
                    style={{
                      color: "#4990C8",
                      fontSize: 20,
                      fontWeight: "700",
                      padding: 5,
                    }}
                  >
                    {tmz.timeZoneName}
                  </Text>
                </TouchableOpacity>
              );
            })}
          </View>
          <View
            style={{ paddingBottom: 40, paddingTop: 1, alignItems: "center" }}
          >
            <TouchableOpacity
              onPress={() => {
                if(actionSheetRef.current){
                  actionSheetRef.current.setModalVisible();
                }
              }}
            >
              <ButtonOne
                text={"Confirm"}
                style={styles.btnWidth}
                onPress={() => {
                  if (selectedTimeZone !== null) {
                    props.setCustomTimeZone(selectedTimeZone);
                    if(actionSheetRef.current){
                      actionSheetRef.current.setModalVisible();
                    }
                  } else {
                    Alert.alert("", "Please select timezone", [
                      { text: "OK", onPress: () => {} },
                    ]);
                  }
                }}
              />
            </TouchableOpacity>
          </View>
        </View>
      </View>
      {/* </ActionSheet>  */}
    </View>
  );
};

const SelectableAddress = ({
  address,
  selected,
  onToggle,
  setChangeUnitText,
  index,
}) => {
  return (
    <View style={[styles.backgroundView, { marginTop: 5, paddingLeft: 5 }]}>
      <View style={{ flex: 1, flexDirection: "row" }}>
        <View style={{ width: "87%" }}>
          <Text style={{ marginTop: 5, fontSize: 16 }}>{address}</Text>
        </View>
        <View
          style={{
            position: "absolute",
            top: 10,
            left: 0,
            right: 0,
            bottom: 0,
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <CheckboxWithText
            text=""
            textStyle={[styles.subHeading, { marginLeft: 10 }]}
            checked={selected}
            onPress={onToggle}
          />
        </View>
      </View>
      <View style={{ marginTop: 10, flex: 1, flexDirection: "row" }}>
        <View>
          <Text style={{ fontWeight: "500" }}>Unit(s) # </Text>
        </View>
        <TextInput
          style={{
            fontSize: 15,
            marginLeft: 10,
            borderBottomWidth: 2,
            padding: 1,
            borderColor: "#888",
            maxWidth: "50%",
            width: "50%",
          }}
          maxLength={50}
          placeholder="Enter Unit(s) #"
          placeholderTextColor="#999"
          onChangeText={(x) => {
            setChangeUnitText(index, x);
            // unitNumber = x;
          }}
          autoCapitalize="none"
          returnKeyLabel="Done"
          returnKeyType="done"
        />
      </View>
    </View>
  );
};

class CreateListing extends Component {
  static navigationOptions = ({ navigation }) => ({
    headerStyle: headerStyleCustom(),
    headerTintColor: "#fff",
    headerTitleStyle: {
      fontWeight: "bold",
    },
    headerBackTitle: "Back",
    headerTitle: () => <HeaderImage height={35} />,
    headerRight: () => (
      <ActionSheetMenu numberOfLines={1} navigation={navigation} />
    ),
  });
  constructor(props) {
    super(props);
    this.state = {
      addressId: [],
      refreshList: false,
      checkedBtn: false,
      submitted: false,
      shownAlert: false,
      clickedButton: false,
      address: {},
      locationBias: {},
      propertyAddress: "",
      searchAddressList: [],
      userCurrentLocation: null,
      isFetchingCurrentLocation: false,
      uniqueId:0
    };
    LogsService.fileLog('CreateListing','constructor','constructor initiated');
    this.handleSetTimeZone = this.handleSetTimeZone.bind(this);
    this.doChnageUnitText = this.doChnageUnitText.bind(this);
    this.openSearchModal = this.openSearchModal.bind(this);
    this.initiateLocationCapture = this.initiateLocationCapture.bind(this);
    this.initiateLocationCaptureForAndroid =
      this.initiateLocationCaptureForAndroid.bind(this);
    this.requestLocation = this.requestLocation.bind(this);
    this.setLocationBiasCoords = this.setLocationBiasCoords.bind(this);
  }
  componentDidMount() {
    LogsService.fileLog('CreateListing','componentDidMount','componentDidMount called'); 
    this.checkLocation();
  }
  checkLocation() {
    LogsService.fileLog('CreateListing','checkLocation','checkLocation called'); 
    if (Platform.OS === "ios") {
      this.initiateLocationWhileInUseCapture();
    } else {
      this.initiateLocationCaptureForAndroid();
    }
  }
  initiateLocationCapture() {
    LogsService.fileLog('CreateListing','initiateLocationCapture','initiateLocationCapture called'); 
    check(PERMISSIONS.IOS.LOCATION_ALWAYS)
      .then((result) => {
        LogsService.fileLog('CreateListing','initiateLocationCapture permission check response=', JSON.stringify(result)); 
        switch (result) {
          case RESULTS.UNAVAILABLE:
            Alert.alert(
              "Open Setting to turn location services on",
              "App needs location to search places",
              [
                {
                  text: "Cancel",
                  onPress: () => console.log("Cancel Pressed"),
                  style: "cancel",
                },
                {
                  text: "OK",
                  onPress: () =>
                    openSettings().catch(() =>
                      console.warn("cannot open settings")
                    ),
                },
              ]
            );
            break;
          case RESULTS.DENIED:
            request(PERMISSIONS.IOS.LOCATION_ALWAYS).then((result) => {
              this.requestLocation();
            });
            break;
          case RESULTS.LIMITED:
            console.log("The permission is limited: some actions are possible");
            break;
          case RESULTS.GRANTED:
            this.requestLocation();
            break;
          case RESULTS.BLOCKED:
            Alert.alert(
              "Open Setting to allow location permission",
              "The permission is denied and not requestable anymore",
              [
                {
                  text: "Cancel",
                  onPress: () => {
                    console.log("Cancel Pressed")
                    LogsService.fileLog('CreateListing','initiateLocationCapture permission check onPress', 'The permission is denied and not requestable anymore'); 
                    LogsService.fileLog('CreateListing','initiateLocationCapture permission check onPress', 'Cancel pressed'); 
                  },
                  style: "cancel",
                },
                {
                  text: "OK",
                  onPress: () => {
                    LogsService.fileLog('CreateListing','initiateLocationCapture permission check onPress', 'The permission is denied and not requestable anymore'); 
                    LogsService.fileLog('CreateListing','initiateLocationCapture permission check onPress', 'OK pressed'); 
                      openSettings().catch((error) =>{
                        console.warn("cannot open settings")
                        LogsService.fileLog('CreateListing','initiateLocationCapture openSettings error=', JSON.stringify(error)); 
                      }
                    )
                  },
                },
              ]
            );
            break;
        }
      })
      .catch((error) => {
        LogsService.fileLog('CreateListing','initiateLocationCapture permission check error=', JSON.stringify(error));
      });
  }
  initiateLocationWhileInUseCapture() {
    LogsService.fileLog('CreateListing','initiateLocationWhileInUseCapture','initiateLocationWhileInUseCapture called'); 
    check(PERMISSIONS.IOS.LOCATION_WHEN_IN_USE)
      .then((result) => {
        LogsService.fileLog('CreateListing','initiateLocationWhileInUseCapture permission check response=', JSON.stringify(result)); 
        switch (result) {
          case RESULTS.UNAVAILABLE:
            this.initiateLocationCapture();
            break;
          case RESULTS.DENIED:
            this.initiateLocationCapture();
            break;
          case RESULTS.LIMITED:
            this.initiateLocationCapture();
            break;
          case RESULTS.GRANTED:
            this.requestLocation();
            break;
          case RESULTS.BLOCKED:
            this.initiateLocationCapture();
            break;
        }
      })
      .catch((error) => {
        LogsService.fileLog('CreateListing','initiateLocationWhileInUseCapture permission check error=', JSON.stringify(error)); 
        this.initiateLocationCapture();
      });
  }
  initiateLocationCaptureForAndroid() {
    try {
      LogsService.fileLog('CreateListing','initiateLocationCaptureForAndroid', 'initiateLocationCaptureForAndroid is called'); 
      request(
        Platform.select({
          android: PERMISSIONS.ANDROID.ACCESS_FINE_LOCATION,
        })
      ).then((res) => {
        LogsService.fileLog('CreateListing','initiateLocationCaptureForAndroid permission request response=', JSON.stringify(res));
        if (res == "granted") {
          this.requestLocation();
        } else {
          LogsService.fileLog('CreateListing','initiateLocationCaptureForAndroid permission request response=', 'The permission is denied and not requestable anymore');
          Alert.alert(
            "Open Setting to allow location permission",
            "The permission is denied and not requestable anymore",
            [
              {
                text: "Cancel",
                onPress: () => {
                  console.log("Cancel Pressed")
                  LogsService.fileLog('CreateListing','initiateLocationCaptureForAndroid permission request response onPress=', 'Cancel clicked');
                },
                style: "cancel",
              },
              {
                text: "OK",
                onPress: () =>{
                  LogsService.fileLog('CreateListing','initiateLocationCaptureForAndroid permission request response onPress=', 'OK clicked');
                    openSettings().catch((error) =>{
                      console.warn("cannot open settings");
                      LogsService.fileLog('CreateListing','initiateLocationCaptureForAndroid permission request response onPress openSettings error=', JSON.stringify(error));
                    }
                  )
                },
              },
            ]
          );
        }
      });
    } catch (error) {
      LogsService.fileLog('CreateListing','initiateLocationCaptureForAndroid permission request error=', JSON.stringify(error));
      console.log("location set error:", error);
    }
  }
  requestLocation() {
    LogsService.fileLog('CreateListing','requestLocation', 'requestLocation is clicked');
    this.setState({
      isFetchingCurrentLocation: true,
    });
    LogsService.fileLog('CreateListing','requestLocation', 'Geolocation.getCurrentPosition is calling');
    // Geolocation.getCurrentPosition(
    //   ({ coords }) => {
    //     LogsService.fileLog('CreateListing','requestLocation response=', JSON.stringify(coords));
    //     this.setState({ userCurrentLocation: coords });
    //     this.setLocationBiasCoords(coords);
    //     this.setState({
    //       isFetchingCurrentLocation: false,
    //     });
    //   },
    //   (error) => {
    //     LogsService.fileLog('CreateListing','requestLocation error=', JSON.stringify(error));
    //     console.log('Android location error=',error);
    //     this.setState({
    //       isFetchingCurrentLocation: false,
    //     });
    //     if (Platform.OS === "android") {
    //       LogsService.fileLog('CreateListing','requestLocation Alert', 'App needs location to search places');
    //       Alert.alert(
    //         "Open Setting to turn location services on",
    //         "App needs location to search places",
    //         [
    //           {
    //             text: "Cancel",
    //             onPress: () => {
    //               console.log("Cancel Pressed");
    //               LogsService.fileLog('CreateListing','requestLocation Alert onPress', 'Cancel pressed');
    //             },
    //             style: "cancel",
    //           },
    //           {
    //             text: "OK",
    //             onPress: () => {
    //               LogsService.fileLog('CreateListing','requestLocation Alert onPress', 'OK pressed');
    //                 openSettings().catch((error) =>{
    //                   LogsService.fileLog('CreateListing','requestLocation Alert onPress openSettings error=', JSON.stringify(error));
    //                   console.warn("cannot open settings")
    //                 }
    //               )},
    //           },
    //         ]
    //       );
    //     }
    //   },
    //   {
    //     enableHighAccuracy: Platform.OS === "ios" ? true : false,
    //     timeout: 15000,
    //     maximumAge: 10000,
    //   }
    // );
  }
  setLocationBiasCoords(coords) {
    LogsService.fileLog('CreateListing','setLocationBiasCoords', 'setLocationBiasCoords is called');
    let lat_change = 50 / 111;
    let lon_change = Math.abs(Math.cos(coords.latitude * (Math.PI / 180)));
    let sw_lat = coords.latitude - lat_change;
    let sw_lon = coords.longitude - lon_change;
    let ne_lat = coords.latitude + lat_change;
    let ne_lon = coords.longitude + lon_change;
    this.setState({
      locationBias: {
        longitudeSW: sw_lon,
        latitudeSW: sw_lat,
        longitudeNE: ne_lon,
        latitudeNE: ne_lat,
      },
    });
  }
  openSearchModal() {
    LogsService.fileLog('CreateListing','openSearchModal', 'openSearchModal is called');
    this.checkLocation();
    let locationParams = {};
    if (Object.keys(this.state.locationBias).length > 0) {
      locationParams = {
        initialQuery: "",
        locationBias: this.state.locationBias,
        type: "ADDRESS",
      };
    } else {
      locationParams = {
        initialQuery: "",
        type: "ADDRESS",
      };
    }
    RNGooglePlaces.openAutocompleteModal(locationParams)
      .then((place) => {
        LogsService.fileLog('CreateListing','openSearchModal RNGooglePlaces.openAutocompleteModal response=', JSON.stringify(place));
        this.setState({ address: place });
        if (this.props.propertylistingtype == "OPENHOUSE") {
          this.props.clearCurrentListings();
        }
        this.props.createAddressListing(
          {
            address: place.address,
            lat: place.location.latitude,
            lng: place.location.longitude,
          },
          {
            lat: place.location.latitude,
            lng: place.location.longitude,
          }
        );
      })
      .catch((error) => {
        console.log(error.message)
        LogsService.fileLog('CreateListing','openSearchModal RNGooglePlaces.openAutocompleteModal error=', JSON.stringify(error));
      }); 
  }
  toggleManualAddressEntry(id) {
    LogsService.fileLog('CreateListing','toggleManualAddressEntry', 'toggleManualAddressEntry is clicked');
    if (this.state.refreshList == false) {
      this.setState({
        refreshList: true,
      });
    } else {
      this.setState({
        refreshList: false,
      });
    }
    if (this.state.addressId.indexOf(id) === -1) {
      this.state.addressId.push(id);
    } else {
      this.state.addressId = this.state.addressId.filter(function (item) {
        return item !== id;
      });
    }
  }

  onLoad() {
  }

  refreshButtonNavBack() {
    LogsService.fileLog('CreateListing','refreshButtonNavBack', 'refreshButtonNavBack is clicked');
    let teamPrevAddress = [];
    var addressesmls = {
      address: "",
      appointment_id: null,
      id: null,
      lat: null,
      lng: null,
      mls_id: "",
    };
    this.props.addressess.map((address, i) => {
      teamPrevAddress.push(address.id);
    });
    if (
      this.props.addressprev.tempdeletedAddressStored != undefined &&
      this.props.addressprev.tempdeletedAddressStored.length != 0
    ) {
      this.props.addressprev.tempdeletedAddressStored.map((addressTeam, i) => {
        if (teamPrevAddress.indexOf(addressTeam.id) != -1) {

        } else {
          addressesmls.address = addressTeam.address;
          // addressesmls.appointment_id = addressTeam.appointmentID;
          addressesmls.id = addressTeam.id;
          addressesmls.lat = addressTeam.lat;
          addressesmls.lng = addressTeam.lng;
          // addressesmls.mls_id = addressTeam.mlsID;
          if (this.props.propertylistingtype != "OPENHOUSE") {
            this.props.checkListingUpdate({ addressesmls });
          }
        }
      });
    }
  }
  nextBuyerInformation() {
    LogsService.fileLog('CreateListing','nextBuyerInformation', 'nextBuyerInformation is clicked');
    if (this.state.addressId.length != 0) {
      this.props.clearCurrentListings();
      let tempAddressStored = [];
      let tempdeletedAddressStored = [];
      let unitList = [];
      this.props.addressess.map((address, i) => {
        unitList.push({ id: address.id, unit_number: address.unit_number });
        if (this.state.addressId.indexOf(address.id) === -1) {
          tempdeletedAddressStored.push(this.props.addressess[i]);
        } else {
          tempdeletedAddressStored.push(this.props.addressess[i]);
          tempAddressStored.push(this.props.addressess[i]);
        }
      });
      // this.props.createListingForUnitNo(unitList);
      var addressesmls = {
        address: "",
        // appointment_id: null,
        id: null,
        lat: null,
        lng: null,
        // mls_id: "",
        unit_number: null,
      };

      if (tempdeletedAddressStored.length != 0) {
        this.props.storePreviousPropertyList({ tempdeletedAddressStored });
      }
      tempAddressStored.map((address, i) => {
        addressesmls.address = address.address;
        // addressesmls.appointment_id = address.appointmentID;
        addressesmls.id = address.id;
        addressesmls.lat = address.lat;
        addressesmls.lng = address.lng;
        // addressesmls.mls_id = address.mlsID;
        addressesmls.unit_number = address.unit_number;
        this.props.checkListingUpdate({ addressesmls });
      });

      if (tempAddressStored.length != 0) {
        let x = new Date();
        var UTCseconds =
          (x.getTime() + x.getTimezoneOffset() * 60 * 1000) / 1000;

        fetch(
          `https://maps.googleapis.com/maps/api/timezone/json?location=${tempAddressStored[0].lat},${tempAddressStored[0].lng}&timestamp=${UTCseconds}&key=AIzaSyDpdvu3YpjdBLA9sWXdvSroceR_x_X46m4`
        )
          .then((response) => response.json())
          .then((responseJson) => {
            LogsService.fileLog('CreateListing','nextBuyerInformation timezone response=', JSON.stringify(responseJson));
            if (responseJson.status == "OK" && responseJson.timeZoneId) {
              this.props.addTimeZone(responseJson);
              LogsService.fileLog('CreateListing','nextBuyerInformation timezone response=', 'Navigating to BuyerInformation');
              this.props.navigation.navigate("BuyerInformation");
            } else {
              actionSheetRef.current?.setModalVisible();
            }
          });
      }
    } else {
      LogsService.fileLog('CreateListing','nextBuyerInformation validation=', 'Please select confirmed properties');
      Alert.alert("", "Please select confirmed properties", [
        { text: "OK", onPress: () => {
          LogsService.fileLog('CreateListing','nextBuyerInformation validation Alert=', 'OK pressed');
        } },
      ]);
    }
  }

  doChnageUnitText(index, x) {
    this.props.addressess[index].unit_number = x;
  }
  handleSetTimeZone() {
    this.props.addTimeZone(selectedTimeZone);
    LogsService.fileLog('CreateListing','handleSetTimeZone', 'Navigating to BuyerInformation');
    this.props.navigation.navigate("BuyerInformation");
  }
  getPropertyListingTypeLabelText(type) {
    const propertyListingTypeArr = {
      HOMESHOWING: "Choose Home Showing Location(s)",
      OPENHOUSE: "Choose Open House Location",
      RUNNER: "Choose Runner Location(s)",
    };
    return propertyListingTypeArr[type];
  }
  onChangePropertyAddress(text) {
    this.setState({
      propertyAddress: text,
      searchAddressList: [],
    });
    if (text !== "") {
      let searchParams = {};
      if (Object.keys(this.state.locationBias).length > 0) {
        searchParams = {
          initialQuery: "",
          locationBias: this.state.locationBias,
          type: "ADDRESS",
        };
      } else {
        searchParams = {
          initialQuery: "",
          type: "ADDRESS",
        };
      }
      RNGooglePlaces.getAutocompletePredictions(text, searchParams)
        .then((results) => {
          console.log("getAutocompletePredictions  results = ", results);
          if (text !== "") {
            this.setState({
              searchAddressList: results || [],
            });
          } else {
            this.setState({
              searchAddressList: [],
            });
          }
        })
        .catch((error) => {
          console.log(error.message)
          LogsService.fileLog('CreateListing','onChangePropertyAddress glePlaces.getAutocompletePredictions error=', JSON.stringify(error));
        });
    }
  }
  selectAddress(address) {
    LogsService.fileLog('CreateListing','selectAddress', 'selectAddress is clicked');
    console.log("address=", address);
    this.setState({
      propertyAddress: "",
      searchAddressList: [],
    });
    Keyboard.dismiss();
    var id = this.state.uniqueId + 1;
    this.setState({uniqueId: id});
    RNGooglePlaces.lookUpPlaceByID(address.placeID)
      .then((results) => {
        console.log("results=", results);
        LogsService.fileLog('CreateListing','selectAddress RNGooglePlaces.lookUpPlaceByID response', JSON.stringify(results));
        if (this.props.propertylistingtype == "OPENHOUSE") {
          this.props.clearCurrentListings();
        }
        this.props.createAddressListing(
          {
            address: results.address,
            lat: results.location.latitude,
            lng: results.location.longitude,
          },
          {
            lat: results.location.latitude,
            lng: results.location.longitude,
          }, id
        );
      })
      .catch((error) => {
        console.log(error.message)
        LogsService.fileLog('CreateListing','selectAddress RNGooglePlaces.lookUpPlaceByID error', JSON.stringify(error));
      });
  }
  render() {
    return (
      <BaseLayout marginTop={10}>
        <View style={{ height: "100%", width: "100%" }}>
          {this.state.isFetchingCurrentLocation ? (
            <ProcessLoadingIndicator
              message="Please wait... fetching your current location"
              backColor=""
            />
          ) : null}
          <KeyboardAvoidingView
            style={{ flex: 9, flexDirection: "column" }}
            behavior="padding"
            enabled
            keyboardVerticalOffset={100}
          >
            {/* <NavigationEvents onDidFocus={() => this.refreshButtonNavBack()} /> */}
{/* 
            <ListMapView
              listings={
                this.props.addressess.length > 0
                  ? this.props.addressess
                  : [
                      {
                        lat: this.state.userCurrentLocation
                          ? this.state.userCurrentLocation.latitude
                          : 33.692164,
                        lng: this.state.userCurrentLocation
                          ? this.state.userCurrentLocation.longitude
                          : -84.1611752,
                      },
                    ]
              }
              agents={this.props.addressess}
            /> */}
            <View
              style={{
                width: "90%",
                position: "absolute",
                margin: 15,
                fontWeight: 700,
              }}
            >
              <TextInput
                style={styles.addressTextInput}
                placeholder="Enter Property Address"
                placeholderTextColor="#5d5d5d"
                value={this.state.propertyAddress}
                onChangeText={(text) => {
                  this.onChangePropertyAddress(text);
                }}
              />
              {this.state.searchAddressList.length > 0 &&
              this.state.propertyAddress !== "" ? (
                <ScrollView
                  style={styles.addressListOuter}
                  keyboardShouldPersistTaps={"always"}
                >
                  {this.state.searchAddressList.map(
                    (eachAddress, addressIndex) => {
                      return (
                        <ScrollView
                          style={styles.addressTextOuter}
                          key={"searchAddress-" + addressIndex}
                          keyboardShouldPersistTaps={"always"}
                          horizontal={true}
                          showsVerticalScrollIndicator={false}
                          showsHorizontalScrollIndicator={false}
                        >
                          <TouchableOpacity
                            activeOpacity={1}
                            onPress={() => {
                              this.selectAddress(eachAddress);
                              LogsService.fileLog('CreateListing','onPress=', JSON.stringify(eachAddress));
                            }}
                          >
                            <Text style={styles.addressText}>
                              {eachAddress.fullText}
                            </Text>
                          </TouchableOpacity>
                        </ScrollView>
                      );
                    }
                  )}
                </ScrollView>
              ) : null}
            </View>

            <ScrollView>
              <View style={styles.mainView}>
                <Text style={styles.textBox}>
                  {" "}
                  {this.getPropertyListingTypeLabelText(
                    this.props.propertylistingtype
                  )}{" "}
                </Text>
                {this.props.addressess.length == 0 && (
                  <Text
                    style={{
                      fontSize: 15,
                      color: "#DC612F",
                      margin: 10,
                      paddingTop: 10,
                      paddingBottom: 10,
                      paddingLeft: 10,
                      borderRadius: 10,
                      marginTop: 10,
                      borderColor: "#DC612F",
                      borderWidth: 1,
                    }}
                  >
                    No Property Added
                  </Text>
                )}
                {this.props.addressess.map((address, i) => {
                  if (address != undefined) {
                    return (
                      <SelectableAddress
                        key={i}
                        mlsID={address.mlsID}
                        id={address.id}
                        index={i}
                        address={address.address}
                        unitNumber={address.unit_number}
                        setChangeUnitText={this.doChnageUnitText}
                        selected={
                          this.state.addressId.indexOf(address.id) === -1
                            ? false
                            : true
                        }
                        refreshing={this.state.refreshList}
                        onToggle={() => {
                          this.toggleManualAddressEntry(address.id);
                          LogsService.fileLog('CreateListing','onToggle=', address.id);
                        }}
                      />
                    );
                  }
                })}
              </View>
            </ScrollView>
          </KeyboardAvoidingView>
          <View
            style={{
              justifyContent: "center",
              alignItems: "center",
              marginVertical: 10,
            }}
          >
            {this.props.propertylistingtype !== "OPENHOUSE" &&
              <Text style={{ color: "#DC612F", fontWeight: "700", padding: 2 }}>
                *To add multiple properties just simply include your next address
                in the search above.
              </Text>
            }

            <ButtonOne
              text={"NEXT"}
              style={styles.btnWidth}
              onPress={() => {
                LogsService.fileLog('CreateListing','onPress=', 'NEXT clicked');
                this.nextBuyerInformation();
              }}
            />
          </View>
          <View style={{ marginTop: 10 }}>
            <Footer />
          </View>
        </View>
        <ActionSheetComponent setCustomTimeZone={this.handleSetTimeZone} />
      </BaseLayout>
    );
  }
}
export default connect(
  (state) => ({
    addressess: state.addresses.current.map((id) => state.addresses.byID[id]),
    propertylistingtype: state.addresses.propertylistingtype,
    addressprev: state.addresses.previouspropertylisting,
    addresses: state.addresses.byID,
  }),
  (dispatch) => ({
    clearCurrentListings: () => dispatch(clearCurrentListings()),
    addAddressByMLSID: (mlsID) => dispatch(addAddressByMLSID(mlsID)),
    storePreviousPropertyList: (address) =>
      dispatch(storePreviousPropertyList(address)),
    checkListingUpdate: (address) => dispatch(checkListingUpdate(address)),
    createListingForUnitNo: (address) =>
      dispatch(createListingForUnitNo(address)),
    createListing: (address) => dispatch(createListing(address)),
    createAddressListing: (address, location, id) =>
      dispatch(createAddressListing(address, location, id)),
    addTimeZone: (timezone) => dispatch(addTimeZone(timezone)),
  })
)(CreateListing);
