import React from "react";
import Svg, { G, Circle, Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={25} height={25} viewBox="0 0 32 32" {...props}>
      <G>
        <Circle cx="16" cy="9.5" r="5.8" fill="#CE7047" />
        <Path
          d="M3,28.4c0-5.4,5.8-9.7,13-9.7s13,4.4,13,9.7H3z"
          fill="#CE7047"
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
