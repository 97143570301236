import React from "react";
import {
  Text,
  View,
  TouchableOpacity,
  KeyboardAvoidingView,
  Alert,
  ScrollView,
} from "react-native";
import { connect } from "react-redux";
import styles from "../Styles/paymentInformationStyles";
import { ButtonOne } from "../Components/Button";
import HeaderImage from "../Images/Header";
import BaseLayout, { headerStyleCustom } from "./BaseLayout";
import Header from "../Components/Signup/Header";
import Title from "../Components/Signup/Title";
import { updateUser, setCurrentUser } from "../redux/auth/actions";
// import stripe from "tipsi-stripe";
import Form from "../Components/Form";
// import Card from "../Images/Card";
// import Hash from "../Images/Hash";
import QuestionMark from "../Images/QuestionMark";
import { addPaymentMethod } from "../redux/auth/api";
import { updateCard } from "../redux/appointments/actions";
import LogsService from "../LogsService";

class PaymentForExistingUser extends React.Component {
  static navigationOptions = ({ navigation }) => ({
    headerStyle: headerStyleCustom(),
    headerTintColor: "#fff",
    headerTitleStyle: {
      fontWeight: "bold",
    },
    headerLeft: null,
    headerTitle: () => <HeaderImage height={35} />,
  });

  constructor(props) {
    super(props);

    this.state = {
      name: "",
      cardNumber: "",
      expirationMonth: "",
      expirationYear: "",
      cvv: "",
      zip: "",
      clickedButton: false,
    };

    // this.handleNext = this.handleNext.bind(this);
    LogsService.fileLog(
      "PaymentForExistingUser",
      "constructor",
      "constructor initiated"
    );
  }
  onLoad() {}

  // async handleNext() {
  //   LogsService.fileLog(
  //     "PaymentForExistingUser",
  //     "handleNext",
  //     "handleNext is called"
  //   );
  //   this.setState({ clickedButton: true });
  //   var year = new Date().getFullYear();
  //   const nameRegex = /^([a-zA-Z]+\s)*[a-zA-Z]+$/;
  //   const cardNumberRegex = /^\d{15,16}$/;
  //   const cvvRegex = /^\d{3,4}$/;
  //   const regex = new RegExp("^[a-zA-Z]+$");
  //   LogsService.fileLog(
  //     "PaymentForExistingUser",
  //     "handleNext",
  //     "name=" + this.state.name
  //   );
  //   LogsService.fileLog(
  //     "PaymentForExistingUser",
  //     "handleNext",
  //     "zip=" + this.state.zip
  //   );
  //   if (
  //     this.state.name.trim() == "" ||
  //     this.state.cardNumber.trim() == "" ||
  //     this.state.expirationMonth == "" ||
  //     this.state.expirationYear == "" ||
  //     this.state.cvv.trim() == "" ||
  //     this.state.zip.trim() == ""
  //   ) {
  //     LogsService.fileLog(
  //       "PaymentForExistingUser",
  //       "handleNext validation",
  //       "All fields are mandatory"
  //     );
  //     Alert.alert("", "All fields are mandatory", [
  //       {
  //         text: "OK",
  //         onPress: () => {
  //           LogsService.fileLog(
  //             "PaymentForExistingUser",
  //             "handleNext validation onPress",
  //             "OK pressed"
  //           );
  //         },
  //       },
  //     ]);
  //     this.setState({ clickedButton: false });
  //   } else {
  //     if (this.state.name.trim() == "") {
  //       LogsService.fileLog(
  //         "PaymentForExistingUser",
  //         "handleNext validation",
  //         "Please enter name on card "
  //       );
  //       Alert.alert("", "Please enter name on card ", [
  //         {
  //           text: "OK",
  //           onPress: () => {
  //             LogsService.fileLog(
  //               "PaymentForExistingUser",
  //               "handleNext validation onPress",
  //               "OK pressed"
  //             );
  //           },
  //         },
  //       ]);
  //       this.setState({ clickedButton: false });
  //     } else if (!nameRegex.test(this.state.name.trim())) {
  //       LogsService.fileLog(
  //         "PaymentForExistingUser",
  //         "handleNext validation",
  //         "Please enter valid name on card"
  //       );
  //       Alert.alert("", "Please enter valid name on card", [
  //         { text: "OK", onPress: () => {} },
  //       ]);
  //       this.setState({ clickedButton: false });
  //     } else if (this.state.cardNumber.trim() == "") {
  //       LogsService.fileLog(
  //         "PaymentForExistingUser",
  //         "handleNext validation",
  //         "Please enter Card Number"
  //       );
  //       Alert.alert("", "Please enter Card Number", [
  //         {
  //           text: "OK",
  //           onPress: () => {
  //             LogsService.fileLog(
  //               "PaymentForExistingUser",
  //               "handleNext validation onPress",
  //               "OK pressed"
  //             );
  //           },
  //         },
  //       ]);
  //       this.setState({ clickedButton: false });
  //     } else if (!cardNumberRegex.test(this.state.cardNumber.trim())) {
  //       LogsService.fileLog(
  //         "PaymentForExistingUser",
  //         "handleNext validation",
  //         "Please enter valid number for Card Number"
  //       );
  //       Alert.alert("", "Please enter valid number for Card Number", [
  //         {
  //           text: "OK",
  //           onPress: () => {
  //             LogsService.fileLog(
  //               "PaymentForExistingUser",
  //               "handleNext validation onPress",
  //               "OK pressed"
  //             );
  //           },
  //         },
  //       ]);
  //       this.setState({ clickedButton: false });
  //     } else if (regex.test(this.state.expirationMonth.trim())) {
  //       LogsService.fileLog(
  //         "PaymentForExistingUser",
  //         "handleNext validation",
  //         "Please enter only numbers for Expiration Month"
  //       );
  //       Alert.alert("", "Please enter only numbers for Expiration Month", [
  //         {
  //           text: "OK",
  //           onPress: () => {
  //             LogsService.fileLog(
  //               "PaymentForExistingUser",
  //               "handleNext validation onPress",
  //               "OK pressed"
  //             );
  //           },
  //         },
  //       ]);
  //       this.setState({ clickedButton: false });
  //     } else if (regex.test(this.state.expirationYear.trim())) {
  //       LogsService.fileLog(
  //         "PaymentForExistingUser",
  //         "handleNext validation",
  //         "Please enter only numbers for Expiration Year"
  //       );
  //       Alert.alert("", "Please enter only numbers for Expiration Year", [
  //         {
  //           text: "OK",
  //           onPress: () => {
  //             LogsService.fileLog(
  //               "PaymentForExistingUser",
  //               "handleNext validation onPress",
  //               "OK pressed"
  //             );
  //           },
  //         },
  //       ]);
  //       this.setState({ clickedButton: false });
  //     } else if (year > this.state.expirationYear.trim()) {
  //       LogsService.fileLog(
  //         "PaymentForExistingUser",
  //         "handleNext validation",
  //         "Your card expiration year is invalid."
  //       );
  //       Alert.alert("", "Your card expiration year is invalid.", [
  //         {
  //           text: "OK",
  //           onPress: () => {
  //             LogsService.fileLog(
  //               "PaymentForExistingUser",
  //               "handleNext validation onPress",
  //               "OK pressed"
  //             );
  //           },
  //         },
  //       ]);
  //       this.setState({ clickedButton: false });
  //     }
  //     // else if (this.state.cvv.trim() == "000" || this.state.cvv.trim() == "0000") {
  //     //   LogsService.fileLog('PaymentForExistingUser','handleNext validation', 'Please enter valid CVV');
  //     //   Alert.alert("", "Please enter valid CVV", [
  //     //     { text: "OK", onPress: () => {
  //     //       LogsService.fileLog('PaymentForExistingUser','handleNext validation onPress', 'OK pressed');
  //     //     } },
  //     //   ]);
  //     //   this.setState({ clickedButton: false });
  //     // }

  //     //Above commented alert is modified to below alert by Removing "000" and "0000" validation
  //     else if (!this.state.cvv.trim()) {
  //       LogsService.fileLog(
  //         "PaymentForExistingUser",
  //         "handleNext validation",
  //         "Please enter valid CVV"
  //       );
  //       Alert.alert("", "Please enter valid CVV", [
  //         {
  //           text: "OK",
  //           onPress: () => {
  //             LogsService.fileLog(
  //               "PaymentForExistingUser",
  //               "handleNext validation onPress",
  //               "OK pressed"
  //             );
  //           },
  //         },
  //       ]);
  //       this.setState({ clickedButton: false });
  //     } else if (!cvvRegex.test(this.state.cvv.trim())) {
  //       LogsService.fileLog(
  //         "PaymentForExistingUser",
  //         "handleNext validation",
  //         "Please enter valid CVV"
  //       );
  //       Alert.alert("", "Please enter valid CVV", [
  //         {
  //           text: "OK",
  //           onPress: () => {
  //             LogsService.fileLog(
  //               "PaymentForExistingUser",
  //               "handleNext validation onPress",
  //               "OK pressed"
  //             );
  //           },
  //         },
  //       ]);
  //       this.setState({ clickedButton: false });
  //     } else if (regex.test(this.state.zip.trim())) {
  //       LogsService.fileLog(
  //         "PaymentForExistingUser",
  //         "handleNext validation",
  //         "Please enter only numbers for zip"
  //       );
  //       Alert.alert("", "Please enter only numbers for zip", [
  //         {
  //           text: "OK",
  //           onPress: () => {
  //             LogsService.fileLog(
  //               "PaymentForExistingUser",
  //               "handleNext validation onPress",
  //               "OK pressed"
  //             );
  //           },
  //         },
  //       ]);
  //       this.setState({ clickedButton: false });
  //     } else {
  //       const data = {
  //         number: this.state.cardNumber,
  //         expMonth: parseInt(this.state.expirationMonth),
  //         expYear: parseInt(this.state.expirationYear),
  //         cvc: this.state.cvv,
  //         name: this.state.name.trim(),
  //       };
  //       LogsService.fileLog(
  //         "PaymentForExistingUser",
  //         "handleNext",
  //         "Calling stripe.createTokenWithCard with data=",
  //         JSON.stringify(data)
  //       );
  //       stripe
  //         .createTokenWithCard(data)
  //         .then((responseStripe) => {
  //           LogsService.fileLog(
  //             "PaymentForExistingUser",
  //             "handleNext stripe.createTokenWithCard response=",
  //             JSON.stringify(data)
  //           );
  //           const tokenID = responseStripe.tokenId;
  //           LogsService.fileLog(
  //             "PaymentForExistingUser",
  //             "handleNext stripe.createTokenWithCard response=",
  //             "calling addPaymentMethod"
  //           );
  //           addPaymentMethod(tokenID)
  //             .then((response) => {
  //               LogsService.fileLog(
  //                 "PaymentForExistingUser",
  //                 "handleNext stripe.createTokenWithCard addPaymentMethod response=",
  //                 JSON.stringify(response)
  //               );
  //               if (response.has_stripe_user) {
  //                 this.setState({ clickedButton: true });
  //                 // this.props.navigation.navigate("AgentPortal");
  //                 if (
  //                   responseStripe &&
  //                   responseStripe.card &&
  //                   responseStripe.card.cardId
  //                 ) {
  //                   let cardDetails = {
  //                     Name: this.state.name.trim(),
  //                     ExpMonth: parseInt(this.state.expirationMonth),
  //                     ExpYear: parseInt(this.state.expirationYear),
  //                     CardId: responseStripe.card.cardId,
  //                   };
  //                   LogsService.fileLog(
  //                     "PaymentForExistingUser",
  //                     "handleNext stripe.createTokenWithCard addPaymentMethod response=",
  //                     "updateCard is calling"
  //                   );
  //                   this.props.updateCard(cardDetails).then((resp) => {
  //                     LogsService.fileLog(
  //                       "PaymentForExistingUser",
  //                       "handleNext updateCard response=",
  //                       JSON.stringify(resp)
  //                     );
  //                     if (resp.status == "ok") {
  //                       LogsService.fileLog(
  //                         "PaymentForExistingUser",
  //                         "handleNext updateCard response=",
  //                         "Navigating to AgentPortal screen"
  //                       );
  //                       this.props.navigation.navigate("AgentPortal");
  //                     }
  //                   });
  //                 } else {
  //                   LogsService.fileLog(
  //                     "PaymentForExistingUser",
  //                     "handleNext stripe.createTokenWithCard response=",
  //                     "Navigating to AgentPortal screen"
  //                   );
  //                   this.props.navigation.navigate("AgentPortal");
  //                 }
  //               } else {
  //                 this.setState({ clickedButton: false });
  //               }
  //             })
  //             .catch((e) => {
  //               this.setState({ clickedButton: false });
  //               LogsService.fileLog(
  //                 "PaymentForExistingUser",
  //                 "handleNext addPaymentMethod error=",
  //                 JSON.stringify(error)
  //               );
  //             });
  //         })
  //         .catch((error) => {
  //           LogsService.fileLog(
  //             "PaymentForExistingUser",
  //             "handleNext stripe.createTokenWithCard error=",
  //             JSON.stringify(error)
  //           );
  //           var err = error;
  //           Alert.alert("", "" + err, [{ text: "OK", onPress: () => {} }]);
  //           this.setState({ clickedButton: false });
  //         });
  //     }
  //   }
  // }

  render() {
    return (
      <BaseLayout>
        <View style={{ height: "100%", width: "100%" }}>
          <Header total={3} complete={3} />
          <Title text="Payment Information" />

          <KeyboardAvoidingView
            style={{ flex: 9, flexDirection: "column" }}
            behavior="padding"
            enabled
            keyboardVerticalOffset={100}
          >
            <ScrollView keyboardShouldPersistTaps="always" style={{ flex: 1 }}>
              <Form
                fields={[
                  {
                    label: "Name on Card",
                    key: "name",
                    value: this.state.name,
                    maxlength: 100,
                    type: "text",
                    required: true,
                    // image: () => Card,
                  },
                  {
                    label: "Card Number",
                    key: "cardNumber",
                    type: "numeric",
                    maxlength: 16,
                    value: this.state.cardNumber,
                    required: true,
                    // image: () => Hash,
                  },
                  {
                    label: "Expiration Month (MM)",
                    key: "expirationMonth",
                    maxlength: 2,
                    type: "numeric",
                    value: this.state.expirationMonth,
                    required: true,
                  },
                  {
                    label: "Expiration Year (YYYY)",
                    key: "expirationYear",
                    maxlength: 4,
                    type: "numeric",
                    value: this.state.expirationYear,
                    required: true,
                  },
                  {
                    label: "CVV",
                    key: "cvv",
                    type: "numeric",
                    value: this.state.cvv,
                    maxlength: 4,
                    required: true,
                    // image: () => QuestionMark,
                  },
                  {
                    label: "Zip/Postal Code",
                    key: "zip",
                    value: this.state.zip,
                    maxlength: 6,
                    type: "numeric",
                    required: true,
                    // image: () => QuestionMark,
                  },
                ]}
                onChange={(k, v) => this.setState({ [k]: v })}
              />
            </ScrollView>
          </KeyboardAvoidingView>
          <View style={styles.btnView}>
            <ButtonOne
              text={this.state.clickedButton ? "LOADING" : "NEXT"}
              style={styles.btnWidth}
              onPress={this.state.clickedButton ? this.onLoad : this.handleNext}
            />
          </View>

          <View style={styles.bottomView}>
            <TouchableOpacity>
              <Text style={styles.termStyles}>View Terms of Use</Text>
            </TouchableOpacity>
          </View>
        </View>
      </BaseLayout>
    );
  }
}

export default connect(
  (state) => ({
    user: state.auth.user,
  }),
  (dispatch) => ({
    setCurrentUser: (user) => dispatch(setCurrentUser(user)),
    updateUser: (user) => {
      dispatch(updateUser(user)) // TODO stop this from redirecting if something fails
        .then((r) => {
          if (r.status === "ok" || r.status === 200) {
            NavigationService.navigate("PaymentForExistingUser");
          }
        });
    },
    updateCard: (cardDetails) => dispatch(updateCard(cardDetails)),
  })
)(PaymentForExistingUser);
