import React from "react";
import Svg, { Path, G } from "react-native-svg";
/* SVGR has dropped some elements not supported by react-native-svg: title */

function SvgComponent(props) {
  return (
    <Svg width={32} height={32} {...props}>
      <Path fill="none" d="M-1-1h582v402H-1z" />
      <G>
        <Path
          fill="#ff8959"
          d="M16 32a16 16 0 1116-16 16.018 16.018 0 01-16 16zm0-29.575A13.576 13.576 0 1029.576 16 13.591 13.591 0 0016 2.425z"
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
