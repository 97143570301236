import React, { useRef, useState, useEffect, StrictMode } from 'react';
import { View, Text, TouchableOpacity, AppState } from 'react-native';
import { NavigationContainer, useFocusEffect } from '@react-navigation/native';
import { createSharedElementStackNavigator } from 'react-navigation-shared-element';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import AccountProfile from './files/Screens/AccountProfile';
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { store, persistor } from './files/redux/store';
import Login from './files/Screens/Login';
import NotificationSettings from './files/Screens/NotificationSettings';
import ConnectStripe from './files/Screens/ConnectStripe';
import ConnectStripeForExistingUser from './files/Screens/ConnectStripeForExistingUser';
import CreatePassword from './files/Screens/CreatePassword';
import PaymentForExistingUser from './files/Screens/PaymentForExistingUser';
import PaymentInformation from './files/Screens/PaymentInformation';
import StripePayment from './files/Screens/StripePayment';
import MyProfile from './files/Screens/MyProfile';
import SettingScreen from './files/Screens/SettingScreen';
import AgentPortal from './files/Screens/AgentPortal';
import CreateListing from './files/Screens/Location/CreateListing';
import BuyerInformation from './files/Screens/Location/BuyerInformation';
import AppointmentDetails from './files/Screens/Location/AppointmentDetails';
import AgentRequest from './files/Screens/AgentRequest';
import PaymentMethods from './files/Screens/PaymentMethods';
import Appointment from './files/Components/Appointment';
import ForgotPassword from './files/Screens/ForgotPassword';
// import AgentPortal from './files/Screens/AgentPortal';


// Shared Screens
const HomeScreen = ({ navigation }) => {
  useFocusEffect(() => {
    navigation.setOptions({ title: 'Home' });
  });
  return (
    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      <Text>Welcome to the Home Screen!</Text>
      <TouchableOpacity onPress={() => navigation.goBack()} >
        <Text>Go to Account Profile</Text>
      </TouchableOpacity>
    </View>
  );
};

const Screen2 = ({ navigation }) => {
  useFocusEffect(() => {
    navigation.setOptions({ title: 'Screen 2' });
  });
  return (
    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      <Text>This is Screen 2</Text>
      <TouchableOpacity onPress={() => navigation.navigate('Screen3')}>
        <Text>Go to Screen 3</Text>
      </TouchableOpacity>
      <TouchableOpacity onPress={() => navigation.goBack()}>
        <Text>Go Back</Text>
      </TouchableOpacity>
    </View>
  );
};

const Screen3 = ({ navigation }) => {
  useFocusEffect(() => {
    navigation.setOptions({ title: 'Screen 3' });
  });
  return (
    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      <Text>Welcome to Screen 3</Text>
      <TouchableOpacity onPress={() => navigation.goBack()}>
        <Text>Go Back</Text>
      </TouchableOpacity>
    </View>
  );
};

// Create a shared element stack navigator
const Stack = createSharedElementStackNavigator();

const App = () => {
  const linking = {
    config: {
      screens: {
        ForgotPassword:'ForgotPassword',
        SettingScreen:'SettingScreen',
        MyProfile:'MyProfile',
        StripePayment:'StripePayment',
        PaymentInformation:'PaymentInformation',
        PaymentForExistingUser: 'PaymentForExistingUser',
        AccountProfile: 'AccountProfile',
        CreatePassword: 'CreatePassword',
        Login: 'Login',
        NotificationSettings:'NotificationSettings',
        ConnectStripe:'ConnectStripe',
        ConnectStripeForExistingUser:'ConnectStripeForExistingUser',
        Home: 'Home',
        Screen2: 'Screen2',
        Screen3: 'Screen3',

      },
    },
  };
  const appState = useRef(AppState.currentState);

  useEffect(() => {
    const subscription = AppState.addEventListener("change", nextAppState => {
      if (
        appState.current.match(/inactive|background/) &&
        nextAppState === "active"
      ) {
        console.log("------------");
        console.log("App has come to the foreground!");
      }

      appState.current = nextAppState;
      console.log("------------");
      console.log("AppState", appState.current);
      if (appState.current === "active") {
        // alert('test')
        // LogsService.deleteDataFromSQLITE();
      }
    });

    return () => {
      subscription.remove();
    };
  }, []);
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <NavigationContainer linking={linking} fallback={<Text>Loading...</Text>}>
          <Stack.Navigator
            screenOptions={{
              headerShown: false,
              gestureEnabled: false,
            }}
          >
            <Stack.Screen name="Login" component={Login} />
            <Stack.Screen name="AccountProfile" component={AccountProfile} />
            <Stack.Screen name="ForgotPassword" component={ForgotPassword} />
            <Stack.Screen name="Appointment" component={Appointment} />
            <Stack.Screen name="PaymentMethods" component={PaymentMethods} />
            <Stack.Screen name="AgentRequest" component={AgentRequest} />
            <Stack.Screen name="AppointmentDetails" component={AppointmentDetails} />
            <Stack.Screen name="BuyerInformation" component={BuyerInformation} />
            <Stack.Screen name="CreateListing" component={CreateListing} />
            <Stack.Screen name="AgentPortal" component={AgentPortal} />
            <Stack.Screen name="SettingScreen" component={SettingScreen} />
            <Stack.Screen name="MyProfile" component={MyProfile} />
            <Stack.Screen name="StripePayment" component={StripePayment} />
            <Stack.Screen name="PaymentInformation" component={PaymentInformation} />
            <Stack.Screen name="PaymentForExistingUser" component={PaymentForExistingUser} />
            <Stack.Screen name="CreatePassword" component={CreatePassword} />
            <Stack.Screen name="ConnectStripeForExistingUser" component={ConnectStripeForExistingUser} />
            <Stack.Screen name="ConnectStripe" component={ConnectStripe} />
            <Stack.Screen name="NotificationSettings" component={NotificationSettings} />
            <Stack.Screen name="Home" component={HomeScreen} />
            <Stack.Screen name="Screen2" component={Screen2} />
            <Stack.Screen name="Screen3" component={Screen3} />
          </Stack.Navigator>
        </NavigationContainer>
      </PersistGate>
    </Provider >
  );
};

// React Native Web App Routing
export const AppWeb = () => {
  return (
    <StrictMode>
      <Router>
      <Route path="/AccountProfile" component={App} />
        <Route path="/SettingScreen" component={App} />
        <Route path="/MyProfile" component={App} />
        <Route path="/StripePayment" component={App} />
        <Route path="/PaymentInformation" component={App} />
        <Route path="/PaymentForExistingUser" component={App} />
        <Route path="/ConnectStripeForExistingUser" component={App} />
        <Route path="/CreatePassword" component={App} />
        <Route path="/ConnectStripe" component={App} />
        <Route path="/NotificationSettings" component={App} />
        <Route path="/Login" component={App} />
        <Route path="/Home" component={App} />
        <Route path="/screen2" component={App} />
        <Route path="/screen3" component={App} />
      </Router>
    </StrictMode>
  );
};

export default App;
