import React from "react";
import { View, Text, TouchableOpacity } from "react-native";
// import LinearGradient from "react-native-linear-gradient";
import styles from "../Styles/WelcomeScreenStyles";

export const ButtonOne = (props) => {
  return (
    <View>
      <TouchableOpacity onPress={() => props.onPress()} style={[{backgroundColor:'#FF8959'},styles.lineargradientBtn2, props.style]}>
        {/* <LinearGradient
          colors={["#FF8959", "#DC612F", "#C34512"]}
          style={[styles.lineargradientBtn2, props.style]}
        > */}
          <Text style={styles.btnAgent}>{props.text} </Text>
        {/* </LinearGradient> */}
      </TouchableOpacity>
    </View>
  );
};

export const ButtonSmall = (props) => {
  return (
    <View>
      <TouchableOpacity onPress={() => props.onPress()}>
        {/* <LinearGradient
          colors={["#FF8959", "#DC612F", "#C34512"]}
          style={[styles.lineargradientBtnSmall, props.style]}
        > */}
          <Text style={styles.btnAgent}>{props.text} </Text>
        {/* </LinearGradient> */}
      </TouchableOpacity>
    </View>
  );
};
export const ButtonBold = (props) => {
  return (
    <View>
      <TouchableOpacity
        onPress={() => props.onPress()}
        disabled={props.disabled}
        style={[{backgroundColor:'#FF8959'},styles.lineargradientBtn2, props.style]}
      >
        
        {/* <LinearGradient
          colors={["#FF8959", "#DC612F", "#C34512"]}
          style={[styles.lineargradientBtn2, props.style]}
        > */}
          <Text style={styles.btnAgentBold}>{props.text} </Text>
        {/* </LinearGradient> */}
      </TouchableOpacity>
    </View>
  );
};
export const ButtonBoldBig = (props) => {
  return (
    <View>
      <TouchableOpacity onPress={() => props.onPress()} style={[{backgroundColor:'#FF8959'},styles.lineargradientBtn2, props.style]}>
        {/* <LinearGradient
          colors={["#FF8959", "#DC612F", "#C34512"]}
          style={[styles.lineargradientBtn2, props.style]}
        > */}
          <Text style={styles.btnAgentBoldBig}>{props.text} </Text>
        {/* </LinearGradient> */}
      </TouchableOpacity>
    </View>
  );
};

export const ButtonTwo = (props) => {
  return (
    <View>
      <TouchableOpacity onPress={() => props.onPress()} style={[{backgroundColor:'#FF8959'},styles.lineargradientBtn2, props.style]}>
        {/* <LinearGradient
          colors={["#FF8959", "#DC612F", "#C34512"]}
          style={[styles.lineargradientBtn, props.style]}
        > */}
          <Text style={styles.btnIAm}>{props.textOne} </Text>
          <Text style={styles.btnAgent}>{props.textTwo}</Text>
        {/* </LinearGradient> */}
      </TouchableOpacity>
    </View>
  );
};
