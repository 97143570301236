import React, { createRef, useState } from "react";
import { connect } from "react-redux";
import { Text, View, TouchableOpacity, StyleSheet, Alert } from "react-native";
import HeaderImage from "../Images/Header";
import ProfileIcon from "../Images/ProfileIcon";
import SetupRadius from "../Images/SetupRadius";
import ShareIcon from "../Images/ShareIcon";
import DeleteIconFilled from "../Images/DeleteIconFilled";
import { headerStyleCustom } from "./BaseLayout";
import ActionSheetMenu from "../Components/ActionSheetMenu";
import { logoutUser,deleteUser } from "../redux/auth/actions";
import { setMasageFromScreen } from "../redux/auth/actions";
import { setErrors } from "../redux/errors/actions";
import ProcessLoadingIndicator from "./ProcessLoadingIndicator";
import { store } from "../redux/store";
import LogsService from "../LogsService";
import { getLogsFlag, setLogsFlag } from "../../config"; 

class SettingScreen extends React.Component {
  static navigationOptions = ({ navigation }) => ({
    headerStyle: headerStyleCustom(),
    headerTintColor: "#fff",
    headerTitleStyle: {
      fontWeight: "bold",
    },
    headerBackTitle: "Back",
    headerTitle: () => <HeaderImage height={35} />,
    headerRight: () => (
      <ActionSheetMenu numberOfLines={1} navigation={navigation} />
    ),
  });

  constructor(props) {
    super(props);
    this.actionSheetRef = createRef();
    this.state = {
     isLoading: false,
     message: "Please wait while we delete your account"
    };
    LogsService.fileLog('SettingScreen','constructor','constructor initiated');
  }

  //Delete My account action
  deleteAccount = () => {
    LogsService.fileLog('SettingScreen','deleteAccount','deleteAccount pressed with confirmation alert Are you sure you want to delete your account?');
    Alert.alert(
      "Confirm Delete Account",
      "Are you sure you want to delete your account?",
      [
        {
          text: "Yes",
          onPress: () => {
            LogsService.fileLog('SettingScreen','deleteAccount onPress','Yes pressed');
            this.setState({isLoading:true});
            this.props.deleteUser().then((response) => {
              this.setState({isLoading:false});
              LogsService.fileLog('SettingScreen','deleteAccount onPress deleteUser response', JSON.stringify(response));
              if (response.status.toLowerCase() === "ok") {
                this.props.setMasageFromScreen(response.message ? response.message : "Your account is deleted successfully");
                this.props.logoutUser();
              } else {
                store.dispatch(setErrors(response.errors, 10));
              }
            }).catch((error) => {
              this.setState({isLoading:false});
              store.dispatch(setErrors(["Could not delete account"]));
              LogsService.fileLog('SettingScreen','deleteAccount onPress deleteUser error', JSON.stringify(error));
            });
          },
        },
        { text: "No", onPress: () => {
          LogsService.fileLog('SettingScreen','deleteAccount onPress', 'No Pressed and cancelled confirmation alert');
        }, style: "cancel" },
      ]
    );
  };

  shareLogs = () => {
    Alert.alert(
      "Confirm Share Log File",
      "Are you sure you want to share the log file?",
      [
        {
          text: "Yes",
          onPress: () => {
            this.setState({isLoading:true});
            this.setState({ message: "Please wait while we share your log file" })
            this.shareLogAPICall();
          },
        },
        { text: "No", onPress: () => {
          LogsService.fileLog('SettingScreen','Share Log file No onPress', 'No Pressed and cancelled confirmation alert');
        }, style: "cancel" },
      ]
    );
  }

  shareLogAPICall = async () => {
      let res = await LogsService.shareFile(this.props.user.id);
      this.setState({isLoading:false});
  }

  render() {
    return (
      <View style={{flex:1}}>
        {this.state.isLoading && (
              <ProcessLoadingIndicator
                message={this.state.message}
                backColor="#ffffff00"
              />
            )}
      <View
        style={{
          top: 5,
          flexDirection: "row",
        }}
      >
        <View>
          <Text style={styles.TextHead}>Settings</Text>
          <View style={{ paddingBottom: 40, paddingLeft: 55, paddingTop: 1 }}>
            <TouchableOpacity
              style={styles.TouchalbeBtn}
              onPress={() => {
                LogsService.fileLog('SettingScreen','onPress', 'My Profile Pressed and navigating to MyProfile screen');
                if(this.actionSheetRef.current){
                  this.actionSheetRef.current.setModalVisible(false);
                }
                this.props.navigation.navigate("MyProfile");
              }}
            >
              <View>
                <ProfileIcon style={styles.NavIcon} />
                <Text style={styles.Text}>My Profile</Text>
              </View>
            </TouchableOpacity>

            <TouchableOpacity
              style={styles.TouchalbeBtn}
              onPress={() => {
                LogsService.fileLog('SettingScreen','onPress', 'Setup Radius Pressed and navigating to SetupRadius screen');
                if(this.actionSheetRef.current){
                  this.actionSheetRef.current.seModalVisible(false);
                }
                this.props.navigation.navigate("SetupRadius");
              }}
            >
              <View>
                <SetupRadius style={styles.NavIcon} />
                <Text style={styles.Text}>Setup Radius</Text>
              </View>
            </TouchableOpacity>

            <TouchableOpacity
              style={styles.TouchalbeBtn}
              onPress={() => {
                LogsService.fileLog('SettingScreen','onPress', 'Delete My Account Pressed and calling deleteAccount');
                if(this.actionSheetRef.current){
                  this.actionSheetRef.current.seModalVisible(false);
                }
                this.setState({ message: "Please wait while we delete your account" })
                this.deleteAccount();
              }}
            >
              <View>
                <DeleteIconFilled style={styles.NavIcon} />
                <Text style={styles.Text}>Delete My Account</Text>
              </View>
            </TouchableOpacity>
            {getLogsFlag() &&
            <TouchableOpacity
              style={styles.TouchalbeBtn}
              onPress={() => {
                // LogsService.fileLog('SettingScreen','onPress', 'Share file pressed and sharing the log file');
                if(this.actionSheetRef.current){
                  this.actionSheetRef.current.seModalVisible(false);
                }
                this.shareLogs();
              }}
            >
              <View>
                <ShareIcon style={styles.NavIcon} />
                <Text style={styles.Text}>Share Log File</Text>
              </View>
            </TouchableOpacity>
            }
            {/* {getLogsFlag() &&
            <TouchableOpacity
              style={styles.TouchalbeBtn}
              onPress={() => {
                LogsService.fileLog('SettingScreen','onPress', 'Share file pressed and sharing the log file');
                if(this.actionSheetRef.current){
                  this.actionSheetRef.current.seModalVisible(false);
                }
                LogsService.deleteFile();
              }}
            >
              <View>
                <DeleteIconFilled style={styles.NavIcon} />
                <Text style={styles.Text}>Delete Log file</Text>
              </View>
            </TouchableOpacity>
            } */}
          </View>
        </View>
      </View>
      </View>
    );
  }
}

// export default SettingScreen;
export default connect(
  (state) => ({
    user: state.auth.user,
  }),
  (dispatch) => ({
    deleteUser: () => dispatch(deleteUser()),
    setMasageFromScreen: (msg) => dispatch(setMasageFromScreen(msg)),
    logoutUser: () => dispatch(logoutUser()),
  })
)(SettingScreen);

const styles = StyleSheet.create({
  TextHead: {
    color: "#4990C8",
    fontSize: 27,
    fontWeight: "700",
    padding: 5,
    paddingLeft: 15,
  },
  Text: {
    color: "#4990C8",
    fontSize: 19,
    fontWeight: "700",
    padding: 5,
    left: 50,
    paddingBottom: 20,
  },
  TouchalbeBtn: {
    marginTop: 15,
    borderBottomColor: "#D9E9F0",
    borderBottomWidth: 0.7,
    marginLeft: -40,
    width: 365,
  },
  TouchalbeLine: {
    borderBottomColor: "#000",
    borderBottomWidth: 5,
    width: 75,
    justifyContent: "center",
    alignItems: "center",
  },
  NavIcon: {
    position: "absolute",
    left: 10,
    top: 7,
  },
  NavIcon1: {
    position: "absolute",
    left: -42,
    top: 5,
  },
  containercenter: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  outerCircle: {
    backgroundColor: "#D6DBDF",
    width: 75,
    height: 8,
    marginTop: 10,
    borderRadius: 10,
    justifyContent: "center",
    alignItems: "center",
  },
  humbergerMenuIos: {
    bottom: 4,
    width: 40,
    height: 40,
    right: 0,
    zIndex: 100000,
    justifyContent: "center",
  },
  humbergerMenuAndroid: {
    top: 7.5,
    width: 40,
    height: 40,
    right: 0,
    zIndex: 100000,
    justifyContent: "center",
  },
});
