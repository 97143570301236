import React from "react";
import Svg, { G, Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      width={27}
      height={27}
      viewBox="0 0 74 74"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <G>
        <Path
          fill="#4990c8"
          d="M72.6,36.2L72.6,36.2c-0.1-0.1-0.2-0.3-0.3-0.6c-1-1.7-3.9-6.9-8.9-12.2c-8-8.5-17.1-12.9-26.4-12.9
		c-9.3,0-18.4,4.5-26.4,12.9c-5.8,6.1-9.1,12.3-9.2,12.7c-0.2,0.5-0.2,1.2,0,1.7l0,0c0,0,0.2,0.3,0.3,0.5c1,1.7,3.9,6.9,8.9,12.2
		c8,8.5,17.1,12.9,26.4,12.9c9.4,0,18.5-4.5,26.4-12.9c5.8-6.1,9.1-12.4,9.2-12.7C72.8,37.3,72.8,36.7,72.6,36.2z M37,59.8
		C19,59.8,7.5,41,5.2,37C7.5,33,19,14.2,37,14.2C55,14.2,66.5,33,68.8,37C66.5,41,55,59.8,37,59.8z"
        />
        <Path
          fill="#4990c8"
          d="M37,25.9c-6.1,0-11.1,5-11.1,11.1c0,6.1,5,11.1,11.1,11.1c6.1,0,11.1-5,11.1-11.1C48.1,30.9,43.1,25.9,37,25.9
		z M37,44.4c-4,0-7.4-3.4-7.4-7.4c0-4,3.4-7.4,7.4-7.4c4,0,7.4,3.4,7.4,7.4C44.4,41,41,44.4,37,44.4z"
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
