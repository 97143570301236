import Config from "react-native-config";
// ============== Dev env ========================

//export const SERVER_URL = "http://ca227062bed7.ngrok.io/" // Development

// ============== UAT env ============heroku-app===============

// export const SERVER_URL = "http://40.71.176.225:85/"; // UAT
// export const StripeRegistrationURL = "https://dashboard.stripe.com/express/oauth/authorize?redirect_uri=http://40.71.176.225:85/users/connect-with-stripe&client_id=ca_GlsXXBMMGa1Yy7P2jjgy5vV3GWJ0atNP&state=";

// export const SERVER_URL = "http://keypleaz-staging.herokuapp.com/"; // UAT
// export const StripePublishableKey = 'pk_test_SJ8XNZ8qk6LtvvVokXb0LiXR00HSthdWRq';
// export const StripeRegistrationURL = "https://dashboard.stripe.com/express/oauth/authorize?redirect_uri=http://keypleaz-staging.herokuapp.com/users/connect-with-stripe&client_id=ca_GlsXXBMMGa1Yy7P2jjgy5vV3GWJ0atNP&state=";
// export const isUATEnv = true;

// ============== Prod env =========heroku-app==============

// export const SERVER_URL = "https://keypleaz-produ.herokuapp.com/"; // Production
// export const StripePublishableKey = 'pk_live_UZqWt5o9cw645xRgwmOuzsAl003iXnnozb';
// export const StripeRegistrationURL = "https://connect.stripe.com/express/oauth/authorize?redirect_uri=https://keypleaz-produ.herokuapp.com/users/connect-with-stripe&client_id=ca_GlsXvE8dUzQ0ZjYaicHsW42z5EwP09yF&state=";
// export const isUATEnv = false;

// ============== UAT env ============Azure-app===============

// export const SERVER_URL = Config.API_KEY; // UAT
// console.log(Config,'SERVER_URL')
// // export const SERVER_URL = "https://keypleazapi.brainvire.net/"; // UAT
// export const StripePublishableKey = Config.STRIPE_KEY;
// export const isUATEnv = Config.IS_UAT === "true";

export const SERVER_URL = "https://api.keypleaz.com:444/"; // UAT
// export const SERVER_URL = "https://keypleazapi.brainvire.net/"; // UAT
export const StripePublishableKey = "pk_test_SJ8XNZ8qk6LtvvVokXb0LiXR00HSthdWRq";
export const isUATEnv = true;

// ENV=DEV
// API_KEY="https://api.keypleaz.com:444/"
// STRIPE_KEY="pk_test_SJ8XNZ8qk6LtvvVokXb0LiXR00HSthdWRq"
// IS_UAT=true
// SIGNAL_APP_ID="43ac5d4a-76a9-447b-a292-02e5899e8f2c"

// ============== Prod env =========Azure-app==============

// export const SERVER_URL = "https://uatapi.keypleaz.com:444/"; // Production
// export const StripePublishableKey =
//   "pk_live_UZqWt5o9cw645xRgwmOuzsAl003iXnnozb";
// export const isUATEnv = false;

export const getStripeRegistrationURL = (userID, APIBaseURL) => {
  if (isUATEnv)
    return (
      "https://dashboard.stripe.com/express/oauth/authorize?redirect_uri=" +
      APIBaseURL +
      "users/connect-with-stripe&client_id=ca_GlsXXBMMGa1Yy7P2jjgy5vV3GWJ0atNP&state=" +
      userID
    );
  else
    return (
      "https://connect.stripe.com/express/oauth/authorize?redirect_uri=" +
      APIBaseURL +
      "users/connect-with-stripe&client_id=ca_GlsXvE8dUzQ0ZjYaicHsW42z5EwP09yF&state=" +
      userID
    );
};

export const isActiveOneSignal = true;
//export const onesignalAppID = "586f6a20-318e-4d59-a910-3fbed80d8d49"; //UAT - Old
export const onesignalAppID = "43ac5d4a-76a9-447b-a292-02e5899e8f2c"; //UAT
// export const onesignalAppID = Config.SIGNAL_APP_ID; //UAT
// export const onesignalAppID = "e9613af7-e116-4a28-97b1-204f0a4f951e"; //LIVE

export const logFileDeleteTimingsHours = 12; // Timings in hours in 24 hour format
export const logFileDeleteTimingsMinues = 0; // Timings in minutes
export const logFileDeleteIntervalPeriod = 8; // Timing in days
export const isCacheClear = true;
export const cacheFileName = "KeyPleazLogs.txt";
var enableLogs = true; //Logs Flag

//Method for get the stored logs flag value
export const getLogsFlag = () => {
  return enableLogs;
};

//Method for set/update the stored logs flag value
export const setLogsFlag = (flag) => {
  enableLogs = flag;
};
