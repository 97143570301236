import React from "react";
import { View, KeyboardAvoidingView, Alert, ScrollView } from "react-native";
import { connect } from "react-redux";
import styles from "../Styles/paymentInformationStyles";
import { ButtonOne } from "../Components/Button";
import HeaderImage from "../Images/Header";
import BaseLayout, { headerStyleCustom } from "./BaseLayout";
import Header from "../Components/Signup/Header";
import Title from "../Components/Signup/Title";
import { updateUser, setCurrentUser } from "../redux/auth/actions";
import { updateCard } from "../redux/appointments/actions";
// import stripe from "tipsi-stripe";
import Form from "../Components/Form";
// import Card from "../Images/Card";
// import Hash from "../Images/Hash";
import QuestionMark from "../Images/QuestionMark";
import { addPaymentMethod } from "../redux/auth/api";
import LogsService from "../LogsService";

class PaymentInformation extends React.Component {
  static navigationOptions = ({ navigation }) => ({
    headerStyle: headerStyleCustom(),
    headerTintColor: "#fff",
    headerTitleStyle: {
      fontWeight: "bold",
    },
    headerLeft: null,
    headerTitle: () => <HeaderImage height={35} />,
  });
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      cardNumber: "",
      expirationMonth: "",
      expirationYear: "",
      cvv: "",
      zip: "",
      clickedButton: false,
    };
    LogsService.fileLog(
      "paymentInformation",
      "Constructor",
      "Constructor called"
    );

    this.handleNext = this.handleNext.bind(this);
  }
  onLoad() {
    LogsService.fileLog(
      "paymentInformation",
      "onLoad",
      "onLoad empty function called"
    );
  }

  async handleNext() {
    LogsService.fileLog(
      "paymentInformation",
      "handleNext",
      "handleNext clicked"
    );
    this.setState({ clickedButton: true });
    LogsService.fileLog(
      "paymentInformation",
      "handleNext",
      "handleNext called"
    );
    var year = new Date().getFullYear();
    const nameRegex = /^([a-zA-Z]+\s)*[a-zA-Z]+$/;
    const cardNumberRegex = /^\d{15,16}$/;
    const cvvRegex = /^\d{3,4}$/;
    const regex = new RegExp("^[a-zA-Z]+$");
    if (
      this.state.name.trim() == "" ||
      this.state.cardNumber.trim() == "" ||
      this.state.expirationMonth == "" ||
      this.state.expirationYear == "" ||
      this.state.cvv.trim() == "" ||
      this.state.zip.trim() == ""
    ) {
      Alert.alert("", "All fields are mandatory", [
        {
          text: "OK",
          onPress: () => {
            LogsService.fileLog(
              "paymentInformation",
              "handleNext validation",
              "All fields are mandatory"
            );
          },
        },
      ]);
      this.setState({ clickedButton: false });
    } else {
      if (this.state.name.trim() == "") {
        Alert.alert("", "Please enter name on card ", [
          {
            text: "OK",
            onPress: () => {
              LogsService.fileLog(
                "paymentInformation",
                "handleNext validation",
                "Please enter name on card "
              );
            },
          },
        ]);
        this.setState({ clickedButton: false });
      } else if (!nameRegex.test(this.state.name.trim())) {
        Alert.alert("", "Please enter valid name on card", [
          {
            text: "OK",
            onPress: () => {
              LogsService.fileLog(
                "paymentInformation",
                "handleNext validation",
                "Please enter valid name on card"
              );
            },
          },
        ]);
        this.setState({ clickedButton: false });
      } else if (this.state.cardNumber.trim() == "") {
        Alert.alert("", "Please enter Card Number", [
          {
            text: "OK",
            onPress: () => {
              LogsService.fileLog(
                "paymentInformation",
                "handleNext validation",
                "Please enter Card Number"
              );
            },
          },
        ]);
        this.setState({ clickedButton: false });
      } else if (!cardNumberRegex.test(this.state.cardNumber.trim())) {
        Alert.alert("", "Please enter valid Card Number", [
          {
            text: "OK",
            onPress: () => {
              LogsService.fileLog(
                "paymentInformation",
                "handleNext validation",
                "Please enter valid Card Number"
              );
            },
          },
        ]);
        this.setState({ clickedButton: false });
      } else if (regex.test(this.state.expirationMonth.trim())) {
        Alert.alert("", "Please enter only numbers for Expiration Month", [
          {
            text: "OK",
            onPress: () => {
              LogsService.fileLog(
                "paymentInformation",
                "handleNext validation",
                "Please enter only numbers for Expiration Month"
              );
            },
          },
        ]);
        this.setState({ clickedButton: false });
      } else if (regex.test(this.state.expirationYear.trim())) {
        Alert.alert("", "Please enter only numbers for Expiration Year", [
          {
            text: "OK",
            onPress: () => {
              LogsService.fileLog(
                "paymentInformation",
                "handleNext validation",
                "Please enter only numbers for Expiration Year"
              );
            },
          },
        ]);
        this.setState({ clickedButton: false });
      } else if (year > this.state.expirationYear.trim()) {
        Alert.alert("", "Your card expiration year is invalid.", [
          {
            text: "OK",
            onPress: () => {
              LogsService.fileLog(
                "paymentInformation",
                "handleNext validation",
                "Your card expiration year is invalid."
              );
            },
          },
        ]);
        this.setState({ clickedButton: false });
      }
      //  else if (this.state.cvv.trim() == "000" || this.state.cvv.trim() == "0000") {
      //   Alert.alert("", "Please enter valid CVV", [
      //     { text: "OK", onPress: () => {
      //       LogsService.fileLog('paymentInformation','handleNext validation','Please enter valid CVV');
      //     } },
      //   ]);
      //   this.setState({ clickedButton: false });
      // }
      //Above commented alert is modified to below alert by Removing "000" and "0000" validation
      else if (!this.state.cvv.trim()) {
        Alert.alert("", "Please enter valid CVV", [
          {
            text: "OK",
            onPress: () => {
              LogsService.fileLog(
                "paymentInformation",
                "handleNext validation",
                "Please enter valid CVV"
              );
            },
          },
        ]);
        this.setState({ clickedButton: false });
      } else if (!cvvRegex.test(this.state.cvv.trim())) {
        Alert.alert("", "Please enter valid CVV", [
          {
            text: "OK",
            onPress: () => {
              LogsService.fileLog(
                "paymentInformation",
                "handleNext validation",
                "Please enter valid CVV"
              );
            },
          },
        ]);
        this.setState({ clickedButton: false });
      } else if (regex.test(this.state.zip.trim())) {
        Alert.alert("", "Please enter only numbers for zip", [
          {
            text: "OK",
            onPress: () => {
              LogsService.fileLog(
                "paymentInformation",
                "handleNext validation",
                "Please enter only numbers for zip"
              );
            },
          },
        ]);
        this.setState({ clickedButton: false });
      } else {
        const data = {
          number: this.state.cardNumber,
          expMonth: parseInt(this.state.expirationMonth),
          expYear: parseInt(this.state.expirationYear),
          cvc: this.state.cvv,
          name: this.state.name.trim(),
        };
        LogsService.fileLog(
          "paymentInformation",
          "handleNext",
          "Calling stripe.createTokenWithCard"
        );
        stripe
          .createTokenWithCard(data)
          .then((responseStripe) => {
            const tokenID = responseStripe.tokenId;
            LogsService.fileLog(
              "paymentInformation",
              "createTokenWithCard response = ",
              JSON.stringify(responseStripe)
            );
            LogsService.fileLog(
              "paymentInformation",
              "addPaymentMethod",
              "addPaymentMethod API calling"
            );
            addPaymentMethod(tokenID)
              .then((response) => {
                LogsService.fileLog(
                  "paymentInformation",
                  "addPaymentMethod response",
                  JSON.stringify(response)
                );
                if (response.has_stripe_user) {
                  this.setState({ clickedButton: true });
                  // this.props.navigation.navigate("AgentPortal");
                  if (
                    responseStripe &&
                    responseStripe.card &&
                    responseStripe.card.cardId
                  ) {
                    let cardDetails = {
                      Name: this.state.name.trim(),
                      ExpMonth: parseInt(this.state.expirationMonth),
                      ExpYear: parseInt(this.state.expirationYear),
                      CardId: responseStripe.card.cardId,
                    };
                    LogsService.fileLog(
                      "paymentInformation",
                      "this.props.updateCard",
                      "Updating card details"
                    );
                    this.props.updateCard(cardDetails).then((resp) => {
                      if (resp.status == "ok") {
                        LogsService.fileLog(
                          "paymentInformation",
                          "this.props.updateCard",
                          "Navigating to AgentPortal screen"
                        );
                        this.props.navigation.navigate("AgentPortal");
                      }
                    });
                  } else {
                    LogsService.fileLog(
                      "paymentInformation",
                      "addPaymentMethod response",
                      "Navigating to AgentPortal screen"
                    );
                    this.props.navigation.navigate("AgentPortal");
                  }
                } else {
                  this.setState({ clickedButton: false });
                }
              })
              .catch((e) => {
                this.setState({ clickedButton: false });
                LogsService.fileLog(
                  "paymentInformation",
                  "addPaymentMethod error",
                  JSON.stringify(e)
                );
              });
          })
          .catch((error) => {
            var err = error;
            Alert.alert("", "" + err, [{ text: "OK", onPress: () => {} }]);
            this.setState({ clickedButton: false });
            LogsService.fileLog(
              "paymentInformation",
              "addPaymentMethod err",
              JSON.stringify(err)
            );
          });
      }
    }
  }

  render() {
    return (
      <BaseLayout>
        <View style={{ height: "100%", width: "100%" }}>
          <Header total={3} complete={3} />
          <Title text="Payment Information" />

          <KeyboardAvoidingView
            style={{ flex: 9, flexDirection: "column" }}
            behavior="padding"
            enabled
            keyboardVerticalOffset={100}
          >
            <ScrollView keyboardShouldPersistTaps="always" style={{ flex: 1 }}>
              <Form
                fields={[
                  {
                    label: "Name on Card",
                    key: "name",
                    value: this.state.name,
                    required: true,
                    maxlength: 100,
                    type: "text",
                    // image: () => Card,
                  },
                  {
                    label: "Card Number",
                    key: "cardNumber",
                    value: this.state.cardNumber,
                    maxlength: 16,
                    required: true,
                    type: "numeric",
                    // image: () => Hash,
                  },
                  {
                    label: "Expiration Month (MM)",
                    key: "expirationMonth",
                    value: this.state.expirationMonth,
                    maxlength: 2,
                    type: "numeric",
                    required: true,
                  },
                  {
                    label: "Expiration Year (YYYY)",
                    key: "expirationYear",
                    value: this.state.expirationYear,
                    maxlength: 4,
                    type: "numeric",
                    required: true,
                  },
                  {
                    label: "CVV",
                    key: "cvv",
                    value: this.state.cvv,
                    maxlength: 4,
                    required: true,
                    type: "numeric",
                    // image: () => QuestionMark,
                  },
                  {
                    label: "Zip/Postal Code",
                    key: "zip",
                    value: this.state.zip,
                    required: true,
                    maxlength: 6,
                    type: "numeric",
                    // image: () => QuestionMark,
                  },
                ]}
                onChange={(k, v) => this.setState({ [k]: v })}
              />
            </ScrollView>
          </KeyboardAvoidingView>
          <View style={styles.btnView}>
            <ButtonOne
              text={this.state.clickedButton ? "LOADING" : "NEXT"}
              style={styles.btnWidth}
              onPress={this.state.clickedButton ? this.onLoad : this.handleNext}
            />
          </View>
        </View>
      </BaseLayout>
    );
  }
}

export default connect(
  (state) => ({
    user: state.auth.user,
  }),
  (dispatch) => ({
    setCurrentUser: (user) => dispatch(setCurrentUser(user)),
    updateUser: (user) => {
      dispatch(updateUser(user)) // TODO stop this from redirecting if something fails
        .then((r) => {
          LogsService.fileLog(
            "paymentInformation",
            "dispatch(updateUser(user)) response",
            JSON.stringify(r)
          );
          if (r.status === "ok" || r.status === 200) {
            LogsService.fileLog(
              "paymentInformation",
              "dispatch(updateUser(user))",
              "Navigating to PaymentInformation"
            );
            NavigationService.navigate("PaymentInformation");
          }
        });
    },
    updateCard: (cardDetails) => dispatch(updateCard(cardDetails)),
  })
)(PaymentInformation);
