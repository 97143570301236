import React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={18} height={18} viewBox="0 0 18 18" {...props}>
      <Path
        d="M4.781 18H.843A.845.845 0 010 17.156v-3.21a.844.844 0 01.247-.6l5.581-5.578a6.185 6.185 0 114.4 4.4L9.15 13.252a.837.837 0 01-.6.248h-.675v1.406a.845.845 0 01-.844.844H5.625v1.406a.845.845 0 01-.844.844zm7.032-16.875a5.065 5.065 0 00-4.7 6.949l-5.988 5.989v2.813H4.5v-2.25h2.25v-2.25h1.688l1.489-1.489a5.063 5.063 0 101.886-9.761zM13.5 6.188A1.688 1.688 0 1115.188 4.5 1.689 1.689 0 0113.5 6.188zm0-2.39a.7.7 0 10.7.7.7.7 0 00-.7-.698z"
        fill="#4990c8"
      />
    </Svg>
  );
}

export default SvgComponent;
