import React from "react";
import { View } from "react-native";
// import MapView from "react-native-maps";
import HomeMapIcon from "../Images/HomeMapIcon";
import KeyMapIcon from "../Images/KeyMapIcon";
import { getLatLngCenter } from "../utils/location";

const DEFAULT_LAT_DIFF = 0.125;
const DEFAULT_LNG_DIFF = 0.125;
const LAT_PADDING = 0.01;
const LNG_PADDING = 0.01;

const ListingMapView = (props) => {
  const center = getLatLngCenter(props.listings);

  const maxLatDelta = props.listings.reduce((maxDelta, listing) => {
    const absDiff = Math.abs(listing.lat - center.lat);
    return absDiff > maxDelta ? absDiff : maxDelta;
  }, DEFAULT_LAT_DIFF);

  const maxLngDelta = props.listings.reduce((maxDelta, listing) => {
    const absDiff = Math.abs(listing.lng - center.lng);
    return absDiff > maxDelta ? absDiff : maxDelta;
  }, DEFAULT_LNG_DIFF);

  const region = {
    latitude: center.lat,
    longitude: center.lng,
    latitudeDelta: maxLatDelta + LAT_PADDING,
    longitudeDelta: maxLngDelta + LNG_PADDING,
  };

  return (
    <View style={{ position: "relative", height: 300 }}>
      {/* <MapView
        style={{ left: 0, right: 0, top: 0, bottom: 0, position: "absolute" }}
        region={region}
        zoomEnabled={true}
        zoomTapEnabled={true}
        zoomControlEnabled={true}
        rotateEnabled={false}
        scrollEnabled={true}
        pitchEnabled={false}
      >
        {props.listings.map((listing, i) => {
          return (
            <MapView.Marker
              key={i}
              coordinate={{
                latitude: listing.lat,
                longitude: listing.lng,
              }}
            >
              <HomeMapIcon />
            </MapView.Marker>
          );
        })}
        {props.agents.map((agent, i) => {
          if (
            agent.lat != "" &&
            agent.lng != "" &&
            agent.lat != null &&
            agent.lng != null
          ) {
            return (
              <MapView.Marker
                key={i}
                coordinate={{
                  latitude: parseFloat(agent.lat),
                  longitude: parseFloat(agent.lng),
                }}
              >
                <KeyMapIcon />
              </MapView.Marker>
            );
          }
        })}
      </MapView> */}
    </View>
  );
};

export default ListingMapView;
