import React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={18} height={13} viewBox="0 0 18 13" {...props}>
      <Path
        d="M16.313 13H1.688A1.659 1.659 0 010 11.375v-9.75A1.659 1.659 0 011.688 0h14.625A1.659 1.659 0 0118 1.625v9.75A1.659 1.659 0 0116.313 13zM1.125 4.434v6.941a.553.553 0 00.563.542h14.625a.553.553 0 00.563-.542V4.434c-.851.673-2.226 1.733-4.6 3.545-.11.085-.233.185-.363.29a5.181 5.181 0 01-2.9 1.48h-.024a5.267 5.267 0 01-2.914-1.485c-.128-.1-.248-.2-.355-.285C3.295 6.124 1.963 5.1 1.125 4.434zM9 8.667h.015a4.712 4.712 0 002.211-1.25c.13-.105.252-.2.355-.283 3.4-2.6 4.514-3.472 5.295-4.107v-1.4a.553.553 0 00-.562-.542H1.688a.553.553 0 00-.562.542v1.4C1.887 3.647 3 4.518 6.42 7.133c.1.076.217.172.342.274a4.737 4.737 0 002.224 1.26z"
        fill="#4990c8"
      />
    </Svg>
  );
}

export default SvgComponent;
