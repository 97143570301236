import { StyleSheet } from "react-native";
import Colors from "../../assets/colors";
const styles = StyleSheet.create({
  showView: {
    backgroundColor: "#A3C7E3",
    height: 150,
  },
  contentView: {
    flexDirection: "row",
    paddingTop: 10,
  },
  historyview: {
    color: "#1B75BA",
    marginLeft: 15,
    fontSize: 15,
  },
  accountView: {
    color: "#1B75BA",
    fontSize: 15,
  },
  btnWidth: {
    width: 140,
  },
  inputText: {
    height: 50,
    color: "#292B34",
    margin: 5,
    marginLeft: 30,
    marginRight: 30,
    borderBottomWidth: 1,
    borderTopLeftRadius: 1,
    fontFamily: "RobotoCondensed-Light",
    fontSize: 17,
    paddingHorizontal: 15,
  },
  showDetails: {
    backgroundColor: "#EAF8FC",
    width: "90%",
    height: 90,
    borderRadius: 20,
  },

  Appoinment: {
    fontSize: 20,
    color: "#4990C8",
    fontWeight: "bold",
  },
  showTime: {
    fontSize: 13,
    color: "#4990C8",
  },
  showTime1: {
    // paddingLeft: 10,
    fontSize: 14,
    color: "#292B34",
    flexWrap: "wrap",
  },
  showFee1: {
    paddingLeft: 20,
    fontSize: 15,
    color: "#292B34",
  },
  distance1: {
    paddingLeft: 25,
    fontSize: 15,
    color: "#292B34",
  },
  headerImage: {
    height: 80,
    alignSelf: "center",
  },
  lineargradientBtn: {
    borderWidth: 2,
    borderRadius: 30,
    borderColor: "white",
    borderTopWidth: 0,
    shadowColor: "black",
    shadowOffset: { width: 0, height: 30 },
    shadowOpacity: 10,
    shadowRadius: 30,
    elevation: 8,
    width: "50%",
    height: 50,
  },
  btnBack: {
    marginBottom: 0,
    borderBottomWidth: 0,
    shadowColor: "black",
    shadowOffset: { width: 0, height: 50 },
    shadowOpacity: 10,
    shadowRadius: 2,
    elevation: 4,
  },
  ConfirmShowing: {
    backgroundColor: "#F2F7FB",
    width: "95%",
    borderRadius: 20,
  },
  buyerInfo: {
    fontSize: 20,
    padding: 10,
    color: "#4990C8",
    fontWeight: "bold",
  },
  buyerNotesInfo: {
    fontSize: 20,
    padding: 10,
    color: "#4990C8",
    fontWeight: "bold",
  },
  feedbackInfo: {
    fontSize: 20,
    color: "#4990C8",
    fontWeight: "bold",
  },
  widthStyle: {
    width: 150,
  },
  btnView1: {
    justifyContent: "center",
    alignItems: "center",
    marginTop: 15,
  },
  oval: {
    width: 50,
    height: 45,
    paddingTop: 15,
    paddingLeft: 10,
  },
  myaccount: {
    width: 30,
    height: 30,
    paddingTop: 5,
  },
  row: {
    flex: 1,
    flexDirection: "row",
  },
  inputWrap1: {
    flex: 0.3,
  },
  inputWrap2: {
    flex: 1,
  },
  code: {
    height: 40,
    marginLeft: 30,
    textAlign: "center",
    color: "#292B34",
    fontSize: 17,
    borderRadius: 12,
    borderRightWidth: 1,
    borderLeftWidth: 1,
    fontFamily: "RobotoCondensed-Light",
  },
  inputphone: {
    height: 40,
    color: "#292B34",

    borderTopLeftRadius: 1,
    fontFamily: "RobotoCondensed-Light",
    fontSize: 17,
    marginRight: 30,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 10,
  },
  backgroundViewNotes: {
    backgroundColor: Colors.backgroundBlue,
    borderRadius: 10,
    margin: 20,
    marginTop: 25,
  },
  backgroundViewCancelReasonNotes: {
    backgroundColor: Colors.backgroundBlue,
    borderRadius: 10,
    marginLeft: 20,
    marginRight: 20,
  },
  inputTextArea: {
    height: 100,
    color: "#292B34",
    marginLeft: 15,
    marginRight: 15,
    marginBottom: 15,
    borderBottomWidth: 1,
    borderTopLeftRadius: 1,
    fontFamily: "RobotoCondensed-Light",
    fontSize: 17,
  },
  feedbackModal: {
    backgroundColor: "#FFF",
    flexDirection: "column",
    marginTop: "10%",
    marginBottom: "10%",
    borderRadius: 10,
    borderWidth: 1,
  },
  selectPropertyModal: {
    backgroundColor: "#FFF",
    flexDirection: "column",
    marginTop: "10%",
    marginBottom: "10%",
    borderRadius: 10,
    borderWidth: 1,
    height: "80%",
  },
  selectCompletedProperty: {
    color: "#292B34",
    marginLeft: 15,
    marginRight: 15,
    marginBottom: 15,
    borderBottomWidth: 2,
    borderTopLeftRadius: 1,
    borderBottomColor: Colors.backgroundBlue,
    fontFamily: "RobotoCondensed-Light",
    fontSize: 17,
  },
});
export default styles;
