// import * as api from "./api";
import { camelCaseUser, snakeCaseUser } from "./utils";
import { setLogsFlag } from "../../../config";
// import LogsService from "../../LogsService";
// import NavigationService from "../../NavigationService";
import { setMessages, setMessagesSucess } from "../messages/actions";
import { setErrors } from "../errors/actions";


export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const RECEIVE_LOGIN_USER = "RECEIVE_LOGIN_USER";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_LOADING = "SET_LOADING";
export const SET_FETCHED_USER_INFO = "SET_FETCHED_USER_INFO";
export const SET_RESET_FETCHED_USER_INFO_FLAG =
  "SET_RESET_FETCHED_USER_INFO_FLAG";
export const SET_FETCHED_USER_INFO_BEFORE_LOGIN =
  "SET_FETCHED_USER_INFO_BEFORE_LOGIN";
export const SET_STRIPE_LOGIN_URL = "SET_STRIPE_LOGIN_URL";
export const SET_USERS = "SET_USERS";
export const SET_REMEMBER_ME = "SET_REMEMBER_ME";
export const SET_LOGININ_USER = "SET_LOGININ_USER";
export const SET_PASSWORD_FIELD_DISABLED = "SET_PASSWORD_FIELD_DISABLED";
export const SET_USER_PAYOUT_INFO_PRESENT = "SET_USER_PAYOUT_INFO_PRESENT";
export const SET_USER_PAYMENT_INFO_PRESENT = "SET_USER_PAYMENT_INFO_PRESENT";
export const SET_PUSHNOTIFIACTION_TOKEN = "SET_PUSHNOTIFIACTION_TOKEN";
export const SET_BROKERAGES_LIST = "SET_BROKERAGES_LIST";

export const receiveLoginUser = (user) => {
  return {
    type: RECEIVE_LOGIN_USER,
    user,
  };
};

export const savePushnotificationInToken = (token) => {
  return {
    type: SET_PUSHNOTIFIACTION_TOKEN,
    token,
  };
};
export const savebrokeragesList = (brokeragesList) => {
  return {
    type: SET_BROKERAGES_LIST,
    brokeragesList,
  };
};

export const setCurrentUser = (user) => {
  return {
    type: SET_CURRENT_USER,
    user,
  };
};

export const setLoading = (loading) => {
  return {
    type: SET_LOADING,
    loading,
  };
};

export const setPasswordFieldDisableGoOnStrip = (passwordDisable) => {
  return {
    type: SET_PASSWORD_FIELD_DISABLED,
    passwordDisable,
  };
};

export const setPayoutInfo = (isUserStripePayoutInfo) => {
  return {
    type: SET_USER_PAYOUT_INFO_PRESENT,
    isUserStripePayoutInfo,
  };
};
export const setPaymentInfo = (isUserStripePaymentInfo) => {
  return {
    type: SET_USER_PAYMENT_INFO_PRESENT,
    isUserStripePaymentInfo,
  };
};

export const setFetchedUserInfo = (fetchedUserInfo) => {
  return {
    type: SET_FETCHED_USER_INFO,
    fetchedUserInfo,
  };
};
export const setFetchedUserInfoBeforeLogin = (fetchedUserInfo) => {
  return {
    type: SET_FETCHED_USER_INFO_BEFORE_LOGIN,
    fetchedUserInfo,
  };
};

export const setStripeLoginURL = (url) => {
  return {
    type: SET_STRIPE_LOGIN_URL,
    url,
  };
};

export const setUsers = (users) => {
  return {
    type: SET_USERS,
    users,
  };
};

export const setRememberMe = (rememberMe) => {
  return {
    type: SET_REMEMBER_ME,
    rememberMe,
  };
};

export const setLoggedInUser = (loggedInUser) => {
  return {
    type: SET_LOGININ_USER,
    loggedInUser,
  };
};

export const logoutUser = () => {
  return (dispatch) => {
    dispatch(_logoutUser());
    // NavigationService.navigate("Login");
  };
};
export const goAgentPortal = () => {
  // NavigationService.navigate("AgentPortal");
};

export const setInitial = () => {
  return (dispatch) => {
    dispatch(_setInitials());
  };
};
export const _setInitials = () => {
  return {
    type: SET_RESET_FETCHED_USER_INFO_FLAG,
  };
};

// export const _logoutUser = () => {
//   return {
//     type: LOGOUT_USER,
//   };
// };

export const setNotificationToken = () => {
  return (dispatch) => {
    dispatch(savePushnotificationInToken(token));
  };
};

export const deleteUser = () => {
  return (dispatch) => {
    return api.deleteUser();
  };
};

export const shareLogFile = (id, file) => {
  return (dispatch) => {
    return api.shareLogFile(id, file);
  };
};

export const loginUser = (email, password, deviceInfo) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    // LogsService.fileLog("Login", "loginUser", email);
    // LogsService.fileLog("Login", "loginUser", password);
    // LogsService.fileLog("Login", "loginUser", JSON.stringify(deviceInfo));
    api
      .login(email, password, deviceInfo)
      .then((response) => {
        dispatch(setLoading(false));
        if (response.status === "ok") {
          console.log("Login Response=", response);
          if (response.user && response.user.LogsEnabled != undefined) {
            setLogsFlag(response.user.LogsEnabled);
          }
          dispatch(receiveLoginUser(camelCaseUser(response.user)));
          dispatch(setLoggedInUser(true));
          if (
            response &&
            (response.has_stripe_user === false ||
              response.has_stripe_user === null)
          ) {
            // LogsService.fileLog(
            //   "Login",
            //   "loginUser",
            //   "Stripe info missing and navigating to ConnectStripeForExistingUser"
            // );
            // NavigationService.navigate("ConnectStripeForExistingUser");
          } else if (
            response &&
            (response.payment_info === false || response.payment_info === null)
          ) {
            // LogsService.fileLog(
            //   "Login",
            //   "loginUser",
            //   "Payment info missing and navigating to PaymentForExistingUser"
            // );
            // NavigationService.navigate("PaymentForExistingUser");
          } else {
            // LogsService.fileLog(
            //   "Login",
            //   "loginUser",
            //   "Login Successful and navigating to AgentPortal"
            // );
            // NavigationService.navigate("AgentPortal");
          }
        } else {
          // LogsService.fileLog("Login", "loginUser", JSON.stringify(response));
          dispatch(setErrors(response.errors));
        }
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const getAllBrokerages = () => {
  return (dispatch) => {
    dispatch(setLoading(true));
    // LogsService.fileLog(
    //   "redux/auth/actions.js",
    //   "getAllBrokerages",
    //   "getAllBrokerages api calling"
    // );
    api
      .getAllBrokerages()
      .then((response) => {
        dispatch(setLoading(false));
        if (response.status === "ok") {
          const bksNewList = () => {
            let newArr = [];
            response.all_brokerages.map((element) => {
              newArr.push({
                brokerage_code: element.brokerage_code,
                name: element.brokerage_code,
                selectedItemWithName:
                  element.brokerage_name + " - " + element.brokerage_code,
              });
            });
            return newArr;
          };
          // LogsService.fileLog(
          //   "redux/auth/actions.js",
          //   "getAllBrokerages",
          //   "getAllBrokerages response save into redux storage"
          // );
          dispatch(savebrokeragesList(bksNewList()));
        } else {
        }
      })
      .catch((error) => {
        // LogsService.fileLog(
        //   "redux/auth/actions.js",
        //   "getAllBrokerages error",
        //   JSON.stringify(error)
        // );
        dispatch(setErrors(["Could not fetch user information"]));
      });
  };
};
export const getFindAgent = (user) => {
  return (dispatch) => {
    // LogsService.fileLog(
    //   "redux/auth/actions.js",
    //   "getFindAgent",
    //   "findAgent api calling"
    // );
    dispatch(setLoading(true));
    api
      .findAgent(user)
      .then((response) => {
        dispatch(setLoading(false));
        if (response.status === "ok") {
          dispatch(setCurrentUser(camelCaseUser(response)));
          dispatch(setFetchedUserInfoBeforeLogin(true));
        } else {
          dispatch(setErrors(response.errors));
          dispatch(setFetchedUserInfoBeforeLogin(false));
          // LogsService.fileLog(
          //   "redux/auth/actions.js",
          //   "getFindAgent response",
          //   JSON.stringify(response.errors)
          // );
        }
      })
      .catch((error) => {
        // LogsService.fileLog(
        //   "redux/auth/actions.js",
        //   "getFindAgent error",
        //   JSON.stringify(error)
        // );
        dispatch(setErrors(["Could not fetch user information"]));
        dispatch(setFetchedUserInfoBeforeLogin(false));
      });
  };
};

export const createUser = (user, pushNotificationToken = null) => {
  console.log("user=", user);
  var newUserObj = {
    accepting_showings: true,
    first_name: user.firstName,
    last_name: user.lastName,
    email: user.email,
    grec_id: user.grecID,
    address: null,
    brokerage_id: user.brokerageID,
    phone: user.phone,
    password: user.password,
    otp: user.validateCode,
    notification_token: pushNotificationToken,
  };
  return (dispatch) => {
    dispatch(setLoading(true));
    // LogsService.fileLog(
    //   "redux/auth/actions.js",
    //   "createUser",
    //   "createUser API calling"
    // );
    api
      .createUser(newUserObj)
      .then((response) => {
        if (response.status === "error") {
          dispatch(setErrors([response.errors]));
          // LogsService.fileLog(
          //   "redux/auth/actions.js",
          //   "createUser status error",
          //   JSON.stringify(response.errors)
          // );
        }
        if (response.status === "ok") {
          dispatch(setCurrentUser(camelCaseUser(response.user)));
          dispatch(setFetchedUserInfo(true));
          setTimeout(() => {
            var tempUser = response.user;
            tempUser["lat"] = user.lat;
            tempUser["lng"] = user.lng;
            tempUser["app_version"] = user.app_version;
            tempUser["platform_version"] = user.platform_version;
            tempUser["devise_model"] = user.devise_model;
            tempUser["manufacturer"] = user.manufacturer;
            tempUser["os"] = user.os;
            dispatch(updateUser(tempUser));
          }, 500);
          dispatch(setPasswordFieldDisableGoOnStrip(false));
          // LogsService.fileLog(
          //   "redux/auth/actions.js",
          //   "createUser status ok",
          //   "User created and navigating to ConnectStripe page"
          // );
          // NavigationService.navigate("ConnectStripe");
        } else if (response.errors["base"] != undefined) {
          if (response.errors.base.length == 1) {
            dispatch(setPasswordFieldDisableGoOnStrip(true));
            var errMsg = response.errors.base[0];
            // LogsService.fileLog(
            //   "redux/auth/actions.js",
            //   "createUser status errors.base",
            //   JSON.stringify(response.errors)
            // );
            dispatch(setErrors([errMsg]));
          }
        } else if (response.errors["email"] != undefined) {
          // LogsService.fileLog(
          //   "redux/auth/actions.js",
          //   "createUser status errors.email",
          //   JSON.stringify(response.errors)
          // );
          if (response.errors.email.length == 1) {
            dispatch(setPasswordFieldDisableGoOnStrip(true));
            var errMsg = response.errors.email[0];
            dispatch(setErrors([errMsg]));
          }
        }
      })
      .catch((error) => {
        dispatch(setPasswordFieldDisableGoOnStrip(true));
        dispatch(setErrors(["Could not fetch user information"]));
        // LogsService.fileLog(
        //   "redux/auth/actions.js",
        //   "createUser error",
        //   JSON.stringify(error)
        // );
      });
  };
};

export const toggleModal = () => {
  console.warn("toggleModal");
};
export const getAppVersion = () => {
  return api.getAppVersion();
};

export const validateToken = (token) => {
  var URL = "https://onesignal.com/api/v1/players/";
  console.log("URL=", URL + token);
  return fetch(URL + token, {
    method: "GET",
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.log("validateToken error=", error);
    });
};

export const updateUser = (user) => {
  var userNew = JSON.parse(JSON.stringify(user));
  delete userNew["first_name"];
  delete userNew["last_name"];
  delete userNew["firstName"];
  delete userNew["lastName"];
  delete userNew["phone"];
  delete userNew["photo"];
  console.log("updateUserNew = ", snakeCaseUser(userNew));
  console.log("updateUser = ", snakeCaseUser(user));
  var notificationToken = userNew["notification_token"]
    ? userNew["notification_token"]
    : "";
  return (dispatch) => {
    validateToken(notificationToken).then(
      (resp) => {
        console.log("response==", resp);
        if (resp.errors) {
          delete userNew["notification_token"];
        }
        dispatch(setLoading(true));
        // LogsService.fileLog(
        //   "redux/auth/actions.js",
        //   "updateUser",
        //   "updateUser API calling"
        // );
        return api.updateUser(snakeCaseUser(userNew)).then(
          (response) => {
            console.log("updateUser response= ", response);
            dispatch(setLoading(false));
            if (response.status === "ok") {
              if (response.user && response.user.LogsEnabled != undefined) {
                setLogsFlag(response.user.LogsEnabled);
              }
              dispatch(setCurrentUser(camelCaseUser(response.user)));
            }
            return Promise.resolve(response);
          },
          (error) => {
            // LogsService.fileLog(
            //   "redux/auth/actions.js",
            //   "updateUser error",
            //   JSON.stringify(error)
            // );
            throw error;
          }
        );
      },
      (error) => {
        // LogsService.fileLog(
        //   "redux/auth/actions.js",
        //   "validateToken error",
        //   JSON.stringify(error)
        // );
        throw error;
      }
    );
  };
};

export const updateUserToggle = (user) => {
  var userNew = JSON.parse(JSON.stringify(user));
  delete userNew["first_name"];
  delete userNew["last_name"];
  delete userNew["firstName"];
  delete userNew["lastName"];
  delete userNew["phone"];
  delete userNew["photo"];
  console.log("updateUserToggle updateUserNew = ", snakeCaseUser(userNew));
  console.log("updateUserToggle updateUser = ", snakeCaseUser(user));
  var notificationToken = userNew["notification_token"]
    ? userNew["notification_token"]
    : "";
  return (dispatch) => {
    return validateToken(notificationToken).then(
      (resp) => {
        console.log("updateUserToggle response==", resp);
        if (resp.errors) {
          delete userNew["notification_token"];
        }
        dispatch(setLoading(true));
        // LogsService.fileLog(
        //   "redux/auth/actions.js",
        //   "updateUserToggle",
        //   "updateUser API calling"
        // );
        return api.updateUser(snakeCaseUser(userNew)).then(
          (response) => {
            console.log("updateUser response= ", response);
            dispatch(setLoading(false));
            if (response.status === "ok") {
              if (response.user && response.user.LogsEnabled != undefined) {
                setLogsFlag(response.user.LogsEnabled);
              }
              dispatch(setCurrentUser(camelCaseUser(response.user)));
            }
            return Promise.resolve(response);
          },
          (error) => {
            // LogsService.fileLog(
            //   "redux/auth/actions.js",
            //   "updateUserToggle error",
            //   JSON.stringify(error)
            // );
            throw error;
          }
        );
      },
      (error) => {
        // LogsService.fileLog(
        //   "redux/auth/actions.js",
        //   "validateToken error",
        //   JSON.stringify(error)
        // );
        throw error;
      }
    );
  };
};

export const updateUserCoords = (user, location) => {
  console.log("updateUserCoords = ", location);
  console.log("updateUserCoords user = ", user);
  // LogsService.fileLog(
  //   "redux/auth/actions.js",
  //   "updateUserCoords location",
  //   JSON.stringify(location)
  // );
  // LogsService.fileLog(
  //   "redux/auth/actions.js",
  //   "updateUserCoords user",
  //   JSON.stringify(user)
  // );
  return (dispatch) => {
    dispatch(setLoading(true));
    // LogsService.fileLog(
    //   "redux/auth/actions.js",
    //   "updateUserCoords",
    //   "updateUserCoords API calling"
    // );
    return api.updateUserCoords(snakeCaseUser(user), location).then(
      (response) => {
        console.log("updateUserCoords response= ", response);
        // LogsService.fileLog(
        //   "redux/auth/actions.js",
        //   "updateUserCoords response",
        //   JSON.stringify(response)
        // );
        dispatch(setLoading(false));
        if (response.status === "ok") {
        }
        return Promise.resolve(response);
      },
      (error) => {
        console.log("updateUserCoords error= ", error);
        // LogsService.fileLog(
        //   "redux/auth/actions.js",
        //   "updateUserCoords error",
        //   JSON.stringify(error)
        // );
        throw error;
      }
    );
  };
};

export const validateUser = (user) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    // LogsService.fileLog(
    //   "redux/auth/actions.js",
    //   "validateUser",
    //   "validateUser API calling"
    // );
    return api.validateUserApi(user).then(
      (response) => {
        dispatch(setLoading(false));
        if (response.status === "ok") {
          dispatch(setMessagesSucess([response.message]));
        }
        return Promise.resolve(response);
      },
      (error) => {
        // LogsService.fileLog(
        //   "redux/auth/actions.js",
        //   "validateUser error",
        //   JSON.stringify(error)
        // );
        throw error;
      }
    );
  };
};
export const updateMyProfile = (user) => {
  // LogsService.fileLog(
  //   "redux/auth/actions.js",
  //   "updateMyProfile",
  //   "updateMyProfile is called with details=" + JSON.stringify(user)
  // );
  return api.updateUser(snakeCaseUser(user));
};

export const editPassword = (user) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    // LogsService.fileLog(
    //   "redux/auth/actions.js",
    //   "editPassword",
    //   "updatePassword API calling"
    // );
    api.updatePassword(user.email, user.oldPassword).then((response) => {
      if (response.status === "ok") {
        var userNew = {
          email: user.email,
          id: user.id,
          password: user.password,
          passwordConfirmation: user.passwordConfirmation,
          accepting_showings: user.accepting_showings,
        };
        if (user.password == user.oldPassword) {
          dispatch(setLoading(false));
          dispatch(
            setErrors(["Your new password cannot be same as old password."])
          );
          // LogsService.fileLog(
          //   "redux/auth/actions.js",
          //   "editPassword",
          //   "Your new password cannot be same as old password."
          // );
        } else {
          // LogsService.fileLog(
          //   "redux/auth/actions.js",
          //   "editPassword",
          //   "updateUser API calling"
          // );
          return api.updateUser(snakeCaseUser(userNew)).then(
            (response) => {
              dispatch(setLoading(false));
              // LogsService.fileLog(
              //   "redux/auth/actions.js",
              //   "editPassword updateUser response",
              //   JSON.stringify(response)
              // );
              if (response.status === "ok") {
                dispatch(setMessages(["Successfully updated your password"]));
                // LogsService.fileLog(
                //   "redux/auth/actions.js",
                //   "editPassword updateUser",
                //   "updateUser API calling"
                // );
                // NavigationService.navigate("MyProfile");
              } else {
                dispatch(setErrors(response.errors));
                // LogsService.fileLog(
                //   "redux/auth/actions.js",
                //   "editPassword updateUser error",
                //   JSON.stringify(response.errors)
                // );
              }
            },
            (error) => {
              // LogsService.fileLog(
              //   "redux/auth/actions.js",
              //   "editPassword error",
              //   JSON.stringify(error)
              // );
              throw error;
            }
          );
        }
      } else {
        dispatch(setLoading(false));
        dispatch(setErrors(["The old password entered was invalid"]));
      }
    });
  };
};

export const requestResetPassword = (email) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    // LogsService.fileLog(
    //   "redux/auth/actions.js",
    //   "requestResetPassword",
    //   "requestResetPassword api calling"
    // );
    return api.requestResetPassword(email).then(
      (response) => {
        dispatch(setLoading(false));
        // LogsService.fileLog(
        //   "redux/auth/actions.js",
        //   "requestResetPassword response",
        //   JSON.stringify(response)
        // );
        if (response.status === "ok") {
          return Promise.resolve(response);
        }
        dispatch(setErrors(["Could not send a reset password email"]));
      },
      (error) => {
        // LogsService.fileLog(
        //   "redux/auth/actions.js",
        //   "requestResetPassword error",
        //   JSON.stringify(error)
        // );
        throw error;
      }
    );
  };
};

export const resetPassword = (email) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    // LogsService.fileLog(
    //   "redux/auth/actions.js",
    //   "resetPassword",
    //   "resetPassword api calling"
    // );
    return api.resetPassword(email).then(
      (response) => {
        dispatch(setLoading(false));
        // LogsService.fileLog(
        //   "redux/auth/actions.js",
        //   "resetPassword response=",
        //   response
        // );
        if (response.status === "error") {
        } else {
          if (response.status === "ok") {
            dispatch(setMessages(["Successfully reset your password"]));
            return Promise.resolve(response);
          } else if (email.password !== email.passwordConfirmation) {
            dispatch(
              setErrors(["password and confirmation password don’t match."])
            );
          } else {
            dispatch(setErrors(["Could not reset your password"]));
          }
        }
      },
      (error) => {
        // LogsService.fileLog(
        //   "redux/auth/actions.js",
        //   "resetPassword error",
        //   JSON.stringify(error)
        // );
        throw error;
      }
    );
  };
};

export const getStripeLoginURL = (user) => {
  // LogsService.fileLog(
  //   "redux/auth/actions.js",
  //   "getStripeLoginURL",
  //   "getStripeLoginURL is called"
  // );
  return (dispatch) => {
    dispatch(setLoading(true));
    // LogsService.fileLog(
    //   "redux/auth/actions.js",
    //   "getStripeLoginURL",
    //   "getStripeLoginURL api calling"
    // );
    return api.getStripeLoginURL().then(
      (response) => {
        dispatch(setLoading(false));
        // LogsService.fileLog(
        //   "redux/auth/actions.js",
        //   "getStripeLoginURL response",
        //   JSON.stringify(response)
        // );
        if (response.status === "ok") {
          dispatch(setStripeLoginURL(response.url));
        }
        return Promise.resolve(response);
      },
      (error) => {
        // LogsService.fileLog(
        //   "redux/auth/actions.js",
        //   "getStripeLoginURL error",
        //   JSON.stringify(error)
        // );
        throw error;
      }
    );
  };
};

export const getUsers = (lat, lng) => {
  // LogsService.fileLog(
  //   "redux/auth/actions.js",
  //   "getUsers",
  //   "getUsers is called"
  // );
  return (dispatch) => {
    dispatch(setLoading(true));
    // LogsService.fileLog(
    //   "redux/auth/actions.js",
    //   "getUsers",
    //   "getUsers API is calling"
    // );
    return api.getUsers(lat, lng).then(
      (response) => {
        dispatch(setLoading(false));
        // LogsService.fileLog('redux/auth/actions.js','getUsers response', JSON.stringify(response));
        if (response.status === "ok") {
          dispatch(setUsers(response.users.map((u) => camelCaseUser(u))));
        }
        return Promise.resolve(response);
      },
      (error) => {
        // LogsService.fileLog(
        //   "redux/auth/actions.js",
        //   "getUsers error",
        //   JSON.stringify(error)
        // );
        throw error;
      }
    );
  };
};

export const getNotificationUnreadCount = () => {
  // LogsService.fileLog(
  //   "redux/auth/actions.js",
  //   "getNotificationUnreadCount",
  //   "getNotificationUnreadCount is called"
  // );
  return (dispatch) => {
    // LogsService.fileLog(
    //   "redux/auth/actions.js",
    //   "getNotificationUnreadCount",
    //   "getNotificationUnreadCount API is calling"
    // );
    return api.getNotificationUnreadCount().then(
      (response) => {
        // LogsService.fileLog(
        //   "redux/auth/actions.js",
        //   "getNotificationUnreadCount response",
        //   JSON.stringify(response)
        // );
        if (response.status === "ok") {
          return Promise.resolve(response);
        }
        return Promise.resolve(response);
      },
      (error) => {
        // LogsService.fileLog(
        //   "redux/auth/actions.js",
        //   "getNotificationUnreadCount error",
        //   JSON.stringify(error)
        // );
        throw error;
      }
    );
  };
};
export const getUser = (id) => {
  // LogsService.fileLog("redux/auth/actions.js", "getUser", "getUser is called");
  return (dispatch) => {
    dispatch(setLoading(true));
    // LogsService.fileLog(
    //   "redux/auth/actions.js",
    //   "getUser",
    //   "getUser API is calling"
    // );
    return api.getUser(id).then(
      (response) => {
        dispatch(setLoading(false));
        // LogsService.fileLog(
        //   "redux/auth/actions.js",
        //   "getUser response",
        //   JSON.stringify(response)
        // );
        if (response.status === "ok") {
          dispatch(setUsers([camelCaseUser(response.user)]));
        }
        return Promise.resolve(response);
      },
      (error) => {
        // LogsService.fileLog(
        //   "redux/auth/actions.js",
        //   "getUser error",
        //   JSON.stringify(error)
        // );
        throw error;
      }
    );
  };
};
export const setMasageFromScreen = (msg) => {
  // LogsService.fileLog(
  //   "redux/auth/actions.js",
  //   "setMasageFromScreen",
  //   "setMasageFromScreen is called"
  // );
  return (dispatch) => {
    dispatch(setMessages([msg]));
  };
};
export const setLoadingFromScreen = (value) => {
  // LogsService.fileLog(
  //   "redux/auth/actions.js",
  //   "setLoadingFromScreen",
  //   "setLoadingFromScreen is called"
  // );
  return (dispatch) => {
    dispatch(setLoading(value));
  };
};

export const isCheckStripeDone = (id) => {
  // LogsService.fileLog(
  //   "redux/auth/actions.js",
  //   "isCheckStripeDone",
  //   "isCheckStripeDone is called"
  // );
  return api.isCheckStripeDone(id);
};
