import { BASE_URL, doGet, doPost, doDelete, doPut } from "../api";

export const createAppointment = (appointment) => {
  return doPost(BASE_URL + `appointments`, appointment);
};

export const getCreatedAppointments = () => {
  return doGet(BASE_URL + "appointments/created");
};

export const getAvailableAppointments = () => {
  return doGet(BASE_URL + "appointments");
};

export const getCompletedAppointments = () => {
  return doGet(BASE_URL + "appointments/completed");
};
export const getAppointment = (id) => {
  return doGet(BASE_URL + "appointments/" + id);
};
export const getAvailablePayoutMethods = () => {
  return doGet(BASE_URL + "users/available_payout_methods");
};
export const claimAppointment = (id) => {
  return doPost(BASE_URL + `appointments/${id}/confirm`);
};

export const getClaimedAppointments = () => {
  return doGet(BASE_URL + "appointments/confirmed");
};
export const getUserAndRoasterAgentList = () => {
  return doGet(BASE_URL + "GetAgentsList");
};
export const getMyFavouriteAgents = () => {
  return doGet(BASE_URL + "GetMyFavouriteAgents");
};
export const getMyBlockedAgents = () => {
  return doGet(BASE_URL + "GetMyBlockedAgents");
};
export const getNotifications = () => {
  return doGet(BASE_URL + "notifications");
};
export const getPaymentMethods = () => {
  return doGet(BASE_URL + "users/list_all_cards");
};
export const getMyInvitedAgents = () => {
  return doGet(BASE_URL + "GetInviteesList");
};
export const deleteFavoriteAgent = (id) => {
  return doPost(BASE_URL + `DeleteFavoriteAgent`, { user_id: id });
};
export const removeBlockAgent = (id) => {
  return doPost(BASE_URL + `RemoveBlockAgent`, { blocked_agent_id: id });
};
export const deleteNotifications = (ids) => {
  return doDelete(BASE_URL + `notifications/setDelete`, ids);
};
export const deleteCard = (cardId) => {
  return doDelete(BASE_URL + `users/delete_card`, { card_id: cardId });
};
export const defaultCard = (cardId) => {
  return doPost(BASE_URL + `users/set_default_card`, { card_id: cardId });
};
export const addCard = (cardDetails) => {
  return doPost(BASE_URL + `users/create_token_add_card`, cardDetails);
};
export const updateCard = (cardDetails) => {
  return doPost(BASE_URL + `users/update_card`, cardDetails);
};
export const readNotifications = (ids) => {
  return doPost(BASE_URL + `notifications/setRead`, ids);
};
export const inviteRosterAgent = (id) => {
  return doPost(BASE_URL + `InviteRosterAgent`, { roster_id: id });
};
export const resendInviteRosterAgent = (id) => {
  return doPost(BASE_URL + `ResendInviteRosterAgent`, { roster_id: id });
};
export const addFavoriteAgents = (ids) => {
  return doPost(BASE_URL + `AddFavoriteAgents`, { user_id: ids });
};
export const getMyClaimedAppointments = (id) => {
  return doPost(BASE_URL + `appointments/my-claimed-appointments`, { id: id });
};

export const cancelAppointment = (id, bodyParams) => {
  return doPost(BASE_URL + `appointments/${id}/cancel`, bodyParams);
};
export const completeAppointment = (
  id,
  feedbackText,
  selectedAddressIds,
  isInstantPayout = false
) => {
  return doPost(BASE_URL + `appointments/${id}/complete`, {
    id: id,
    feedback_note: feedbackText,
    listings_id: selectedAddressIds,
    isInstantPayout: isInstantPayout,
  });
};

export const deleteAppointment = (id, bodyParams) => {
  return doDelete(BASE_URL + `appointments/${id}`, bodyParams);
};
export const editAppointment = (appointment, id) => {
  return doPut(BASE_URL + `appointments/${id}`, appointment);
};

/* Setup Radius */
export const addAddress = (addressDetails) => {
  return doPost(BASE_URL + `add_base_location`, addressDetails);
};
export const updateAddress = (addressDetails) => {
  return doPost(BASE_URL + `update_base_location`, addressDetails);
};
export const updateRadiusValue = (radiusDetails) => {
  return doPost(BASE_URL + `Updateradius`, radiusDetails);
};
export const deleteRadiusAddress = (addressId) => {
  return doPost(
    BASE_URL + `deleteAgentBaseLocation?AgentBaseLocID=` + addressId,
    {}
  );
};
export const getBaseLocations = () => {
  return doGet(BASE_URL + "get_base_location");
};

export const updateBaseLocations = (location) => {
  return doPost(BASE_URL + "add_gps_tracking", location);
};

/*
this Change get list address*/
export const getListingsForAppointment = (appointmentID) => {
  return doGet(`${BASE_URL}listings/?appointment_id=${appointmentID}`);
};
/*End here*/

// Block an Agent
export const getBlockAgentReasonList = () => {
  return doGet(BASE_URL + "BlockAgentReasonList");
};

export const blockAndReportAgent = (blockDetails) => {
  console.log('params=',blockDetails)
  return doPost(BASE_URL + "BlockAnAgent",blockDetails);
};

export const getAgentListToBlock = () => {
  return doGet(BASE_URL + "getAllAgentsExceptBlockAgents");
};