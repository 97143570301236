import { BASE_URL, doPost, doPut, doGet, doPostForPassword, doPostFormData } from "../api";

export const login = (email, password, deviceInfo) => {
  return doPost(BASE_URL + "login", {
    user: { email, password },
    devise_info: deviceInfo,
  });
};

export const findAgent = (user) => {
  var brokerage_user = {
    grec_id: user.grec_id,
    brokerage_id: user.brokerage_id,
  };
  return doPost(BASE_URL + "brokerage_users/search-brokerage", {
    brokerage_user,
  });
};

export const createUser = (user) => {
  return doPost(BASE_URL + "signup", { user });
};
export const updatePassword = (email, password) => {
  return doPostForPassword(BASE_URL + "login", { user: { email, password } });
};
export const validateUserApi = (user) => {
  return doPost(BASE_URL + "brokerage_users/genreate-otp", {
    brokerage_id: user.brokerageID,
    license_number: user.grecID,
    email: user.email,
  });
};
export const getAllBrokerages = () => {
  return doGet(BASE_URL + `master_brokerages/get-all-brokerages`);
};
export const getAppVersion = () => {
  return doGet(BASE_URL + `versions/get_version`);
};

export const updateUser = (user) => {
  return doPut(BASE_URL + "users/" + user.id, { user });
};

export const updateUserCoords = (user, location) => {
  console.log("updateUserCoords user=",user);
  console.log("updateUserCoords location=",location);
  return doPut(BASE_URL + "userslatlong/" + user.id, { 
   lat: location.lat,
   lng: location.lng
   });
};

export const deleteUser = () => {
  return doPost(BASE_URL + "users/deactivate_user",{});
};
export const isCheckStripeDone = (id) => {
  return doPost(BASE_URL + "users/get-user-info", { id });
};
export const addPaymentMethod = (token) => {
  return doPost(BASE_URL + "users/add-payment-method", { token });
};

export const getStripeLoginURL = () => {
  return doGet(BASE_URL + "users/get-login-link");
};
export const getUsers = (lat, lng) => {
  return doGet(BASE_URL + `users?lat=${lat}&lng=${lng}`);
};

export const getUser = (id) => {
  return doGet(BASE_URL + `users/${id}`);
};

export const getNotificationUnreadCount = () => {
  return doGet(BASE_URL + `notifications/unread_count`);
};

export const requestResetPassword = (email) => {
  return doPost(BASE_URL + "users/request-reset-password", { email });
};

export const shareLogFile = (id, file) => {
  return doPostFormData(BASE_URL + `users/send_email_LogFile/${id}`, file );
};

export const resetPassword = ({
  resetPasswordKey,
  password,
  passwordConfirmation,
  email,
}) => {
  return doPost(BASE_URL + "users/reset-password", {
    reset_password_key: resetPasswordKey,
    password: password,
    password_confirmation: passwordConfirmation,
    email: email,
  });
};
