import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  headerImage: {
    height: 80,
    alignSelf: "center",
  },
  topView: {
    flexDirection: "row",
    marginTop: "5%",
    marginLeft: "5%",
  },
  accountProfile: {
    color: "#4990C8",
    fontSize: 30,
    fontFamily: "RobotoCondensed-Bold",
    fontWeight: "bold",
  },
  cardView: {
    backgroundColor: "#D1E3F1",
    width: "90%",
    borderColor: "#D1E3F1",
    borderRadius: 25,
    borderWidth: 1,
    marginLeft: "5%",
    opacity: 0.8,
    marginTop: "3%",
  },
  cardView3: {
    flexDirection: "row",
    marginTop: "3%",
  },
  ovalImg: {
    height: 70,
    width: 70,
  },
  uploadProfile: {
    color: "black",
    fontFamily: "RobotoCondensed-Light",
    fontSize: 15,
  },

  lineargradientBtn: {
    width: 140,
    height: 50,
    borderRadius: 30,
    justifyContent: "center",
    borderWidth: 2,
    borderColor: "white",
    borderTopWidth: 0,
    shadowColor: "#2E363D99",
    shadowOffset: { width: 5, height: 5 },
    shadowOpacity: 0.8,
    shadowRadius: 10,
    elevation: 6,
  },
  btnView: {
    alignItems: "center",
    paddingBottom: 10,
    paddingTop: 10,
    zIndex: 1
  },
  next: {
    color: "#FFFFFF",
    alignSelf: "center",
    fontFamily: "RobotoCondensed-Regular",
    fontSize: 17,
  },
  bottomView: {
    backgroundColor: "#4990C8",
    height: 50,
    width: "100%",
    justifyContent: "center",
  },
  termStyles: {
    color: "#FFFFFF",
    fontSize: 20,
    alignSelf: "center",
    fontFamily: "RobotoCondensed-Regular",
  },
  textView: {
    borderColor: "#E41E2A",
    borderBottomWidth: 1,
    height: 40,
  },
  textView1: {
    borderColor: "#00467E",
    borderBottomWidth: 1,
    height: 40,
  },
  inputText: {
    height: 40,
    color: "#292B34",
    fontFamily: "RobotoCondensed-Light",
    fontSize: 15,
    paddingHorizontal: 10,
  },
  textImg: {
    height: 20,
    width: 20,
    marginTop: "2%",
  },
  btnWidth: {
    width: 140,
    zIndex:100
  },
});

export default styles;
