import {
  SET_MESSAGES,
  SET_MESSAGES_SUCCESS,
  CLEAR_MESSAGES_SUCCESS,
  CLEAR_MESSAGES,
  SET_PUSHNOTIFICATION_MESSAGES,
  SET_PUSH_MESSAGES,
  CLEAR_PUSH_MESSAGES,
  SET_READ_NOTIFICATION,
} from "./actions";

const initialState = {
  isUnRead: false,
  messages: [],
  messagesSuccess: [],
  pushMsgs: [],
  pushnotificationsMsgs: [], //PushNotification
};

// Reducers (Modifies The State And Returns A New State)
const messagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MESSAGES: {
      return {
        ...state,
        messages: action.messages,
      };
    }
    case SET_MESSAGES_SUCCESS: {
      return {
        ...state,
        messagesSuccess: action.messagesSuccess,
      };
    }
    case SET_PUSH_MESSAGES: {
      return {
        ...state,
        pushMsgs: [action.pushMsgs, ...state.pushMsgs],
        isUnRead: action.isUnRead,
      };
    }
    case SET_READ_NOTIFICATION: {
      return {
        ...state,
        isUnRead: action.isUnRead,
        ...state.isUnRead,
      };
    }
    case SET_PUSHNOTIFICATION_MESSAGES: {
      return {
        ...state,
        pushnotificationsMsgs: [
          action.pushnotificationsMsgs,
          ...state.pushnotificationsMsgs,
        ],
      };
    }
    case CLEAR_PUSH_MESSAGES: {
      return {
        ...state,
        pushMsgs: [],
      };
    }
    case CLEAR_MESSAGES: {
      return {
        ...state,
        messages: [],
      };
    }
    case CLEAR_MESSAGES_SUCCESS: {
      return {
        ...state,
        messagesSuccess: [],
      };
    }
    default: {
      return state;
    }
  }
};

export default messagesReducer;
