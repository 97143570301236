import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  ScrollView,
  Alert,
  Platform,
  Keyboard,
  Modal
} from "react-native";
import styles from "../Styles/AvaliableShowingstyle.js";
import { connect } from "react-redux";
// import NavigationService from "../NavigationService";
import {
  cancelAppointment,
  completeAppointment,
  deleteAppointment,
} from "../redux/appointments/actions";
import { store } from "../redux/store";
// import Modal from "react-native-modal";
import Title from "./CreateAppointment/Title";
import { ButtonOne } from "./Button";
// import { SvgXml } from "react-native-svg";
import { setErrors } from "../redux/errors/actions";
import CheckboxWithText from "./CheckboxWithText";
import ProcessLoadingIndicator from "../Screens/ProcessLoadingIndicator";

const SelectableAddress = ({ address, selected, onToggle, unitNumber }) => {
  return (
    <View
      style={[styles.selectCompletedProperty, { marginLeft: 5, padding: 5 }]}
    >
      <TouchableOpacity onPress={onToggle}>
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            flexWrap: "wrap",
            alignItems: "flex-start", // if you want to fill rows left to right
          }}
        >
          <View
            style={{
              width: "13%", // is 50% of container width
            }}
          >
            <CheckboxWithText text="" checked={selected} onPress={onToggle} />
          </View>
          <View
            style={{
              width: "87%", // is 50% of container width
            }}
          >
            <Text style={{ fontSize: 16 }}>{address}</Text>
            <View>
              <Text style={{ fontWeight: "500" }}>Unit(s) # {unitNumber}</Text>
            </View>
          </View>
        </View>
      </TouchableOpacity>
    </View>
  );
};

const SelectableCancelReason = ({ reason, selected, onToggle }) => {
  return (
    <View
      style={[styles.selectCompletedProperty, { marginLeft: 5, padding: 5 }]}
    >
      <TouchableOpacity onPress={onToggle}>
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            flexWrap: "wrap",
            alignItems: "flex-start", // if you want to fill rows left to right
          }}
        >
          <View
            style={{
              width: "13%", // is 50% of container width
            }}
          >
            <CheckboxWithText text="" checked={selected} onPress={onToggle} />
          </View>
          <View
            style={{
              width: "87%", // is 50% of container width
            }}
          >
            <Text style={{ fontSize: 16 }}>{reason}</Text>
          </View>
        </View>
      </TouchableOpacity>
    </View>
  );
};

const CancelAppointmentModal = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [loaderText, setLoaderText] = useState(
    "Processing request, please wait."
  );
  const [feedbackText, setFeedbackText] = useState("");
  const [isCancelReasonList, setIsCancelReasonList] = useState(false);
  const [selectedAddressIds, setSelectedAddressIds] = useState([]);
  const [selectedReasonId, setSelectedReasonId] = useState(null);
  const [selectedReasonText, setSelectedReasonText] = useState(null);
  const [isSelectAddressBoxOpened, setIsSelectAddressBoxOpened] =
    useState(true);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [cancelReasonList, setCancelReasonList] = useState(false);
  if (!props.appointment) {
    return <></>;
  }
  useEffect(() => {
    setSelectedAddressIds([]);
    setSelectedReasonId(null);
    setFeedbackText("");
    setSelectedReasonText(null);
    setIsSelectAddressBoxOpened(false);
    setIsCancelReasonList(false);
    let cancelReasonListArr = [...props.CancelReasonList];
    let crl = cancelReasonListArr.filter((reson) => {
      return props.appointment.userID == props.user.id
        ? reson.user_type == "HA"
        : reson.user_type == "SA";
    });
    setCancelReasonList(crl);
    if (props.appointment.userID == props.user.id) {
      if (props.appointment.dataListings.length > 1)
        setIsSelectAddressBoxOpened(true);
      else {
        if (
          props.appointment.dataListings &&
          props.appointment.dataListings.length > 0
        )
          setSelectedAddressIds([props.appointment.dataListings[0].id]);
        setIsCancelReasonList(true);
      }
    } else {
      setIsCancelReasonList(true);
    }
  }, [props.showModal]);
  const handleCheckBoxPress = (id) => {
    let adrs = [...selectedAddressIds];
    if (selectedAddressIds.indexOf(id) === -1) {
      adrs.push(id);
    } else {
      adrs = selectedAddressIds.filter(function (item) {
        return item !== id;
      });
    }
    setSelectedAddressIds(adrs);
    adrs.length == props.appointment.dataListings.length
      ? setIsSelectAll(true)
      : setIsSelectAll(false);
  };
  const handleRadioBoxPress = (id, reason) => {
    setSelectedReasonId(id);
    setSelectedReasonText(reason);
  };
  const selectAllCheckbox = () => {
    let adrs = [];
    if (!isSelectAll) {
      props.appointment.dataListings.map((listing) => {
        adrs.push(listing.id);
      });
    }
    setSelectedAddressIds(adrs);
    setIsSelectAll(!isSelectAll);
  };

  const cancelAppointment = (id) => {
    setIsLoading(true);
    setLoaderText("Processing request, please wait.");
    let bodyParams = {
      id: id,
      cancel_reason_id: selectedReasonId,
      other_note: feedbackText,
    };
    props.cancelAppointment(id, bodyParams).then((r) => {
      setIsLoading(false);
      props.closeModal();
      if (r.status === 200 || r.status === "ok") {
        // NavigationService.navigate("AgentPortal");
      } else if (r.status == "error") {
        store.dispatch(setErrors(response.errors));
      }
    });
  };
  const deleteAppointment = (id) => {
    setIsLoading(true);
    setLoaderText("Processing request, please wait.");
    let bodyParams = {
      id: id,
      listings_id: selectedAddressIds,
      cancel_reason_id: selectedReasonId,
      other_note: feedbackText,
    };
    props.deleteAppointment(id, bodyParams).then((r) => {
      setIsLoading(false);
      props.closeModal();
      if (r.status === 200 || r.status === "ok") {
        // NavigationService.navigate("AgentPortal");
      } else if (r.status == "error") {
        store.dispatch(setErrors(response.errors));
      }
    });
  };
  return (
    <View>
      <Modal isVisible={props.showModal}>
        {isLoading && (
          <View style={{ height: 50 }}>
            <ProcessLoadingIndicator message={loaderText} backColor="" />
          </View>
        )}
        {isCancelReasonList && (
          <View style={styles.feedbackModal}>
            <TouchableOpacity
              style={{
                marginTop: 5,
                padding: 5,
                right: 15,
                position: "absolute",
              }}
              onPress={props.closeModal}
            >
              {/* <SvgXml
                width={27}
                height={27}
                xml={`<svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Close</title><path fill="none" stroke="#DC612F" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M368 368L144 144M368 144L144 368"/></svg>`}
              /> */}
            </TouchableOpacity>
            <Title
              style={{ marginTop: "10%" }}
              fontSize={27}
              text={"Cancelation Reason"}
            />
            <View style={{ marginTop: "10%", height: 250 }}>
              <ScrollView>
                {cancelReasonList &&
                  cancelReasonList.map((item, i) => {
                    if (item != undefined) {
                      return (
                        <SelectableCancelReason
                          key={i}
                          id={item.id}
                          index={i}
                          reason={item.reason}
                          selected={selectedReasonId == item.id ? true : false}
                          onToggle={() => {
                            handleRadioBoxPress(item.id, item.reason);
                          }}
                        />
                      );
                    }
                  })}
              </ScrollView>
            </View>
            {selectedReasonText == "Other" && (
              <View>
                <View style={styles.backgroundViewCancelReasonNotes}>
                  <TextInput
                    style={styles.inputTextArea}
                    multiline={true}
                    maxLength={100}
                    placeholder={"Provide reason here"}
                    placeholderTextColor="black"
                    onChangeText={(notes) => {
                      setFeedbackText(notes);
                    }}
                    value={feedbackText}
                    autoCapitalize="none"
                    returnKeyLabel="Done"
                    returnKeyType="done"
                    scrollEnabled={false}
                    onSubmitEditing={Keyboard.dismiss}
                    keyboardType="default"
                  />
                </View>
                <Text
                  style={{ color: "#DC612F", marginLeft: 20, marginTop: 10 }}
                >
                  * Other reason field required{" "}
                </Text>
              </View>
            )}
            <View>
              <View style={{ marginTop: 15, marginBottom: 35, marginLeft: 55 }}>
                {!isLoading && (
                  <ButtonOne
                    text={"CONFIRM & CANCEL"}
                    style={{ width: "80%" }}
                    onPress={() => {
                      if (selectedReasonId) {
                        if (
                          selectedReasonText == "Other" &&
                          feedbackText.trim() == ""
                        ) {
                        } else {
                          Alert.alert(
                            "Confirm Canceling Appointment",
                            "Are you sure you want to cancel this appointment?",
                            [
                              {
                                text: "Yes",
                                onPress: () => {
                                  props.appointment.userID == props.user.id
                                    ? deleteAppointment(props.appointment.id)
                                    : cancelAppointment(props.appointment.id);
                                },
                              },
                              {
                                text: "No",
                                onPress: () => {},
                                style: "cancel",
                              },
                            ]
                          );
                        }
                      }
                    }}
                  />
                )}
              </View>
            </View>
          </View>
        )}
        {isSelectAddressBoxOpened && (
          <View style={styles.selectPropertyModal}>
            <TouchableOpacity
              style={{
                marginTop: 5,
                padding: 5,
                right: 15,
                position: "absolute",
              }}
              onPress={props.closeModal}
            >
              {/* <SvgXml
                width={27}
                height={27}
                xml={`<svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Close</title><path fill="none" stroke="#DC612F" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M368 368L144 144M368 144L144 368"/></svg>`}
              /> */}
            </TouchableOpacity>
            <Text
              style={{
                color: "#DC612F",
                padding: 5,
                marginTop: "22%",
                marginBottom: 20,
                right: 15,
                position: "absolute",
                zIndex:1000
              }}
              onPress={() => selectAllCheckbox()}
            >
              {selectedAddressIds.length ==
              props.appointment.dataListings.length
                ? "Unselect all"
                : "Select all"}
            </Text>
            <Title
              style={{ marginTop: "10%" }}
              fontSize={27}
              text={"Cancel Selected Properties"}
            />
            <View style={{ marginTop: "13%", height: Platform.OS === "android" ? "72%" : "75%" }}>
              <ScrollView>
                {props.appointment.dataListings.map((address, i) => {
                  if (address != undefined) {
                    return (
                      <SelectableAddress
                        key={i}
                        id={address.id}
                        index={i}
                        address={address.address}
                        unitNumber={address.unit_number}
                        selected={
                          selectedAddressIds.indexOf(address.id) === -1
                            ? false
                            : true
                        }
                        onToggle={() => {
                          handleCheckBoxPress(address.id);
                        }}
                      />
                    );
                  }
                })}
              </ScrollView>
              <View style={{ marginTop: 20, marginLeft: 55 }}>
                <ButtonOne
                  text={"CONFIRM & CANCEL"}
                  style={{ width: "80%" }}
                  onPress={() => {
                    if (selectedAddressIds.length > 0) {
                      setIsCancelReasonList(true);
                      setIsSelectAddressBoxOpened(false);
                    }
                  }}
                />
              </View>
            </View>
          </View>
        )}
      </Modal>
    </View>
  );
};

CancelAppointmentModal.defaultProps = {
  showModal: false,
};

export default connect(
  (state) => ({
    user: state.auth.user,
    CancelReasonList: state.app.CancelReasonList,
  }),
  (dispatch) => ({
    completeAppointment: (id, feedbackText, selectedAddressIds) =>
      dispatch(completeAppointment(id, feedbackText, selectedAddressIds)),
    cancelAppointment: (id, bodyParams) =>
      dispatch(cancelAppointment(id, bodyParams)),
    deleteAppointment: (id, bodyParams) =>
      dispatch(deleteAppointment(id, bodyParams)),
  })
)(CancelAppointmentModal);
