import * as api from "./api";
import { DeviceEventEmitter } from "react-native";
// import LogsService from "../../LogsService";

export const SET_LOADING = "SET_LOADING";
export const ADD_CURRENT_LISTING = "ADD_CURRENT_LISTING";
export const RECEIVE_UPDATE_LISTING = "RECEIVE_UPDATE_LISTING";
export const UPDATE_CURRENT_LISTINGS = "UPDATE_CURRENT_LISTINGS";
export const ADD_PROPERTY_LISTING_TYPE = "ADD_PROPERTY_LISTING_TYPE";
export const ADD_TIME_ZONE = "ADD_TIME_ZONE";
export const SAVE_RUNNER_DESCRIPTION = "SAVE_RUNNER_DESCRIPTION";
export const STORE_RUNNER_DESCRIPTION = "STORE_RUNNER_DESCRIPTION";
export const STORE_RUNNER_DESCRIPTION_NOTE = "STORE_RUNNER_DESCRIPTION_NOTE";
export const STORE_PREVIOUS_PROPERTY_LISTING =
  "STORE_PREVIOUS_PROPERTY_LISTING";

export const setLoading = (loading) => {
  return {
    type: SET_LOADING,
    loading,
  };
};

export const addPropertyListingType = (propertylistingtype) => {
  return {
    type: ADD_PROPERTY_LISTING_TYPE,
    propertylistingtype,
  };
};
export const addTimeZone = (timezone) => {
  return {
    type: ADD_TIME_ZONE,
    timezone,
  };
};

export const addCurrentListing = (listing) => {
  return {
    type: ADD_CURRENT_LISTING,
    listing,
  };
};

export const receiveUpdateListing = (listing) => {
  return {
    type: RECEIVE_UPDATE_LISTING,
    listing,
  };
};

export const clearCurrentListings = () => {
  return {
    type: UPDATE_CURRENT_LISTINGS,
    current: [],
  };
};

export const storePreviousPropertyList = (storeaddress) => {
  return {
    type: STORE_PREVIOUS_PROPERTY_LISTING,
    storeaddress,
  };
};

export const storeRunnerDescription = (storeListing) => {
  return {
    type: STORE_RUNNER_DESCRIPTION,
    storeDescription: storeListing,
  };
};

export const storeRunnerDescriptionNote = (storeListingNote) => {
  return {
    type: STORE_RUNNER_DESCRIPTION_NOTE,
    storeDescriptionNote: storeListingNote,
  };
};

export const saveRunnerDescription = (listing) => {
  return {
    type: SAVE_RUNNER_DESCRIPTION,
    runnerDescription: listing,
  };
};

export const addAddressByMLSID = (mlsID) => {
  LogsService.fileLog('redux/address/actions.js','addAddressByMLSID', 'addAddressByMLSID clicked with ID -> ' + mlsID);
  LogsService.fileLog('redux/address/actions.js','addAddressByMLSID', 'getAddressByMLSID API calling');
  return api.getAddressByMLSID(mlsID);
};

export const createListingForUnitNo = (listing) => {
  LogsService.fileLog('redux/address/actions.js','createListingForUnitNo', 'createListingForUnitNo is clicked');
  return (dispatch) => {
    dispatch(setLoading(true));
    LogsService.fileLog('redux/address/actions.js','createListingForUnitNo', 'createListingForUnitNo API is calling');
    api.createListingForUnitNoApi(listing).then((response) => {
      LogsService.fileLog('redux/address/actions.js','createListingForUnitNo response', JSON.stringify(response));
      if (response.status === "error") {
        return;
      }
      dispatch(setLoading(false));
    });
  };
};
export const createListing = (listing) => {
  LogsService.fileLog('redux/address/actions.js','createListing', 'createListing is called');
  return (dispatch) => {
    dispatch(setLoading(true));
    api.createListing(listing).then((response) => {
      LogsService.fileLog('redux/address/actions.js','createListing response', JSON.stringify(response));
      if (response.status === "error") {
        return;
      }
      dispatch(setLoading(false));
      dispatch(addCurrentListing(response.listing));
    });
  };
};

export const createAddressListing = (listing, location, id) => {
  LogsService.fileLog('redux/address/actions.js','createAddressListing', 'createAddressListing is clicked');
  return (dispatch) => {
    dispatch(setLoading(true));
    // api.createListing(listing).then((response) => {
    //   if (response.status === "error") {
    //     return;
    //   }
      dispatch(setLoading(false));
      let address = listing;
      address.id= id;
      address.lat = location.lat;
      address.lng = location.lng;
      dispatch(addCurrentListing(address));
      LogsService.fileLog('redux/address/actions.js','createAddressListing', 'Stored Address in redux => ' + JSON.stringify(address));
    // });
  };
};

export const checkListingUpdate = (listing) => {
  LogsService.fileLog('redux/address/actions.js','checkListingUpdate', 'checkListingUpdate is clicked');
  return (dispatch) => {
    dispatch(addCurrentListing(listing.addressesmls));
  };
};

export const updateListing = (listingID, applicationID) => {
  LogsService.fileLog('redux/address/actions.js','updateListing', 'updateListing is clicked');
  return (dispatch) => {
    dispatch(setLoading(true));
    LogsService.fileLog('redux/address/actions.js','updateListing', 'updateListing API is calling with listingID=' + listingID + " & applicationID=" + applicationID);
    api.updateListing(listingID, applicationID).then((response) => {
      LogsService.fileLog('redux/address/actions.js','updateListing response=', JSON.stringify(response));
      if (response.status === "error") {
        return;
      }
      dispatch(setLoading(false));
      dispatch(receiveUpdateListing(response.listing));
    });
  };
};

export const updateNewListing = (listingID, applicationID) => {
  LogsService.fileLog('redux/address/actions.js','updateNewListing', 'updateNewListing is called');
  return (dispatch) => {
    dispatch(setLoading(true));
    LogsService.fileLog('redux/address/actions.js','updateListing', 'updateNewListing API is calling with listingID=' + listingID + " & applicationID=" + applicationID);
    api.updateNewListing(listingID, applicationID).then((response) => {
      LogsService.fileLog('redux/address/actions.js','updateNewListing response=', JSON.stringify(response));
      dispatch(setLoading(false));
      if (response.status === "error") {
        return;
      }
      response.listing.map((listing) => {
        dispatch(receiveUpdateListing(listing));
      });
    });
  };
};

export const getListingsForAppointment = (appointmentID) => {
  LogsService.fileLog('redux/address/actions.js','getListingsForAppointment', 'getListingsForAppointment is called');
  return (dispatch) => {
    dispatch(setLoading(true));
    LogsService.fileLog('redux/address/actions.js','updateListing', 'getListingsForAppointment API is calling with appointmentID=' + appointmentID);
    api.getListingsForAppointment(appointmentID).then((response) => {
      LogsService.fileLog('redux/address/actions.js','getListingsForAppointment response=', JSON.stringify(response));
      if (response.status === "error") {
        return;
      }
      dispatch(setLoading(false));
      response.listings.map((listing) =>
        dispatch(receiveUpdateListing(listing))
      );
    });
  };
};

export const getRunnerActivity = () => {
  LogsService.fileLog('redux/address/actions.js','getRunnerActivity', 'getRunnerActivity is called');
  return (dispatch) => {
    dispatch(setLoading(true));
    LogsService.fileLog('redux/address/actions.js','getRunnerActivity', 'getRunnerActivityList API is calling');
    api.getRunnerActivityList().then((response) => {
      LogsService.fileLog('redux/address/actions.js','getRunnerActivityList response=', JSON.stringify(response));
      if (response.status === "error") {
        return;
      }
      dispatch(setLoading(false));
      var list = response['RunnerActivityList'] || [];
      DeviceEventEmitter.emit('RunnerActivityList', list);
      dispatch(saveRunnerDescription(list))
    });
  };
};
