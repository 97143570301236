import React from "react";
import { View, Text } from "react-native";

const styles = {
  container: {
    marginLeft: "8%",
    marginTop: 10,
  },
  textBox: {
    color: "#4990C8",
    fontSize: 30,
    fontFamily: "RobotoCondensed-Bold",
    fontWeight: "bold",
  },
};
export default function (props) {
  return (
    <View style={{ ...styles.container, ...(props.style || {}) }}>
      <Text style={styles.textBox}>{props.text}</Text>
    </View>
  );
}
