import React from "react";
import {
  View,
  Text,
  ImageBackground,
  TouchableOpacity,
  TextInput,
  Dimensions,
  Linking,
  Image,
  Platform,
  // BackHandler,
  Alert,
  DeviceEventEmitter,
} from "react-native";
import styles from "../Styles/LoginStyles";
import { ButtonBold } from "../Components/Button";
import CheckboxWithText from "../Components/CheckboxWithText";
import Mail from "../Images/Mail";
import PwdKey from "../Images/PwdKey";
import Eyes from "../Images/Eyes";
import Eyesoff from "../Images/Eyesoff";
import BaseLayout from "./BaseLayout";
// import { NavigationEvents } from "react-navigation";
import {
  loginUser,
  setRememberMe,
  logoutUser,
  setLoading,
} from "../redux/auth/actions";
import { APIBaseURLSetup, setAPIBaseURL } from "../redux/app/actions";
import { connect } from "react-redux";
import { store } from "../redux/store";
import DeviceInfo from "react-native-device-info";
import { setErrors } from "../redux/errors/actions";
import LogsService from "../LogsService";
import AsyncStorage from "@react-native-async-storage/async-storage";
import moment, { utc } from "moment-timezone";
import {
  logFileDeleteTimingsHours,
  logFileDeleteTimingsMinues,
  logFileDeleteIntervalPeriod,
  // getLastLogFileDeleted,
  // setLastLogFileDeleted
} from "../../config";

class Login extends React.Component {
  backAction = () => {
    if (this.props.navigation.isFocused()) {
      Alert.alert("Hold on!", "Are you sure you want to close the app?", [
        {
          text: "Cancel",
          onPress: () => null,
          style: "cancel",
        },
        // { text: "YES", onPress: () => BackHandler.exitApp() },
      ]);
      return true;
    } else {
      return false;
    }
  };
  static navigationOptions = ({ navigation }) => ({
    header: null,
  });

  getUrlFromBackEnd = async() =>{
    // const data = await fetch('https://api.keypleaz.com:444/APIBaseURL/getAPIBaseURL?appVersion=3.14');
    // const response = data.json()
    // console.log('responseurl----',response)

    const data = await fetch('https://api.keypleaz.com:444/APIBaseURL/getAPIBaseURL?appVersion=3.14', {
      method: "GET",
      mode:'no-cors',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    console.log('responseurl----',data)
  }


  componentDidMount() {
    // this.backHandler = BackHandler.addEventListener(
    //   "hardwareBackPress",
    //   this.backAction
    // );
    // this.checkForLogFileDelete();
    // this.getUrlFromBackEnd();
    // LogsService.fileLog("Login", "componentDidMount", "component initiated");
    // DeviceEventEmitter.addListener("notificationToken", (res) => {
    //   var deviceInfo = this.state.deviceInfo;
    //   if (res.token) {
    //     var device_info = { ...this.state.deviceInfo };
    //     device_info.notification_token = res.token;
    //     this.setState({ deviceInfo: device_info });
    //   }
    // });
  }

  /**
   * checkForLogFileDelete()
   * Delete log file at specific time interval
   */
  checkForLogFileDelete = async () => {
    var recevieddate = JSON.parse(
      await AsyncStorage.getItem("lastLogFileDeletedTime")
    );
    recevieddate = moment(recevieddate)
      .add(logFileDeleteIntervalPeriod, "d")
      .toDate(); // Adding logFileDeleteIntervalPeriod days into last deleted file date
    if (logFileDeleteTimingsHours)
      recevieddate.setHours(logFileDeleteTimingsHours); // Adding logFileDeleteTimingsHours hours into last deleted file date
    if (logFileDeleteTimingsMinues)
      recevieddate.setMinutes(logFileDeleteTimingsMinues); // Adding logFileDeleteTimings Minutes into last deleted file date
    recevieddate.setSeconds(0); // Adding logFileDeleteTimings Seconds into last deleted file date
    var fileDate = moment(recevieddate).format("YYYY-MM-DD HH:mm:ss");
    var fileModifiedDate = new Date();
    var todayDate = new Date();
    if (fileDate) {
      var dateArray = fileDate.split(" ");
      if (dateArray.length > 0) {
        var splitDateArray = dateArray[0].split("-");
        var splitTimeArray = dateArray[1].split(":");
        fileModifiedDate.setFullYear(splitDateArray[0]);
        fileModifiedDate.setMonth(splitDateArray[1] - 1);
        fileModifiedDate.setDate(splitDateArray[2]);
        fileModifiedDate.setHours(splitTimeArray[0]);
        fileModifiedDate.setMinutes(splitTimeArray[1]);
        fileModifiedDate.setSeconds(splitTimeArray[2]);
        if (todayDate.getTime() > fileModifiedDate.getTime()) {
          LogsService.deleteFile(); // Delete the log file
          AsyncStorage.setItem(
            "lastLogFileDeletedTime",
            JSON.stringify(new Date())
          ); // Store the file deleted timings into storage
        }
      }
    } else {
      console.log("else");
      AsyncStorage.setItem(
        "lastLogFileDeletedTime",
        JSON.stringify(new Date())
      ); // Store the file deleted timings into storage
    }
  };

  componentWillUnmount() {
    // this.backHandler.remove();
    DeviceEventEmitter.removeListener("notificationToken");
  }

  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      setPasswordView: true,
      deviceInfo: {
        app_version: DeviceInfo.getVersion(),
        platform: Platform.OS,
        // platform_version:
        //   Platform.OS == "ios"
        //     ? Platform.constants.osVersion
        //     : Platform.constants.Release,
        devise_model: DeviceInfo.getModel(),
        manufacturer: "",
        notification_token: props.pushnotificationToken.token,
      },
    };
    console.log("TOKEN Login=", props.pushnotificationToken.token);

    DeviceInfo.getManufacturer().then((manufacturer) => {
      var deviceInfo = { ...this.state.deviceInfo };
      deviceInfo.manufacturer = manufacturer;
      this.setState({ deviceInfo });
    });

    this.handlePress = this.handlePress.bind(this);
  }

  handlePress() {
    LogsService.fileLog(
      "Login",
      "handlePress",
      "handle press pressed for Keep me logged in"
    );
    this.props.setRememberMe(!this.props.rememberMe);
  }
  refreshButtonNavBack() {
    const { params } = this.props.navigation.state;
    const isSessionExpired = params ? params.isSessionExpired : null;
    if (isSessionExpired) {
      LogsService.fileLog("Login", "refreshButtonNavBack", "Session expired");
      this.props.logout();
      store.dispatch(setErrors(["Session Expired... Please login again"], 5));
      this.props.navigation.setParams({
        isSessionExpired: false,
      });
    } else {
      if (
        this.props.loggedIn &&
        this.props.user.token !== null &&
        this.props.rememberMe &&
        this.props.loggedInUser
      ) {
        LogsService.fileLog(
          "Login",
          "refreshButtonNavBack",
          "Navigating to AgentPortal"
        );
        // this.props.navigation.navigate("AgentPortal");
      }
    }
    APIBaseURLSetup().then((response) => {
      LogsService.fileLog(
        "Login",
        "refreshButtonNavBack -- APIBaseURLSetup()",
        JSON.stringify(response)
      );
      this.props.setAPIBaseURL(response.URL);
      console.log('response----',response)
    });
  }
  
  

  render() {
    const { width, height } = Dimensions.get("window");
    return (
      <BaseLayout>
        <View style={{ height: "100%", width: "100%" }}>
          {/* <NavigationEvents onDidFocus={() => this.refreshButtonNavBack()} /> */}
          <ImageBackground
            source={require("../../assets/loginBg.png")}
            style={styles.bgImage}
          >
            <Image
              source={require("../../assets/KeyPleazVertical.png")}
              style={styles.bgImage2}
            ></Image>
            <View style={[styles.cardViewLogin, { height: 400 }]}>
              <Text style={styles.heddingTxt}>Sign-In</Text>
              <View style={{ margin: "5%", marginTop: "2%" }}>
                <View style={styles.textView}>
                  <TextInput
                    style={styles.inputTextLogin}
                    placeholder="agent@brokerage.com"
                    placeholderTextColor="black"
                    onChangeText={(email) => this.setState({ email })}
                    value={this.state.email}
                    autoCapitalize="none"
                  />

                  <Mail
                    style={{ marginTop: 15, right: 1, position: "absolute" }}
                  />
                </View>
                <View style={styles.textView}>
                  <TextInput
                    style={styles.inputTextLogin}
                    placeholder="•••••••••"
                    placeholderTextColor="black"
                    onChangeText={(password) =>
                      this.setState({ password: password })
                    }
                    value={this.state.password}
                    secureTextEntry={this.state.setPasswordView}
                    autoCapitalize="none"
                  />
                  <PwdKey
                    style={{ marginTop: 15, right: 1, position: "absolute" }}
                  />

                  {!this.state.setPasswordView && (
                    <TouchableOpacity
                      onPress={() => {
                        LogsService.fileLog(
                          "Login",
                          "Eyes -- onPress",
                          "Hide password pressed"
                        );
                        this.setState({ setPasswordView: true });
                      }}
                      style={{ marginTop: 8, right: 30, position: "absolute" }}
                    >
                      <Eyes />
                    </TouchableOpacity>
                  )}
                  {this.state.setPasswordView && (
                    <TouchableOpacity
                      onPress={() => {
                        LogsService.fileLog(
                          "Login",
                          "EyesOff -- onPress",
                          "Show password pressed"
                        );
                        this.setState({ setPasswordView: false });
                      }}
                      style={{ marginTop: 8, right: 30, position: "absolute" }}
                    >
                      <Eyesoff />
                    </TouchableOpacity>
                  )}
                </View>
                <View
                  style={{ flexDirection: "row", alignItems: "flex-start" }}
                >
                  <CheckboxWithText
                    onPress={this.handlePress}
                    checked={this.props.rememberMe}
                    text="Keep me logged in"
                    textStyle={styles.loggedTxt}
                    style={{
                      marginTop: 8,
                    }}
                  />
                </View>
              </View>

              <View style={styles.btnView}>
                {/* <Eyesoff /> */}
                <ButtonBold
                  text={"LOGIN"}
                  style={styles.btnWidth}
                  disabled={this.props.loading}
                  onPress={() => {
                    this.props.loader(true);
                    LogsService.fileLog(
                      "Login",
                      "LOGIN -- onPress",
                      "Login button pressed"
                    );
                    if (this.props.APIBaseURL) {
                      LogsService.fileLog(
                        "Login",
                        "LOGIN -- onPress",
                        this.props.APIBaseURL
                      );
                      this.props.login(
                        this.state.email.trim(),
                        this.state.password,
                        this.state.deviceInfo
                      );
                    } else {
                      APIBaseURLSetup().then((response) => {
                        LogsService.fileLog(
                          "Login",
                          "LOGIN -- onPress -- APIBaseURLSetup()",
                          JSON.stringify(response)
                        );
                        this.props.setAPIBaseURL(response.URL);
                        this.props.login(
                          this.state.email.trim(),
                          this.state.password,
                          this.state.deviceInfo
                        );
                      });
                    }
                  }}
                />
              </View>
              <TouchableOpacity
                onPress={() => {
                  LogsService.fileLog(
                    "Login",
                    "onPress",
                    "Forgot Password pressed."
                  );
                  this.props.navigation.navigate("ForgotPassword");
                }}
              >
                <Text style={styles.forgetTxt}>Forgot Password?</Text>
              </TouchableOpacity>
              <View
                style={{
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <View
                  style={{
                    backgroundColor: "#D6DBDF",
                    width: 55,
                    height: 2,
                    marginTop: "5%",
                    borderRadius: 10,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                ></View>
              </View>
              <View>
                <Text style={styles.joinTxt}>New to KeyPleaz®?</Text>
              </View>

              <View style={styles.btnView}>
                <ButtonBold
                  text={"SIGN-UP HERE"}
                  style={styles.btnWidth}
                  onPress={() => {
                    LogsService.fileLog(
                      "Login",
                      "onPress",
                      "SIGN-UP HERE pressed."
                    );
                    this.props.navigation.navigate("WelcomeScreen");
                  }}
                />
              </View>
            </View>

            <View style={styles.patentPending}>
              <TouchableOpacity
                style={{ flexDirection: "row", alignSelf: "center" }}
                onPress={() => {
                  LogsService.fileLog(
                    "Login",
                    "Learn More. -- onPress",
                    "Learn More pressed and opening http://www.keypleaz.com"
                  );
                  Linking.openURL("http://www.keypleaz.com");
                }}
              >
                <View style={{ flexDirection: "row" }}>
                  <Text
                    style={[
                      styles.bottomViewTxt,
                      { color: "#A3C7E3", fontSize: 20 },
                    ]}
                  >
                    What is KeyPleaz®?{" "}
                  </Text>
                  <Text
                    style={[
                      styles.bottomViewTxt,
                      {
                        fontFamily: "RobotoCondensed-Bold",
                        color: "#FFFFFF",
                        fontWeight: "700",
                        fontSize: 22,
                        marginTop: "-0.7%",
                      },
                    ]}
                  >
                    Learn More.
                  </Text>
                </View>
              </TouchableOpacity>
            </View>
          </ImageBackground>
        </View>
      </BaseLayout>
    );
  }
}

export default connect(
  (state) => ({
    loggedIn: state.auth.loggedIn,
    loggedInUser: state.auth.loggedInUser,
    loading: state.auth.loading,
    user: state.auth.user,
    rememberMe: state.auth.rememberMe,
    APIBaseURL: state.app.APIBaseURL,
    pushnotificationToken: state.auth.pushnotificationToken,
  }),
  (dispatch) => ({
    loader: (isLoading) => dispatch(setLoading(isLoading)),
    login: (email, password, deviceInfo) =>
      dispatch(loginUser(email, password, deviceInfo)),
    setRememberMe: (rememberMe) => dispatch(setRememberMe(rememberMe)),
    logout: () => dispatch(logoutUser()),
    setAPIBaseURL: (APIBaseURL) => dispatch(setAPIBaseURL(APIBaseURL)),
  })
)(Login);
