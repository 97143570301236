import React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" {...props}>
      <Path
        d="M9.00004 0.166504C12.5859 0.166504 15.5 3.04817 15.5 6.61234C15.5 11.444 9.00004 18.5832 9.00004 18.5832C9.00004 18.5832 2.50004 11.444 2.50004 6.61234C2.50004 3.04817 5.41421 0.166504 9.00004 0.166504ZM9.00004 4.49984C8.4254 4.49984 7.8743 4.72811 7.46798 5.13444C7.06165 5.54077 6.83337 6.09187 6.83337 6.6665C6.83337 7.24114 7.06165 7.79224 7.46798 8.19857C7.8743 8.6049 8.4254 8.83317 9.00004 8.83317C9.57468 8.83317 10.1258 8.6049 10.5321 8.19857C10.9384 7.79224 11.1667 7.24114 11.1667 6.6665C11.1667 6.09187 10.9384 5.54077 10.5321 5.13444C10.1258 4.72811 9.57468 4.49984 9.00004 4.49984V4.49984ZM17.6667 18.5832C17.6667 20.9773 13.7884 22.9165 9.00004 22.9165C4.21171 22.9165 0.333374 20.9773 0.333374 18.5832C0.333374 17.1857 1.65504 15.9398 3.70254 15.149L4.39587 16.1348C3.22587 16.6223 2.50004 17.294 2.50004 18.0415C2.50004 19.5365 5.41421 20.7498 9.00004 20.7498C12.5859 20.7498 15.5 19.5365 15.5 18.0415C15.5 17.294 14.7742 16.6223 13.6042 16.1348L14.2975 15.149C16.345 15.9398 17.6667 17.1857 17.6667 18.5832Z"
        fill="#CD7047"
      />
    </Svg>
  );
}
export default SvgComponent;
