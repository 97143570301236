export const camelCaseUser = (user) => {
  const {
    accepting_showings,
    first_name,
    last_name,
    grec_id,
    brokerage_id,
    has_stripe_user,
    ...rest
  } = user;
  return Object.assign(
    {},
    {
      ...rest,
    },
    accepting_showings !== undefined && {
      acceptingShowings: accepting_showings,
    },
    first_name && { firstName: first_name },
    last_name && { lastName: last_name },
    grec_id && { grecID: grec_id },
    brokerage_id && { brokerageID: brokerage_id },
    has_stripe_user !== undefined && { hasStripeUser: has_stripe_user }
  );
};

export const snakeCaseUser = (user) => {
  const {
    acceptingShowings,
    firstName,
    lastName,
    grecID,
    brokerageID,
    passwordConfirmation,
    ...rest
  } = user;
  return Object.assign(
    {},
    {
      ...rest,
    },
    acceptingShowings !== undefined && {
      accepting_showings: acceptingShowings,
    },
    firstName && { first_name: firstName },
    lastName && { last_name: lastName },
    grecID && { grec_id: grecID },
    brokerageID && { brokerage_id: brokerageID },
    passwordConfirmation && { password_confirmation: passwordConfirmation }
  );
};
