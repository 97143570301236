import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  ScrollView,
  Alert,
  Keyboard,
} from "react-native";
import styles from "../Styles/AvaliableShowingstyle.js";
import { connect } from "react-redux";
// import NavigationService from "../NavigationService";
import {
  cancelAppointment,
  completeAppointment,
  deleteAppointment,
} from "../redux/appointments/actions";
import { store } from "../redux/store";
// import Modal from "react-native-modal";
import Title from "./CreateAppointment/Title";
import { ButtonOne } from "./Button";
// import { SvgXml } from "react-native-svg";
import { setErrors } from "../redux/errors/actions";
import CheckboxWithText from "./CheckboxWithText";
import ProcessLoadingIndicator from "../Screens/ProcessLoadingIndicator";

const SelectableAddress = ({ address, selected, onToggle, unitNumber }) => {
  return (
    <View
      style={[styles.selectCompletedProperty, { marginLeft: 5, padding: 5 }]}
    >
      <TouchableOpacity onPress={onToggle}>
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            flexWrap: "wrap",
            alignItems: "flex-start", // if you want to fill rows left to right
          }}
        >
          <View
            style={{
              width: "13%", // is 50% of container width
            }}
          >
            <CheckboxWithText text="" checked={selected} onPress={onToggle} />
          </View>
          <View
            style={{
              width: "87%", // is 50% of container width
            }}
          >
            <Text style={{ fontSize: 16 }}>{address}</Text>
            <View>
              <Text style={{ fontWeight: "500" }}>Unit(s) # {unitNumber}</Text>
            </View>
          </View>
        </View>
      </TouchableOpacity>
    </View>
  );
};

const CompleteAppointmentModal = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [loaderText, setLoaderText] = useState(
    "Processing request, please wait."
  );
  const [feedbackText, setFeedbackText] = useState("");
  const [isFeedbackBoxOpened, setIsFeedbackBoxOpened] = useState(false);
  const [selectedAddressIds, setSelectedAddressIds] = useState([]);
  const [isSelectAddressBoxOpened, setIsSelectAddressBoxOpened] =
    useState(true);
  const [isSelectAll, setIsSelectAll] = useState(false);
  if (!props.appointment) {
    return <></>;
  }
  useEffect(() => {
    setSelectedAddressIds([]);
    setFeedbackText("");
    setIsSelectAddressBoxOpened(false);
    setIsFeedbackBoxOpened(false);
    if (props.appointment.dataListings.length > 1) {
      setIsSelectAddressBoxOpened(true);
    } else {
      setIsFeedbackBoxOpened(true);
      // set propertId in case of only one
      if (
        props.appointment.dataListings &&
        props.appointment.dataListings.length > 0
      )
        setSelectedAddressIds([props.appointment.dataListings[0].id]);
    }
  }, [props.showModal]);
  const handleCheckBoxPress = (id) => {
    let adrs = [...selectedAddressIds];
    if (selectedAddressIds.indexOf(id) === -1) {
      adrs.push(id);
    } else {
      adrs = selectedAddressIds.filter(function (item) {
        return item !== id;
      });
    }
    setSelectedAddressIds(adrs);
    adrs.length == props.appointment.dataListings.length
      ? setIsSelectAll(true)
      : setIsSelectAll(false);
  };
  const selectAllCheckbox = () => {
    let adrs = [];
    if (!isSelectAll) {
      props.appointment.dataListings.map((listing) => {
        adrs.push(listing.id);
      });
    }
    setSelectedAddressIds(adrs);
    setIsSelectAll(!isSelectAll);
  };
  const completeAppointment = (id) => {
    setIsLoading(true);
    setLoaderText("Processing request, please wait.");
    props
      .completeAppointment(
        id,
        feedbackText.trim(),
        selectedAddressIds,
        props.isInstantPayout || false
      )
      .then((r) => {
        setIsLoading(false);
        setIsFeedbackBoxOpened(false);
        props.closeModal();
        if (r.status === 200 || r.status === "ok") {
          NavigationService.navigate("AgentPortal");
        } else if (r.status === "error") {
          store.dispatch(setErrors(response.errors));
        }
      });
  };
  const getPropertyListingTypeLabelText = (type) => {
    const propertyListingTypeArr = {
      HOMESHOWING: "Showing",
      OPENHOUSE: "Open House",
      RUNNER: "Runner",
    };
    return propertyListingTypeArr[type];
  };
  const getPropertyListingTypePlaceHolderText = (type) => {
    const propertyListingTypeArr = {
      HOMESHOWING:
        "Your feedback (i.e. not a fit for home buyer, didn't like floor plan)",
      OPENHOUSE: "Your feedback (i.e. Two interested buyers; 12 attended)",
      RUNNER: "Your feedback (i.e. Signs were placed as instructed)",
    };
    return propertyListingTypeArr[type];
  };
  return (
    <View>
      <Modal isVisible={props.showModal}>
        {isLoading && (
          <View style={{ height: 50 }}>
            <ProcessLoadingIndicator message={loaderText} backColor="" />
          </View>
        )}
        {isFeedbackBoxOpened && (
          <View style={styles.feedbackModal}>
            <TouchableOpacity
              style={{
                marginTop: 5,
                padding: 5,
                right: 15,
                position: "absolute",
              }}
              onPress={props.closeModal}
            >
              {/* <SvgXml
                width={27}
                height={27}
                xml={`<svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Close</title><path fill="none" stroke="#DC612F" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M368 368L144 144M368 144L144 368"/></svg>`}
              /> */}
            </TouchableOpacity>
            <Title
              style={{ marginTop: "10%" }}
              fontSize={27}
              text={
                getPropertyListingTypeLabelText(
                  props.appointment.showing_type
                ) + " Feedback"
              }
            />
            <View style={styles.backgroundViewNotes}>
              <TextInput
                style={styles.inputTextArea}
                multiline={true}
                maxLength={150}
                placeholder={getPropertyListingTypePlaceHolderText(
                  props.appointment.showing_type
                )}
                placeholderTextColor="black"
                onChangeText={(notes) => {
                  setFeedbackText(notes);
                }}
                value={feedbackText}
                autoCapitalize="none"
                returnKeyLabel="Done"
                returnKeyType="done"
                scrollEnabled={false}
                onSubmitEditing={Keyboard.dismiss}
                keyboardType="default"
              />
            </View>
            <View>
              <Text style={{ color: "#DC612F", marginLeft: 20 }}>
                * Feedback field required{" "}
              </Text>
              <View style={{ marginTop: 25, marginBottom: 35, marginLeft: 55 }}>
                {!isLoading && (
                  <ButtonOne
                    text={"SUBMIT"}
                    style={{ width: "80%" }}
                    onPress={() => {
                      if (feedbackText.trim() != "") {
                        completeAppointment(props.appointment.id);
                      }else{
                        setFeedbackText(feedbackText.trim());
                      }
                    }}
                  />
                )}
              </View>
            </View>
          </View>
        )}
        {isSelectAddressBoxOpened && (
          <View style={styles.selectPropertyModal}>
            <TouchableOpacity
              style={{
                marginTop: 5,
                padding: 5,
                right: 15,
                position: "absolute",
              }}
              onPress={props.closeModal}
            >
              {/* <SvgXml
                width={27}
                height={27}
                xml={`<svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Close</title><path fill="none" stroke="#DC612F" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M368 368L144 144M368 144L144 368"/></svg>`}
              /> */}
            </TouchableOpacity>
            <Text
              style={{
                color: "#DC612F",
                padding: 5,
                marginTop: "25%",
                marginBottom: 20,
                right: 15,
                position: "absolute",
              }}
              onPress={() => selectAllCheckbox()}
            >
              {selectedAddressIds.length ==
              props.appointment.dataListings.length
                ? "Unselect all"
                : "Select all"}
            </Text>
            <Title
              style={{ marginTop: "10%" }}
              fontSize={27}
              text={"Select Completed Properties"}
            />
            <View style={{ marginTop: "10%", height: "70%" }}>
              <ScrollView>
                {props.appointment.dataListings.map((address, i) => {
                  if (address != undefined) {
                    return (
                      <SelectableAddress
                        key={i}
                        id={address.id}
                        index={i}
                        address={address.address}
                        unitNumber={address.unit_number}
                        selected={
                          selectedAddressIds.indexOf(address.id) === -1
                            ? false
                            : true
                        }
                        onToggle={() => {
                          handleCheckBoxPress(address.id);
                        }}
                      />
                    );
                  }
                })}
              </ScrollView>
              <View style={{ marginTop: 20, marginLeft: 55 }}>
                <ButtonOne
                  text={"CONFIRM & COMPLETE"}
                  style={{ width: "80%" }}
                  onPress={() => {
                    if (selectedAddressIds.length > 0) {
                      setIsFeedbackBoxOpened(true);
                      setIsSelectAddressBoxOpened(false);
                    }
                  }}
                />
              </View>
            </View>
          </View>
        )}
      </Modal>
    </View>
  );
};

CompleteAppointmentModal.defaultProps = {
  showModal: false,
};

export default connect(
  (state) => ({
    user: state.auth.user,
    CancelReasonList: state.app.CancelReasonList,
  }),
  (dispatch) => ({
    completeAppointment: (
      id,
      feedbackText,
      selectedAddressIds,
      isInstantPayout
    ) =>
      dispatch(
        completeAppointment(
          id,
          feedbackText,
          selectedAddressIds,
          isInstantPayout
        )
      ),
    cancelAppointment: (id) => dispatch(cancelAppointment(id)),
    deleteAppointment: (id) => dispatch(deleteAppointment(id)),
  })
)(CompleteAppointmentModal);
