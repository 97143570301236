import { StyleSheet, Platform } from "react-native";
import Colors from "../../assets/colors";

const styles = StyleSheet.create({
  headerImage: {
    height: 80,
    alignSelf: "center",
  },
  mainView: {
    marginTop: 5,
    marginHorizontal: 10,
  },
  heading: {
    fontFamily: Platform === "ios" ? "RobotoCondensed" : "Arial",
    color: Colors.text,
    fontSize: 25,
    fontWeight: "bold",
    marginLeft: 10,
    marginTop: 10,
  },
  otherHeading: {
    fontFamily: Platform === "ios" ? "RobotoCondensed" : "Arial",
    color: Colors.text,
    fontSize: 25,
    fontWeight: "bold",
    marginBottom: 10,
  },
  text: {
    color: Colors.textInput,
    fontSize: 18,
  },
  backgroundView: {
    backgroundColor: Colors.backgroundBlue,
    borderRadius: 10,
    paddingBottom: 15,
  },
  seekbackgroundView: {
    backgroundColor: Colors.backgroundBlue,
    borderRadius: 10,
    paddingVertical: 20,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    marginBottom: 10,
  },
  textInput: {
    color: Colors.textInput,
    fontSize: 15,
  },
  textInputIcon: {
    alignSelf: "flex-end",
    marginRight: 5,
    marginBottom: 15,
  },
  linearGradient: {
    width: 150,
    borderRadius: 25,
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 2,
    borderColor: "white",
    borderBottomWidth: 0,
    shadowColor: "#2E363D99",
    shadowOffset: { width: 5, height: 5 },
    shadowOpacity: 0.8,
    shadowRadius: 10,
    elevation: 6,
    marginVertical: 10,
  },
  buttonText: {
    fontSize: 15,
    fontFamily: "Gill Sans",
    textAlign: "center",
    margin: 10,
    color: "#ffffff",
    backgroundColor: "transparent",
  },
  title: {
    padding: 20,
    fontSize: 18,
  },
  btnWidth: {
    width: 140,
  },
  borderView: {
    flexDirection: "row",
    borderBottomColor: Colors.text,
    borderBottomWidth: 1,
    marginHorizontal: 10,
    marginTop: 5,
  },
  borderViewTime: {
    width: 140,
    borderBottomColor: Colors.text,
    borderBottomWidth: 1,
    marginHorizontal: 10,
    marginTop: 5,
  },
  textAlignTimeZone: {
    right: 0,
    lineHeight: 50,
  },
  radioButtonList: {
    marginHorizontal: 30,
    marginVertical: 20,
  },
  radioButtonOuter: {
    borderBottomColor: "#E2E9EE",
    borderBottomWidth: 1,
    paddingVertical: 10,
    flexDirection: "row",
    alignItems: "center",
  },
  radioButtonRound: {
    width: 25,
    height: 25,
    borderColor: "#EC8F64",
    borderWidth: 2,
    borderRadius: 12.5,
    flexDirection: "column",
    marginRight: 15,
    alignItems: "center",
    justifyContent: "center",
  },
  radioButtonInner: {
    width: 10,
    height: 10,
    borderRadius: 5,
    backgroundColor: "#EC8F64",
  },
  radioButtonTextOuter: {
    flexDirection: "column",
  },
  radioButtonMainText: {
    fontSize: 16,
    color: "#63696F",
  },
  radioButtonSmallText: {
    fontSize: 12,
    color: "#63696F",
  },
  radioButtonDisableTextColor: {
    color: "#ADB4BC",
  },
});

export default styles;
