import { StyleSheet, Platform } from "react-native";

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
    marginTop: 50,
  },
  agentText: {
    fontSize: 24,
    paddingTop: 10,
    color: "#A3C7E3",
  },
  keyWelcome: {
    fontSize: 20,
    paddingTop: 0,
    color: "#FFFFFF",
    paddingBottom: 10,
  },
  cardView: {
    backgroundColor: "#fff",
    width: "75%",
    borderRadius: 20,
    paddingBottom: 30,
  },
  textKey: {
    fontSize: 22,
    color: "#4990C8",
    paddingBottom: 10,
    paddingTop: 20,
    textAlign: "center",
  },
  butnView: {
    justifyContent: "center",
    alignItems: "center",
  },
  textBtnCenter: {
    justifyContent: "center",
    alignItems: "center",
  },
  btnText: {
    fontSize: 20,
    color: "#fff",
    padding: 10,
    textAlign: "center",
  },
  btnView1: {
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 15,
  },
  textView: {
    fontSize: 20,
    color: "#fff",
    padding: 10,
    textAlign: "center",
  },
  showView: {
    backgroundColor: "#fff",
    width: "75%",
    borderRadius: 20,
    paddingBottom: 20,
    marginTop: 10,
  },
  opportunityText: {
    fontSize: 22,
    color: "#4990C8",
    padding: 10,
    left: 20,
  },
  notificationView: {
    backgroundColor: "#fff",
    width: "75%",
    borderRadius: 20,
    paddingBottom: 0,
    marginTop: 12,
  },
  lineargradientBtn: {
    borderWidth: 2,
    borderRadius: 30,
    borderColor: "white",
    borderTopWidth: 0,
    shadowColor: "black",
    shadowOffset: { width: 0, height: 30 },
    shadowOpacity: 10,
    shadowRadius: 30,
    elevation: 8,
    width: "75%",
    height: 50,
  },
  headerImage: {
    padding: 60,
    alignSelf: "flex-start",
  },
  myaccount: {
    width: 50,
    height: 25,
    paddingTop: 5,
  },
  oval: {
    position: "absolute",
    width: 50,
    height: 35,
  },
  widthStyle: {
    width: 200,
  },
});
export default styles;
