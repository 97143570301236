import React, { createRef } from "react";
import { connect } from "react-redux";
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  Linking,
  Platform,
} from "react-native";
import {
  logoutUser,
  goAgentPortal,
  getStripeLoginURL,
} from "../redux/auth/actions";
import { setReadNotification } from "../redux/messages/actions";
// import ActionSheet from "react-native-actions-sheet";
import HomeIconMenu from "../Images/HomeIconMenu";
import SettingsIcon from "../Images/SettingsIcon";
import ClaimedIcon from "../Images/ClaimedIcon";
import FavoriteIcon from "../Images/FavoriteIcon";
import RequestedIcon from "../Images/RequestedIcon";
import PaymentsIcon from "../Images/PaymentsIcon";
import ResourcesIcon from "../Images/ResourcesIcon";
import Notification from "../Images/Notification";
import HelpIcon from "../Images/HelpIcon";
import LogoutIcon from "../Images/LogoutIcon";
import UserBlockedOrange from "../Images/UserBlockedOrange";
// import { SvgXml } from "react-native-svg";
import LogsService from "../LogsService";
import { setLogsFlag } from "../../config";

class ActionSheetMenu extends React.Component {
  constructor(props) {
    super(props);
    this.actionSheetRef = createRef();
    this.state = {
      lastRefresh: Date(Date.now()).toString(),
    };
    this.refreshScreen = this.refreshScreen.bind(this);
    // LogsService.fileLog("ActionSheetMenu", "constructor", "screen initiated");
  }
  refreshScreen() {
    this.setState({ lastRefresh: Date(Date.now()).toString() });
    // LogsService.fileLog(
    //   "ActionSheetMenu",
    //   "refreshScreen",
    //   "refreshScreen is called"
    // );
  }

  render() {
    return (
      <View style={{ backgroundColor: "#215F90", flex: 1 }}>
        <View
          style={{
            top: 5,
            flexDirection: "row",
          }}
        >
          <TouchableOpacity
            style={
              Platform.OS === "ios"
                ? styles.humbergerMenuIos
                : styles.humbergerMenuAndroid
            }
            onPress={() => {
              this.props.setReadNotification(false);
              // LogsService.fileLog(
              //   "ActionSheetMenu",
              //   "onPress",
              //   "Notifications is clicked and navigating to Notifications"
              // );
              this.props.navigation.navigate("Notifications");
            }}
          >
            {/* <Notification /> */}
            <View style={styles.menuOuter}>
              <Text
                style={
                  Platform.OS === "ios"
                    ? styles.notificationTextIOS
                    : styles.notificationTextAndroid
                }
              >
                Notifications
              </Text>
            </View>

            {this.props.isUnRead &&
            this.props.navigation.state.routeName !== "Notifications" ? (
              <View style={styles.unReadNotificationOuter}></View>
            ) : null}
          </TouchableOpacity>
          <TouchableOpacity
            style={
              Platform.OS === "ios"
                ? styles.humbergerMenuIos
                : styles.humbergerMenuAndroid
            }
            onPress={() => {
              // LogsService.fileLog(
              //   "ActionSheetMenu",
              //   "onPress",
              //   "Menu is clicked"
              // );
              if (this.actionSheetRef.current) {
                // LogsService.fileLog(
                //   "ActionSheetMenu",
                //   "onPress",
                //   "Open Action sheet menu"
                // );
                this.actionSheetRef.current.setModalVisible(true);
              }
            }}
          >
            <SvgXml
              width={27}
              height={27}
              xml={`<svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Menu</title><path fill="none" stroke="#FFFFFF" stroke-linecap="round" stroke-miterlimit="10" stroke-width="32" d="M80 160h352M80 256h352M80 352h352"/></svg>`}
            />
            <View style={styles.menuOuter}>
              <Text style={styles.menuText}>Menu</Text>
            </View>
          </TouchableOpacity>
        </View>
        {/* <ActionSheet ref={this.actionSheetRef}>
          <View>
            <View style={styles.containercenter}>
              <TouchableOpacity
                onPress={() => {
                  // LogsService.fileLog(
                  //   "ActionSheetMenu",
                  //   "onPress",
                  //   "Actionsheet top notch pressed and hide the actionsheet"
                  // );
                  if (this.actionSheetRef.current) {
                    this.actionSheetRef.current.setModalVisible(false);
                  }
                }}
              >
                <Text style={styles.outerCircle}></Text>
              </TouchableOpacity>
            </View>
            <View style={{ paddingBottom: 40, paddingLeft: 55, paddingTop: 1 }}>
              <TouchableOpacity
                style={styles.TouchalbeBtn}
                onPress={() => {
                  // LogsService.fileLog(
                  //   "ActionSheetMenu",
                  //   "onPress",
                  //   "Home is clicked, hiding the actionsheet and navigating to AgentPortal"
                  // );
                  if (this.actionSheetRef.current) {
                    this.actionSheetRef.current.setModalVisible(false);
                  }
                  this.props.navigation.navigate("AgentPortal");
                }}
              >
                <View>
                  <HomeIconMenu style={styles.NavIcon} />
                  <Text style={styles.Text}>Home</Text>
                </View>
              </TouchableOpacity>

              <TouchableOpacity
                style={styles.TouchalbeBtn}
                onPress={() => {
                  // LogsService.fileLog(
                  //   "ActionSheetMenu",
                  //   "onPress",
                  //   "My Claimed / Completed is clicked, hiding the actionsheet and navigating to MyClaimed"
                  // );
                  if (this.actionSheetRef.current) {
                    this.actionSheetRef.current.setModalVisible(false);
                  }
                  this.props.navigation.navigate("MyClaimed");
                }}
              >
                <View>
                  <ClaimedIcon style={styles.NavIcon} />
                  <Text style={styles.Text}>My Claimed / Completed</Text>
                </View>
              </TouchableOpacity>
              <TouchableOpacity
                style={styles.TouchalbeBtn}
                onPress={() => {
                  LogsService.fileLog(
                    "ActionSheetMenu",
                    "onPress",
                    "My Requested / Completed is clicked, hiding the actionsheet and navigating to PastShowing"
                  );
                  if (this.actionSheetRef.current) {
                    this.actionSheetRef.current.setModalVisible(false);
                  }
                  this.props.navigation.navigate("PastShowing");
                }}
              >
                <View>
                  <RequestedIcon style={styles.NavIcon1} />
                  <Text style={styles.Text}>My Requested / Completed </Text>
                </View>
              </TouchableOpacity>

              <TouchableOpacity
                style={styles.TouchalbeBtn}
                onPress={() => {
                  LogsService.fileLog(
                    "ActionSheetMenu",
                    "onPress",
                    "My Favorite Agents is clicked, hiding the actionsheet and navigating to MyFavorite"
                  );
                  if (this.actionSheetRef.current) {
                    this.actionSheetRef.current.setModalVisible(false);
                  }
                  this.props.navigation.navigate("MyFavorite");
                }}
              >
                <View>
                  <FavoriteIcon style={styles.NavIcon} width={25} height={22} />
                  <Text style={styles.Text}>My Favorite Agents</Text>
                </View>
              </TouchableOpacity>

              <TouchableOpacity
                style={styles.TouchalbeBtn}
                onPress={() => {
                  LogsService.fileLog(
                    "ActionSheetMenu",
                    "onPress",
                    "My Blocked Agents is clicked, hiding the actionsheet and navigating to MyBlocked"
                  );
                  if (this.actionSheetRef.current) {
                    this.actionSheetRef.current.setModalVisible(false);
                  }
                  this.props.navigation.navigate("MyBlocked");
                }}
              >
                <View>
                  <UserBlockedOrange
                    style={styles.NavIcon}
                    width={25}
                    height={22}
                  />
                  <Text style={styles.Text}>My Blocked Agents</Text>
                </View>
              </TouchableOpacity>

              <TouchableOpacity
                style={styles.TouchalbeBtn}
                onPress={() => {
                  LogsService.fileLog(
                    "ActionSheetMenu",
                    "onPress",
                    "Payments is clicked, hiding the actionsheet and navigating to PaymentScreen"
                  );
                  if (this.actionSheetRef.current) {
                    this.actionSheetRef.current.setModalVisible(false);
                  }
                  this.props.navigation.navigate("PaymentScreen");
                }}
              >
                <View>
                  <PaymentsIcon style={styles.NavIcon} />
                  <Text style={styles.Text}>Payments</Text>
                </View>
              </TouchableOpacity>

              <TouchableOpacity
                style={styles.TouchalbeBtn}
                onPress={() => {
                  LogsService.fileLog(
                    "ActionSheetMenu",
                    "onPress",
                    "Resources is clicked, hiding the actionsheet and navigating to Resources"
                  );
                  if (this.actionSheetRef.current) {
                    this.actionSheetRef.current.setModalVisible(false);
                  }
                  this.props.navigation.navigate("Resources");
                }}
              >
                <View>
                  <ResourcesIcon style={styles.NavIcon} />
                  <Text style={styles.Text}>Resources</Text>
                </View>
              </TouchableOpacity>

              <TouchableOpacity
                style={styles.TouchalbeBtn}
                onPress={() => {
                  LogsService.fileLog(
                    "ActionSheetMenu",
                    "onPress",
                    "Settings is clicked, hiding the actionsheet and navigating to SettingScreen"
                  );
                  if (this.actionSheetRef.current) {
                    this.actionSheetRef.current.setModalVisible(false);
                  }
                  this.props.navigation.navigate("SettingScreen");
                }}
              >
                <View>
                  <SettingsIcon style={styles.NavIcon} />
                  <Text style={styles.Text}>Settings</Text>
                </View>
              </TouchableOpacity>

              <TouchableOpacity
                style={styles.TouchalbeBtn}
                onPress={() => {
                  LogsService.fileLog(
                    "ActionSheetMenu",
                    "onPress",
                    "Help is clicked, hiding the actionsheet and opening email agent mailto:support@keypleaz.com"
                  );
                  Linking.openURL("mailto:support@keypleaz.com");
                }}
              >
                <View>
                  <HelpIcon style={styles.NavIcon} />
                  <Text style={styles.Text}>Help</Text>
                </View>
              </TouchableOpacity>
              <TouchableOpacity
                style={styles.TouchalbeBtn}
                onPress={() => {
                  LogsService.fileLog(
                    "ActionSheetMenu",
                    "onPress",
                    "Logout is clicked, hiding the actionsheet, logging out the user and navigating to the Login screen"
                  );
                  setLogsFlag(true);
                  this.props.logout();

                  if (this.actionSheetRef.current) {
                    this.actionSheetRef.current.setModalVisible(false);
                  }
                }}
              >
                <View>
                  <LogoutIcon style={styles.NavIcon} />
                  <Text style={styles.Text}>Logout</Text>
                </View>
              </TouchableOpacity>
            </View>
          </View>
        </ActionSheet> */}
      </View>
    );
  }
}

export default connect(
  (state) => ({
    isUnRead: state.messages.isUnRead,
    user: state.auth.user,
  }),
  (dispatch) => ({
    logout: () => dispatch(logoutUser()),
    goAgentPortal: () => dispatch(goAgentPortal()),
    setReadNotification: (isUnRead) => dispatch(setReadNotification(isUnRead)),
    getStripeLoginURL: () => dispatch(getStripeLoginURL()),
  })
)(ActionSheetMenu);

const styles = StyleSheet.create({
  Text: {
    color: "#4990C8",
    fontSize: 20,
    fontWeight: "700",
    padding: 5,
  },
  TouchalbeBtn: {
    marginTop: 15,
    borderBottomColor: "#D9E9F0",
    borderBottomWidth: 0.7,
  },
  TouchalbeLine: {
    borderBottomColor: "#000",
    borderBottomWidth: 5,
    width: 75,
    justifyContent: "center",
    alignItems: "center",
  },
  NavIcon: {
    position: "absolute",
    left: -35,
    top: 7,
  },
  NavIcon1: {
    position: "absolute",
    left: -42,
    top: 5,
  },
  containercenter: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  outerCircle: {
    backgroundColor: "#D6DBDF",
    width: 75,
    height: 8,
    marginTop: 10,
    borderRadius: 10,
    justifyContent: "center",
    alignItems: "center",
  },
  humbergerMenuIos: {
    bottom: 18,
    width: 50,
    height: 40,
    right: 0,
    zIndex: 100000,
    justifyContent: "center",
    alignItems: "center",
  },
  humbergerMenuAndroid: {
    top: -3,
    width: 45,
    height: 40,
    right: 0,
    zIndex: 100000,
    justifyContent: "center",
    alignItems: "center",
  },
  menuOuter: {
    position: "absolute",
    bottom: -2.5,
  },
  menuText: {
    fontWeight: "700",
    fontSize: 10,
    color: "rgba(255,255,255,0.5)",
  },
  notificationTextAndroid: {
    fontWeight: "700",
    width: 63,
    fontSize: 10,
    color: "rgba(255,255,255,0.5)",
  },
  notificationTextIOS: {
    fontWeight: "700",
    width: 70,
    fontSize: 10,
    color: "rgba(255,255,255,0.5)",
  },
  unReadNotificationOuter: {
    height: 10,
    width: 10,
    borderRadius: 5,
    backgroundColor: "#EE8B62",
    position: "absolute",
    borderWidth: 2,
    borderColor: "#215F90",
    top: 12.5,
    right: 11,
  },
});
