import React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      width={props.width ? props.width : props.size}
      height={props.height ? props.height : props.size}
      viewBox="0 0 32 25"
      {...props}
    >
      <Path
        d="M13 3.25C14.2929 3.25 15.5329 3.76361 16.4471 4.67785C17.3614 5.59209 17.875 6.83207 17.875 8.125C17.875 9.41793 17.3614 10.6579 16.4471 11.5721C15.5329 12.4864 14.2929 13 13 13C11.7071 13 10.4671 12.4864 9.55285 11.5721C8.63861 10.6579 8.125 9.41793 8.125 8.125C8.125 6.83207 8.63861 5.59209 9.55285 4.67785C10.4671 3.76361 11.7071 3.25 13 3.25V3.25ZM13 22.75C13 22.75 22.75 22.75 22.75 20.3125C22.75 17.3875 17.9969 14.2188 13 14.2188C8.00313 14.2188 3.25 17.3875 3.25 20.3125C3.25 22.75 13 22.75 13 22.75Z"
        fill="#D87031"
      />
      <Path fill-rule="evenodd" clip-rule="evenodd" d="M0.999773 22.9998L22.9998 0.999773L25.1211 3.12109L3.12109 25.1211L0.999773 22.9998Z" fill="#D87031"/>
    </Svg>
  );
}

export default SvgComponent;
