import React, { Component } from "react";
import {
  Text,
  View,
  ScrollView,
  Alert,
  TextInput,
  Keyboard,
  KeyboardAvoidingView,
  Platform,
} from "react-native";
// import { NavigationEvents } from "react-navigation";
import styles from "../../Styles/BuyerInfoStyles";
import Footer from "../../Components/Footer.js";
import { ButtonOne } from "../../Components/Button";
import Form from "../../Components/Form";
import ProgressBar from "../../Components/ProgressBar";
import Title from "../../Components/CreateAppointment/Title";
import User from "../../Images/User";
import Call from "../../Images/Call";
import HeaderImage from "../../Images/Header";
import { connect } from "react-redux";
import {
  updateCurrentAppointment,
  setCountryCode,
} from "../../redux/appointments/actions";
import BaseLayout, { headerStyleCustom } from "../BaseLayout";
import ActionSheetMenu from "../../Components/ActionSheetMenu";
import { getTimezoneAbbreviation } from "../../utils/timezone";
import LogsService from "../../LogsService";

class BuyerInformation extends Component {
  static navigationOptions = ({ navigation }) => ({
    headerStyle: headerStyleCustom(),
    headerTintColor: "#fff",
    headerTitleStyle: {
      fontWeight: "bold",
    },
    headerBackTitle: "Back",
    headerTitle: () => <HeaderImage height={35} />,
    headerRight: () => (
      <ActionSheetMenu numberOfLines={1} navigation={navigation} />
    ),
  });
  constructor(props) {
    super(props);
    this.state = {
      ccode: "+1",
      phoneSetNo: "",
      clickedButton: true,
      changeState: true,
      note: "",
    };
    LogsService.fileLog('BuyerInformation','constructor','constructor initiated');
  }
  componentDidMount() {
    this.state.clickedButton = true;
    this.props.setCountryCode(this.props.conuntCode);
    LogsService.fileLog('BuyerInformation','componentDidMount','componentDidMount called');
  }

  formatPhoneNumber(value) {
    LogsService.fileLog('BuyerInformation','formatPhoneNumber','formatPhoneNumber is called with value= ' + value);
    if (value != null) {
      const currentValue = value.replace(/[^\d]/g, "");
      const cvLength = value.length;
      let phoneNo = "";
      if (cvLength <= 3) phoneNo = currentValue;
      else if (cvLength <= 7)
        phoneNo = `${currentValue.slice(0, 3)}-${currentValue.slice(3)}`;
      else
        phoneNo = `${currentValue.slice(0, 3)}-${currentValue.slice(
          3,
          6
        )}-${currentValue.slice(6, 10)}`;

      return phoneNo;
    }
  }

  //Empty Function
  refreshButtonNavBack() {

  }

  getAbrr(timeZoneName) {
    LogsService.fileLog('BuyerInformation','getAbrr','getAbrr is called with timeZone=' + timeZoneName);
    return getTimezoneAbbreviation(timeZoneName);
  }
  pressBuyerInfoNext() {
    LogsService.fileLog('BuyerInformation','pressBuyerInfoNext','pressBuyerInfoNext is called');
    var propertylistingtype = this.props.addresses.propertylistingtype;
    var buyerFirstName = this.props.currentAppointment.buyerFirstName;
    var buyerLastName = this.props.currentAppointment.buyerLastName;
    var buyerPhone = this.props.currentAppointment.buyerPhoneWithoutCcode;
    var timeZoneName = JSON.stringify({
      timeZoneId: this.props.addresses.timezone.timeZoneId,
      timeZoneName: this.props.addresses.timezone.timeZoneName,
      abrr: this.getAbrr(this.props.addresses.timezone.timeZoneName),
    });
    LogsService.fileLog('BuyerInformation','pressBuyerInfoNext propertylistingtype=',JSON.stringify(propertylistingtype));
    LogsService.fileLog('BuyerInformation','pressBuyerInfoNext buyerFirstName=',buyerFirstName);
    LogsService.fileLog('BuyerInformation','pressBuyerInfoNext buyerLastName=',buyerLastName);
    LogsService.fileLog('BuyerInformation','pressBuyerInfoNext buyerPhone=',buyerPhone);
    LogsService.fileLog('BuyerInformation','pressBuyerInfoNext timeZoneName=',JSON.stringify(timeZoneName));
    LogsService.fileLog('BuyerInformation','pressBuyerInfoNext propertylistingtype=',propertylistingtype);
    if (buyerPhone != undefined && buyerPhone != "") {
      let phone = buyerPhone.replace(/\-/g, "");
      phone = phone.replace(/[^\d]/g, "");
      if (phone.slice(0, 1) == "+") {
        var s = 0;
        var l = phone.length;
        s = l - 10;
        this.props.currentAppointment.buyerPhoneWithoutCcode = phone.slice(
          s,
          l
        );
      } else {
        this.props.currentAppointment.buyerPhoneWithoutCcode = phone.slice(
          0,
          10
        );
      }
    }
    if (propertylistingtype == "OPENHOUSE") {
      const regex = new RegExp("^[a-zA-Z']+$");
      const nameRegex = new RegExp("^[a-z A-Z']+$");
      var regexs = /^(\+?\d{1,5}|\d{1,4})$/gm;
      if (this.props.conuntCode != "") {
        var match = this.props.conuntCode.match(regexs);
      }
      var setbuyerFirstNameErr = true;
      var setbuyerLastNameErr = true;
      var setbuyerPhoneErr = true;
      var setCountryCodeErr = true;

      if (match == null) {
        Alert.alert("", "Please enter a valid country code", [
          { text: "OK", onPress: () => {
            LogsService.fileLog('BuyerInformation','pressBuyerInfoNext vlidation', 'Please enter a valid country code, and OK pressed');
          } },
        ]);
        setCountryCodeErr = false;
      } else {
        setCountryCodeErr = true;
      }

      if (buyerFirstName != undefined && buyerFirstName != "") {
        if (!nameRegex.test(buyerFirstName.trim())) {
          Alert.alert("", "Please enter only characters for First Name", [
            { text: "OK", onPress: () => {
              LogsService.fileLog('BuyerInformation','pressBuyerInfoNext vlidation', 'Please enter only characters for First Name, and OK pressed');
            } },
          ]);
          setbuyerFirstNameErr = false;
        } else {
          setbuyerFirstNameErr = true;
        }
      } else {
        setbuyerFirstNameErr = true;
      }
      if (buyerLastName != undefined && buyerLastName != "") {
        if (!nameRegex.test(buyerLastName.trim())) {
          Alert.alert("", "Please enter only characters for Last Name", [
            { text: "OK", onPress: () => {
              LogsService.fileLog('BuyerInformation','pressBuyerInfoNext vlidation', 'Please enter only characters for Last Name, and OK pressed');
            } },
          ]);
          setbuyerLastNameErr = false;
        } else {
          setbuyerLastNameErr = true;
        }
      } else {
        setbuyerLastNameErr = true;
      }
      if (buyerPhone != undefined && buyerPhone != "") {
        if (this.props.currentAppointment.buyerPhoneWithoutCcode.length != 10) {
          Alert.alert("", "Please enter a valid phone number", [
            { text: "OK", onPress: () => {
              LogsService.fileLog('BuyerInformation','pressBuyerInfoNext vlidation', 'Please enter a valid phone number, and OK pressed');
            } },
          ]);
          setbuyerPhoneErr = false;
        } else {
          setbuyerPhoneErr = true;
        }
      } else {
        setbuyerPhoneErr = true;
      }

      if (
        setbuyerFirstNameErr &&
        setbuyerLastNameErr &&
        setbuyerPhoneErr &&
        setCountryCodeErr
      ) {
        this.state.clickedButton = false;
        if (buyerPhone != undefined && buyerPhone != "") {
          let phone = buyerPhone.replace(/\-/g, "");
          phone = phone.replace(/[^\d]/g, "");
          this.props.currentAppointment.buyerPhoneWithoutCcode = phone.slice(
            0,
            10
          );
          let buyerPhone1 = "";
          let ccode = "";
          buyerPhone1 = this.props.currentAppointment.buyerPhoneWithoutCcode;
          ccode = this.props.conuntCode;
          this.props.currentAppointment.buyerPhone = ccode + buyerPhone1;
        }
        // here add showing type and time zone //
        this.props.currentAppointment.showing_type = propertylistingtype;
        this.props.currentAppointment.time_zone = timeZoneName;
        LogsService.fileLog('BuyerInformation','pressBuyerInfoNext', 'Navigating to AppointmentDetails screen');
        this.props.navigation.navigate("AppointmentDetails");
      }
    } else {
      const regex = new RegExp("^[a-zA-Z']+$");
      const nameRegex = new RegExp("^[a-z A-Z']+$");
      var regexs = /^(\+?\d{1,5}|\d{1,4})$/gm;
      if (this.props.conuntCode != "") {
        var match = this.props.conuntCode.match(regexs);
      }
      if (
        buyerFirstName == undefined ||
        buyerLastName == undefined ||
        buyerPhone == undefined
      ) {
        Alert.alert("", "All fields are mandatory", [
          { text: "OK", onPress: () => {
            LogsService.fileLog('BuyerInformation','pressBuyerInfoNext validation onPress', 'All fields are mandatory');
            LogsService.fileLog('BuyerInformation','pressBuyerInfoNext validation onPress buyerFirstName', buyerFirstName);
            LogsService.fileLog('BuyerInformation','pressBuyerInfoNext validation onPress buyerLastName', buyerLastName);
            LogsService.fileLog('BuyerInformation','pressBuyerInfoNext validation onPress buyerPhone', buyerPhone);
          } },
        ]);
      } else if (
        buyerFirstName == "" ||
        buyerLastName == "" ||
        buyerPhone == ""
      ) {
        Alert.alert("", "All fields are mandatory", [
          { text: "OK", onPress: () => {
            LogsService.fileLog('BuyerInformation','pressBuyerInfoNext validation onPress', 'All fields are mandatory');
            LogsService.fileLog('BuyerInformation','pressBuyerInfoNext validation onPress buyerFirstName', buyerFirstName);
            LogsService.fileLog('BuyerInformation','pressBuyerInfoNext validation onPress buyerLastName', buyerLastName);
            LogsService.fileLog('BuyerInformation','pressBuyerInfoNext validation onPress buyerPhone', buyerPhone);
          } },
        ]);
      } else {
        if (!nameRegex.test(buyerFirstName.trim())) {
          Alert.alert("", "Please enter only characters for First Name", [
            { text: "OK", onPress: () => {
              LogsService.fileLog('BuyerInformation','pressBuyerInfoNext validation onPress', 'Please enter only characters for First Name');
            } },
          ]);
        } else if (!nameRegex.test(buyerLastName.trim())) {
          Alert.alert("", "Please enter only characters for Last Name", [
            { text: "OK", onPress: () => {
              LogsService.fileLog('BuyerInformation','pressBuyerInfoNext validation onPress', 'Please enter only characters for Last Name');
            } },
          ]);
        } else if (this.props.conuntCode == "") {
          Alert.alert("", "Please enter country code", [
            { text: "OK", onPress: () => {
              LogsService.fileLog('BuyerInformation','pressBuyerInfoNext validation onPress', 'Please enter country code');
            } },
          ]);
        } else if (match == null) {
          Alert.alert("", "Please enter a valid country code", [
            { text: "OK", onPress: () => {
              LogsService.fileLog('BuyerInformation','pressBuyerInfoNext validation onPress', 'Please enter a valid country code');
            } },
          ]);
        } else if (
          this.props.currentAppointment.buyerPhoneWithoutCcode.length != 10
        ) {
          Alert.alert("", "Please enter a valid phone number", [
            { text: "OK", onPress: () => {
              LogsService.fileLog('BuyerInformation','pressBuyerInfoNext validation onPress', 'Please enter a valid phone number');
            } },
          ]);
        } else {
          this.state.clickedButton = false;
          let phone = buyerPhone.replace(/\-/g, "");
          this.props.currentAppointment.buyerPhoneWithoutCcode = phone.slice(
            0,
            10
          );
          let buyerPhone1 = "";
          let ccode = "";
          buyerPhone1 = this.props.currentAppointment.buyerPhoneWithoutCcode;
          ccode = this.props.conuntCode;
          this.props.currentAppointment.buyerPhone = ccode + buyerPhone1;
          // here add showing type and time zone //
          this.props.currentAppointment.showing_type = propertylistingtype;
          this.props.currentAppointment.time_zone = timeZoneName;
          LogsService.fileLog('BuyerInformation','pressBuyerInfoNext', 'Navigating to AppointmentDetails screen');
          this.props.navigation.navigate("AppointmentDetails");
        }
      }
    }
  }
  getPropertyListingTypeLabelText(type) {
    LogsService.fileLog('BuyerInformation','getPropertyListingTypeLabelText', 'getPropertyListingTypeLabelText is called with type= ' + type);
    const propertyListingTypeArr = {
      HOMESHOWING: "Home Showing",
      OPENHOUSE: "Open House",
      RUNNER: "Runner",
    };
    return propertyListingTypeArr[type];
  }
  render() {
    return (
      <BaseLayout>
        <View style={{ height: "100%", width: "100%" }}>
          <ProgressBar total={4} complete={3} />

          {/* <NavigationEvents onDidFocus={() => this.refreshButtonNavBack()} /> */}
          <KeyboardAvoidingView
            style={{ flex: 9, flexDirection: "column" }}
            behavior="padding"
            enabled
            keyboardVerticalOffset={100}
          >
            <ScrollView
              keyboardShouldPersistTaps={"always"}
              keyboardDismissMode={
                Platform.OS === "ios" ? "interactive" : "on-drag"
              }
            >
              <View style={styles.mainView}>
                {this.props.addresses.propertylistingtype == "HOMESHOWING" && (
                  <Title text={"Buyer Information"} />
                )}
                {this.props.addresses.propertylistingtype == "OPENHOUSE" && (
                  <Title text={"Seller Information"} />
                )}
                {this.props.addresses.propertylistingtype == "RUNNER" && (
                  <Title text={"Contact Information"} />
                )}
                <Form
                  fields={[
                    {
                      label: "First Name",
                      key: "buyerFirstName",
                      value: this.props.currentAppointment.buyerFirstName,
                      image: () => User,
                    },
                    {
                      label: "Last Name",
                      key: "buyerLastName",
                      value: this.props.currentAppointment.buyerLastName,
                      image: () => User,
                    },
                    {
                      label: "Code",
                      key: "ccode",
                      value: this.props.conuntCode,
                      image: () => Call,
                    },
                    {
                      label: "Mobile Phone",
                      key: "buyerPhoneWithoutCcode",
                      value: this.formatPhoneNumber(
                        this.props.currentAppointment.buyerPhoneWithoutCcode
                      ),
                      image: () => Call,
                    },
                  ]}
                  onChange={(k, v) => {
                    this.props.updateCurrentAppointment({
                      ...this.props.currentAppointment,
                      [k]: v,
                    });

                    if (k == "ccode") {
                      if (v == "") {
                        this.props.setCountryCode("+");
                      } else {
                        this.props.setCountryCode(v);
                        this.state.ccode = v;
                      }
                      if (this.state.ccode != "") {
                        if (this.state.ccode.slice(0, 1) != "+") {
                          var setPlus = "+" + this.state.ccode;
                          this.props.setCountryCode(setPlus);
                        }
                      }
                    }
                  }}
                />

                {this.props.addresses.propertylistingtype == "HOMESHOWING" && (
                  <Text
                    style={{
                      textAlign: "center",
                      marginTop: 15,
                      color: "#DC612F",
                    }}
                  >
                    *Buyer information is only shared with the showing agent
                    once a confirmation is made.
                  </Text>
                )}
                {this.props.addresses.propertylistingtype == "OPENHOUSE" && (
                  <Text
                    style={{
                      textAlign: "center",
                      marginTop: 15,
                      color: "#DC612F",
                    }}
                  >
                    *Seller Information is only shared with the showing agent
                    once a confirmation is made.
                  </Text>
                )}
                {this.props.addresses.propertylistingtype == "RUNNER" && (
                  <Text
                    style={{
                      textAlign: "center",
                      marginTop: 15,
                      color: "#DC612F",
                    }}
                  >
                    *Contact information is only shared with the agent that claims the opportunity.
                  </Text>
                )}

                <View style={{ marginTop: 20 }}>
                  <Title
                    fontSize={27}
                    text={
                      "Important " +
                      this.getPropertyListingTypeLabelText(
                        this.props.addresses.propertylistingtype
                      ) +
                      " Details"
                    }
                  />
                  <Text style={{ textAlign: "center" }}>
                    (Example: Alarm/Gate Code, Lockbox at backdoor, etc)
                  </Text>
                  <View style={styles.backgroundViewNotes}>
                    <TextInput
                      style={styles.inputTextArea}
                      multiline={true}
                      maxLength={100}
                      placeholder="Add note ..."
                      placeholderTextColor="black"
                      onChangeText={(note) => {
                        this.props.updateCurrentAppointment({
                          ...this.props.currentAppointment,
                          ["note"]: note,
                        });
                      }}
                      value={this.props.currentAppointment.note}
                      autoCapitalize="none"
                      returnKeyLabel="Done"
                      returnKeyType="done"
                      scrollEnabled={false}
                      onSubmitEditing={Keyboard.dismiss}
                      keyboardType="default"
                    />
                  </View>
                </View>
              </View>
            </ScrollView>
          </KeyboardAvoidingView>
          <View
            style={{
              justifyContent: "center",
              alignItems: "center",
              marginVertical: 10,
            }}
          >
            <ButtonOne
              text={"NEXT"}
              style={styles.btnWidth}
              onPress={() => {
                LogsService.fileLog('BuyerInformation','onPress', 'NEXT button clicked');
                this.pressBuyerInfoNext()
              }}
            />
          </View>
          <View style={{ marginTop: 10 }}>
            <Footer />
          </View>
        </View>
      </BaseLayout>
    );
  }
}

export default connect(
  (state) => ({
    user: state.auth.user,
    currentAppointment: state.appointments.current,
    addresses: state.addresses,
    conuntCode: state.appointments.countryCode,
    note: state.appointments.note,
  }),
  (dispatch) => ({
    updateCurrentAppointment: (appointment) =>
      dispatch(updateCurrentAppointment(appointment)),
    setCountryCode: (conuntCode) => dispatch(setCountryCode(conuntCode)),
  })
)(BuyerInformation);
