import React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <Path d="M17.5 6V6.5C17.5 6.66667 17.4167 6.75 17.25 6.75H16.5V7.125C16.5 7.375 16.375 7.5 16.125 7.5H3.875C3.625 7.5 3.5 7.375 3.5 7.125V6.75H2.75C2.58333 6.75 2.5 6.66667 2.5 6.5V6C2.5 5.89583 2.55208 5.82292 2.65625 5.78125L9.90625 3.03125C9.92708 3.01042 9.95833 3 10 3C10.0417 3 10.0729 3.01042 10.0938 3.03125L17.3438 5.78125C17.4479 5.82292 17.5 5.89583 17.5 6ZM16.75 15.5C16.9583 15.5 17.1354 15.5729 17.2812 15.7188C17.4271 15.8646 17.5 16.0417 17.5 16.25V16.75C17.5 16.9167 17.4167 17 17.25 17H2.75C2.58333 17 2.5 16.9167 2.5 16.75V16.25C2.5 16.0417 2.57292 15.8646 2.71875 15.7188C2.86458 15.5729 3.04167 15.5 3.25 15.5H16.75ZM5 8H7V14H9V8H11V14H13V8H15V14H16.125C16.375 14 16.5 14.125 16.5 14.375V15H3.5V14.375C3.5 14.125 3.625 14 3.875 14H5V8Z" fill="#FFFFFF" />
    </Svg>
  );
}

export default SvgComponent;
