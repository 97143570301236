import React from "react";
import { StyleSheet, View, Text } from "react-native";
import ProgressBar from "../ProgressBar";
import { homeSold } from "../../../assets/SvgImages";
import { SvgXml } from "react-native-svg";

const styles = StyleSheet.create({
  content: {
    flexDirection: "row",
    marginTop: "5%",
    marginLeft: "5%",
  },
  agentTxt: {
    color: "#FF8959",
    fontSize: 16,
    fontFamily: "RobotoCondensed-Regular",
  },
  signupTxt: {
    color: "#4990C8",
    fontSize: 28,
    fontFamily: "RobotoCondensed-Bold",
    fontWeight: "bold",
  },
  imgStyles: {
    width : 91.9,
    height : 87.27
  }
});

export default function (props) {
  return (
    <View>
      <ProgressBar {...props} />
      <View style={styles.content}>
        <View style={styles.imgStyles}>
          {/* <SvgXml xml={homeSold} /> */}
        </View>
        <View style={{ marginLeft: "1%" }}>
          <Text style={styles.agentTxt}>I am an agent</Text>
          <Text style={styles.signupTxt}>Sign up now</Text>
        </View>
      </View>
    </View>
  );
}
