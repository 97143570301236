import { combineReducers } from "redux";
import authReducer from "./auth/reducer";
import appReducer from "./app/reducer";
import errorsReducer from "./errors/reducer";
import messagesReducer from "./messages/reducer";
import addressReducer from "./address/reducer";
import appointmentsReducer from "./appointments/reducer";

const rootReducer = combineReducers({
  auth: authReducer,
  app: appReducer,
  errors: errorsReducer,
  messages: messagesReducer,
  addresses: addressReducer,
  appointments: appointmentsReducer,
});

export default rootReducer;
