export const SET_ERRORS = "SET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const setErrors = (errors, timeInSeconds = 10) => {
  return (dispatch) => {
    dispatch(_setErrors(errors));
    setTimeout(() => dispatch(clearErrors()), timeInSeconds * 1000);
  };
};

export const _setErrors = (errors) => {
  return {
    type: SET_ERRORS,
    errors,
  };
};

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};
