import React from "react";
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  Linking,
} from "react-native";
// import LogsService from "../../LogsService";

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#4990C8",
    height: 50,
    width: "100%",
    justifyContent: "center",
  },
  text: {
    color: "#FFFFFF",
    fontSize: 20,
    alignSelf: "center",
    fontFamily: "RobotoCondensed-Regular",
  },
});

export default function () {
  return (
    <View style={styles.container}>
      <TouchableOpacity
        onPress={() => {
          // LogsService.fileLog('TermsOfUseLink','View Terms of Use onPress','opening URL: http://www.keypleaz.com/terms.html');
          Linking.openURL("http://www.keypleaz.com/terms.html")
          }
        }
      >
        <Text style={styles.text}>View Terms of Use</Text>
      </TouchableOpacity>
    </View>
  );
}
