import React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={16} height={18} viewBox="0 0 16 18" {...props}>
      <Path
        d="M14.286 18H1.715A1.675 1.675 0 01.5 17.508a1.623 1.623 0 01-.5-1.2v-1.472a4.576 4.576 0 01.643-2.356 4.805 4.805 0 014.144-2.355A4.13 4.13 0 016 10.371c.33.093.606.164.823.212A6.137 6.137 0 008 10.688a6.151 6.151 0 001.178-.105c.22-.048.5-.119.822-.212a4.142 4.142 0 011.214-.246 4.8 4.8 0 014.143 2.355A4.569 4.569 0 0116 14.836v1.477a1.621 1.621 0 01-.5 1.2 1.671 1.671 0 01-1.214.487zm-9.5-6.75a3.553 3.553 0 00-2.572 1.05 3.441 3.441 0 00-1.071 2.531v1.477a.545.545 0 00.16.4.562.562 0 00.411.158h12.572a.558.558 0 00.41-.158.545.545 0 00.161-.4v-1.472a3.438 3.438 0 00-1.072-2.536 3.549 3.549 0 00-2.571-1.055 3.621 3.621 0 00-.893.176A7.7 7.7 0 018 11.813a7.688 7.688 0 01-2.321-.386 3.621 3.621 0 00-.893-.177zM8 9a4.611 4.611 0 01-2.3-.6 4.452 4.452 0 01-2.271-3.9A4.452 4.452 0 015.7.6a4.739 4.739 0 014.6 0 4.45 4.45 0 012.268 3.9A4.45 4.45 0 0110.3 8.4 4.6 4.6 0 018 9zm0-7.875a3.343 3.343 0 00-2.428.985 3.242 3.242 0 00-1 2.39 3.242 3.242 0 001 2.39A3.343 3.343 0 008 7.875a3.343 3.343 0 002.428-.985 3.242 3.242 0 001-2.39 3.242 3.242 0 00-1-2.39A3.343 3.343 0 008 1.125z"
        fill="rgba(27,117,187,.8)"
      />
    </Svg>
  );
}

export default SvgComponent;
