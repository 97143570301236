export const camelCaseListing = (listing) => {
  const { mls_id, appointment_id, ...rest } = listing;
  return {
    ...rest,
    mlsID: mls_id,
    appointmentID: appointment_id,
  };
};

export const snakeCaseListing = (listing) => {
  const { mlsID, appointmentID, ...rest } = listing;
  return {
    ...rest,
    mls_id: mlsID,
    appointment_id: appointmentID,
  };
};
