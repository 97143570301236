import { BASE_URL, doGet, doPost, doPut } from "../api";

export const getAddressByMLSID = (mlsID) => {
  return doGet(BASE_URL + `addresses/${mlsID}`);
};

export const createListing = (listing) => {
  return doPost(BASE_URL + "listings", listing);
};
export const createListingForUnitNoApi = (listing) => {
  return doPost(BASE_URL + "listings/update-unit-number", {
    listing_unit_numbers: listing,
  });
};

export const updateListing = (listingID, appointmentID) => {
  return doPut(`${BASE_URL}listings/${listingID}`, {
    appointment_id: appointmentID,
  });
};

export const updateNewListing = (listingID, appointmentID) => {
  var lst = listingID.toString();
  return doPut(`${BASE_URL}listings/${lst}`, {
    appointment_id: appointmentID,
    id: listingID,
  });
};

export const getListingsForAppointment = (appointmentID) => {
  return doGet(`${BASE_URL}listings/?appointment_id=${appointmentID}`);
};

export const getRunnerActivityList = () => {
  return doGet(BASE_URL + `RunnerActivityList`);
};
