import { StyleSheet, Dimensions } from "react-native";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#FFFFFF",
  },
  mainTitle: {
    fontSize: 24,
    color: "#518AC1",
    paddingTop: 15,
    fontWeight: "bold",
    textAlign: "center",
  },
  outerSection: {
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
  favoriteOuter: {
    height: 72,
    width: 72,
    backgroundColor: "#ECF1F5",
    borderRadius: 36,
    justifyContent: "center",
    alignItems: "center",
  },
  subTitleTextOuter: {
    paddingTop: 12.5,
  },
  subTitle: {
    fontSize: 16,
    color: "#63696F",
    fontWeight: "400",
  },
  createListButton: {
    width: 160,
    marginTop: 30,
  },
  howItWorksText: {
    color: "#CD7047",
    textDecorationLine: "underline",
    fontSize: 18,
    fontWeight: "bold",
    paddingTop: 40,
  },
  closeOuter: {
    position: "absolute",
    top: 10,
    right: 10,
  },
  firstSection: {
    marginBottom: 20,
  },
  secondSection: {
    marginHorizontal: 15,
    marginBottom: 15,
  },
  iconTitleOuter: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#E2E9EE",
    paddingVertical: 10,
  },
  lastIconTitleOuter: {
    borderBottomColor: "transparent",
  },
  iconOuter: {
    flexDirection: "column",
    width: 45,
    paddingTop: 5,
    alignItems: "center",
  },
  titleOuter: {
    flexDirection: "column",
    flex: 1,
  },
  titleText: {
    color: "#63696F",
    fontWeight: "bold",
    fontSize: 16,
  },
  subTitleText: {
    color: "rgba(99,105,111,0.5)",
    fontSize: 14,
  },
  listOuterSection: {
    marginHorizontal: 20,
  },
  listTitle: {
    fontSize: 28,
    color: "#518AC1",
    fontWeight: "bold",
  },
  addAgentButton: {
    width: 160,
    marginVertical: 30,
  },
  addAgentButtonOuter: {
    alignItems: "center",
  },
  agentListOuter: {
    height: Dimensions.get("window").height - 200,
  },
  agentListOuterForSearchSection: {
    height: Dimensions.get("window").height - 230,
  },
  agentOuter: {
    flexDirection: "row",
    alignItems: "center",
    borderBottomColor: "#E2E9EE",
    borderBottomWidth: 1,
    paddingVertical: 10,
  },
  lastAgentOuter: {
    borderBottomColor: "transparent",
  },
  agentNameEmailOuter: {
    flexDirection: "column",
    flex: 1,
    marginLeft: 10,
  },
  agentNameText: {
    fontSize: 16,
    color: "#63696F",
    fontWeight: "bold",
  },
  agentEmailText: {
    fontSize: 14,
    color: "rgba(99,105,111,0.5)",
  },
  agentFavoriteSection: {
    flexDirection: "column",
    alignItems: "flex-end",
    marginRight: 10,
  },
  inviteText: {
    fontSize: 14,
    color: "#EC8F64",
    textDecorationLine: "underline",
    fontWeight: "500",
  },
  invitedText: {
    color: "#808080",
  },
  moreSelected: {
    width: 44,
    height: 44,
    borderRadius: 22,
    backgroundColor: "#F2F2F2",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 5,
  },
  moreOuter: {
    width: 44,
    height: 44,
    borderRadius: 22,
    justifyContent: "center",
    alignItems: "center",
    marginRight: 5,
  },
  notificationTopOuter: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 15,
  },
  notificationTitleOuter: {
    flex: 1,
  },
  searchMainOuter: {
    padding: 20,
    backgroundColor: "#215F90",
  },
  searchOuter: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#F2F7FB",
    paddingHorizontal: 10,
    borderRadius: 5,
  },
  searchTextOuter: {
    flexDirection: "column",
    flex: 1,
  },
  searchIconOuter: {
    flexDirection: "column",
    height: 50,
    justifyContent: "center",
  },
  notificationItemOuter: {
    flexDirection: "row",
    marginTop: 15,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: "#E2E9EE",
  },
  notificationSelectedItemOuter: {
    flexDirection: "row",
    marginTop: 15,
    borderRadius: 10,
    borderWidth: 2,
    borderColor: "#EC8F64",
  },
  directionFlexRow: {
    flexDirection: "row",
  },
  notificationIconOuter: {
    marginHorizontal: 15,
  },
  notificationDetailsOuter: {
    width: Dimensions.get("window").width - 161,
  },
  notificationTitle: {
    color: "#518AC1",
    fontSize: 16,
    fontWeight: "bold",
  },
  notificationReadTitle: {
    color: "#000002",
    fontSize: 14,
    fontWeight: "normal",
    marginBottom: 5,
  },
  notificationDescriptionOuter: {
    marginBottom: 5,
  },
  notificationDescription: {
    color: "#63696F",
    fontSize: 12,
  },
  notificationDefaultDescription: {
    color: "#63696F",
    fontSize: 12,
    fontWeight: "bold",
  },
  notificationReadDescription: {
    color: "rgba(99,105,111,0.5)",
    fontSize: 12,
  },
  notificationTime: {
    color: "rgba(99,105,111,0.5)",
    fontSize: 12,
    fontStyle: "italic",
  },
  moreSectionOuter: {
    height: "100%",
  },
  moreSectionInner: {
    position: "absolute",
    right: 20,
    backgroundColor: "#FFFFFF",
    borderWidth: 1,
    borderRadius: 10,
    borderColor: "rgba(226,232,238,0.23)",
    shadowColor: "rgba(0,0,0,0.07)",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  moreSectionInnerForSearchSection: {
    position: "absolute",
    top: 210,
    right: 20,
    backgroundColor: "#FFFFFF",
    borderWidth: 1,
    borderRadius: 10,
    borderColor: "rgba(226,232,238,0.23)",
    shadowColor: "rgba(0,0,0,0.07)",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  moreInnerSection: {
    marginHorizontal: 15,
    borderBottomWidth: 1,
    borderBottomColor: "#E2E9EE",
    paddingVertical: 20,
    paddingRight: 40,
  },
  lastMoreInnerSection: {
    marginHorizontal: 15,
    borderBottomWidth: 1,
    borderBottomColor: "transparent",
    paddingVertical: 20,
  },
  moreOptionText: {
    fontSize: 14,
    color: "#63696F",
    fontWeight: "bold",
  },
  scrollableFirstSection: {
    flexDirection: "row",
    alignItems: "center",
    borderRadius: 10,
    paddingVertical: 10,
  },
  scrollableSecondSection: {
    backgroundColor: "#EC4D2C",
    justifyContent: "center",
    alignItems: "center",
    width: 75,
    paddingTop: 15,
    paddingBottom: 15,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    paddingLeft: 10,
  },
  deleteFirstSection: {
    position: "absolute",
    height: "200%",
    width: 10,
    backgroundColor: "#FFFFFF",
    left: 0,
    borderRightWidth: 1,
    borderRightColor: "#E2E9EE",
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
  },
  deleteText: {
    fontSize: 12,
    color: "#FFFFFF",
    marginTop: 5,
  },
  errorText: {
    fontSize: 15,
    color: "#DC612F",
    margin: 10,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    borderRadius: 10,
    marginTop: 20,
    borderColor: "#DC612F",
    borderWidth: 1,
  },
  addCardSection: {
    flexDirection: "row",
    marginTop: 15,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F0F6FC",
    paddingVertical: 15,
    borderRadius: 10,
  },
  addCardText: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#63696F",
    paddingLeft: 5,
  },
  newCardSectionOuter: {
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.5)",
    justifyContent: "center",
  },
  newCardSection: {
    marginHorizontal: 10,
    backgroundColor: "#FFFFFF",
    padding: 25,
    borderRadius: 10,
  },
  closeModalOuter: {
    position: "absolute",
    top: 15,
    right: 15,
  },
  modalTitle: {
    fontSize: 20,
    fontWeight: "bold",
    color: "#518AC1",
    textAlign: "center",
  },
  modalDeclineTitle: {
    fontSize: 20,
    fontWeight: "bold",
    color: "#EC8F64",
    textAlign: "center",
  },
  declineContentSection: {
    marginTop: 10,
    marginBottom: 20,
  },
  declineText: {
    fontSize: 16,
    color: "#63696F",
    textAlign: "center",
  },
  modalContentSection: {
    backgroundColor: "#F0F6FC",
    padding: 15,
    borderRadius: 10,
    marginVertical: 20,
  },
  payoutContentSection: {
    paddingHorizontal: 35,
    paddingTop: 25,
  },
  payoutOptionOuter: {
    flexDirection: "row",
    marginBottom: 25,
  },
  radioButtonRound: {
    width: 25,
    height: 25,
    borderColor: "#EC8F64",
    borderWidth: 2,
    borderRadius: 12.5,
    flexDirection: "column",
    marginRight: 15,
    marginTop: 5,
    alignItems: "center",
    justifyContent: "center",
  },
  radioButtonInner: {
    width: 10,
    height: 10,
    borderRadius: 5,
    backgroundColor: "#EC8F64",
  },
  payoutOptionTextOuter: {
    flex: 1,
  },
  payoutOptionText: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#63696F",
  },
  inputIconOuter: {
    flexDirection: "row",
    alignItems: "center",
    borderBottomWidth: 1,
    borderBottomColor: "#545D9E",
  },
  errorInputIconOuter: {
    flexDirection: "row",
    alignItems: "center",
    borderBottomWidth: 1,
    borderBottomColor: "#EC4D2C",
  },
  modalInputOuter: {
    flex: 1,
  },
  modalInput: {
    color: "#000000",
    fontSize: 14,
    height: 36,
  },
  errorModalInput: {
    color: "#EC4D2C",
    fontSize: 14,
    height: 36,
  },
  errorMessage: {
    color: "#EC4D2C",
    fontSize: 10,
  },
  modalButtonsOuter: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  modalCancelButton: {
    width: 160,
  },
  cancelText: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#63696F",
    textAlign: "center",
  },
  modalSaveButton: {
    width: 160,
  },
  modalInputRightText: {
    fontWeight: "900",
    fontSize: 14,
    color: "#518AC1",
  },
  requestButtonOuter: {
    alignItems: "center",
  },
  requestButton: {
    width: 180,
  },
});

export default styles;
