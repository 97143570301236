import {
  SET_LOADING,
  RECEIVE_LOGIN_USER,
  SET_BROKERAGES_LIST,
  SET_CURRENT_USER,
  SET_PASSWORD_FIELD_DISABLED,
  SET_USER_PAYOUT_INFO_PRESENT,
  SET_USER_PAYMENT_INFO_PRESENT,
  SET_FETCHED_USER_INFO_BEFORE_LOGIN,
  SET_FETCHED_USER_INFO,
  SET_STRIPE_LOGIN_URL,
  SET_RESET_FETCHED_USER_INFO_FLAG,
  SET_USERS,
  SET_LOGININ_USER,
  SET_REMEMBER_ME,
  LOGOUT_USER,
  SET_PUSHNOTIFIACTION_TOKEN,
} from "./actions";

const initialState = {
  loading: false,
  passwordDisable: true,
  isUserStripePayoutInfo: false,
  isUserStripePaymentInfo: false,
  loggedIn: false,
  loggedInUser: false,
  rememberMe: true,
  user: {},
  stripeLoginURL: null,
  fetchedUserInfo: false,
  byID: {},
  byBrokerageID: {},
  pushnotificationToken: {},
  brokeragesList: [],
  usersList: [],
};

// Reducers (Modifies The State And Returns A New State)
const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGOUT_USER: {
      return initialState;
    }
    case SET_LOADING: {
      return {
        ...state,
        loading: action.loading,
      };
    }

    case SET_PASSWORD_FIELD_DISABLED: {
      return {
        ...state,
        passwordDisable: action.passwordDisable,
      };
    }
    case SET_USER_PAYOUT_INFO_PRESENT: {
      return {
        ...state,
        isUserStripePayoutInfo: action.isUserStripePayoutInfo,
      };
    }
    case SET_USER_PAYMENT_INFO_PRESENT: {
      return {
        ...state,
        isUserStripePaymentInfo: action.isUserStripePaymentInfo,
      };
    }

    case SET_LOGININ_USER: {
      return {
        ...state,
        loggedInUser: action.loggedInUser,
      };
    }
    case SET_REMEMBER_ME: {
      return {
        ...state,
        rememberMe: action.rememberMe,
      };
    }
    case RECEIVE_LOGIN_USER: {
      return {
        ...state,
        loggedIn: true,
        user: action.user,
        loading: false,
      };
    }
    case SET_CURRENT_USER: {
      return {
        ...state,
        user: {
          ...state.user,
          ...action.user,
        },
      };
    }
    case SET_FETCHED_USER_INFO_BEFORE_LOGIN: {
      return {
        ...state,
        fetchedUserInfo: action.fetchedUserInfo,
        // This is kinda a hack to get the auth headers to work
        // loggedIn: true,
      };
    }
    case SET_FETCHED_USER_INFO: {
      return {
        ...state,
        fetchedUserInfo: action.fetchedUserInfo,
        // This is kinda a hack to get the auth headers to work
        loggedIn: true,
      };
    }
    case SET_RESET_FETCHED_USER_INFO_FLAG: {
      return initialState;
    }
    case SET_STRIPE_LOGIN_URL: {
      return {
        ...state,
        stripeLoginURL: action.url,
      };
    }

    case SET_PUSHNOTIFIACTION_TOKEN: {
      return {
        ...state,
        pushnotificationToken: action.token,
      };
    }
    case SET_BROKERAGES_LIST: {
      return {
        ...state,
        brokeragesList: action.brokeragesList,
      };
    }

    case SET_USERS: {
      let byID = state.byID;
      let byBrokerageID = state.byBrokerageID;
      let usersList = action.users;
      action.users.map((user) => {
        byID = {
          ...byID,
          [user.id]: user,
        };

        byBrokerageID = {
          ...byBrokerageID,
          [user.brokerageID]: [
            ...(byBrokerageID[user.brokerageID] || []).filter(
              (id) => id !== user.id
            ),
            user.id,
          ],
        };
      });

      return {
        ...state,
        byID,
        byBrokerageID,
        usersList,
      };
    }
    // Default
    default: {
      return state;
    }
  }
};

export default authReducer;
