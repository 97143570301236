import React from "react";
import {
  View,
  Text,
  ImageBackground,
  ScrollView,
  Platform,
  TouchableOpacity,
  KeyboardAvoidingView,
  TextInput,
  Dimensions,
  Linking,
  Image,
  Alert,
} from "react-native";
import styles from "../Styles/LoginStyles";
import { ButtonOne } from "../Components/Button";
// import Mail from "../Images/Mail";
import BaseLayout from "./BaseLayout";
const { width } = Dimensions.get("window");
import { requestResetPassword } from "../redux/auth/actions";
import { connect } from "react-redux";
// import NavigationService from "../NavigationService";
import LogsService from "../LogsService";

class ForgotPassword extends React.Component {
  static navigationOptions = ({ navigation }) => ({
    header: null,
  });

  constructor(props) {
    super(props);
    this.state = {
      email: "",
    };
    LogsService.fileLog("ForgotPassword", "constructor", "screen initiated");
  }
  requestResetPassword(email) {
    LogsService.fileLog(
      "ForgotPassword",
      "requestResetPassword",
      "Request for reset password for email " + email
    );
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(email.trim()).toLowerCase())) {
      Alert.alert("", "Please enter a valid email address", [
        {
          text: "OK",
          onPress: () => {
            LogsService.fileLog(
              "ForgotPassword",
              "onPress",
              "Please enter a valid email address"
            );
          },
        },
      ]);
    } else {
      this.state.email = email.trim().toLowerCase();
      LogsService.fileLog(
        "ForgotPassword",
        "requestResetPassword",
        "requestResetPassword is calling"
      );
      this.props.requestResetPassword(this.state.email);
    }
    //
  }

  render() {
    return (
      <BaseLayout>
        <View style={{ height: "100%", width: "100%" }}>
          <ImageBackground
            source={require("../../assets/loginBg1.png")}
            style={styles.bgImage}
          >
            <KeyboardAvoidingView
              style={{ flex: 9, flexDirection: "column" }}
              behavior={Platform.OS === "ios" ? "padding" : null}
              enabled
              keyboardVerticalOffset={1}
            >
              <ScrollView
                style={{ paddingHorizontal: 10 }}
                keyboardShouldPersistTaps={"always"}
              >
                <View
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 50,
                  }}
                >
                  <Image
                    source={require("../../assets/KeyPleazVertical.png")}
                    style={{
                      width: width * 0.5,
                      height: width * 0.5,
                      flex: 1,
                      resizeMode: "contain",
                    }}
                  ></Image>
                </View>
                <View
                  style={{
                    height: 280,
                    backgroundColor: "#FFFFFF",
                    width: "90%",
                    borderColor: "#D1E3F1",
                    borderRadius: 25,
                    borderWidth: 1,
                    marginLeft: "5%",
                    marginTop: "15%",
                    marginBottom: "5%",
                  }}
                >
                  <View style={{ margin: "5%" }}>
                    <Text>
                      Enter your email and you'll receive a password reset code.
                    </Text>
                  </View>

                  <View style={{ margin: "5%" }}>
                    <View style={styles.textView}>
                      <TextInput
                        style={styles.inputText}
                        placeholder="andre@brokerage.com"
                        placeholderTextColor="black"
                        onChangeText={(email) => this.setState({ email })}
                        value={this.state.email}
                        autoCapitalize="none"
                      />
                      {/* <Mail style={{ marginTop: -25 }} /> */}
                    </View>
                  </View>

                  <View style={styles.btnView}>
                    <ButtonOne
                      text={"Reset Password"}
                      style={styles.btnWidth}
                      onPress={() => {
                        LogsService.fileLog(
                          "ForgotPassword",
                          "onPress",
                          "Reset Password is clicked"
                        );
                        this.requestResetPassword(this.state.email);
                      }}
                    />
                  </View>
                  <TouchableOpacity
                    onPress={() => {
                      LogsService.fileLog(
                        "ForgotPassword",
                        "onPress",
                        "Login is clicked and navigating to Login screen"
                      );
                      this.props.navigation.navigate("Login");
                    }}
                  >
                    <Text style={styles.forgetTxt}>Login?</Text>
                  </TouchableOpacity>
                </View>

                <View style={{ height: 100 }}>
                  <TouchableOpacity
                    style={{ flexDirection: "row", alignSelf: "center" }}
                    onPress={() => {
                      LogsService.fileLog(
                        "ForgotPassword",
                        "onPress",
                        "Learn More. is clicked and navigating to http://www.keypleaz.com"
                      );
                      Linking.openURL("http://www.keypleaz.com");
                    }}
                  >
                    <Text style={([styles.bottomTxt], { color: "#A3C7E3" })}>
                      What is KeyPleaz®?{" "}
                    </Text>
                    <Text style={([styles.bottomTxt], { color: "#FFFFFF" })}>
                      Learn More.
                    </Text>
                  </TouchableOpacity>
                </View>
              </ScrollView>
            </KeyboardAvoidingView>
          </ImageBackground>

          <View style={styles.bottomView}>
            <TouchableOpacity
              style={{ flexDirection: "row", alignSelf: "center" }}
              onPress={() => {
                LogsService.fileLog(
                  "ForgotPassword",
                  "onPress",
                  "Sign up here is clicked and navigating to WelcomeScreen"
                );
                this.props.navigation.navigate("WelcomeScreen");
              }}
            >
              <View style={{ flexDirection: "row" }}>
                <Text style={[styles.bottomViewTxt, { color: "#A3C7E3" }]}>
                  Don’t have an account?{" "}
                </Text>
                <Text
                  style={[
                    styles.bottomViewTxt,
                    {
                      fontFamily: "RobotoCondensed-Bold",
                      color: "#FFFFFF",
                      fontWeight: "700",
                      fontSize: 18,
                      marginTop: "-0.7%",
                    },
                  ]}
                >
                  Sign up here
                </Text>
              </View>
            </TouchableOpacity>
          </View>
        </View>
      </BaseLayout>
    );
  }
}

export default connect(
  (state) => ({}),
  (dispatch) => ({
    requestResetPassword: (email) => {
      dispatch(requestResetPassword(email)).then((r) => {
        LogsService.fileLog(
          "ForgotPassword",
          "dispatch(requestResetPassword(email) -- respomse => ",
          r
        );
        if (r.status === "ok" || r.status === 200) {
          LogsService.fileLog(
            "ForgotPassword",
            "dispatch(requestResetPassword(email) => ",
            "Navigating to ResetPassword Screen"
          );
          // NavigationService.navigate("ResetPassword", { email });
        }
      });
    },
  })
)(ForgotPassword);
