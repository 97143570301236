import { StyleSheet, Dimensions } from "react-native";
const { height } = Dimensions.get("window");

const styles = StyleSheet.create({
  btnIAm: {
    color: "#FFFFFF",
    fontStyle: "italic",
    fontSize: 13,
    fontFamily: "RobotoCondensed-Regular",
    lineHeight: 15,
    opacity: 0.5,
  },
  btnAgent: {
    color: "#FFFFFF",
    alignSelf: "center",
    fontFamily: "RobotoCondensed-Regular",
    fontSize: 17,
    lineHeight: 18,
  },
  headerImage: {
    height: 80,
    alignSelf: "center",
  },
  bgImage: {
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  welcomeText: {
    color: "#A3C7E3",
    fontSize: 24,
  },
  startedText: {
    color: "#FFFFFF",
    fontSize: 24,
  },
  viewCard: {
    width: "90%",
    backgroundColor: "white",
    borderRadius: 15,
    borderWidth: 1,
    borderColor: "#ffffff",
    marginLeft: "5%",
    marginTop: "5%",
    justifyContent: "center",
  },
  signupText: {
    marginTop: "5%",
    color: "#4990C8",
    fontSize: 32,
    alignSelf: "center",
    fontWeight: "bold",
    fontFamily: "RobotoCondensed-Bold",
  },
  imgStyles: {
    flexDirection: "row",
    justifyContent: "space-around",
    height: height / 8,
    marginTop: "5%",
    paddingLeft: "10%",
    width: "100%",
  },
  bottomView: {
    backgroundColor: "#4990C8",
    height: 50,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute", //Here is the trick
    bottom: 0,
  },
  termStyles: {
    color: "#FFFFFF",
    fontSize: 20,
    alignSelf: "center",
    fontFamily: "RobotoCondensed-Regular",
    textAlignVertical: "center",
  },
  btnIAm: {
    color: "#FFFFFF",
    fontStyle: "italic",
    fontSize: 13,
    fontFamily: "RobotoCondensed-Regular",
    lineHeight: 15,
    opacity: 0.5,
  },
  btnAgent: {
    color: "#FFFFFF",
    alignSelf: "center",
    fontFamily: "RobotoCondensed-Regular",
    fontSize: 17,
    lineHeight: 18,
  },
  btnAgentBold: {
    color: "#FFFFFF",
    alignSelf: "center",
    fontFamily: "RobotoCondensed-Bold",
    fontSize: 18,
    fontWeight: "700",
    lineHeight: 20,
  },
  btnAgentBoldBig: {
    color: "#FFFFFF",
    alignSelf: "center",
    fontFamily: "RobotoCondensed-Bold",
    fontSize: 19,
    fontWeight: "700",
    lineHeight: 20,
  },
  buttonText: {
    fontSize: 15,
    fontFamily: "Gill Sans",
    textAlign: "center",
    color: "#ffffff",
    backgroundColor: "transparent",
  },
  lineargradientBtn: {
    padding: 3,
    borderRadius: 30,
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 2,
    borderColor: "white",
    borderTopWidth: 0,
    shadowColor: "#2E363D99",
    shadowOffset: { width: 5, height: 5 },
    shadowOpacity: 0.8,
    shadowRadius: 10,
    elevation: 6,
    paddingVertical: 10,
    marginBottom: 10,
  },
  lineargradientBtn2: {
    height: 50,
    borderRadius: 30,
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 2,
    borderColor: "white",
    borderTopWidth: 0,
    shadowColor: "#2E363D99",
    shadowOffset: { width: 5, height: 5 },
  },
  lineargradientBtnSmall: {
    height: 30,
    borderRadius: 10,
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 1,
    borderColor: "white",
    borderTopWidth: 0,
    shadowColor: "#2E363D99",
    shadowOffset: { width: 1, height: 1 },
  },
  btnView: {
    flexDirection: "row",
    justifyContent: "space-around",
    marginTop: "8%",
  },
  widthStyle: {
    width: 135,
  },
});

export default styles;
