import { StyleSheet, Platform } from "react-native";
import Colors from "../../assets/colors";

const styles = StyleSheet.create({
  headerImage: {
    height: 80,
    alignSelf: "center",
  },
  mainView: {
    marginTop: 5,
    marginHorizontal: 10,
  },
  heading: {
    fontFamily: Platform === "ios" ? "RobotoCondensed" : "Arial",
    color: Colors.text,
    fontSize: 25,
    fontWeight: "bold",
    marginLeft: 10,
    marginVertical: 10,
  },
  textInput: {
    color: Colors.textInput,
    fontSize: 15,
  },
  textInputIcon: {
    alignSelf: "flex-end",
    marginRight: 5,
    marginBottom: 15,
  },
  backgroundView: {
    backgroundColor: Colors.backgroundBlue,
    borderRadius: 10,
    padding: 10,
  },
  backgroundViewNotes: {
    backgroundColor: Colors.backgroundBlue,
    borderRadius: 10,
    margin: 20,
    marginTop: 10,
  },
  linearGradient: {
    width: 150,
    borderRadius: 25,
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 2,
    borderColor: "white",
    borderBottomWidth: 0,
    shadowColor: "#2E363D99",
    shadowOffset: { width: 5, height: 5 },
    shadowOpacity: 0.8,
    shadowRadius: 10,
    elevation: 6,
    marginVertical: 10,
  },
  buttonText: {
    fontSize: 15,
    fontFamily: "Gill Sans",
    textAlign: "center",
    margin: 10,
    color: "#ffffff",
    backgroundColor: "transparent",
  },
  btnWidth: {
    width: 140,
  },
  borderView: {
    flexDirection: "row",
    borderBottomColor: Colors.text,
    borderBottomWidth: 1,
    marginHorizontal: 5,
    marginTop: 5,
  },
  inputTextArea: {
    height: 80,
    color: "#292B34",
    marginLeft: 15,
    marginRight: 15,
    marginBottom: 15,

    borderBottomWidth: 1,
    borderTopLeftRadius: 1,
    fontFamily: "RobotoCondensed-Light",
    fontSize: 17,
  },
});

export default styles;
