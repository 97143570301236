import moment from "moment";
import {
  SET_LOADING,
  SET_LOADING_ForCompleted,
  SET_AVAILABLE,
  EDIT_APPOINTMENT_ID,
  DISABLE_CLAIM_APPOINTMENT_ID,
  UPDATE_CURRENT,
  ADD_APPOINTMENT,
  UPDATE_APPOINTMENT,
  REMOVE_AVAILABLE,
  UPDATE_CLAIMED_APPOINTMENTS,
  ADD_AVAILABLE,
  REMOVE_CLAIMED,
  ADD_CLAIMED,
  SET_COMPLETED,
  RESET_CURRENT,
  REMOVE_APPOINTMENT,
  SET_COUNTRY_CODE,
} from "./actions";
import { camelCaseAppointment } from "./utils";

/*
  The shape of an appointment looks like:
  {
    id,
    feeCents,
    appointmentType: 'asap' | 'scheduled',
    startsAt,
    endsAt,
    assignedAgentID,
    state,
    buyerFirstName,
    buyerLastName,
    buyerPhone,
  }
*/
const defaultFee = 50;
const defaultAppointment = {
  appointmentType: "asap",
  feeCents: defaultFee * 100,
  startsAt: moment().toISOString(),
  endsAt: moment().toISOString(),
};

const initialState = {
  loading: false,
  loadingForCompleted: false,
  current: defaultAppointment,
  editAppointmentID: null,
  disableclickClaim: null,
  countryCode: "",
  // createdBy is a map where keys are userIDs
  // and values are a list of appointmentIDs
  // for appointments created by a given user
  createdBy: {},
  byID: {},
  // array of available appointment IDs
  available: [],
  // array of claimed appointment IDs
  claimed: [],
  // array of completed appointment IDs
  completed: [],
};

// Reducers (Modifies The State And Returns A New State)
const appointmentReducer = (state = initialState, action) => {
  let appointment;

  switch (action.type) {
    case SET_LOADING: {
      return {
        ...state,
        loading: action.loading,
      };
    }
    case SET_LOADING_ForCompleted: {
      return {
        ...state,
        loadingForCompleted: action.loadingForCompleted,
      };
    }
    case RESET_CURRENT: {
      return {
        ...state,
        current: defaultAppointment,
      };
    }
    case SET_COUNTRY_CODE: {
      return {
        ...state,
        countryCode: action.countryCode,
      };
    }
    case EDIT_APPOINTMENT_ID: {
      return {
        ...state,
        editAppointmentID: action.appointmentId,
      };
    }
    case DISABLE_CLAIM_APPOINTMENT_ID: {
      return {
        ...state,
        disableclickClaim: action.appointmentId,
      };
    }
    case UPDATE_CURRENT: {
      return {
        ...state,
        current: {
          ...state.current,
          ...camelCaseAppointment(action.appointment),
        },
      };
    }
    case ADD_APPOINTMENT:
      appointment = camelCaseAppointment(action.appointment);
      return {
        ...state,
        byID: {
          ...state.byID,
          [appointment.id]: appointment,
        },
        createdBy: {
          ...state.createdBy,
          [appointment.userID]: [
            ...(state.createdBy[appointment.userID] || []).filter(
              (id) => id !== appointment.id
            ),
            appointment.id,
          ],
        },
      };
    case UPDATE_APPOINTMENT:
      appointment = camelCaseAppointment(action.appointment);
      return {
        ...state,
        byID: {
          ...state.byID,
          [appointment.id]: appointment,
        },
      };
    case UPDATE_CLAIMED_APPOINTMENTS:
      return {
        ...state,
        claimed: action.ids,
      };
    case REMOVE_CLAIMED:
      return {
        ...state,
        claimed: state.claimed.filter((id) => id !== action.id),
      };
    case ADD_CLAIMED:
      return {
        ...state,
        claimed: [...state.claimed, action.id],
      };
    case SET_AVAILABLE:
      return {
        ...state,
        available: action.available,
      };
    case REMOVE_AVAILABLE:
      return {
        ...state,
        available: state.available.filter(
          (appointment) => appointment.id !== action.id
        ),
      };
    case ADD_AVAILABLE:
      return {
        ...state,
        available: [...state.available, action.id],
      };
    case SET_COMPLETED:
      return {
        ...state,
        completed: action.completed,
      };
    case REMOVE_APPOINTMENT:
      const byID = Object.assign({}, state.byID);
      delete byID[action.id];

      return {
        ...state,
        byID: byID,
        createdBy: Object.keys(state.createdBy).reduce((acc, key) => {
          acc[key] = state.createdBy[key].filter((id) => id !== action.id);
          return acc;
        }, {}),
        available: state.available.filter((id) => id !== action.id),
        claimed: state.claimed.filter((id) => id !== action.id),
        completed: state.completed.filter((id) => id !== action.id),
      };
    // Default
    default: {
      return state;
    }
  }
};

export default appointmentReducer;
